import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import 'rc-checkbox/assets/index.css';

import _ from 'underscore';

import XLSX from 'xlsx';
import FileSaver from 'file-saver';

import { confirmAlert } from 'react-confirm-alert';
import LeadDetails from '../../AdminClient/components/Modals/LeadDetails';
import * as routes from '../../routes';
import { NavBar } from '../NavBar';
import { SideBar } from '../SideBar';
import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class ProspectLeads extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      searchObj: {
        name: '',
        actionId: '',
        cpf: '',
      },
      leads: [],
      lead: {},
      actions: [],
      loadingActions: false,
      modalIsOpen: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
  }

  exporProspectLeads() {
    const { leads } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(leads, (lead) => ({
        Nome: lead.name,
        'E-mail': lead.email,
        Cidade: lead.city,
        Telefone: lead.phone,
        Empresa: lead.company,
        Cargo: lead.role,
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PublicoAlvo');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'ProspectLeads.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  loadActionList() {
    const parameters = {
      searchFunctionality: 'allActions',
      userType: 'administrator',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ ...result.data });
        console.log('loadActionList res:', res);
      })
      .catch((error) => {
        console.log('loadActionList error:', error);
      });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }

  filterList() {
    this.setState({ isLoading: true }, () => {
      const { searchObj } = this.state;

      const parameters = {
        searchFunctionality: 'getProspectLeads',
        userType: 'administrator',
      };

      if (searchObj.name || searchObj.actionId || searchObj.cpf) {
        parameters.filter = searchObj;
      }

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          console.log('result:', result);
          this.setState({ ...result.data, isLoading: false });
          console.log('getActionById res:', res);
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('getActionById error:', error);
        });
    });
  }

  renderAdvancedFilter() {
    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; {this.translate('advanced-filters')}
          </span>
          <div className="row">
            <div className="col-md-4 form-group">
              <input
                className="form-control input-oq"
                placeholder={this.translate('name')}
                name="name"
                onChange={(e) => this.handleSearchInputChange(e)}
              />
            </div>
            <div className="col-md-4 form-group">
              <button
                className="btn btn-block btn-oq pull-right"
                style={{ width: '75%' }}
                disabled={this.state.isLoading}
                onClick={() => this.filterList()}
              >
                <i className="fa fa-search" aria-hidden="true" />
                &nbsp;
                {this.state.isLoading
                  ? this.translate('Pesquisando')
                  : this.translate('Pesquisar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title-action">
          <i className="fa fa-bullseye" />
          &nbsp; {this.translate('Lista de público-alvo')}
        </span>
        <button
          className="btn btn-oq pull-right"
          onClick={() => this.exporProspectLeads()}
          disabled={_.size(this.state.leads) === 0}
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
          {this.translate('Exportar')}
        </button>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTargetPublicTable()}</div>
      </div>
    );
  }

  handleSelectedLeadChange(lead) {
    console.log('/handleSelectedLeadChange lead:', lead);
    this.setState({ lead }, () => this.toggleModal());
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  confirmConversion(playerId, lead) {
    console.log('confirmConversion playerId:', playerId);
    console.log('confirmConversion lead:', lead);
    const { player, actionId, uid } = lead;
    const { converting } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja dar a pontuação de conversão para o'
              )}{' '}
              {player.name} ?
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                disabled={converting}
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR CONVERSÃO')}
              </button>
              <button
                disabled={converting}
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.addConversion(playerId, actionId, uid);
                  onClose();
                }}
              >
                {this.translate('CANCELAR CONVERSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  addConversion(playerId, actionId, leadId) {
    const { userAuth } = this.context;
    const clientId = userAuth.uid;
    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');

      const parameters = {
        actionId,
        playerId,
        clientId,
        leadId,
      };

      api
        .post(`/conversions`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ converting: false });
          Notify(result.message, result.success ? 'success' : 'error');
          console.log('addConversion result:', result);

          if (result.success) {
            this.filterList();
          }
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('addConversion error:', error);
        });
    });
  }

  renderTargetPublicTable() {
    const data = _.values(this.state.leads);

    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'name',
        Cell: (props) => (
          <div
            className="clickable oq-link"
            onClick={() => this.handleSelectedLeadChange(props.original)}
          >
            {props.value}
          </div>
        ),
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
      },
      {
        Header: 'Cargo',
        accessor: 'role',
      },
      {
        Header: 'Empresa',
        accessor: 'company',
      },
    ];
    return (
      <ReactTable
        data={data}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum lead para ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('lines')}
      />
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          {this.renderAdvancedFilter()}
          <ToastContainer />
          <div className="oq-card-container">
            {this.renderTargetPublicList()}
          </div>
        </div>
        <LeadDetails
          closeModal={() => this.toggleModal()}
          lead={this.state.lead}
          modalIsOpen={this.state.modalIsOpen}
        />
      </div>
    );
  }
}

const ProspectLeadsPageLink = (props) => (
  <Link
    className="menu-link collapsed list-unstyled"
    to={routes.PROSPECT_LEADS_ADMIN}
  >
    <i className="fa fa-bullseye" />
    &nbsp;
    <span className="sidebarText">Prospect Leads</span>
  </Link>
);

export default ProspectLeads;

export { ProspectLeadsPageLink };
