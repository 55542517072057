import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import _ from 'lodash';
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { Link } from 'react-router-dom';
import 'react-tagsinput/react-tagsinput.css';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { api, getAPIURL } from '../../../Config';
import ModalConfirmGamification from '../../components/Modals/ModalConfirmGamification';
import ModalShowIncompletFormDialog from '../../components/Modals/ShowIncompletFormDialog';
import estrategia from '../../Images/ESTRATEGIA.png';
import grafico from '../../Images/GRAFICOO.png';
import iconProfileGamification from '../../Images/icon-profile-gamification.png';
import regras from '../../Images/REGRAS.png';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as mask from '../../../utils/Mascaras';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { List, ListItem } from '../../components/List';
import { UserDataContext } from '../../../hooks/useUserData';
import axios from 'axios';

import './style.css';

const BY_POINTS = 0;
const BY_GOALS = 1;
const NO_GAME = 2;
const WITH_GAME = 3;
const WITH_TEAM = 4;
const WITH_MGM = 5;
class Gamification extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
    this.fileSelector = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formData: {},
      formErrors: {},
      loading: false,
      currentPage: '',
      modalShowImcompletFormDialogIsOpen: false,
      // isGamified: '',
      isTeamCompetition: '',
      goalLevels: [],
      goalLevelsErrors: [],
      errorForm: false,
      showProfileIcon: false,
      currentPageSelected: '',
      modalConfirmGamificationIsOpen: false,
      stepsGamification: [],
      rules: [
        {
          minPoints: 0,
          maxPoints: 999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: '#FCDC53',
        },
        {
          minPoints: 1000,
          maxPoints: 4999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: '#7B51FC',
        },
        {
          minPoints: 5000,
          maxPoints: 9999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: '#F19755',
        },
        {
          minPoints: 10000,
          maxPoints: 19999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: '#79F7D2',
        },
        {
          minPoints: 20000,
          maxPoints: 49999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: '#555555',
        },
        {
          minPoints: 50000,
          maxPoints: 'Infinito',
          icon: 'fa-trophy',
          boost: '100',
          editable: false,
          color: Color('#002363').hex(),
        },
      ],
    };
    return initialState;
  }

  async componentDidMount() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;
    this.setState({ loading: true });

    await this.getClientByUid(clientId);
    await this.getGoalsRules();
    await this.getRules();
    await this.getStepsGamification();

    this.setState({ loading: false });
  }

  getClientByUid = async (clientId) => {
    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'administrator',
      clientId,
    };

    const res = await api.post(`/search`, parameters);

    this.setState(
      {
        isLoading: false,
        clientId,
        formData: { ...this.state.formData, ...res.data.data },
      },
      () => {
        Notify(res.data.message, res.data.success ? 'success' : 'error');
        const { gamificationType, isGamified, isTeamCompetition } =
          this.state.formData;
        if (gamificationType) {
          this.setState({
            currentPage: gamificationType === 'points' ? BY_POINTS : BY_GOALS,
          });
        }
        if (isGamified) {
          this.setState({ isGamified });
        }
        if (isTeamCompetition) {
          this.setState({ isTeamCompetition });
        }

        this.validateForm();
      }
    );
  };

  getRules = async () => {
    try {
      const parameters = {
        userType: 'client',
        searchFunctionality: 'getRules',
      };

      const response = await api.post(`/search`, parameters);

      const result = response.data;

      if (result.data && result.data.rules) {
        this.setState({ rules: result.data.rules });
      }

      Notify(result.message, result.success ? 'success' : 'error');
    } catch (error) {
      console.log('getRules error: ', error);
    }
  };

  updateRules = async () => {
    this.setState({ loading: true });
    const { rules } = this.state;
    const parameters = {
      userType: 'client',
      updateFunctionality: 'updateRules',
      rules,
    };
    const response = await api.post(`/update`, parameters);

    const result = response.data;
    Notify(result.message, result.success ? 'success' : 'error');

    this.setState({ loading: false });
  };

  async updateGamification(isGamified, isTeamCompetition) {
    this.setState({ loading: true });
    await this.updateRules();
    await this.updateClient('points', isGamified, isTeamCompetition);
    this.setState({ loading: false });
  }

  async updateClientToMgm(mgmVinculated) {
    try {
      const { clientId } = this.state;

      const parameters = {
        updateFunctionality: 'updateClientToMgm',
        userType: 'administrator',
        clientId,
        mgmVinculated: mgmVinculated,
      };

      const response = await api.post(`/update`, parameters);
      Notify(
        response.data.message,
        response.data.success ? 'success' : 'error'
      );
    } catch (error) {
      console.log('error updateClientToMgm: ', error);
    }
  }

  async updateStepsGamification() {
    try {
      let alias = this.context.clientData.clientUrl;
      let body = { brokerSteps: this.state.stepsGamification };

      let result = await api.put(`/broker_steps/client/${alias}`, body);

      Notify('Regras de Gamificação feitas!', 'success');
    } catch (error) {
      Notify('Não foi possível configurar!', 'error');
      console.log('error updateStepsGamification: ', error);
    }
  }

  async getStepsGamification() {
    try {
      let alias = this.context.clientData.clientUrl;

      let result = await api.get(`/broker_steps/client/${alias}`);

      if (result.data !== '') {
        this.setState({ stepsGamification: result.data });
      }

      Notify('Regras de Gamificação obtidas!', 'success');
    } catch (error) {
      Notify('Não foi possível obter as configurações!', 'error');
      console.log('error getStepsGamification: ', error);
    }
  }

  updateClient = async (gamificationType, isGamified, isTeamCompetition) => {
    try {
      const client = this.state.formData;
      client.gamificationType = gamificationType;
      client.isGamified = isGamified;
      client.isTeamCompetition = isTeamCompetition;

      const { clientId } = this.state;

      client.brokerSteps = this.state.stepsGamification;

      const parameters = {
        updateFunctionality: 'updateClient',
        userType: 'administrator',
        clientId,
        client,
      };

      const response = await api.post(`/update`, parameters);
      Notify(
        response.data.message,
        response.data.success ? 'success' : 'error'
      );
    } catch (error) {
      console.log('updateClient error: ', error);
    }
  };

  async getGoalsRules() {
    const parameters = {
      userType: 'client',
      searchFunctionality: 'getGoalsLevels',
    };
    const response = await api.post(`/search`, parameters);
    const goalLevels =
      response.data && response.data.data ? response.data.data : [];
    this.setState({ goalLevels });
    this.updateFormData('levelAmount', _.size(goalLevels));
  }

  handleRuleChange(e, ruleIndex) {
    const { rules } = this.state;
    const nextRule = rules[ruleIndex + 1];
    const { name, value } = e.target;

    this.handleRuleDataChange(name, Number(value), ruleIndex);
    this.handleRuleDataChange('minPoints', Number(value) + 1, ruleIndex + 1);

    if (parseInt(value) + 1 >= nextRule.maxPoints) {
      document.getElementById(ruleIndex + 1).className =
        'form-group col-md-3 has-error';
      document.getElementById(`error-${ruleIndex + 1}`).className =
        'help-block';
      this.setState({ errorForm: true });
    } else {
      document.getElementById(ruleIndex + 1).className = 'form-group col-md-3';
      document.getElementById(`error-${ruleIndex + 1}`).className =
        'help-block hidden';
      this.setState({ errorForm: false });
    }
  }

  handleRuleChangeOut(e, ruleIndex, minValue) {
    const { name, value } = e.target;
    if (name === 'maxPoints' && value < minValue + 1) {
      document.getElementById(ruleIndex).className =
        'form-group col-md-3 has-error';
      document.getElementById(`error-${ruleIndex}`).className = 'help-block';
      this.setState({ errorForm: true });
    } else {
      document.getElementById(ruleIndex).className = 'form-group col-md-3';
      document.getElementById(`error-${ruleIndex}`).className =
        'help-block hidden';
      this.setState({ errorForm: false });
    }
  }

  handleRuleDataChange(name, value, ruleIndex) {
    const { rules } = this.state;
    const rule = rules[ruleIndex];
    rule[name] = value;
    rules[ruleIndex] = rule;
    this.setState({ rules });
  }

  renderRule(rule, ruleIndex) {
    const { loading, rules } = this.state;
    const previousRulesMaxPoints =
      ruleIndex === 0 ? 0 : rules[ruleIndex - 1].maxPoints;

    // Defindo o valor mínimo aceitável
    const minValue = previousRulesMaxPoints + 1;

    return (
      <div className="row" key={ruleIndex}>
        <div
          className="form-group col-md-1"
          style={{ textAlignLast: 'center' }}
        >
          <span>{ruleIndex + 1}</span>
        </div>
        <div
          className="form-group col-md-1"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{
              backgroundColor: this.getInitialState().rules[ruleIndex].color,
              height: 34,
              width: 34,
              borderRadius: 3,
            }}
          />
        </div>
        <div
          className="form-group col-md-2"
          style={{ textAlignLast: 'center' }}
        >
          <input
            className="form-control profile-placeholder"
            type="number"
            name="minPoints"
            onChange={(e) => this.handleRuleChange(e, ruleIndex)}
            value={Number(previousRulesMaxPoints) + 1}
            disabled
          />
        </div>
        <div
          className="form-group col-md-2"
          id={ruleIndex}
          style={{ textAlignLast: 'center' }}
        >
          <input
            className="form-control profile-placeholder"
            type={rule.maxPoints === 'Infinito' ? 'text' : 'number'}
            name="maxPoints"
            onChange={(e) => this.handleRuleChange(e, ruleIndex)}
            value={rule.maxPoints}
            disabled={!rule.editable || loading}
            onBlur={(e) => this.handleRuleChangeOut(e, ruleIndex, minValue)}
          />
          <span id={`error-${ruleIndex}`} className="help-block hidden">
            Valor não pode ser inferior a {minValue + 1}
          </span>
        </div>

        <div
          className="form-group col-md-2"
          style={{ textAlignLast: 'center' }}
        >
          <div className="input-group">
            <input
              className="form-control profile-placeholder"
              type="number"
              name="boost"
              onChange={(e) => this.handleRuleChange(e, ruleIndex)}
              value={rule.boost}
              disabled={loading}
            />
            <div className="input-group-addon profile-add-on">%</div>
          </div>
        </div>
      </div>
    );
  }

  renderRuleHeader() {
    return (
      <div className="row">
        <div
          className="form-group col-md-1"
          style={{ textAlignLast: 'center' }}
        >
          <label className="profile-label">Nível</label>
        </div>
        <div
          className="form-group col-md-1"
          style={{ textAlignLast: 'center' }}
        >
          <label className="profile-label">Cor</label>
        </div>
        <div
          className="form-group col-md-2"
          style={{ textAlignLast: 'center' }}
        >
          <label className="profile-label">De (pts)</label>
        </div>
        <div
          className="form-group col-md-2"
          style={{ textAlignLast: 'center' }}
        >
          <label className="profile-label">Até (pts)</label>
        </div>
        <div className="form-group col-md-2">
          <label className="profile-label">Regra de Pontuação</label>
        </div>
      </div>
    );
  }

  toggleModalConfirmGamification(currentPage) {
    if (currentPage) this.setState({ currentPageSelected: currentPage });

    this.setState({
      modalConfirmGamificationIsOpen:
        !this.state.modalConfirmGamificationIsOpen,
    });
  }

  async gamificationConfigs(currentPage) {
    await this.setGamificationConfigs(currentPage);

    const { gamificationType, isGamified, isTeamCompetition } = this.state;

    if (currentPage === WITH_MGM) {
      const { formData } = this.state;

      this.setState(
        {
          loading: true,
          formData: {
            ...formData,
            mgmVinculated: 'true',
          },
        },
        async () => {
          await this.updateFormData(
            'mgmVinculated',
            this.state.formData.mgmVinculated
          );

          await this.updateClientToMgm(this.state.formData.mgmVinculated);
        }
      );
      this.setState({ loading: false });
    }

    if (
      currentPage === BY_GOALS ||
      currentPage === BY_POINTS ||
      currentPage === WITH_GAME ||
      currentPage === WITH_TEAM ||
      currentPage === NO_GAME
    ) {
      this.setState({ loading: true }, async () => {
        await this.updateFormData('gamificationType', gamificationType);
        await this.updateClient(
          gamificationType,
          isGamified,
          isTeamCompetition
        );
      });
      this.setState({ loading: false });
    }
  }

  showAlertDialog = () => {
    let currentPageText;
    const { currentPageSelected } = this.state;

    if (currentPageSelected === WITH_GAME) {
      currentPageText = this.translate(
        'uma vez que a modalidade for definida como COM GAMIFICATION, ela não poderá ser alterada. Tem certeza que deseja prosseguir no modo COM GAMIFICATION?'
      );
    } else if (currentPageSelected === NO_GAME) {
      currentPageText = this.translate(
        'uma vez que a modalidade for definida como SEM GAMIFICATION, ela não poderá ser alterada. Tem certeza que deseja prosseguir no modo SEM GAMIFICATION?'
      );
    } else if (currentPageSelected === BY_POINTS) {
      currentPageText = this.translate(
        'o modo de pontuação, depois de salvo, não poderá mais ser alterado. Tem certeza que deseja prosseguir com a definição do modo POR PONTOS?'
      );
    } else if (currentPageSelected === BY_GOALS) {
      currentPageText = this.translate(
        'o modo de pontuação, depois de salvo, não poderá mais ser alterado. Tem certeza que deseja prosseguir com a definição do modo POR METAS?'
      );
    } else if (currentPageSelected === WITH_TEAM) {
      currentPageText = this.translate(
        'uma vez que a modalidade for definida como POR EQUIPES, ela não poderá ser alterada. Tem certeza que deseja prosseguir no modo POR EQUIPES?'
      );
    } else if (currentPageSelected === WITH_MGM) {
      currentPageText = this.translate(
        'uma vez que a modalidade for definida como REDES DE PROSPECTORES, ela não poderá ser alterada. Tem certeza que deseja prosseguir no modo REDES DE PROSPECTORES?'
      );
    }

    return (
      <ModalConfirmGamification
        open={this.state.modalConfirmGamificationIsOpen}
        close={this.toggleModalConfirmGamification.bind(this)}
        currentPageText={currentPageText}
        loading={this.state.loading}
        currentPage={currentPageSelected}
        gamificationConfigs={this.gamificationConfigs.bind(this)}
      />
    );
  };

  setGamificationConfigs(currentPage) {
    if (currentPage === BY_GOALS || currentPage === BY_POINTS) {
      this.setState({
        currentPage: currentPage === BY_POINTS ? BY_POINTS : BY_GOALS,
        gamificationType: currentPage === BY_POINTS ? 'points' : 'goalsLevels',
      });
    } else if (currentPage === NO_GAME) {
      this.setState({
        currentPage: NO_GAME,
        isGamified: 'false',
        isTeamCompetition: 'false',
      });
    } else if (currentPage === WITH_GAME) {
      this.setState({
        currentPage: WITH_GAME,
        isGamified: 'true',
        isTeamCompetition: 'false',
      });
    } else if (currentPage === WITH_TEAM) {
      this.setState({
        currentPage: WITH_TEAM,
        isGamified: 'true',
        isTeamCompetition: 'true',
      });
    }
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.updateFormData(name, value);
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      })
      // () => this.validateField(name, value)
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    switch (fieldName) {
      default:
        if (value.length <= 0) {
          fieldValidationErrors[fieldName] = this.translate(
            'Você precisa preencher esse campo.'
          );
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateGoalField(fieldName, value, index) {
    const fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors[index] = fieldValidationErrors[index]
      ? fieldValidationErrors[index]
      : {};
    const { goalLevels } = this.state;

    const conversionsUntil =
      goalLevels[index] && goalLevels[index].conversionsUntil
        ? this.moneyStringToNumber(goalLevels[index].conversionsUntil)
        : 0;
    const conversionsFrom =
      goalLevels[index] && goalLevels[index].conversionsFrom
        ? this.moneyStringToNumber(goalLevels[index].conversionsFrom)
        : 0;

    switch (fieldName) {
      case 'conversionsFrom':
        if (conversionsFrom >= conversionsUntil) {
          fieldValidationErrors[index].conversionsFrom = this.translate(
            "O valor 'até R$' deve ser maior do que o valor em 'R$'"
          );
        } else {
          delete fieldValidationErrors[index].conversionsFrom;
        }
        break;
      case 'color':
        goalLevels[index].color = goalLevels[index].color
          ? goalLevels[index].color
          : '#22194D';
        this.changeGoalLevelsState(index, 'color', goalLevels[index].color);
        break;
      default:
        if (!value || value.length <= 0) {
          fieldValidationErrors[index][fieldName] = this.translate(
            'Você precisa preencher esse campo.'
          );
        } else {
          delete fieldValidationErrors[index][fieldName];
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input && inputKey !== 'playerRegisterPoints') {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  renderCoinNameField = () => {
    const { isGamified, gamificationType, currentPage, formData } = this.state;
    let hasCoin;
    if (
      isGamified === 'true' &&
      (gamificationType === 'points' || currentPage === 0)
    ) {
      hasCoin = true;
    } else hasCoin = false;

    return (
      hasCoin && (
        <div className="row">
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="coinName">
              Nome da moeda &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="coin"
              />
              <ReactTooltip id="coin" type="dark" effect="solid" multiline>
                <span>
                  É o nome que os usuário verão como estão performando. Ex.:
                  Pontos, Coins, Stelins, etc. Você decide!
                </span>
              </ReactTooltip>
            </label>

            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              id="coinName"
              name="coinName"
              aria-describedby="coinNameHelp"
              value={formData.coinName}
              placeholder="Nome da moeda"
            />
            {this.getError('coinName') && (
              <small id="coinNameHelp" className="form-text text-muted">
                {this.getError('coinName')}
              </small>
            )}
          </div>
        </div>
      )
    );
  };

  // renderPointsTextInputs = () => {
  //   return (
  //     <div>
  //       {this.renderCoinNameField()}
  //       <div className="row">
  //         <div className="form-group col-md-4">
  //           <label className="profile-label" htmlFor="targetPublic">
  //             Pontos para o cadastro do player &nbsp;
  //             <i
  //               className="fa fa-info-circle"
  //               aria-hidden="true"
  //               data-tip="React-tooltip"
  //               data-for="playerRegisterPoints"
  //             />
  //             <ReactTooltip
  //               id="playerRegisterPoints"
  //               type="dark"
  //               effect="solid"
  //               multiline
  //             >
  //               <span>
  //                 Defina quantos pontos cada player irá ganhar para se cadastrar
  //                 na sua plataforma
  //               </span>
  //             </ReactTooltip>
  //           </label>
  //           <input
  //             required
  //             type="number"
  //             className="form-control profile-placeholder"
  //             name="playerRegisterPoints"
  //             onChange={e => this.handleUserInput(e)}
  //             value={0}
  //           />
  //           {this.getError('coinName') && (
  //             <small id="playerRegisterPoints" className="form-text text-muted">
  //               {this.getError('playerRegisterPoints')}
  //             </small>
  //           )}
  //         </div>
  //       </div>{' '}
  //     </div>
  //   );
  // };

  renderByPointsForm() {
    const {
      rules,
      loading,
      isGamified,
      formErrors,
      errorForm,
      isTeamCompetition,
    } = this.state;

    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    return (
      isGamified && (
        <div>
          {/* {this.renderPointsTextInputs()} */}

          <div className="oq-padding-vertical">
            {this.renderRuleHeader()}
            {_.map(rules, (rule, ruleIndex) =>
              this.renderRule(rule, ruleIndex)
            )}
          </div>

          <div className="col-md-offset-4 col-md-4 m-bottom-20">
            <button
              className="btn btn-oq btn-oq-lg btn-block"
              disabled={
                loading ||
                formErrors.coinName ||
                formErrors.companyName ||
                errorForm
              }
              onClick={() => {
                if (
                  (groupPermissions &&
                    groupPermissions.permissions &&
                    groupPermissions.permissions.gamification &&
                    groupPermissions.permissions.gamification.create_edit) ||
                  (userData && userData.master && userData.master)
                ) {
                  this.updateStepsGamification();
                  this.updateGamification(isGamified, isTeamCompetition);
                } else {
                  Notify('Você não tem permissão!', 'warn');
                }
              }}
            >
              {loading ? 'Atualizando regras...' : 'Atualizar regras'}
            </button>
          </div>
        </div>
      )
    );
  }

  async handleImage(e, ref, fileIndex, index) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        index
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref, index) {
    this.setState({ progress: 100, isUploading: false });

    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.changeGoalLevelsState(index, fileIndex, data.data);
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderImagePreview(img) {
    return (
      <div className="col-md-12">
        <div className="col-md-offset-4 p-left-10 m-bottom-10">
          <img
            src={img}
            className="img-thumbnail img-responsive form-group"
            alt="gamification"
          />
        </div>
      </div>
    );
  }

  veriffyIfLevelIsIncomplete = (level) => {
    return _.map(level).includes('') || !_.size(level);
  };

  renderLevel = (item, index) => {
    const { formErrors, goalLevels } = this.state;
    const imageURL =
      goalLevels[index] && goalLevels[index].imageURL
        ? goalLevels[index].imageURL
        : null;
    goalLevels[index] = goalLevels[index] ? goalLevels[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};
    const levelIsIcomplete = _.size(_.map(formErrors[index])) > 0;

    return (
      <div key={index}>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label m-10" />
          <h4
            className="arrow-toggle clickable collapsed"
            data-toggle="collapse"
            data-target={`#phase${index}`}
            aria-expanded="false"
          >
            {`${this.translate('Nível')} ${Number(index) + 1}`}
            {levelIsIcomplete && (
              <span className="uncompleted-form">
                {this.translate('Existem campos vazios nesta seção')}
              </span>
            )}
          </h4>
          <div id={`phase${index}`} aria-expanded="false" className="collapse">
            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="levelName" className="profile-label m-10">
                  {this.translate('Nome do nível')}
                </label>
              </div>
              <div className="col-md-8">
                <input
                  id="levelName"
                  name="levelName"
                  className="form-control w-100"
                  aria-describedby="levelNameHelpInline"
                  value={goalLevels[index].levelName}
                  onChange={(e) => this.handleGoalLevelsChange(e, index)}
                />
              </div>
              {formErrors[index].levelName && this.renderErrorMessage()}
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="awardName" className="profile-label m-10">
                  {this.translate('Nome da premiação')}
                </label>
              </div>
              <div className="col-md-8">
                <input
                  id="awardName"
                  name="awardName"
                  className="form-control w-100"
                  aria-describedby="levelNameHelpInline"
                  value={goalLevels[index].awardName}
                  onChange={(e) => this.handleGoalLevelsChange(e, index)}
                />
              </div>
              {formErrors[index].awardName && this.renderErrorMessage()}
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="levelName" className="profile-label m-10">
                  {this.translate('Foto da Premiação')}
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="file"
                  accept="image/*"
                  id="imageURL"
                  onChange={async (event) => {
                    await this.handleImage(
                      event,
                      'GamificationGoals/',
                      'imageURL',
                      index
                    );
                  }}
                />
              </div>
              {formErrors[index].imageURL && this.renderErrorMessage()}
            </div>
            {imageURL && this.renderImagePreview(imageURL)}

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label className="profile-label m-10">
                  {this.translate('Meta')}
                </label>
              </div>
              <div className="col-md-8">
                <div className="col-md-12 p-0 align-items-center d-flex">
                  <label
                    htmlFor="conversionsFrom"
                    className="profile-label conversionsFrom col-md-1"
                  >
                    {this.translate('R$')}
                  </label>
                  <div className="col-md-4 p-0">
                    <input
                      id="conversionsFrom"
                      name="conversionsFrom"
                      disabled
                      className="form-control w-100 col-md-4 p-1"
                      aria-describedby="levelNameHelpInline"
                      value={this.getConversionsFrom(index, goalLevels)}
                    />
                  </div>
                  <div className="col-md-3 p-0 d-flex justify-content-center">
                    <label
                      htmlFor="conversionsUntil"
                      className="profile-label m-0"
                    >
                      {this.translate('até R$')}
                    </label>
                  </div>
                  <div className="col-md-4 p-0">
                    <input
                      id="conversionsUntil"
                      name="conversionsUntil"
                      className="form-control w-100"
                      aria-describedby="levelNameHelpInline"
                      value={goalLevels[index].conversionsUntil}
                      onChange={(e) => {
                        const input = e.target;

                        const masked = mask.maskVALOR(input.value);
                        this.changeGoalLevelsState(index, input.name, masked);

                        const conversionsFrom =
                          this.moneyStringToNumber(masked) + 0.01;

                        const masked2 = mask.maskVALOR(
                          conversionsFrom.toFixed(2)
                        );
                        this.changeGoalLevelsState(
                          Number(index) + 1,
                          'conversionsFrom',
                          masked2
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {formErrors[index].conversionsFrom && (
                <div className="error-wrapper">
                  <small
                    id="levelNameHelpInline"
                    className="text-muted align-self-end"
                  >
                    {formErrors[index].conversionsFrom}
                  </small>
                </div>
              )}
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label
                  htmlFor="amountOfIntervals"
                  className="profile-label m-10"
                >
                  {this.translate('Quantidade de medalhas')}
                </label>
              </div>
              <div className="col-md-2">
                <input
                  id="amountOfIntervals"
                  name="amountOfIntervals"
                  type="number"
                  min="1"
                  disabled
                  className="form-control w-100"
                  aria-describedby="amountOfIntervalsHelpInline"
                  value={5}
                  onChange={(e) => this.handleGoalLevelsChange(e, index)}
                />
              </div>
              {formErrors[index].amountOfIntervals && this.renderErrorMessage()}
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="winnersAmount" className="profile-label m-10">
                  {this.translate('Ganhadores')}
                </label>
              </div>
              <div className="col-md-2">
                <input
                  id="winnersAmount"
                  name="winnersAmount"
                  type="number"
                  min="1"
                  className="form-control w-100"
                  aria-describedby="winnersAmountHelpInline"
                  value={goalLevels[index].winnersAmount}
                  onChange={(e) => this.handleGoalLevelsChange(e, index)}
                />
              </div>
              {formErrors[index].winnersAmount && this.renderErrorMessage()}
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="levelName" className="profile-label m-10">
                  {this.translate('Cor do nível')}
                </label>
              </div>
              <div className="col-md-6 m-bottom-10">
                <SketchPicker
                  color={goalLevels[index].color}
                  width="100%"
                  onChangeComplete={(color) => {
                    this.changeGoalLevelsState(index, 'color', color.hex);
                  }}
                />
              </div>
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-4">
                <label htmlFor="levelName" className="profile-label m-10">
                  {this.translate('Regras')}
                </label>
              </div>
              <div className="col-md-8 p-right-0">
                <textarea
                  id="rules"
                  name="rules"
                  className="form-control w-100"
                  rows="5"
                  aria-describedby="rulesHelpInline"
                  value={goalLevels[index].rules}
                  onChange={(e) => this.handleGoalLevelsChange(e, index)}
                />
              </div>
              {formErrors[index].rules && this.renderErrorMessage()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderByGoalsForm = () => {
    const { loading, formErrors, formData } = this.state;

    formData.levelAmount = formData.levelAmount
      ? Number(formData.levelAmount)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.levelAmount; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de Níveis:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              id="levelAmount"
              name="levelAmount"
              value={formData.levelAmount}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderLevel(item, index))}

        <div className="col-md-offset-8 col-md-4 m-bottom-20 m-top-20">
          <button
            className="btn btn-oq btn-oq-lg btn-block"
            disabled={loading || formErrors.coinName || formErrors.companyName}
            onClick={async () => {
              this.setState({ loading: true });

              const levelAmount = formData.levelAmount
                ? Number(formData.levelAmount)
                : 1;
              this.verifyLevelsFields(levelAmount);

              this.formHasBeenCompleted(formErrors, levelAmount)
                ? await this.saveGoalLevels()
                : this.toggleShowIncompleteFormDialog();

              this.setState({ loading: false });
            }}
          >
            {loading ? 'Atualizando regras...' : 'Atualizar regras'}
          </button>
        </div>
      </div>
    );
  };

  saveGoalLevels = async () => {
    const { goalLevels } = this.state.formData;

    const parameters = {
      userType: 'client',
      updateFunctionality: 'updateGoalLevels',
      goalLevels,
    };

    const response = await api.post(`/update`, parameters);
    const result = response.data;

    Notify(result.message, result.success ? 'success' : 'error');
  };

  toggleShowIncompleteFormDialog() {
    this.setState({
      modalShowImcompletFormDialogIsOpen:
        !this.state.modalShowImcompletFormDialogIsOpen,
    });
  }

  showIncompleteFormDialog() {
    return (
      <ModalShowIncompletFormDialog
        open={this.state.modalShowImcompletFormDialogIsOpen}
        close={this.toggleShowIncompleteFormDialog.bind(this)}
      />
    );
  }

  moneyStringToNumber = (money) => {
    let number = 0;

    number = money.split('.').join('');
    number = number.replace(',', '.');

    return Number(number);
  };

  getConversionsFrom = (index, goalLevels) => {
    const conversionsFrom =
      Number(index) > 0 &&
      goalLevels[index - 1] &&
      goalLevels[index - 1].conversionsUntil
        ? this.moneyStringToNumber(goalLevels[index - 1].conversionsUntil) +
          0.01
        : 0;

    const masked = mask.maskVALOR(conversionsFrom.toFixed(2));

    return masked;
  };

  formHasBeenCompleted(formErrors, levelAmount) {
    _.map(this.state.goalLevels, (level) =>
      this.veriffyIfLevelIsIncomplete(level)
    );

    formErrors = _.take(_.map(formErrors), levelAmount);

    let formIsComplete = true;
    _.map(formErrors, (value, index) => {
      if (_.size(_.map(value)) > 0) formIsComplete = false;
    });
    return formIsComplete;
  }

  verifyLevelsFields(levelAmount) {
    const { formData } = this.state;
    const goalLevels = _.map(this.state.goalLevels);
    formData.goalLevels = _.take(goalLevels, levelAmount);

    for (let index = 0; index < _.size(formData.goalLevels); index++) {
      // this.validateGoalField("conversionWeight", goalLevels[index].conversionWeight, index);
      // this.validateGoalField("amountOfIntervals", goalLevels[index].amountOfIntervals, index);
      goalLevels[index].conversionWeight = 100;
      goalLevels[index].amountOfIntervals = 5;

      if (index === 0) {
        this.changeGoalLevelsState(index, 'conversionsFrom', 0);
      } else if (goalLevels[index - 1].conversionsUntil) {
        const conversionsFrom =
          index === 0
            ? 0
            : this.moneyStringToNumber(
                goalLevels[Number(index) - 1].conversionsUntil
              ) + 0.01;
        const masked = mask.maskVALOR(conversionsFrom.toFixed(2));
        this.changeGoalLevelsState(index, 'conversionsFrom', masked);
      }

      this.validateGoalField('awardName', goalLevels[index].awardName, index);
      this.validateGoalField('color', goalLevels[index].color, index);
      this.validateGoalField(
        'conversionsUntil',
        goalLevels[index].conversionsUntil,
        index
      );
      this.validateGoalField('imageURL', goalLevels[index].imageURL, index);
      this.validateGoalField('levelName', goalLevels[index].levelName, index);
      this.validateGoalField('rules', goalLevels[index].rules, index);
      this.validateGoalField(
        'winnersAmount',
        goalLevels[index].winnersAmount,
        index
      );
    }
  }

  renderErrorMessage() {
    return (
      <div className="error-wrapper">
        <small id="levelNameHelpInline" className="text-muted align-self-end">
          Este campo precisa ser preenchido
        </small>
      </div>
    );
  }

  handleGoalLevelsChange(e, index) {
    const { name, value } = e.target;
    this.changeGoalLevelsState(index, name, value);
  }

  changeGoalLevelsState(index, name, value) {
    this.setState((prevState) => ({
      goalLevels: {
        ...prevState.goalLevels,
        [index]: {
          ...prevState.goalLevels[index],
          [name]: value,
        },
      },
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical" style={{ paddingBottom: 0 }}>
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderGamificationModel() {
    const { currentPage, isGamified } = this.state;
    const { gamificationType } = this.state.formData;
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    const existsValueGamified =
      (isGamified || (isGamified !== undefined && isGamified !== '')) && true;

    const existsValueGamificationType =
      (gamificationType ||
        (gamificationType !== undefined && gamificationType !== '')) &&
      true;

    return (
      <>
        <div
          className="row justify-content-center oq-fundo"
          style={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled={existsValueGamified === true}
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: WITH_GAME,
                        },
                        () => this.toggleModalConfirmGamification(WITH_GAME)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Com competição')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Estimule a competição saudável entre equipes, motive e faça
                    a gestão do engajamento do time de vendas com um programa de
                    incentivo 100% digital. Monitore desde a divulgação de
                    ofertas e a geração de leads até o resgate de prêmios.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            display: 'flex',
            alignItems: 'center',
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled={
                    this.state.formData &&
                    this.state.formData.mgmVinculated &&
                    this.state.formData.mgmVinculated === 'true'
                  }
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      const { formData } = this.state;
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: WITH_MGM,
                        },
                        () => this.toggleModalConfirmGamification(WITH_MGM)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Rede de Prospectores')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Multiplique o alcance das suas prospecções permitindo que
                    seus vendedores e parceiros criem suas próprias forças de
                    vendas on-line. Os leads gerados são do líder da equipe e
                    quem captou o cliente é recompensado por cada venda que for
                    realizada por ele.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            display: 'flex',
            alignItems: 'center',
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled={existsValueGamificationType === true}
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: WITH_TEAM,
                        },
                        () => this.toggleModalConfirmGamification(WITH_TEAM)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Por equipes')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Crie temporadas, defina suas regras, forme equipes e lance
                    desafios para os usuários disputarem um com os outros em
                    tempo real. Aqueles que tiverem melhor desempenho são vistos
                    e reconhecidos. No final, todos ganham!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            display: 'flex',
            alignItems: 'center',
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  disabled={existsValueGamified === true}
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: NO_GAME,
                        },
                        () => this.toggleModalConfirmGamification(NO_GAME)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Sem competição')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Modelo individual para você se relacionar e reconhecer seus
                    clientes dando a eles descontos, prêmios, e/ou benefícios
                    exclusivos na medida que se engajam em suas publicações.
                    Divulgue ofertas, promova up-selling e cross-selling, crie
                    campanhas de indicação e recompense seus clientes de acordo
                    com os resultados.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            // paddingTop: 20,
            // paddingBottom: 20,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled={existsValueGamificationType === true}
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: BY_POINTS,
                        },
                        () => this.toggleModalConfirmGamification(BY_POINTS)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Por pontos')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    São mais de 20 maneiras de mensurar o engajamento e pontuar
                    o usuário. Se cadastrar, ganha! Se compartilhar, ganha! Se
                    gerar leads, ganha! Se engajar em conteúdos, ganha! Se
                    comprar ou converter uma venda, ganha mais ainda!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            // paddingTop: 20,
            // paddingBottom: 20,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled
                  onClick={() => {
                    if (
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.gamification &&
                        groupPermissions.permissions.gamification
                          .create_edit) ||
                      (userData && userData.master && userData.master)
                    ) {
                      return this.setState(
                        {
                          oldCurrentPage: this.state.currentPage,
                          currentPage: BY_GOALS,
                        },
                        () => this.toggleModalConfirmGamification(BY_GOALS)
                      );
                    } else {
                      return Notify('Você não tem permissão!', 'warn');
                    }
                  }}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Por metas')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Estabeleça metas e premie os usuários que atingirem estes
                    objetivos a ajudarem você a vender. Crie níveis de status e
                    recompense quem ajudar na divulgação e gerar retornos para o
                    seu negócio. Quanto melhor o desempenho do usuário, maior é
                    o seu reconhecimento. <strong>SOB CONSULTA.</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            // paddingTop: 20,
            // paddingBottom: 20,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div
              className="oq-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="oq-indication">
                <button
                  className="btn"
                  style={{
                    backgroundColor: 'var(--primary-color)',
                    color: '#FFF',
                    width: 200,
                    height: 40,
                  }}
                  disabled={true}
                  type="submit"
                >
                  Escolher
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                <span
                  className="span-oq-title-steps"
                  style={{
                    fontFamily: 'Gotham Rounded Medium',
                    fontSize: 24,
                    paddingBottom: 10,
                    color: 'var(--primary-color)',
                  }}
                >
                  {this.translate('Game of influencers')}
                </span>
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Multiplique o alcance das suas campanhas e das suas vendas
                    convidando influenciadores digitais para formar, junto com
                    seus seguidores, equipes de prospectores 100% on-line.
                    Defina uma premiação e quanto o influenciador e cada um dos
                    seus seguidores vão ganhar! <strong>SOB CONSULTA.</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  changeIconProfile() {
    let clientForm = this.state.formData;
    clientForm.showProfileIcon = !this.state.formData.showProfileIcon;

    this.setState({
      formData: clientForm,
    });

    console.log('clientForm.showProfileIcon: ', clientForm.showProfileIcon);
  }

  render() {
    const { currentPage } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              <div className="col-md-12">
                <div className="row justify-content-md-center">
                  <div className="col-md-9 ">
                    <h1
                      className="oq-filter-title"
                      style={{ marginBottom: '10px', color: '#000' }}
                    >
                      {/* <i className="fa fa-edit" />
                  &nbsp; */}
                      {this.renderBackButton()}
                      <span>{this.translate('Regras da gamificação')}</span>
                    </h1>
                  </div>
                </div>
                <div
                  className="col-md-12"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    className="col-md-8"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div
                      className="col-md-3"
                      style={{
                        maxWidth: 236,
                        display: 'block',
                        margin: 'auto',
                      }}
                    >
                      <img
                        src={estrategia}
                        alt=""
                        style={{ display: 'block', margin: 'auto' }}
                      />
                      <span style={{ textAlign: 'center' }}>
                        Monte sua estratégia, coloque um objetivo e lance estes
                        desafios para engajar seu time de vendas.
                      </span>
                    </div>
                    <div
                      className="col-md-3"
                      style={{
                        maxWidth: 236,
                        display: 'block',
                        margin: 'auto',
                      }}
                    >
                      <img
                        src={regras}
                        alt=""
                        style={{ display: 'block', margin: 'auto' }}
                      />
                      <span style={{ textAlign: 'center' }}>
                        Defina as regras, as metas, as premiações e quando e
                        quanto os usuários ganham.
                      </span>
                    </div>
                    <div
                      className="col-md-3"
                      style={{
                        maxWidth: 236,
                        display: 'block',
                        margin: 'auto',
                      }}
                    >
                      <img
                        src={grafico}
                        alt=""
                        style={{ display: 'block', margin: 'auto' }}
                      />
                      <span style={{ textAlign: 'center' }}>
                        Acompanhe os resultados em tempo real e premie os
                        usuários que cumprirem os desafios.
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{ width: 453, paddingTop: 40, paddingBottom: 20 }}
                  >
                    <p
                      className="gamification-title m-0"
                      style={{ textAlign: 'center', fontWeight: 'normal' }}
                    >
                      <span
                        style={{
                          color: 'var(--primary-color)',
                          fontWeight: 'bold',
                        }}
                      >
                        Configure abaixo seu programa
                      </span>{' '}
                      de acordo com o seu orçamento, sua estratégia e seus
                      objetivos de marketing e vendas.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="col-md-8">
                    <List>
                      <ListItem
                        content="Ver sugestões"
                        expansible
                        removeBorderContentExpansible
                        contentExpansible={this.renderGamificationModel()}
                      />
                    </List>
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="col-md-8">
                    <div className="row">
                      <div className="card">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ marginTop: '20px' }}
                          >
                            Defina quantas etapas serão pontuadas.
                          </h5>
                          <p className="card-text">
                            São as etapas do lead no funil onde o usuário será
                            premiado.
                          </p>

                          <div
                            className="form-group"
                            style={{
                              textAlignLast: 'center',
                              paddingLeft: '0',
                              marginTop: '20px',
                            }}
                          >
                            <select
                              className="form-control profile-placeholder"
                              style={{ margin: 0 }}
                              onChange={(e) => {
                                console.log('e.target.value: ', e.target.value);

                                let total = Number(e.target.value);
                                let itemsStep = [];

                                for (let index = 0; index < total; index++) {
                                  itemsStep.push({
                                    externalId: index + 1,
                                    name: `step ${index + 1}`,
                                    points: 0,
                                  });
                                }

                                console.log('itemsStep: ', itemsStep);

                                this.setState({
                                  stepsGamification: itemsStep,
                                });
                              }}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>

                          <h5
                            className="card-title"
                            style={{ marginTop: '20px' }}
                          >
                            Defina os nomes e o ID de cada etapa.
                          </h5>
                          <p className="card-text">
                            São as etapas do funil onde os usuários serão
                            premiados.
                          </p>

                          {this.state.stepsGamification.map((step, index) => {
                            return (
                              <>
                                <div style={{ marginTop: '20px' }}>
                                  <div
                                    className="form-group col-md-6"
                                    style={{
                                      textAlignLast: 'center',
                                      paddingLeft: '0',
                                    }}
                                  >
                                    <input
                                      className="form-control profile-placeholder"
                                      type="text"
                                      name="name"
                                      data-index={index}
                                      placeholder={step.name}
                                      value={step.name}
                                      onChange={(e) => {
                                        let steps =
                                          this.state.stepsGamification;
                                        let indexStep = Number(
                                          e.target.dataset.index
                                        );

                                        steps[indexStep].name = e.target.value;

                                        this.setState({
                                          stepsGamification: steps,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="form-group col-md-6"
                                    style={{
                                      textAlignLast: 'center',
                                      paddingLeft: '0',
                                    }}
                                  >
                                    <input
                                      className="form-control profile-placeholder"
                                      type="text"
                                      name="id"
                                      data-index={index}
                                      placeholder="ID do campo"
                                      value={step.externalId}
                                      onChange={(e) => {
                                        let steps =
                                          this.state.stepsGamification;
                                        let indexStep = Number(
                                          e.target.dataset.index
                                        );

                                        steps[indexStep].externalId =
                                          e.target.value;

                                        this.setState({
                                          stepsGamification: steps,
                                        });

                                        console.log(
                                          'this.state.stepsGamification: ',
                                          this.state.stepsGamification
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          <h5
                            className="card-title"
                            style={{ marginTop: '20px' }}
                          >
                            Defina o valor em pontos de cada campo.
                          </h5>
                          <p className="card-text">
                            É quanto cada usuário ganhará caso cumpra essa
                            etapa.
                          </p>

                          {this.state.stepsGamification.map((step, index) => {
                            return (
                              <>
                                <div style={{ marginTop: '20px' }}>
                                  <div
                                    className="form-group col-md-6"
                                    style={{
                                      textAlignLast: 'center',
                                      paddingLeft: '0',
                                    }}
                                  >
                                    <input
                                      className="form-control profile-placeholder"
                                      type="text"
                                      name="name"
                                      disabled
                                      placeholder={step.name}
                                      onChange={(e) => {
                                        console.log(
                                          'e.target.value: ',
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="form-group col-md-6"
                                    style={{
                                      textAlignLast: 'center',
                                      paddingLeft: '0',
                                    }}
                                  >
                                    <input
                                      className="form-control profile-placeholder"
                                      type="text"
                                      name="points"
                                      data-index={index}
                                      placeholder="Valor do step"
                                      value={step.points}
                                      onChange={(e) => {
                                        let steps =
                                          this.state.stepsGamification;
                                        let indexStep = Number(
                                          e.target.dataset.index
                                        );

                                        steps[indexStep].points = Number(
                                          e.target.value
                                        );

                                        this.setState({
                                          stepsGamification: steps,
                                        });

                                        console.log(
                                          'this.state.stepsGamification: ',
                                          this.state.stepsGamification
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-icon-change">
                  <div className="card-profile-icon-change">
                    <div className="content-left">
                      <img src={iconProfileGamification} alt="" />
                    </div>
                    <div className="content-right">
                      <p>Habilitar Status/Medalhas</p>
                      <br />
                      <span>
                        Você pode atribuir “status” para os usuários de acordo
                        com o número de pontos que ele acumular na Plataforma. E
                        ainda: definir múltiplos para premiar e incentivar
                        aqueles que mais se destacarem e gerarem resultados.
                        <br />
                        <br />
                        Ex.: 1º nível = 1 ponto X 100%;
                        <br />
                        2º nível = 1 ponto X 110% = 1,1 ponto;
                        <br />
                        <br />E assim por diante. Com isso, os usuários ficam
                        sabendo qual o seu status de acordo com a cor da medalha
                        que ficará em seu perfil.
                      </span>
                      <button
                        onClick={() => {
                          this.changeIconProfile();
                        }}
                        className={
                          this.state.formData.showProfileIcon ? 'active' : ''
                        }
                      >
                        {this.state.formData.showProfileIcon
                          ? 'Desabilitar'
                          : 'Habilitar'}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-12 col-md-offset-2">
                <h5>Status do player</h5>
              </div> */}

                <div className="col-md-12 col-md-offset-2">
                  {currentPage === BY_GOALS && this.renderByGoalsForm()}
                  {currentPage === BY_POINTS && this.renderByPointsForm()}
                </div>
              </div>
              {/* {this.renderIsGamifiedBtns()}
            {this.renderGamificationsButtons()} */}
            </div>
          </div>
          {this.showIncompleteFormDialog()}
        </div>
        {this.showAlertDialog()}
      </div>
    );
  }
}
const GamificationLink = (props) => (
  <Link className="menu-link" to={routes.GAMIFICATION_CLIENT}>
    <FontAwesomeIcon icon={faMedal} />
    <span className="sidebarText">{props.translate('Gamification')}</span>
  </Link>
);

export default Gamification;

export { GamificationLink };
