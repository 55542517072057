import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import moment from 'moment';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';
import { NotifyUpdate } from '../../../../components/Notify';
import ModalConfirmPaymentSelectedRescue from '../../../components/Modals/ConfirmPaymentSelectedRescue';
import ModalConfirmPaymentsGroup from '../../../components/Modals/ConfirmPaymentsGroup';
import ModalPaymentSelectedRescue from '../../../components/Modals/PaymentSelectedRescue';
import ModalShowSuccessErrorsTransfer from '../../../components/Modals/ShowSuccessErrorsTransfer';
import * as routes from '../../../../routes';
import { Notify } from '../../../../components/Notify';
import { api } from '../../../../Config';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

let nId = null;

class Rescues extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      isLoadingRescuesForExport: false,
      selectedPersonRescue: 'players',
      authUser: {},
      modalPaymentSelectedRescueIsOpen: false,
      modalConfirmPaymentSelectedRescueIsOpen: false,
      modalConfirmPaymentsGroupIsOpen: false,
      modalShowSuccessErrorsTransferIsOpen: false,
      isCheckedAll: false,
      rescueErrors: [],
      rescueSuccess: [],
      users: [],
      name: '',
      email: '',
      city: '',
      countUsers: 0,
      isLoading: false,
      searching: false,
      search: {},
      rescues: [],
      makeTransfer: {},
      typeRescues: [
        {
          label: 'Voucher',
          value: 'digital',
        },
        {
          label: 'Físico',
          value: 'físico',
        },
        {
          label: 'Dinheiro',
          value: 'bs2',
        },
      ],
    };

    return initialState;
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();

    if (!isValidDate) {
      this.setState((prevState) => ({
        search: {
          ...prevState.search,
          [`${name}Epoch`]: '',
        },
      }));
      return;
    }

    const epochDate = moment(value).valueOf();

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [`${name}Epoch`]: epochDate,
      },
    }));
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  handlePaymentRescue(e, rescueId, rescueValidated, rescuePayment) {
    let { checked } = e.target;

    if (!rescueValidated || rescueValidated === false)
      return Notify('Resgate não validado', 'warn');

    if (!rescuePayment || rescuePayment === false) {
      checked = true;
      this.setState({
        rescueIdSelected: rescueId,
        checkedSelected: checked,
      });

      return this.toggleConfirmPaymentSelectedRescue();
    }
    if (rescuePayment || rescuePayment === true) {
      checked = false;
      this.setState({
        rescueIdSelected: rescueId,
        checkedSelected: checked,
      });

      return this.togglePaymentSelectedRescue();
    }
  }

  togglePaymentSelectedRescue() {
    this.setState({
      modalPaymentSelectedRescueIsOpen:
        !this.state.modalPaymentSelectedRescueIsOpen,
    });
  }
  toggleConfirmPaymentSelectedRescue() {
    this.setState({
      modalConfirmPaymentSelectedRescueIsOpen:
        !this.state.modalConfirmPaymentSelectedRescueIsOpen,
    });
  }

  paymentSelectedRescue() {
    const { userData } = this.context;
    const { checkedSelected, rescueIdSelected } = this.state;

    const clientId = userData.clientId;
    const userId = userData.uid;
    this.setState({ creatingPayment: true }, () => {
      this.toastId = toast.info('Adicionando pagamento. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'postPaymentRescue',
        userType: 'client',
        clientId,
        userId,
        rescueId: rescueIdSelected,
        payment: checkedSelected,
      };

      api
        .post(`/appScan`, parameters)
        .then((res) => {
          Notify('Atualização concluída', 'success');
          // this.props.history.goBack();
          this.searchRescues();
        })
        .catch((error) => {
          this.setState({ creatingPayment: false });
          console.log('paymentSelectedRescue error:', error);
        });
    });
  }

  searchRescues() {
    const { userData } = this.context;
    const clientId = userData.clientId;
    const { search, selectedPersonRescue } = this.state;

    this.setState({ searching: true }, () => {
      const parameters = {
        clientId,
        searchFunctionality: 'getClientRescues',
        userType: 'client',
        search,
        selectedPersonRescue: selectedPersonRescue,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            Notify('Nenhum resgate encontrado', 'warn');
            this.setState({
              rescues: [],
              searching: false,
              makeTransfer: [],
            });
            return;
          }

          this.setState({
            rescues: result.data,
            searching: false,
            makeTransfer: [],
          });

          Notify(result.message, 'success');
        })
        .catch((error) => {
          this.setState({ searching: false, rescues: [] });
          console.log('searchPremium error:', error);
        });
    });
  }

  exportPlayers() {
    const defaultArray = [];
    const defaultObject = {
      'NOME DO PLAYER': '--',
      'CPF DO PLAYER': '--',
      'DATA DO RESGATE': '--',
      PROCESSADO: '--',
      'STATUS DA TRANSFERÊNCIA': '--',
      'PONTOS RESGATADOS': '--',
      'VALOR RESGATADO': '--',
      'QTD DE PRÊMIOS': '--',
      'ENTREGUE AO PLAYER': '--',
    };
    const { rescues } = this.state;

    rescues.forEach((rescue) => {
      const rescueObj = { ...defaultObject };

      if (rescue.player && rescue.player.name)
        rescueObj['NOME DO PLAYER'] = rescue.player.name;
      if (rescue.player && rescue.player.cpf)
        rescueObj['CPF DO PLAYER'] = rescue.player.cpf;
      if (rescue.rescue && rescue.rescue.createdAt)
        rescueObj['DATA DO RESGATE'] = moment(rescue.rescue.createdAt).format(
          'DD/MM/YYYY'
        );
      if (rescue.rescue && rescue.rescue.closeProccess)
        rescueObj['PROCESSADO'] = rescue.rescue.closeProccess ? 'SIM' : 'NÃO';

      if (rescue.rescue && rescue.rescue.statusTransfer)
        rescueObj['STATUS DA TRANSFERÊNCIA'] = rescue.rescue.statusTransfer
          ? rescue.rescue.statusTransfer
          : 'N/A';

      if (rescue.rescue.redeemedPoints !== 0 && !rescue.rescue.redeemedPoints)
        rescueObj['PONTOS RESGATADOS'] = 'N/A';
      if (rescue.rescue && rescue.rescue.redeemedPoints === 1)
        rescueObj['PONTOS RESGATADOS'] =
          `${rescue.rescue.redeemedPoints} ponto`;
      if (rescue.rescue && rescue.rescue.redeemedPoints > 1)
        rescueObj['PONTOS RESGATADOS'] =
          `${rescue.rescue.redeemedPoints} pontos`;
      if (rescue.rescue && rescue.rescue.totalPaymentValue)
        rescueObj['VALOR RESGATADO'] = `R$ ${rescue.rescue.totalPaymentValue}`;

      if (rescue.rescue && rescue.rescue.awards)
        rescueObj['QTD DE PRÊMIOS'] = rescue.rescue.awards.length;
      else rescueObj['QTD DE PRÊMIOS'] = 'N/A';

      if (rescue.rescue && rescue.rescue.delivered)
        rescueObj['ENTREGUE AO PLAYER'] = 'sim';
      else rescueObj['ENTREGUE AO PLAYER'] = 'não';

      defaultArray.push(rescueObj);
    });

    const ws = XLSX.utils.json_to_sheet(defaultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PublicoAlvo');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'ListaResgate.xlsx'
    );
  }

  async exportRescueList() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    this.setState({ isLoadingRescuesForExport: true }, async () => {
      const parameters = {
        searchFunctionality: 'getRescuesForExportation',
        userType: 'client',
        clientId: userData.clientId,
        userEmail: userData.email,
      };

      try {
        const res = await api.post(`/exportation`, parameters);

        const result = res.data;

        console.log('resultado getRescuesForExportation: ', result);

        if (result.success) {
          this.setState({
            isLoadingRescuesForExport: false,
          });
          Notify(result.message, 'success');
        } else {
          Notify(result.message, 'warn');
          this.setState({
            isLoadingRescuesForExport: false,
          });
        }
      } catch (error) {
        this.setState({
          isLoadingRescuesForExport: false,
        });
        console.log('exporLeads error: ', error);
        return;
      }
    });

    return;
  }

  numberWithCommas(x) {
    var decimalComma = x.replace('.', ',');
    var parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  renderSearchFormPlayers() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    const months = [
      {
        label: 'Janeiro',
        value: 0,
      },
      {
        label: 'Fevereiro',
        value: 1,
      },
      {
        label: 'Março',
        value: 2,
      },
      {
        label: 'Abril',
        value: 3,
      },
      {
        label: 'Maio',
        value: 4,
      },
      {
        label: 'Junho',
        value: 5,
      },
      {
        label: 'Julho',
        value: 6,
      },
      {
        label: 'Agosto',
        value: 7,
      },
      {
        label: 'Setembro',
        value: 8,
      },
      {
        label: 'Outubro',
        value: 9,
      },
      {
        label: 'Novembro',
        value: 10,
      },
      {
        label: 'Dezembro',
        value: 11,
      },
    ];

    const years = [
      {
        label: '2023',
        value: 2023,
      },
      {
        label: '2022',
        value: 2022,
      },
    ];

    let isPermissionExport = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionExport =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.reedemAwards &&
          groupPermissions.permissions.reedemAwards.rescues &&
          groupPermissions.permissions.reedemAwards.rescues.export) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionExport =
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.reedemAwards &&
        groupPermissions[userData.group.value].permissions.reedemAwards
          .rescues &&
        groupPermissions[userData.group.value].permissions.reedemAwards.rescues
          .export;
    }

    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.searchRescues();
                    }
                  }}
                  placeholder="Nome completo do player"
                  onChange={(e) => this.handleUserInput(e)}
                />
              </div>
              {this.context.clientData &&
                (this.context.clientData.clientUrl === 'testesemgame' ||
                  this.context.clientData.clientUrl === 'emr') && (
                  <div className="col-md-6 form-group">
                    <select
                      className="form-control"
                      name="selectedPersonRescue"
                      onChange={(e) => {
                        const { value } = e.target;
                        this.setState({
                          selectedPersonRescue: value,
                        });
                      }}
                      value={this.state.selectedPersonRescue}
                    >
                      <option value="players">Resgates dos players</option>
                      <option value="leads">Resgates dos leads</option>
                    </select>
                  </div>
                )}
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.searchRescues();
                    }
                  }}
                  placeholder="E-mail do player"
                  onChange={(e) => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  placeholder="Ano"
                  name="year"
                  onChange={(e) => this.handleUserInput(e)}
                >
                  <option value="">Selecione o ano</option>
                  {_.size(years) > 0 &&
                    _.map(years, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  placeholder="Mês"
                  name="month"
                  onChange={(e) => this.handleUserInput(e)}
                >
                  <option value="">Selecione o mês</option>
                  {_.size(months) > 0 &&
                    _.map(months, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="premiumName"
                  name="premiumName"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.searchRescues();
                    }
                  }}
                  placeholder="Nome do prêmio"
                  onChange={(e) => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  name="typeRescue"
                  onChange={(e) => this.handleUserInput(e)}
                  required
                >
                  <option value="">Tipo de prêmio</option>
                  {this.state.typeRescues.map((typeRescue) => (
                    <option key={typeRescue.value} value={typeRescue.value}>
                      {typeRescue.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group col-md-12">
                <button
                  type="button"
                  className="btn btn-block btn-oq pull-right"
                  style={{ width: '75%' }}
                  disabled={
                    this.state.searching || this.state.isLoadingRescuesForExport
                  }
                  onClick={() => this.searchRescues()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  &nbsp;
                  {this.state.searching
                    ? this.translate('Pesquisando...')
                    : this.translate('Pesquisar')}
                </button>
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-oq pull-right"
                  onClick={() =>
                    isPermissionExport
                      ? // ? this.exportPlayers()
                        this.exportRescueList()
                      : Notify('Você não tem permissão!', 'warn')
                  }
                  disabled={
                    this.state.searching || this.state.isLoadingRescuesForExport
                  }
                  style={{ marginTop: '10px', width: '50%' }}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  {this.translate('Exportar')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSearchFormLeads() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    const months = [
      { label: 'Janeiro', value: 0 },
      { label: 'Fevereiro', value: 1 },
      { label: 'Março', value: 2 },
      { label: 'Abril', value: 3 },
      { label: 'Maio', value: 4 },
      { label: 'Junho', value: 5 },
      { label: 'Julho', value: 6 },
      { label: 'Agosto', value: 7 },
      { label: 'Setembro', value: 8 },
      { label: 'Outubro', value: 9 },
      { label: 'Novembro', value: 10 },
      { label: 'Dezembro', value: 11 },
    ];

    const years = [
      {
        label: '2023',
        value: 2023,
      },
      {
        label: '2022',
        value: 2022,
      },
    ];

    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.searchRescues();
                    }
                  }}
                  placeholder="Nome completo do lead"
                  onChange={(e) => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  name="selectedPersonRescue"
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState({
                      selectedPersonRescue: value,
                    });
                  }}
                  value={this.state.selectedPersonRescue}
                >
                  <option value="players">Resgates dos players</option>
                  <option value="leads">Resgates dos leads</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.searchRescues();
                    }
                  }}
                  placeholder="E-mail do lead"
                  onChange={(e) => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  placeholder="Ano"
                  name="year"
                  onChange={(e) => this.handleUserInput(e)}
                >
                  <option value="">Selecione o ano</option>
                  {_.size(years) > 0 &&
                    _.map(years, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  placeholder="Mês"
                  name="month"
                  onChange={(e) => this.handleUserInput(e)}
                >
                  <option value="">Selecione o mês</option>
                  {_.size(months) > 0 &&
                    _.map(months, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group col-md-12">
                <button
                  type="button"
                  className="btn btn-block btn-oq pull-right"
                  style={{ width: '75%' }}
                  disabled={
                    this.state.searching || this.state.isLoadingRescuesForExport
                  }
                  onClick={() => this.searchRescues()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  &nbsp;
                  {this.state.searching
                    ? this.translate('Pesquisando...')
                    : this.translate('Pesquisar')}
                </button>
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-oq pull-right"
                  onClick={() =>
                    (groupPermissions &&
                      groupPermissions.permissions &&
                      groupPermissions.permissions.reedemAwards &&
                      groupPermissions.permissions.reedemAwards.rescues &&
                      groupPermissions.permissions.reedemAwards.rescues
                        .export) ||
                    (userData && userData.master && userData.master)
                      ? // ? this.exportPlayers()
                        this.exportRescueList()
                      : Notify('Você não tem permissão!', 'warn')
                  }
                  disabled={
                    this.state.searching || this.state.isLoadingRescuesForExport
                  }
                  style={{ marginTop: '10px', width: '50%' }}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  {this.translate('Exportar')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    const label = e.target.options[e.target.selectedIndex].text;

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  toggleConfirmPaymentsGroup() {
    this.setState({
      modalConfirmPaymentsGroupIsOpen:
        !this.state.modalConfirmPaymentsGroupIsOpen,
    });
  }

  confirmPaymentsGroup() {
    return (
      <ModalConfirmPaymentsGroup
        open={this.state.modalConfirmPaymentsGroupIsOpen}
        close={this.toggleConfirmPaymentsGroup.bind(this)}
        makeBankTransfer={this.makeBankTransfer.bind(this)}
      />
    );
  }

  toggleShowSuccessErrorsTransfer() {
    this.setState({
      modalShowSuccessErrorsTransferIsOpen:
        !this.state.modalShowSuccessErrorsTransferIsOpen,
    });
  }

  ModalShowSuccessErrorsTransfer() {
    return (
      <ModalShowSuccessErrorsTransfer
        open={this.state.modalShowSuccessErrorsTransferIsOpen}
        close={this.toggleShowSuccessErrorsTransfer.bind(this)}
        rescuesSuccess={this.state.rescueSuccess}
        rescuesErrors={this.state.rescueErrors}
      />
    );
  }

  async makeBankTransfer() {
    const { makeTransfer } = this.state;
    const { clientData } = this.context;

    const arrTypeAccountTransfeera = [
      {
        label: 'Conta Corrente',
        value: 'CONTA_CORRENTE',
      },
      {
        label: 'Conta Poupança',
        value: 'CONTA_POUPANCA',
      },
      {
        label: 'Conta Fácil',
        value: 'CONTA_FACIL',
      },
      {
        label: 'Entidades Públicas',
        value: 'ENTIDADES_PUBLICAS',
      },
    ];

    // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
    const arrTypePixTransfeera = [
      {
        label: 'CPF',
        value: 'CPF',
      },
      {
        label: 'CNPJ',
        value: 'CNPJ',
      },
      {
        label: 'Telefone',
        value: 'TELEFONE',
      },
      {
        label: 'E-mail',
        value: 'EMAIL',
      },
      {
        label: 'Chave aleatória',
        value: 'CHAVE_ALEATORIA',
      },
    ];

    let accountType = null;
    let typePix = null;
    let isRescuesTransfeera = [];
    let isRescuesBs2 = [];
    let searchFunctionality = '';

    _.each(makeTransfer, (res) => {
      accountType = arrTypeAccountTransfeera.find((bankAccount) => {
        if (res.rescue) {
          return (
            res.rescue &&
            res.rescue.accountData &&
            res.rescue.accountData.accountType &&
            bankAccount.value.includes(res.rescue.accountData.accountType)
          );
        }
      });

      if (
        (res.rescue &&
          res.rescue.accountData &&
          res.rescue.accountData.paymentMethod &&
          res.rescue.accountData.paymentMethod.label === 'pix' &&
          res.rescue.accountData.paymentMethod.value) ||
        (res.rescue &&
          res.rescue.accountData &&
          res.rescue.accountData.paymentMethod &&
          res.rescue.accountData.paymentMethod === 'pix')
      ) {
        typePix = arrTypePixTransfeera.find(
          (type) => type.value === res.rescue.accountData.typePix
        );
      }

      if (accountType || typePix) {
        isRescuesTransfeera.push(res);
      } else {
        isRescuesBs2.push(res);
      }
    });

    if (isRescuesTransfeera.length > 0 && isRescuesBs2.length > 0) {
      return Notify(`Selecione apenas os resgates da mesma integração`, 'warn');
    }

    if (
      clientData &&
      clientData.integration &&
      clientData.integration.transfeera &&
      clientData.integration.transfeera.active === 'true' &&
      isRescuesTransfeera.length > 0 &&
      isRescuesBs2.length === 0
    ) {
      searchFunctionality = 'makeBankTransferGroup';
    } else if (
      clientData &&
      clientData.integration &&
      clientData.integration.bs2 &&
      clientData.integration.bs2.active === 'true' &&
      isRescuesTransfeera.length === 0 &&
      isRescuesBs2.length > 0
    ) {
      searchFunctionality = 'makeBankTransferGroupBs2';
    } else {
      return Notify(
        `Integracao Ativa, mas é diferente da que está resgatando`,
        'warn'
      );
    }

    this.setState({ isLoading: true });
    NotifyUpdate('Aguarde... realizando a transferência...', 'loading', false);
    try {
      let parameters = {
        searchFunctionality,
        userType: 'client',
        clientId: clientData.uid,
        rescues: makeTransfer,
      };

      const responseMakeBankTransfer = await api.post(`/search`, parameters);

      console.log('responseMakeBankTransfer: ', responseMakeBankTransfer.data);
      const { rescueErrors, rescueSuccess } =
        responseMakeBankTransfer.data.data;

      this.setState({
        rescueErrors,
        rescueSuccess,
      });

      if (responseMakeBankTransfer.data.success) {
        // Chegou aqui, quer dizer que deu tudo certo
        NotifyUpdate(
          responseMakeBankTransfer.data.message,
          'success',
          nId,
          true
        );
        this.toggleShowSuccessErrorsTransfer();
        this.searchRescues();
        this.setState({ makeTransfer: {}, isLoading: false });
      } else {
        NotifyUpdate(responseMakeBankTransfer.data.message, 'error', nId, true);
        this.toggleShowSuccessErrorsTransfer();
        this.searchRescues();
        this.setState({ makeTransfer: {}, isLoading: false });
      }
    } catch (error) {
      console.log('error makeBankTransferGroup: ', error);
      this.searchRescues();
      this.setState({ makeTransfer: {}, isLoading: false });
    }
  }

  handleChangePrint(e, props, index) {
    const { checked } = e.target;
    if (checked === false) {
      this.setState((prevState) => ({
        ...prevState,
        makeTransfer: _.omit(this.state.makeTransfer, index),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        makeTransfer: {
          ...prevState.makeTransfer,
          [index]: {
            rescue: props.rescue,
            checked: checked,
            player: props.player,
          },
        },
      }));
    }
  }

  handleChangeCheckboxAll(e) {
    const { checked } = e.target;
    const { makeTransfer, rescues } = this.state;

    const a = _.map(rescues, async (rescue, index) => {
      if (
        !rescue.rescue.closeProccess &&
        rescue.rescue.statusTransfer !== 'CRIADA' &&
        rescue.rescue.statusTransfer !== 'FINALIZADO' &&
        checked
      ) {
        this.handleChangePrint(e, rescue, index);
      } else if (
        !rescue.rescue.closeProccess &&
        rescue.rescue.statusTransfer !== 'CRIADA' &&
        rescue.rescue.statusTransfer !== 'FINALIZADO' &&
        !checked
      ) {
        this.setState({ makeTransfer: [] });
      }
    });

    this.setState({
      isCheckedAll: checked,
    });
  }

  renderRescuesList() {
    const { rescues } = this.state;
    const findRescuePhysical = rescues.find(
      (rescue) =>
        rescue &&
        rescue.rescue &&
        rescue.rescue.awards &&
        rescue.rescue.awards.length > 0 &&
        rescue.rescue.awards[0] &&
        rescue.rescue.awards[0].premiumType &&
        rescue.rescue.awards[0].premiumType.label === 'Físico'
    );
    const findRescueTedPix = rescues.find(
      (rescue) =>
        rescue &&
        rescue.rescue &&
        rescue.rescue.awards &&
        rescue.rescue.awards.length > 0 &&
        rescue.rescue.awards[0] &&
        rescue.rescue.awards[0].premiumType &&
        rescue.rescue.awards[0].premiumType.label === 'BS2'
    );
    const findRescueVoucher = rescues.find(
      (rescue) =>
        rescue &&
        rescue.rescue &&
        rescue.rescue.awards &&
        rescue.rescue.awards.length > 0 &&
        rescue.rescue.awards[0] &&
        rescue.rescue.awards[0].premiumType &&
        rescue.rescue.awards[0].premiumType.label === 'Voucher'
    );

    const arrTypeAccountTransfeera = [
      {
        label: 'Conta Corrente',
        value: 'CONTA_CORRENTE',
      },
      {
        label: 'Conta Poupança',
        value: 'CONTA_POUPANCA',
      },
      {
        label: 'Conta Fácil',
        value: 'CONTA_FACIL',
      },
      {
        label: 'Entidades Públicas',
        value: 'ENTIDADES_PUBLICAS',
      },
    ];

    // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
    const arrTypePixTransfeera = [
      {
        label: 'CPF',
        value: 'CPF',
      },
      {
        label: 'CNPJ',
        value: 'CNPJ',
      },
      {
        label: 'Telefone',
        value: 'TELEFONE',
      },
      {
        label: 'E-mail',
        value: 'EMAIL',
      },
      {
        label: 'Chave aleatória',
        value: 'CHAVE_ALEATORIA',
      },
    ];
    let columns = [];
    if (findRescueTedPix && !findRescuePhysical && !findRescueVoucher) {
      columns = [
        {
          Header: (
            <span data-tip="React-tooltip" data-for="user">
              {this.translate('Usuário')}
              <ReactTooltip
                id="user"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Usuário')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive ? (
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                href={`${routes.EDIT_PLAYER}/${d.player.uid}`}
              >
                {d.player.name}
              </a>
            ) : d.lead ? (
              d.lead.name
            ) : (
              'Deletado'
            ),
          id: 'user',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="cpf">
              {this.translate('cpf')}
              <ReactTooltip
                id="cpf"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive
              ? d.player.cpf
              : d.lead && d.lead.cpf
                ? d.lead.cpf
                : 'N/A',
          id: 'cpf',
        },
        {
          id: 'rescueData',
          Header: (
            <span data-tip="React-tooltip" data-for="date">
              {this.translate('Data e Hora')}
              <ReactTooltip
                id="date"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Data')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue
              ? moment(d.rescue.createdAt).format('DD/MM/YYYY HH:mm:ss')
              : '--',
          width: 180,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="tedOrPix">
              {this.translate('Ted/Pix')}
              <ReactTooltip
                id="tedOrPix"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Ted/Pix')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) => {
            if (
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod.label === 'pix') ||
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod === 'pix')
            ) {
              return 'Pix';
            }
            if (
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod.label === 'ted') ||
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod === 'ted')
            ) {
              return 'Ted';
            }
            return '-';
          },
          id: 'tedOrPix',
          width: 80,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="typeRescue">
              {this.translate('Tipo de resgate')}
              <ReactTooltip
                id="typeRescue"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Tipo de resgate')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue &&
            d.rescue.awards &&
            d.rescue.awards[0].premiumType &&
            d.rescue.awards[0].premiumType.label,
          id: 'typeRescue',
          width: 80,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="closeProccess">
              {this.translate('Processado')}
              <ReactTooltip
                id="closeProccess"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Processado')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) => (d.rescue.closeProccess ? 'Sim' : 'Não'),
          id: 'closeProccess',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="statusTransfer">
              {this.translate('Status da Transf.')}
              <ReactTooltip
                id="statusTransfer"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Status da Transferência')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue.statusTransfer ? d.rescue.statusTransfer : 'N/A',
          id: 'statusTransfer',
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="nfNumber"
              className="center-cell"
            >
              {this.translate('Pontos resgatados')}
              <ReactTooltip
                id="redeemedPoints"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Pontos resgatados')}
                </span>
              </ReactTooltip>
            </span>
          ),
          id: 'redeemedPoints',
          accessor: (d) => {
            if (d.rescue.redeemedPoints !== 0 && !d.rescue.redeemedPoints)
              return 'N/A';
            if (d.rescue.redeemedPoints === 1)
              // return `${d.rescue.redeemedPoints} ponto`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} ponto`;
            if (d.rescue.redeemedPoints > 1)
              // return `${d.rescue.redeemedPoints} pontos`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} pontos`;

            return '--';
          },
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Qtd de prêmios')}
                <ReactTooltip
                  id="awards"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'awards',
          accessor: (d) =>
            d.rescue ? d.rescue.awards && d.rescue.awards.length : 'N/A',
          className: 'center-cell',
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Validado')}
                <ReactTooltip
                  id="validated"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'validated',
          accessor: (d) =>
            d.rescue.validated
              ? d.rescue.validated === true
                ? 'Sim'
                : 'Não'
              : 'Não',
          className: 'center-cell',
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="payment"
              className="center-block text-center"
            >
              {this.translate('Pago')}
              <ReactTooltip
                id="payment"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Pago')}
                </span>
              </ReactTooltip>
            </span>
          ),
          // accessor: d => d.rescue && d.purchase,
          id: 'payment',
          Cell: ({ original }) => {
            return (
              <div className="center-block">
                <Checkbox
                  className="oq-checkbox"
                  checked={
                    original.rescue.payment ? original.rescue.payment : ''
                  }
                  onChange={(e) =>
                    this.handlePaymentRescue(
                      e,
                      original.rescue.id,
                      original.rescue.validated,
                      original.rescue.payment
                    )
                  }
                />
              </div>
            );
          },
        },
        {
          Header: <span>Transferir</span>,
          Cell: (props) => {
            const isCloseProccess =
              props.original.rescue && props.original.rescue.closeProccess
                ? props.original.rescue.closeProccess
                : false;
            return (
              <div className="center-block">
                <Checkbox
                  className="oq-checkbox"
                  checked={
                    (this.state.makeTransfer[props.row._index] &&
                      this.state.makeTransfer[props.row._index].checked) ||
                    isCloseProccess ||
                    props.original.rescue.statusTransfer === 'CRIADA' ||
                    props.original.rescue.statusTransfer === 'FINALIZADO'
                  }
                  disabled={
                    isCloseProccess ||
                    props.original.rescue.statusTransfer === 'CRIADA' ||
                    props.original.rescue.statusTransfer === 'FINALIZADO'
                  }
                  onChange={(e) =>
                    this.handleChangePrint(e, props.original, props.row._index)
                  }
                />
              </div>
            );
          },
          id: 'transfer',
        },
        {
          Header: '',
          // accessor: 'uid',
          Cell: ({ original }) => {
            const newTo = {
              pathname: routes.RESCUE_DETAILS + '/' + original.rescue.id + '/',
              rescue: original.rescue,
            };

            return (
              <Link to={newTo}>
                <button className="btn btn-oq">
                  {this.translate('Detalhes')}
                </button>
              </Link>
            );
          },
        },
      ];
    } else if (findRescuePhysical && !findRescueTedPix && !findRescueVoucher) {
      columns = [
        {
          Header: (
            <span data-tip="React-tooltip" data-for="user">
              {this.translate('Usuário')}
              <ReactTooltip
                id="user"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Usuário')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive ? (
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                href={`${routes.EDIT_PLAYER}/${d.player.uid}`}
              >
                {d.player.name}
              </a>
            ) : d.lead ? (
              d.lead.name
            ) : (
              'Deletado'
            ),
          id: 'user',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="cpf">
              {this.translate('cpf')}
              <ReactTooltip
                id="cpf"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive
              ? d.player.cpf
              : d.lead && d.lead.cpf
                ? d.lead.cpf
                : 'N/A',
          id: 'cpf',
        },
        {
          id: 'rescueData',
          Header: (
            <span data-tip="React-tooltip" data-for="date">
              {this.translate('Data e Hora')}
              <ReactTooltip
                id="date"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Data')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue
              ? moment(d.rescue.createdAt).format('DD/MM/YYYY HH:mm:ss')
              : '--',
          width: 180,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="typeRescue">
              {this.translate('Tipo de resgate')}
              <ReactTooltip
                id="typeRescue"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Tipo de resgate')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue &&
            d.rescue.awards &&
            d.rescue.awards[0].premiumType &&
            d.rescue.awards[0].premiumType.label,
          id: 'typeRescue',
          width: 80,
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="nfNumber"
              className="center-cell"
            >
              {this.translate('Pontos resgatados')}
              <ReactTooltip
                id="redeemedPoints"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Pontos resgatados')}
                </span>
              </ReactTooltip>
            </span>
          ),
          id: 'redeemedPoints',
          accessor: (d) => {
            if (d.rescue.redeemedPoints !== 0 && !d.rescue.redeemedPoints)
              return 'N/A';
            if (d.rescue.redeemedPoints === 1)
              // return `${d.rescue.redeemedPoints} ponto`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} ponto`;
            if (d.rescue.redeemedPoints > 1)
              // return `${d.rescue.redeemedPoints} pontos`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} pontos`;

            return '--';
          },
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Qtd de prêmios')}
                <ReactTooltip
                  id="awards"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'awards',
          accessor: (d) =>
            d.rescue ? d.rescue.awards && d.rescue.awards.length : 'N/A',
          className: 'center-cell',
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Validado')}
                <ReactTooltip
                  id="validated"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'validated',
          accessor: (d) =>
            d.rescue.validated
              ? d.rescue.validated === true
                ? 'Sim'
                : 'Não'
              : 'Não',
          className: 'center-cell',
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="payment"
              className="center-block text-center"
            >
              {this.translate('Pago')}
              <ReactTooltip
                id="payment"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Pago')}
                </span>
              </ReactTooltip>
            </span>
          ),
          // accessor: d => d.rescue && d.purchase,
          id: 'payment',
          Cell: ({ original }) => {
            return (
              <div className="center-block">
                <Checkbox
                  className="oq-checkbox"
                  checked={
                    original.rescue.payment ? original.rescue.payment : ''
                  }
                  onChange={(e) =>
                    this.handlePaymentRescue(
                      e,
                      original.rescue.id,
                      original.rescue.validated,
                      original.rescue.payment
                    )
                  }
                />
              </div>
            );
          },
        },
        {
          Header: '',
          // accessor: 'uid',
          Cell: ({ original }) => {
            const newTo = {
              pathname: routes.RESCUE_DETAILS + '/' + original.rescue.id + '/',
              rescue: original.rescue,
            };

            return (
              <Link to={newTo}>
                <button className="btn btn-oq">
                  {this.translate('Detalhes')}
                </button>
              </Link>
            );
          },
        },
      ];
    } else if (findRescueVoucher && !findRescueTedPix && !findRescuePhysical) {
      columns = [
        {
          Header: (
            <span data-tip="React-tooltip" data-for="user">
              {this.translate('Usuário')}
              <ReactTooltip
                id="user"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Usuário')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive ? (
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                href={`${routes.EDIT_PLAYER}/${d.player.uid}`}
              >
                {d.player.name}
              </a>
            ) : d.lead ? (
              d.lead.name
            ) : (
              'Deletado'
            ),
          id: 'user',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="cpf">
              {this.translate('cpf')}
              <ReactTooltip
                id="cpf"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive
              ? d.player.cpf
              : d.lead && d.lead.cpf
                ? d.lead.cpf
                : 'N/A',
          id: 'cpf',
        },
        {
          id: 'rescueData',
          Header: (
            <span data-tip="React-tooltip" data-for="date">
              {this.translate('Data e Hora')}
              <ReactTooltip
                id="date"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Data')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue
              ? moment(d.rescue.createdAt).format('DD/MM/YYYY HH:mm:ss')
              : '--',
          width: 180,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="typeRescue">
              {this.translate('Tipo de resgate')}
              <ReactTooltip
                id="typeRescue"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Tipo de resgate')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue &&
            d.rescue.awards &&
            d.rescue.awards[0].premiumType &&
            d.rescue.awards[0].premiumType.label,
          id: 'typeRescue',
          width: 80,
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="nfNumber"
              className="center-cell"
            >
              {this.translate('Pontos resgatados')}
              <ReactTooltip
                id="redeemedPoints"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Pontos resgatados')}
                </span>
              </ReactTooltip>
            </span>
          ),
          id: 'redeemedPoints',
          accessor: (d) => {
            if (d.rescue.redeemedPoints !== 0 && !d.rescue.redeemedPoints)
              return 'N/A';
            if (d.rescue.redeemedPoints === 1)
              // return `${d.rescue.redeemedPoints} ponto`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} ponto`;
            if (d.rescue.redeemedPoints > 1)
              // return `${d.rescue.redeemedPoints} pontos`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} pontos`;

            return '--';
          },
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Qtd de prêmios')}
                <ReactTooltip
                  id="awards"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'awards',
          accessor: (d) =>
            d.rescue ? d.rescue.awards && d.rescue.awards.length : 'N/A',
          className: 'center-cell',
        },
        {
          Header: '',
          // accessor: 'uid',
          Cell: ({ original }) => {
            const newTo = {
              pathname: routes.RESCUE_DETAILS + '/' + original.rescue.id + '/',
              rescue: original.rescue,
            };

            return (
              <Link to={newTo}>
                <button className="btn btn-oq">
                  {this.translate('Detalhes')}
                </button>
              </Link>
            );
          },
        },
      ];
    } else {
      columns = [
        {
          Header: (
            <span data-tip="React-tooltip" data-for="user">
              {this.translate('Usuário')}
              <ReactTooltip
                id="user"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Usuário')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive ? (
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                href={`${routes.EDIT_PLAYER}/${d.player.uid}`}
              >
                {d.player.name}
              </a>
            ) : d.lead ? (
              d.lead.name
            ) : (
              'Deletado'
            ),
          id: 'user',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="cpf">
              {this.translate('cpf')}
              <ReactTooltip
                id="cpf"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.playerActive
              ? d.player.cpf
              : d.lead && d.lead.cpf
                ? d.lead.cpf
                : 'N/A',
          id: 'cpf',
        },
        {
          id: 'rescueData',
          Header: (
            <span data-tip="React-tooltip" data-for="date">
              {this.translate('Data e Hora')}
              <ReactTooltip
                id="date"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Data')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue
              ? moment(d.rescue.createdAt).format('DD/MM/YYYY HH:mm:ss')
              : '--',
          width: 180,
        },
        // {
        //   Header: (
        //     <span data-tip="React-tooltip" data-for="integration">
        //       {this.translate('Integração')}
        //       <ReactTooltip
        //         id="integration"
        //         type="dark"
        //         effect="solid"
        //         multiline
        //         className="tool-tip"
        //       >
        //         <span>
        //           {this.translate('OrderBy') + this.translate('Integração')}
        //         </span>
        //       </ReactTooltip>
        //     </span>
        //   ),
        //   accessor: d => {
        //     let accountType = null;
        //     let typePix = null;

        //     accountType = arrTypeAccountTransfeera.find(bankAccount => {
        //       if (d.rescue) {
        //         return (
        //           d.rescue &&
        //           d.rescue.accountData &&
        //           d.rescue.accountData.accountType &&
        //           bankAccount.value.includes(
        //             d.rescue.accountData.accountType
        //           )
        //         );
        //       }
        //     });

        //     if (
        //       d.rescue &&
        //       d.rescue.accountData &&
        //       d.rescue.accountData.paymentMethod &&
        //       d.rescue.accountData.paymentMethod.label === 'pix' &&
        //       d.rescue.accountData.paymentMethod.value
        //     ) {
        //       typePix = arrTypePixTransfeera.find(
        //         type => type.value === d.rescue.accountData.typePix
        //       );
        //     }

        //     if (accountType || typePix) {
        //       return 'Transfeera';
        //     } else {
        //       return 'Bs2';
        //     }
        //   },
        //   id: 'integration',
        //   width: 100,
        // },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="tedOrPix">
              {this.translate('Ted/Pix')}
              <ReactTooltip
                id="tedOrPix"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Ted/Pix')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) => {
            if (
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod.label === 'pix') ||
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod === 'pix')
            ) {
              return 'Pix';
            }
            if (
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod.label === 'ted') ||
              (d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.paymentMethod &&
                d.rescue.accountData.paymentMethod === 'ted')
            ) {
              return 'Ted';
            }
            return '-';
          },
          id: 'tedOrPix',
          width: 80,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="typeRescue">
              {this.translate('Tipo de resgate')}
              <ReactTooltip
                id="typeRescue"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Tipo de resgate')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue &&
            d.rescue.awards &&
            d.rescue.awards[0].premiumType &&
            d.rescue.awards[0].premiumType.label,
          id: 'typeRescue',
          width: 80,
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="closeProccess">
              {this.translate('Processado')}
              <ReactTooltip
                id="closeProccess"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Processado')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) => (d.rescue.closeProccess ? 'Sim' : 'Não'),
          id: 'closeProccess',
        },
        {
          Header: (
            <span data-tip="React-tooltip" data-for="statusTransfer">
              {this.translate('Status da Transf.')}
              <ReactTooltip
                id="statusTransfer"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Status da Transferência')}
                </span>
              </ReactTooltip>
            </span>
          ),
          accessor: (d) =>
            d.rescue.statusTransfer ? d.rescue.statusTransfer : 'N/A',
          id: 'statusTransfer',
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="nfNumber"
              className="center-cell"
            >
              {this.translate('Pontos resgatados')}
              <ReactTooltip
                id="redeemedPoints"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Pontos resgatados')}
                </span>
              </ReactTooltip>
            </span>
          ),
          id: 'redeemedPoints',
          accessor: (d) => {
            if (d.rescue.redeemedPoints !== 0 && !d.rescue.redeemedPoints)
              return 'N/A';
            if (d.rescue.redeemedPoints === 1)
              // return `${d.rescue.redeemedPoints} ponto`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} ponto`;
            if (d.rescue.redeemedPoints > 1)
              // return `${d.rescue.redeemedPoints} pontos`;
              return `${this.numberWithCommas(
                Number(d.rescue.redeemedPoints).toFixed(1)
              )} pontos`;

            return '--';
          },
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Qtd de prêmios')}
                <ReactTooltip
                  id="awards"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'awards',
          accessor: (d) =>
            d.rescue ? d.rescue.awards && d.rescue.awards.length : 'N/A',
          className: 'center-cell',
        },
        {
          Header: (
            <div style={{ textAlign: 'center' }}>
              <span
                data-tip="React-tooltip"
                data-for="awards"
                className="center-cell"
              >
                {this.translate('Validado')}
                <ReactTooltip
                  id="validated"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') +
                      this.translate('Qtd. de Prêmios')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          ),
          id: 'validated',
          accessor: (d) =>
            d.rescue.validated
              ? d.rescue.validated === true
                ? 'Sim'
                : 'Não'
              : 'Não',
          className: 'center-cell',
        },
        {
          Header: (
            <span
              data-tip="React-tooltip"
              data-for="payment"
              className="center-block text-center"
            >
              {this.translate('Pago')}
              <ReactTooltip
                id="payment"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') + this.translate('Pago')}
                </span>
              </ReactTooltip>
            </span>
          ),
          // accessor: d => d.rescue && d.purchase,
          id: 'payment',
          Cell: ({ original }) => {
            return (
              <div className="center-block">
                <Checkbox
                  className="oq-checkbox"
                  checked={
                    original.rescue.payment ? original.rescue.payment : ''
                  }
                  onChange={(e) =>
                    this.handlePaymentRescue(
                      e,
                      original.rescue.id,
                      original.rescue.validated,
                      original.rescue.payment
                    )
                  }
                />
              </div>
            );
          },
        },
        {
          Header: <span>Transferir</span>,
          Cell: (props) => {
            const isCloseProccess =
              props.original.rescue && props.original.rescue.closeProccess
                ? props.original.rescue.closeProccess
                : false;
            return (
              <div className="center-block">
                <Checkbox
                  className="oq-checkbox"
                  checked={
                    (this.state.makeTransfer[props.row._index] &&
                      this.state.makeTransfer[props.row._index].checked) ||
                    isCloseProccess ||
                    props.original.rescue.statusTransfer === 'CRIADA' ||
                    props.original.rescue.statusTransfer === 'FINALIZADO'
                  }
                  disabled={
                    isCloseProccess ||
                    props.original.rescue.statusTransfer === 'CRIADA' ||
                    props.original.rescue.statusTransfer === 'FINALIZADO'
                  }
                  onChange={(e) =>
                    this.handleChangePrint(e, props.original, props.row._index)
                  }
                />
              </div>
            );
          },
          id: 'transfer',
        },
        {
          Header: '',
          // accessor: 'uid',
          Cell: ({ original }) => {
            const newTo = {
              pathname: routes.RESCUE_DETAILS + '/' + original.rescue.id + '/',
              rescue: original.rescue,
            };

            return (
              <Link to={newTo}>
                <button className="btn btn-oq">
                  {this.translate('Detalhes')}
                </button>
              </Link>
            );
          },
        },
      ];
    }

    let lengthMakeTransfer = _.values(this.state.makeTransfer).length;

    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-gift" />
          &nbsp; Lista de resgates
        </span>
        <div className="d-flex pull-right">
          <label className=" col-md-12">
            <input
              type="checkbox"
              className="oq-checkbox"
              onChange={(e) => this.handleChangeCheckboxAll(e)}
              checked={this.state.isCheckedAll}
            />{' '}
            Selecionar Todos
          </label>
          <button
            type="button"
            className="btn  btn-oq pull-right"
            onClick={() => this.toggleConfirmPaymentsGroup()}
            disabled={lengthMakeTransfer <= 0 || this.state.isLoading}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.state.isLoading
              ? this.translate('Transferindo...')
              : this.translate('Transferir')}
          </button>
        </div>
        <div className="oq-line" />
        <div className="oq-padding-bottom" />
        <ReactTable
          data={rescues}
          defaultPageSize={10}
          columns={columns}
          loading={this.state.searching}
          previousText="Anterior"
          nextText="Próxima"
          loadingText="Carregando..."
          noDataText="Nenhum resgate para ser exibido"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    );
  }

  renderRemovePaymentRescue() {
    return (
      <ModalPaymentSelectedRescue
        open={this.state.modalPaymentSelectedRescueIsOpen}
        close={this.togglePaymentSelectedRescue.bind(this)}
        paymentSelectedRescue={this.paymentSelectedRescue.bind(this)}
      />
    );
  }

  renderConfirmPaymentRescue() {
    return (
      <ModalConfirmPaymentSelectedRescue
        open={this.state.modalConfirmPaymentSelectedRescueIsOpen}
        close={this.toggleConfirmPaymentSelectedRescue.bind(this)}
        paymentSelectedRescue={this.paymentSelectedRescue.bind(this)}
      />
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.state.selectedPersonRescue === 'players'
              ? this.renderSearchFormPlayers()
              : this.renderSearchFormLeads()}
            {this.renderRescuesList()}
            {this.renderRemovePaymentRescue()}
            {this.renderConfirmPaymentRescue()}
            {this.confirmPaymentsGroup()}
            {this.ModalShowSuccessErrorsTransfer()}
          </div>
        </div>
      </div>
    );
  }
}

const RescuesLink = () => (
  <Link className="menu-link" to={routes.RESCUES}>
    <i className="fas fa-parachute-box" />
    &nbsp;<span className="sidebarText">Resgates</span>
  </Link>
);

export default Rescues;

export { RescuesLink };
