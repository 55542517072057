import axios from 'axios';
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { Notify } from '../../../../../components/Notify';
import { api, getAPIURL } from '../../../../../Config';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';

import { UserDataContext } from '../../../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class NewBanner extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      isUploading: false,
      progress: 0,
      bannerImage: [],
      bannerImageMobile: [],
      formData: {
        bannerName: '',
        bannerImage: '',
        bannerStatus: '',
        bannerType: '',
        link: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
      },
    };

    return initialState;
  }
  async componentDidMount() {
    await this.getStateList();
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value?.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }
  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;
    const { stateList } = this.state;

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );
      const stateCities = response.data;

      const newCitiesarr = _.map(stateCities, async (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        return data;
      });
      return Promise.all(newCitiesarr).then((newCity) => newCity);
    });

    Promise.all(newCitesPromises).then((newCitiesarr) =>
      this.setState({ cityList: newCitiesarr[0], isLoadingCitys: false })
    );
  }

  validateForm() {
    let emptyFieldsCount = 0;

    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'link' &&
        inputKey !== 'scope' &&
        inputKey !== 'bannerImage'
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  createBanner() {
    const { formValid, formData, bannerImage, bannerImageMobile } = this.state;

    if (!bannerImage) {
      Notify(
        'Você deve escolher uma imagem(Desktop) para o seu banner!',
        'warn'
      );
      return;
    }

    if (!bannerImageMobile) {
      Notify(
        'Você deve escolher uma imagem(Mobile) para o seu banner!',
        'warn'
      );
      return;
    }

    if (
      this.context.clientData.segmentationChoice === 'true' &&
      !formData.scope
    ) {
      Notify('Você deve escolher uma abrangência para o seu banner!', 'warn');
      return;
    }

    if (!formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação do banner',
        'warn'
      );
      return;
    }

    this.setState({ isLoading: true }, () => {
      const { formData } = this.state;

      const active = !!(
        formData.bannerStatus &&
        formData.bannerStatus.value === this.translate('active')
      );
      const isPrimary = !!(
        formData.bannerType &&
        formData.bannerType.value === this.translate('primary')
      );

      formData.active = active;
      formData.isPrimary = isPrimary;

      const parameters = {
        userType: 'client',
        searchFunctionality: 'createBanner',
        formData,
        bannerImage,
        bannerImageMobile,
      };

      api
        .post(`/create`, parameters)
        .then((response) => {
          const result = response.data;
          if (result.success) {
            this.setState(this.getInitialState());
          }
          Notify(result.message, result.success ? 'success' : 'error');
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log('createBanner error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  async handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    if (this.state.formData.bannerType.value === this.translate('primary')) {
      if (fileIndex === 'bannerImageMobile') {
        this.setState((oldState) => ({
          bannerImageMobile: [
            ...oldState.bannerImageMobile,
            { url: data.data, isMobile: true },
          ],
          uploadProgress: 100,
          isUploading: false,
        }));
      } else {
        this.setState((oldState) => ({
          bannerImage: [...oldState.bannerImage, { url: data.data }],
          uploadProgress: 100,
          isUploading: false,
        }));
      }
    } else {
      this.setState((oldState) => ({
        bannerImage: [...oldState.bannerImage, { url: data.data }],
        uploadProgress: 100,
        isUploading: false,
      }));
      // this.handleFormDataChange(fileIndex, data.data);
      // this.setState({ isUploading: false });
    }
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
          readOnly={clientData.segmentationChoice === 'false'}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;

    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {console.log('scopeValues: ', scopeValues)}
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderInfluencerSegmentation() {
    const { isTeamCompetition } = this.context.clientData;
    const { userType } = this.state.formData;

    return (
      isTeamCompetition &&
      isTeamCompetition === 'true' && (
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="userType">
            {'Tipo de usuario'} <code>*</code>
          </label>
          <Select
            name="userType"
            placeholder={`Selecione quais usuarios deseja atingir ...`}
            options={[
              {
                value: 'todos',
                label: 'Todos',
              },
              {
                value: 'influencers',
                label: 'Influencers',
              },
              {
                value: 'players',
                label: 'Players',
              },
            ]}
            className="basic-multi-select"
            defaultValue={this.state.formData.userType}
            onChange={(e) => this.handleFormDataChange('userType', e)}
            classNamePrefix="select"
          />
          {!userType && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderImageLogo() {
    const { formData } = this.state;
    if (this.state.formData.bannerType.value === this.translate('primary')) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {this.state.bannerImage.map((image, index) => {
                  return (
                    <>
                      <p className="d-flex justify-content-center">
                        Imagem Desktop {index + 1}
                      </p>
                      <div className="flex">
                        <>
                          <div
                            className="delete-banner"
                            key={index}
                            onClick={() => {
                              this.handleDeleteImageDesktop(index);
                            }}
                          >
                            <IconDelete />
                          </div>
                          <img
                            key={image.url}
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      </div>
                    </>
                  );
                })}
                {this.state.bannerImageMobile.map((image, index) => {
                  return (
                    <>
                      <p className="d-flex justify-content-center">
                        Imagem Mobile {index + 1}
                      </p>
                      <div className="flex">
                        <>
                          <div
                            className="delete-banner"
                            key={index}
                            onClick={() => {
                              this.handleDeleteImageMobile(index);
                            }}
                          >
                            <IconDelete />
                          </div>
                          <img
                            key={image.url}
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-md-12 form-group text-center">
              <input
                className="hidden"
                type="file"
                accept="image/*"
                id="bannerImage"
                onChange={async (event) => {
                  await this.handleUserImage(event, `Banners/`, 'bannerImage');
                }}
              />
              <input
                className="hidden"
                type="file"
                accept="image/*"
                id="bannerImageMobile"
                onChange={async (event) => {
                  await this.handleUserImage(
                    event,
                    `Banners/`,
                    'bannerImageMobile'
                  );
                }}
              />

              {this.state.isUploading ? (
                <span className="small-oq text-center">
                  {this.translate('sending')}
                  {this.state.progress}%
                </span>
              ) : null}
            </div>
            <div className="col-md-12 form-group text-center">
              <label
                htmlFor="bannerImage"
                className="btn btn-oq-back text-center w-100"
                disabled={this.state.isUploading}
              >
                <span className="small-oq">Imagem do banner (Desktop)</span>
              </label>
              <span className="small-oq text-center">
                {this.state.formData.bannerType.value ===
                this.translate('primary')
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span>
            </div>
            <div className="col-md-12 form-group text-center">
              <label
                htmlFor="bannerImageMobile"
                className="btn btn-oq-back text-center w-100"
                disabled={this.state.isUploading}
              >
                <span className="small-oq">Imagem do banner (Mobile)</span>
              </label>
              <span className="small-oq text-center">
                {this.state.formData.bannerType.value ===
                this.translate('primary')
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.formData.bannerType.value === 'Secundário') {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {this.state.bannerImage.map((image, index) => {
                  return (
                    <>
                      <p className="d-flex justify-content-center">
                        Imagem Desktop {index + 1}
                      </p>
                      <div className="flex">
                        <>
                          <div
                            className="delete-banner"
                            key={index}
                            onClick={() => {
                              this.handleDeleteImageDesktop(index);
                            }}
                          >
                            <IconDelete />
                          </div>
                          <img
                            key={image.url}
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-md-12 form-group text-center">
              <input
                className="hidden"
                type="file"
                accept="image/*"
                id="bannerImage"
                onChange={async (event) => {
                  await this.handleUserImage(event, `Banners/`, 'bannerImage');
                }}
              />

              {this.state.isUploading ? (
                <span className="small-oq text-center">
                  {this.translate('sending')}
                  {this.state.progress}%
                </span>
              ) : null}
            </div>
            <div className="col-md-12 form-group text-center">
              <label
                htmlFor="bannerImage"
                className="btn btn-oq-back text-center w-100"
                disabled={this.state.isUploading}
              >
                <span className="small-oq">imagem do banner (Desktop)</span>
              </label>
              <span className="small-oq text-center">
                {this.state.formData.bannerType.value ===
                this.translate('primary')
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }
  renderCitySelection() {
    // const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          className="basic-multi-select"
          defaultValue={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  handleDeleteImageDesktop = (index) => {
    const { bannerImage } = this.state;

    const newImages = bannerImage.filter((img, idx) => idx !== index);

    this.setState((prevState) => ({
      ...prevState,
      bannerImage: newImages,
    }));
  };

  handleDeleteImageMobile = (index) => {
    const { bannerImageMobile } = this.state;

    const newImages = bannerImageMobile.filter((img, idx) => idx !== index);

    this.setState((prevState) => ({
      ...prevState,
      bannerImageMobile: newImages,
    }));
  };

  renderBannerForm() {
    const { isLoading, formData } = this.state;
    const { clientData } = this.context;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('name')}
            </label>
            <input
              className="form-control profile-placeholder"
              disabled={isLoading}
              id="bannerName"
              name="bannerName"
              placeholder={this.translate('name')}
              value={formData.bannerName}
              onChange={(e) => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerLink">
              {this.translate('Redirect link')}
            </label>
            <input
              className="form-control profile-placeholder"
              disabled={isLoading}
              id="bannerLink"
              name="link"
              placeholder={this.translate('Redirect Link')}
              value={formData.link}
              onChange={(e) => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('banner-type')}
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerType}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_type')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerType', selectedOption)
              }
              options={[
                {
                  value: this.translate('primary'),
                  label: this.translate('primary'),
                },
                {
                  value: this.translate('secondary'),
                  label: this.translate('secondary'),
                },
              ]}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerStatus}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerStatus', selectedOption)
              }
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          {this.renderScope()}
          {this.context.clientData.segmentationChoice === 'true' &&
            this.renderScopeForm()}
          {clientData.isActiveRegisterStateAndCity &&
            clientData.isActiveRegisterStateAndCity === 'true' && (
              <>
                {this.renderStateSelection()}
                {this.renderCitySelection()}
              </>
            )}

          {this.context.clientData.isTeamCompetition === 'true' &&
            this.renderInfluencerSegmentation()}

          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={!this.state.formValid || isLoading}
                onClick={() => this.createBanner()}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-banner')
                    : this.translate('save-banner')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-9">{this.renderBannerForm()}</div>
          {/* <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div> */}
          <div className="col-xs-12 col-md-3">
            {this.state.formData.bannerType === '' ? (
              <p className="d-flex justify-content-center">
                Selecione o tipo de banner
              </p>
            ) : (
              this.renderImageLogo()
            )}
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default NewBanner;
