import React, { Component } from 'react';

import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';

import Color from 'color';
import { ToastContainer } from 'react-toastify';
import { api } from '../../../../Config';

import { Notify } from '../../../../components/Notify';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';
import { UserDataContext } from '../../../../hooks/useUserData';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class DetailsBenefit extends Component {
  static contextType = UserDataContext;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      formData: {
        benefitCode: '',
        benefitDescription: '',
        benefitLinkFacebook: '',
        benefitLinkInstagram: '',
        benefitLinkWhatsapp: '',
        benefitName: '',
        benefitImage: '',
        regionalBenefit: [],
        benefitStatus: '',
        benefitCategory: '',
        benefitLinkCompany: '',
      },
      formErrors: {},
      isLoading: false,
      isUpdate: false,
      isUploading: false,
    };
    return initialState;
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    const { params } = this.props.match;
    this.getBenefitById(params.benefitId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3)
      );
      p = p + 10;
    }

    return result;
  }

  getBenefitById(benefitId) {
    const parameters = {
      searchFunctionality: 'getBenefit',
      userType: 'client',
      benefitId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }

        Notify(result.message, result.success ? 'success' : 'error');
        this.setState({
          formData: {
            ...this.state.formData,
            ...result.data,
          },
          // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
          benefitId: result.data.uid,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log('getBenefitByiD error:', error);
      });
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          margin: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  render() {
    const regionalBenefit =
      this.context.clientData &&
      this.context.clientData.regionalBenefitName &&
      this.context.clientData.regionalBenefitName !== ''
        ? this.context.clientData.regionalBenefitName
        : 'Segementação dos Benefícios';

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          {this.context.clientData.moduleBenefits &&
          this.context.clientData.moduleBenefits !== 'true' ? (
            this.renderNotAuthorization()
          ) : (
            <div className="oq-content-area">
              <NavBar />
              <div className="oq-content-work">
                <ToastContainer />
                <div className="container-fluid">
                  <div className="col-md-12">{this.renderBackButton()}</div>
                  <h4>Benefícios</h4>
                  <h1 className="oq-filter-title">
                    <i className="fa fa-rocket" />

                    <span>
                      &nbsp; {this.translate('Detalhes do benefício')}
                    </span>
                  </h1>
                </div>
                <div className="col-md-12">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Nome *
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="name-benefit"
                      />
                      <ReactTooltip
                        id="name-benefit"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline={true}
                      >
                        <span>
                          Nome da empresa que está oferecendo o benefício
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      disabled
                      readOnly
                      maxLength={24}
                      className="form-control profile-placeholder"
                      name="benefitName"
                      placeholder="Digite o nome"
                      value={this.state.formData.benefitName}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Código promocional
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="promo-code"
                      />
                      <ReactTooltip
                        id="promo-code"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. A empresa pode criar um código único
                          para os usuários da plataforma para medir o número de
                          aquisições/conversões{' '}
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      disabled
                      readOnly
                      maxLength={24}
                      className="form-control profile-placeholder"
                      name="benefitCode"
                      placeholder="Digite o código"
                      value={this.state.formData.benefitCode}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Link do instagram
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="insta-link"
                      />
                      <ReactTooltip
                        id="insta-link"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. A empresa pode colocar o link da rede
                          social aqui
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      disabled
                      readOnly
                      required={false}
                      className="form-control profile-placeholder"
                      name="benefitLinkInstagram"
                      placeholder="Digite o link do instagram"
                      value={this.state.formData.benefitLinkInstagram}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Link do facebook
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="face-link"
                      />
                      <ReactTooltip
                        id="face-link"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. A empresa pode colocar o link da rede
                          social aqui
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      disabled
                      readOnly
                      className="form-control profile-placeholder"
                      name="benefitLinkFacebook"
                      placeholder="Digite o link do facebook"
                      value={this.state.formData.benefitLinkFacebook}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Link do whatsapp
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="wpp-link"
                      />
                      <ReactTooltip
                        id="wpp-link"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. Caso o usuário queira redirecionar os
                          usuários para o Whatsapp.
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      disabled
                      readOnly
                      className="form-control profile-placeholder"
                      name="benefitLinkWhatsapp"
                      placeholder="Digite o link do whatsapp"
                      value={this.state.formData.benefitLinkWhatsapp}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="benefitName">
                      {this.translate('status')} *
                    </label>
                    <Select
                      isDisabled
                      isOptionDisabled={(option) => option.value}
                      closeMenuOnSelect
                      value={this.state.formData.benefitStatus}
                      components={{ SelectOption }}
                      placeholder={this.translate('choose_banner_status')}
                      options={[
                        {
                          value: this.translate('active'),
                          label: this.translate('active'),
                        },
                        {
                          value: this.translate('inactive'),
                          label: this.translate('inactive'),
                        },
                      ]}
                    />
                  </div>
                  {this.context.clientData.useRegionalizationBenefit ===
                    'true' && (
                    <div className="form-group col-md-6" id="regionalBenefit">
                      <label className="input-oq-label">
                        {regionalBenefit}
                      </label>

                      <Select
                        isDisabled
                        isOptionDisabled={(option) => option.value}
                        isMulti
                        name="regionalBenefit"
                        options={_.map(
                          this.context.clientData.regionalBenefit,
                          (regional) => {
                            return {
                              value: normalizeLowerCase(regional),
                              label: regional,
                            };
                          }
                        )}
                        value={this.state.formData.regionalBenefit}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      {console.log(
                        'this.state.formData: ',
                        this.state.formData
                      )}
                    </div>
                  )}
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="benefitName">
                      {this.translate('categoria')} *
                    </label>
                    <Select
                      isDisabled
                      isOptionDisabled={(option) => option.value}
                      closeMenuOnSelect
                      value={this.state.formData.benefitCategory}
                      components={{ SelectOption }}
                      placeholder={this.translate('choose_category')}
                      options={[
                        {
                          value: this.translate('Acessórios'),
                          label: this.translate('Acessórios'),
                        },
                        {
                          value: this.translate('Alimentos e Bebidas'),
                          label: this.translate('Alimentos e Bebidas'),
                        },
                        {
                          value: this.translate(
                            'Brinquedos e Artigos Infantis'
                          ),
                          label: this.translate(
                            'Brinquedos e Artigos Infantis'
                          ),
                        },
                        {
                          value: this.translate('Cultura e Entretenimento'),
                          label: this.translate('Cultura e Entretenimento'),
                        },
                        {
                          value: this.translate('Educação'),
                          label: this.translate('Educação'),
                        },
                        {
                          value: this.translate('Eletroeletrônicos'),
                          label: this.translate('Eletroeletrônicos'),
                        },
                        {
                          value: this.translate('Eletrodomésticos'),
                          label: this.translate('Eletrodomésticos'),
                        },
                        {
                          value: this.translate('Internet e Telecomunicações'),
                          label: this.translate('Internet e Telecomunicações'),
                        },
                        {
                          value: this.translate('Materiais de Construção'),
                          label: this.translate('Materiais de Construção'),
                        },
                        {
                          value: this.translate('Materiais Esportivos'),
                          label: this.translate('Materiais Esportivos'),
                        },
                        {
                          value: this.translate('Materiais de Escritório'),
                          label: this.translate('Materiais de Escritório'),
                        },
                        {
                          value: this.translate('Móveis e Decoração'),
                          label: this.translate('Móveis e Decoração'),
                        },
                        {
                          value: this.translate('Saúde e bem-estar'),
                          label: this.translate('Saúde e bem-estar'),
                        },
                        {
                          value: this.translate('Vestuário'),
                          label: this.translate('Vestuário'),
                        },
                        {
                          value: this.translate('Turismo e Lazer'),
                          label: this.translate('Turismo e Lazer'),
                        },
                        {
                          value: this.translate('Outros'),
                          label: this.translate('Outros'),
                        },
                      ]}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Descrição *
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="description"
                      />
                      <ReactTooltip
                        id="description"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Um breve texto para informar sobre o benefício para o
                          usuário.
                        </span>
                      </ReactTooltip>
                    </label>
                    <textarea
                      disabled
                      readOnly
                      maxLength={140}
                      className="form-control profile-placeholder"
                      rows="3"
                      name="benefitDescription"
                      placeholder="Digite a descrição"
                      value={this.state.formData.benefitDescription}
                    ></textarea>
                  </div>

                  <div className="col-md-6 form-group">
                    <label
                      className="profile-label w-100"
                      htmlFor="callActionText"
                    >
                      Link do site da empresa
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="link-site"
                      />
                      <ReactTooltip
                        id="link-site"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. Caso a empresa queira redirecionar o
                          usuário para seu site.
                        </span>
                      </ReactTooltip>
                    </label>

                    <input
                      disabled
                      readOnly
                      name="benefitLinkCompany"
                      id="benefitLinkCompany"
                      placeholder="Digite o link da empresa"
                      className="form-control profile-placeholder"
                      value={this.state.formData.benefitLinkCompany}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label
                      className="profile-label w-100"
                      htmlFor="callActionText"
                    >
                      Imagem *
                    </label>
                    <p className="tipText">{`${this.translate(
                      'recommended'
                    )}: 360x360`}</p>
                    {this.state.formData.benefitImage && (
                      <img
                        width="300px"
                        className="img-responsive img-thumbnail oq-margin-vertical"
                        src={this.state.formData.benefitImage}
                      />
                    )}
                    <input
                      disabled
                      readOnly
                      type="file"
                      accept="image/*"
                      id="benefitImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default DetailsBenefit;
