import FileSaver from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';
import { Notify } from '../../../components/Notify';
import { api } from '../../../Config';
import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.value}</span>
      </components.Option>
    </div>
  );
};

class HistoricalPoints extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      historicalPoints: [],
      searchObject: {
        playerId: '',
        authUser: '',
        type: '',
        leadId: '',
      },
      isLoadingHistoricalPointsForExport: false,
    };

    this.translate = props.t;
  }

  filterList() {
    const { clientData } = this.context;
    this.setState({ isLoading: true });

    const { searchObject } = this.state;

    const parameters = {
      searchFunctionality: 'getHistoricalPoints',
      userType: 'client',
      clientId: clientData.uid,
    };

    if (
      searchObject.playerId ||
      searchObject.authUser ||
      searchObject.type ||
      searchObject.leadId
    ) {
      parameters.filter = searchObject;
    }

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        console.log('result: ', result);

        this.setState({
          historicalPoints:
            result && result.data && result.data.historicalPointsClient
              ? result.data.historicalPointsClient
              : [],
          isLoading: false,
        });
        Notify(result.message, result.success ? 'success' : 'error');
      })
      .catch((error) => {
        console.log('getHistoricalPoints error:', error);
        this.setState({ isLoading: false });
        Notify(error.message, 'error');
      });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  getStepColumn(step) {
    if (step.currentStep) {
      return Number(Object.keys(step.currentStep)) + 1;
    } else {
      return '--';
    }
  }

  renderTableHistoricalPoints() {
    const { historicalPoints } = this.state;

    historicalPoints &&
      historicalPoints.length > 0 &&
      historicalPoints
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .reverse();

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="playerId">
            {this.translate('Id do player')}
            <ReactTooltip
              id="playerId"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Id do player')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.playerId ? d.playerId : '--'),
        id: 'playerId',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="authUser">
            {this.translate('Id do usuário')}
            <ReactTooltip
              id="authUser"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Id do usuário')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.authUser && d.authUser.uid
            ? d.authUser.uid
            : d.authUser && !d.authUser.uid
              ? d.authUser
              : '--',
        id: 'authUser',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="leadId">
            {this.translate('Id do indicado')}
            <ReactTooltip
              id="leadId"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Id do indicado')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.leadId ? d.leadId : '--'),
        id: 'leadId',
      },
      {
        id: 'createdAt',
        Header: (
          <span data-tip="React-tooltip" data-for="date">
            {this.translate('Data e Hora')}
            <ReactTooltip
              id="date"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Data')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.createdAt
            ? moment(d.createdAt).format('DD/MM/YYYY HH:mm:ss')
            : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="actionId">
            {this.translate('Id da publicação')}
            <ReactTooltip
              id="actionId"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Id da publicação')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.actionId ? d.actionId : '--'),
        id: 'actionId',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="points">
            {this.translate('Pontos')}
            <ReactTooltip
              id="points"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Pontos')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.points ? Number(d.points).toFixed(2) : '--'),
        id: 'points',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="type">
            {this.translate('Tipo de pontos')}
            <ReactTooltip
              id="type"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Tipo de pontos')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.type ? d.type : '--'),
        id: 'type',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="step">
            Step
          </span>
        ),
        accessor: (d) => this.getStepColumn(d),
        id: 'step',
      },
    ];

    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-gift" />
          &nbsp; Lista de históricos de pontos
        </span>
        <div className="oq-line" />
        <div className="oq-padding-bottom" />
        <ReactTable
          data={historicalPoints}
          defaultPageSize={5}
          columns={columns}
          loading={this.state.isLoading}
          previousText="Anterior"
          nextText="Próxima"
          loadingText="Carregando..."
          noDataText="Nenhum resgate para ser exibido"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  async exportHitoricalPoints() {
    this.setState({ isLoading: true });
    const defaultArray = [];
    const defaultObject = {
      'ID DO PLAYER': '--',
      'ID DO USUÁRIO': '--',
      'ID DA PUBLICAÇÃO': '--',
      'ID DA INDICADO': '--',
      'DATA E HORA': '--',
      PONTOS: '--',
      'TIPO DE PONTOS': '--',
    };

    const { historicalPoints } = this.state;

    await historicalPoints.forEach((historical) => {
      const historicalObj = { ...defaultObject };

      if (historical.playerId)
        historicalObj['ID DO PLAYER'] = historical.playerId;
      if (historical.authUser && historical.authUser)
        historicalObj['ID DO USUÁRIO'] = historical.authUser;
      if (historical.actionId && historical.actionId)
        historicalObj['ID DA PUBLICAÇÃO'] = historical.actionId;
      if (historical.createdAt && historical.createdAt)
        historicalObj['DATA E HORA'] = moment(historical.createdAt).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      if (historical.points && historical.points)
        historicalObj['PONTOS'] = historical.points;
      if (historical.type && historical.type)
        historicalObj['TIPO DE PONTOS'] = historical.type;
      if (historical.leadId && historical.leadId)
        historicalObj['ID DO INDICADO'] = historical.leadId;

      defaultArray.push(historicalObj);
    });

    const ws = XLSX.utils.json_to_sheet(defaultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'HistoricoDePontos');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'HistoricoDePontos.xlsx'
    );
    this.setState({ isLoading: false });
  }

  renderSearchForm() {
    const pointsNode = [
      'extraPoints',
      'leadRegistration',
      'membergetmembergest',
      'membergetmember',
      'salesConversion',
      'landingPagePreview',
      'sharePoints',
      'omnichannelPoints',
      'advancedStepLead',
    ];

    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="col-md-8">
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder={this.translate('Id do player')}
                  name="playerId"
                  value={this.state.searchObject.playerId}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder={this.translate('Id do usuário')}
                  name="authUser"
                  value={this.state.searchObject.authUser}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder={this.translate('Id do indicado')}
                  name="leadId"
                  value={this.state.searchObject.leadId}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control input-oq"
                  placeholder={this.translate('Selecione um typo de pontuação')}
                  name="type"
                  value={
                    this.state.searchObject && this.state.searchObject.type
                  }
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleSearchInputChange(e)}
                >
                  <option value="">
                    {this.state.isLoading
                      ? this.translate('loading')
                      : this.translate('Selecione um tipo de pontuação...')}
                  </option>
                  {_.size(pointsNode) > 0 &&
                    _.map(pointsNode, (type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="row col-md-4">
              <div className="col-md-12 form-group pull-right">
                <button
                  className="btn btn-block btn-oq pull-right"
                  style={{ width: '70%' }}
                  disabled={this.state.isLoading}
                  onClick={() => this.filterList()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('Pesquisando')
                    : this.translate('Pesquisar')}
                </button>
              </div>
              <div className="col-md-10 form-group pull-right">
                <button
                  className="btn btn-oq pull-right"
                  onClick={() => this.exportHitoricalPoints()}
                  disabled={this.state.historicalPoints.length < 1}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  {this.translate('Exportar')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log('this.state.historicalPoints: ', this.state.historicalPoints);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderTableHistoricalPoints()}
          </div>
        </div>
      </div>
    );
  }
}

const HistoricalPointsLink = (props) => (
  <Link className="menu-link" to={routes.HISTORICAL_POINTS_CLIENT}>
    {/* <FontAwesomeIcon icon={faCoins} /> */}
    <span className="sidebarText">
      {props.translate('Histórico de pontos')}
    </span>
  </Link>
);

export default HistoricalPoints;

export { HistoricalPointsLink };
