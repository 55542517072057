import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import i18next from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import { api, getAPIURL } from '../../../../Config';
import ModalDeleteBenefit from '../../../components/Modals/EditBenefits';

import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

import { UserDataContext } from '../../../../hooks/useUserData';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class EditBenefits extends Component {
  static contextType = UserDataContext;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      formData: {
        benefitCode: '',
        benefitDescription: '',
        benefitLinkFacebook: '',
        benefitLinkInstagram: '',
        benefitLinkWhatsapp: '',
        benefitName: '',
        benefitImage: '',
        regionalBenefit: [],
        benefitStatus: '',
        benefitCategory: '',
        benefitLinkCompany: '',
      },
      formErrors: {},
      isLoading: false,
      isUpdate: false,
      isUploading: false,
      modalDeleteBenefitsIsOpen: false,
    };
    return initialState;
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    const { params } = this.props.match;
    this.getBenefitById(params.benefitId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3)
      );
      p = p + 10;
    }

    return result;
  }

  getBenefitById(benefitId) {
    const parameters = {
      searchFunctionality: 'getBenefit',
      userType: 'client',
      benefitId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }

        Notify(result.message, result.success ? 'success' : 'error');
        this.setState(
          {
            formData: {
              ...this.state.formData,
              ...result.data,
            },
            // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
            benefitId: result.data.uid,
            isLoading: false,
          },
          () => {
            this.validateForm();
          }
        );
      })
      .catch((error) => {
        console.log('getBenefitByiD error:', error);
      });
  }

  async handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!', 'warn');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleFormDataChange(fileIndex, data.data);
    this.setState({ isUploading: false });
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      })
      // () => {
      //   this.validateField(name, value);
      // }
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'benefitCode' &&
        inputKey !== 'benefitLinkFacebook' &&
        inputKey !== 'benefitLinkInstagram' &&
        inputKey !== 'benefitLinkWhatsapp' &&
        inputKey !== 'benefitLinkCompany'
      ) {
        emptyFieldsCount++;
      }
      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  editBenefit() {
    const { formValid, formData } = this.state;
    this.setState({ isUpdate: true });

    if (!formData.benefitImage) {
      Notify('Você deve escolher uma imagem para o seu banner!', 'warn');
      this.setState({ isUpdate: false });
      return;
    }

    if (!formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação do banner',
        'warn'
      );
      this.setState({ isUpdate: false });
      return;
    }

    this.setState({ isLoading: true }, () => {
      const { formData } = this.state;

      const parameters = {
        userType: 'client',
        searchFunctionality: 'editBenefit',
        formData,
      };

      api
        .post(`/create`, parameters)
        .then((response) => {
          const result = response.data;
          if (result.success) {
            this.setState(this.getInitialState());
          }
          Notify(result.message, result.success ? 'success' : 'error');
          this.setState(
            {
              formData: {
                ...this.state.formData,
                ...result.data,
              },
              // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
              benefitId: result.data.uid,
              isLoading: false,
              isUpdate: false,
            },
            () => {
              this.validateForm();
            }
          );
        })
        .catch((error) => {
          console.log('createBanner error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          margin: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  deleteSelectedBenefit(id) {
    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo beneficio. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deleteBenefit',
        userType: 'client',
        id,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          Notify('Benefício excluid', 'success');
          this.props.history.goBack();
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  toggleModalIsOpen() {
    this.setState({
      modalDeleteBenefitsIsOpen: !this.state.modalDeleteBenefitsIsOpen,
    });
  }

  deleteBenefit() {
    return (
      <ModalDeleteBenefit
        open={this.state.modalDeleteBenefitsIsOpen}
        close={this.toggleModalIsOpen.bind(this)}
        id={this.state.formData.uid}
        deleteSelectedBenefit={this.deleteSelectedBenefit.bind(this)}
      />
    );
  }

  renderRemoveBenefit() {
    return (
      <div className="col-6 text-center oq-padding-vertical oq-margin-top">
        <span
          className="oq-primary-color clickable oq-margin-top"
          onClick={() => this.toggleModalIsOpen()}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir Benefício')}
        </span>
      </div>
    );
  }

  render() {
    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryDark = Color(primaryColor).darken(0.45);
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    let PrimaryLighten2 = Color(primaryColor).lighten(1.5);
    let PrimaryLighten3 = Color(primaryColor).lighten(2.3);
    let PrimaryLighten4 = Color(primaryColor).lighten(3.0);
    let PrimaryLighten5 = Color(primaryColor).lighten(3.9);
    let PrimaryFade = Color(primaryColor).fade(2);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };
    const regionalBenefit =
      this.context.clientData &&
      this.context.clientData.regionalBenefitName &&
      this.context.clientData.regionalBenefitName !== ''
        ? this.context.clientData.regionalBenefitName
        : 'Segementação dos Benefícios';

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              {this.context.clientData.moduleBenefits &&
              this.context.clientData.moduleBenefits !== 'true' ? (
                this.renderNotAuthorization()
              ) : (
                <div id="content">
                  <ToastContainer />
                  <div className="container-fluid">
                    <div className="col-md-12">{this.renderBackButton()}</div>
                    <h4>Benefícios</h4>
                    <h1 className="oq-filter-title">
                      <i className="fa fa-rocket" />

                      <span>&nbsp; {this.translate('Novo Benefício')}</span>
                    </h1>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Nome *
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="name-benefit"
                        />
                        <ReactTooltip
                          id="name-benefit"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline={true}
                        >
                          <span>
                            Nome da empresa que está oferecendo o benefício
                          </span>
                        </ReactTooltip>
                      </label>
                      <input
                        required={false}
                        maxLength={24}
                        className="form-control profile-placeholder"
                        name="benefitName"
                        placeholder="Digite o nome"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitName}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Código promocional
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="promo-code"
                        />
                        <ReactTooltip
                          id="promo-code"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Não obrigatório. A empresa pode criar um código
                            único para os usuários da plataforma para medir o
                            número de aquisições/conversões{' '}
                          </span>
                        </ReactTooltip>
                      </label>
                      <input
                        required={false}
                        maxLength={24}
                        className="form-control profile-placeholder"
                        name="benefitCode"
                        placeholder="Digite o código"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitCode}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Link do instagram
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="insta-link"
                        />
                        <ReactTooltip
                          id="insta-link"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Não obrigatório. A empresa pode colocar o link da
                            rede social aqui
                          </span>
                        </ReactTooltip>
                      </label>
                      <input
                        required={false}
                        className="form-control profile-placeholder"
                        name="benefitLinkInstagram"
                        placeholder="Digite o link do instagram"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitLinkInstagram}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Link do facebook
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="face-link"
                        />
                        <ReactTooltip
                          id="face-link"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Não obrigatório. A empresa pode colocar o link da
                            rede social aqui
                          </span>
                        </ReactTooltip>
                      </label>
                      <input
                        required={false}
                        className="form-control profile-placeholder"
                        name="benefitLinkFacebook"
                        placeholder="Digite o link do facebook"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitLinkFacebook}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Link do whatsapp
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="wpp-link"
                        />
                        <ReactTooltip
                          id="wpp-link"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Não obrigatório. Caso o usuário queira redirecionar
                            os usuários para o Whatsapp.
                          </span>
                        </ReactTooltip>
                      </label>
                      <input
                        required={false}
                        className="form-control profile-placeholder"
                        name="benefitLinkWhatsapp"
                        placeholder="Digite o link do whatsapp"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitLinkWhatsapp}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="benefitName">
                        {this.translate('status')} *
                      </label>
                      <Select
                        closeMenuOnSelect
                        value={this.state.formData.benefitStatus}
                        required
                        components={{ SelectOption }}
                        placeholder={this.translate('choose_banner_status')}
                        onChange={(selectedOption) =>
                          this.handleFormDataChange(
                            'benefitStatus',
                            selectedOption
                          )
                        }
                        options={[
                          {
                            value: this.translate('active'),
                            label: this.translate('active'),
                          },
                          {
                            value: this.translate('inactive'),
                            label: this.translate('inactive'),
                          },
                        ]}
                      />
                    </div>
                    {this.context.clientData.useRegionalizationBenefit ===
                      'true' && (
                      <div className="form-group col-md-6" id="regionalBenefit">
                        <label className="input-oq-label">
                          {regionalBenefit}
                        </label>
                        {/* <select
                      required={true}
                      className="form-control input-oq rounded"
                      name="regionalBenefit"
                      onChange={selectedOption =>
                        this.handleUserInput(selectedOption)
                      }
                      disabled={
                        this.state.loadingData ||
                        this.state.loadingRegionalData ||
                        this.state.alreadySetRegional
                      }
                      value={this.state.formData.regionalBenefit}
                    >
                      <option value="">
                        {this.state.loadingRegionalData
                          ? 'Carregando ...'
                          : 'Escolha ...'}
                      </option>
                      {this.context.clientData.regionalBenefit &&
                        _.map(
                          this.context.clientData.regionalBenefit,
                          regional => {
                            return (
                              <option
                                value={
                                  regional
                                    ? normalizeLowerCase(regional)
                                    : regional
                                }
                              >
                                {regional}
                              </option>
                            );
                          }
                        )}
                    </select> */}

                        <Select
                          isMulti
                          required={true}
                          name="regionalBenefit"
                          options={_.map(
                            this.context.clientData.regionalBenefit,
                            (regional) => {
                              return {
                                value: normalizeLowerCase(regional),
                                label: regional,
                              };
                            }
                          )}
                          disabled={
                            this.state.loadingData ||
                            this.state.loadingRegionalData ||
                            this.state.alreadySetRegional
                          }
                          value={this.state.formData.regionalBenefit}
                          className="basic-multi-select"
                          defaultValue={this.state.formData.regionalBenefit}
                          onChange={(selectedOption) =>
                            this.handleFormDataChange(
                              'regionalBenefit',
                              selectedOption
                            )
                          }
                          classNamePrefix="select"
                        />
                        {console.log(
                          'this.state.formData: ',
                          this.state.formData
                        )}
                      </div>
                    )}
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="benefitName">
                        {this.translate('categoria')} *
                      </label>
                      <Select
                        closeMenuOnSelect
                        value={this.state.formData.benefitCategory}
                        required
                        components={{ SelectOption }}
                        placeholder={this.translate('choose_category')}
                        onChange={(selectedOption) =>
                          this.handleFormDataChange(
                            'benefitCategory',
                            selectedOption
                          )
                        }
                        options={[
                          {
                            value: this.translate('Acessórios'),
                            label: this.translate('Acessórios'),
                          },
                          {
                            value: this.translate('Alimentos e Bebidas'),
                            label: this.translate('Alimentos e Bebidas'),
                          },
                          {
                            value: this.translate(
                              'Brinquedos e Artigos Infantis'
                            ),
                            label: this.translate(
                              'Brinquedos e Artigos Infantis'
                            ),
                          },
                          {
                            value: this.translate('Cultura e Entretenimento'),
                            label: this.translate('Cultura e Entretenimento'),
                          },
                          {
                            value: this.translate('Educação'),
                            label: this.translate('Educação'),
                          },
                          {
                            value: this.translate('Eletroeletrônicos'),
                            label: this.translate('Eletroeletrônicos'),
                          },
                          {
                            value: this.translate('Eletrodomésticos'),
                            label: this.translate('Eletrodomésticos'),
                          },
                          {
                            value: this.translate(
                              'Internet e Telecomunicações'
                            ),
                            label: this.translate(
                              'Internet e Telecomunicações'
                            ),
                          },
                          {
                            value: this.translate('Materiais de Construção'),
                            label: this.translate('Materiais de Construção'),
                          },
                          {
                            value: this.translate('Materiais Esportivos'),
                            label: this.translate('Materiais Esportivos'),
                          },
                          {
                            value: this.translate('Materiais de Escritório'),
                            label: this.translate('Materiais de Escritório'),
                          },
                          {
                            value: this.translate('Móveis e Decoração'),
                            label: this.translate('Móveis e Decoração'),
                          },
                          {
                            value: this.translate('Saúde e bem-estar'),
                            label: this.translate('Saúde e bem-estar'),
                          },
                          {
                            value: this.translate('Vestuário'),
                            label: this.translate('Vestuário'),
                          },
                          {
                            value: this.translate('Turismo e Lazer'),
                            label: this.translate('Turismo e Lazer'),
                          },
                          {
                            value: this.translate('Outros'),
                            label: this.translate('Outros'),
                          },
                        ]}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="callActionText">
                        Descrição *
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="description"
                        />
                        <ReactTooltip
                          id="description"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Um breve texto para informar sobre o benefício para
                            o usuário.
                          </span>
                        </ReactTooltip>
                      </label>
                      <textarea
                        required
                        maxLength={140}
                        className="form-control profile-placeholder"
                        rows="3"
                        name="benefitDescription"
                        placeholder="Digite a descrição"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitDescription}
                      ></textarea>
                    </div>

                    <div className="col-md-6 form-group">
                      <label
                        className="profile-label w-100"
                        htmlFor="callActionText"
                      >
                        Link do site da empresa
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="link-site"
                        />
                        <ReactTooltip
                          id="link-site"
                          type="dark"
                          effect="solid"
                          className="tool-tip"
                          multiline
                        >
                          <span>
                            Não obrigatório. Caso a empresa queira redirecionar
                            o usuário para seu site.
                          </span>
                        </ReactTooltip>
                      </label>

                      <input
                        name="benefitLinkCompany"
                        id="benefitLinkCompany"
                        placeholder="Digite o link da empresa"
                        className="form-control profile-placeholder"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.formData.benefitLinkCompany}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        className="profile-label w-100"
                        htmlFor="callActionText"
                      >
                        Imagem *
                      </label>
                      <p className="tipText">{`${this.translate(
                        'recommended'
                      )}: 360x360`}</p>
                      {this.state.formData.benefitImage && (
                        <img
                          width="300px"
                          className="img-responsive img-thumbnail oq-margin-vertical"
                          src={this.state.formData.benefitImage}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="benefitImage"
                        onChange={async (event) => {
                          await this.handleUserImage(
                            event,
                            `BenefitImages/${this.state.currentUser.uid}/`,
                            'benefitImage'
                          );
                        }}
                      />
                    </div>

                    <div className="row col-md-12 justify-content-center d-flex">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          disabled={
                            this.state.isUploading || !this.state.formValid
                          }
                          onClick={() => {
                            this.editBenefit();
                          }}
                          className="btn btn-oq btn-oq-lg btn-block"
                        >
                          {this.translate('Salvar Benefício')}
                        </button>
                      </div>
                    </div>
                    {this.renderRemoveBenefit()}
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.deleteBenefit()}
        </div>
      </>
    );
  }
}

const BenefitsPageLink = (props) => (
  <Link className="menu-link" to={routes.BENEFITS_CLIENT}>
    <FontAwesomeIcon icon={faSmile} />
    <span className="sidebarText">{i18next.t('Benefícios')}</span>
  </Link>
);

export default EditBenefits;

export { BenefitsPageLink };
