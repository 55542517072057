import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

import Select from 'react-select';
import ReactTable from 'react-table';
import ToggleButton from 'react-toggle-button';
import _ from 'underscore';

import axios from 'axios';
import moment from 'moment';
import { api } from '../../../../Config';

import keyBy from 'lodash.keyby';

import { Link } from 'react-router-dom';
import ChangePassword from '../../../../AdminMaster/Modals/ChangePassword';
import ModalDeletePlayer from '../../../components/Modals/EditPlayer';

import AsyncPaginate from 'react-select-async-paginate';
import { Notify } from '../../../../components/Notify';
import '../../../../CSS/react-select-async-paginate.css';
import * as routes from '../../../../routes';
import * as mask from '../../../../utils/Mascaras';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import avatarImg from '../../../../Assets/Images/avatar.png';
import { UserDataContext } from '../../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

class EditPlayer extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      modalPasswordIsOpen: false,
      loadingPlayer: true,
      loadingPlayers: false,
      listPlayersHost: [{}],
      value: false,
      player: {},
      correctors: [],
      pointsLeads: 0,
      stateList: {},
      cityList: {},
      actions: [],
      actionsCount: 0,
      playerId: null,
      loadingActions: false,
      updatingPlayer: false,
      regional: [],
      modalDeletePlayerIsOpen: false,
      coupons: [],
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    console.log('context: ', this.context);
    if (this.context.clientData.clientUrl === 'indiqueeganhe') {
      await this.getBrokersRelational();
    }

    await this.getStateList();
    this.getCityList();
    this.setState({ playerId: params.playerId });
    await this.getPlayerById(params.playerId);
    await this.getParticipatedPlayerActions(params.playerId);
    if (this.context.clientData.clientUrl === 'emr') {
      await this.getCouponPlayer(params.playerId);
    }

    if (
      this.context.clientData.mgmVinculated &&
      this.context.clientData.mgmVinculated === 'true'
    ) {
      await this.getPlayersGuest(params.playerId);
    }
    // this.getPlayersIndicated(params.playerId);
    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true' &&
      this.state.player &&
      !this.state.player.influencer &&
      this.state.player.influencer !== true
    ) {
      await this.getInfluencer();
    }
    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true'
    ) {
      await this.getTeamMembers(this.context.clientData.uid, params.playerId);
    }

    this.setState({ regional: this.context.clientData.regional });
  }

  async getTeamMembers(clientId, playerId) {
    this.setState({ loadingTeamMembers: true }, () => {
      const parameters = {
        searchFunctionality: 'getTeamMembers',
        userType: 'client',
        clientId,
        playerId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;
          if (result.success) {
            this.setState({
              teamLeader: result.data.teamLeader,
              teamMembers: result.data.teamMembers,
              loadingTeamMembers: false,
            });
          }
        })
        .catch((error) => {
          console.log('getTeamMembers error:', error);
          this.setState({ loadingTeamMembers: false });
        });
    });
  }

  async getPlayersGuest(playerId) {
    const { clientData } = this.context;

    const parameters = {
      userType: 'player',
      searchFunctionality: 'getGuestPlayer',
      playerId,
      clientId: clientData.uid,
    };

    await api
      .post(`/search`, parameters)
      .then((res) => {
        const result =
          res.data && res.data.data && res.data.data.playersGuest
            ? res.data.data.playersGuest
            : [];
        this.setState({ playerGuest: result });
      })
      .catch((error) => {
        console.log('getGuestPlayer error: ', error);
      });
  }

  async getCityList(estado = 'MG') {
    await axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
      )
      .then((city) => {
        this.setState({ cityList: city.data });
      })
      .catch((error) => {
        console.log('getCityList error:', error);
      });
  }

  async getInfluencer() {
    const parameters = {
      searchFunctionality: 'getInfluencers',
      userType: 'client',
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;

    this.setState({
      formData: {
        ...this.state.formData,
        listInfluencer: result.data.players,
      },
    });
  }

  async getBrokersRelational() {
    try {
      let parameters = {
        userType: 'client',
        searchFunctionality: 'getBrokersRelational',
      };

      let endPoint = `/search`;
      await api
        .post(endPoint, parameters)
        .then((response) => {
          this.setState({ correctors: response.data.data });
        })
        .catch((e) => console.log('getBrokersRelational', e));
    } catch (error) {
      //Notify("Não foi possivel atribuir a pontuação!", "info_sad");
      console.log('addPointsToPlayer error:', error);
    }
  }

  async getStateList() {
    await axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getPlayerById(playerId) {
    const { clientData } = this.context;

    this.setState({ loadingPlayer: true });

    const parameters = {
      searchFunctionality: 'getPlayer',
      userType: 'client',
      playerId,
      clientId: clientData && clientData.uid,
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;
    if (!result.success) {
      Notify(result.message, result.success ? 'success' : 'error');
      return;
    }

    const { player, listPlayersHost, pointsLeads } = result.data;

    let playerHost;

    if (player.hostId && result.data.listPlayersHost.length > 0) {
      playerHost = listPlayersHost.find((playerhost) => {
        return player.hostId === playerhost.uid;
      });
    }

    if (result.success) {
      Notify(result.message, result.success ? 'success' : 'error');
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        player,
      },
      playerHostInfor: {
        ...this.state.playerHostInfor,
        playerNameHost: playerHost && playerHost.name,
        playerEmailHost: playerHost && playerHost.email,
      },
      player,
      pointsLeads,
      listPlayersHost: listPlayersHost.length > 0 ? listPlayersHost : [{}],
      loadingPlayer: false,
    });

    this.getCityList(player.state);
  }

  async getParticipatedPlayerActions(playerId) {
    const { uid } = this.context.clientData;
    this.setState({ loadingActions: true }, () => {
      let parameters = {
        searchFunctionality: 'getParticipatedPlayerActions',
        userType: 'client',
        playerId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;
          const actions = keyBy(result.data.participatedActions, 'id');
          this.setState({ actions, loadingActions: false });

          _.each(actions, async (values) => {
            const actionId = values.id;
            parameters = {
              searchFunctionality: 'getActionRanking',
              userType: 'client',
              clientId: uid,
              actionId,
            };

            res = await api.post(`/search`, parameters);

            const rankingPlayer = res.data.data.filter((player) => {
              return player.uid === playerId;
            });

            actions[actionId].points = rankingPlayer[0]
              ? rankingPlayer[0].points
              : '--';
            actions[actionId].position = rankingPlayer[0]
              ? rankingPlayer[0].ranking
              : '--';

            this.setState({ actions });
          });
        })
        .catch((error) => {
          console.log('getParticipatedPlayerActions error:', error);
          this.setState({ loadingActions: false });
        });
    });
  }

  getPlayersIndicated(playerId) {
    // const { uid } = this.context.clientData;
    this.setState({ loadingPlayers: true }, async () => {
      const parameters = {
        searchFunctionality: 'getPlayersIndicatedMGM',
        userType: 'client',
        playerId,
      };

      await api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          this.setState({
            playersIndicated: result.data,
            loadingPlayers: false,
          });
        })
        .catch((error) => {
          console.log('getParticipatedPlayerIndicated error:', error);
          this.setState({ loadingPlayers: false });
        });
    });
  }

  disablePlayer(disabled) {
    const { playerId, player } = this.state;
    this.setState({ updatingPlayer: true }, () => {
      const parameters = {
        updateFunctionality: 'disablePlayer',
        userType: 'client',
        playerId,
        status: disabled,
        clientId: player.clientId,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          const result = res.data;
          // const { player } = this.state;
          player.active = disabled;
          if (result.success) {
            this.setState({ player });
          }

          this.setState({ updatingPlayer: false });
        })
        .catch((error) => {
          this.setState({ updatingPlayer: false });
          console.log('disablePlayer error:', error);
        });
    });
  }

  handleUserInput(e) {
    const { name, value, type } = e.target;
    let valueAbs = value;
    if (type === 'number') {
      valueAbs = Math.abs(value);
    }

    this.setState((prevState) => ({
      player: {
        ...prevState.player,
        [name]: valueAbs,
      },
    }));
  }

  updateFormData(name, value) {
    this.setState((prevState) => ({
      player: {
        ...prevState.player,
        [name]: value,
      },
    }));
  }

  handleHostSelectChange(
    namePlayerHost,
    emailPlayerHost,
    valeNamePlayerHost,
    valueEmailPlayerHost,
    uid
  ) {
    this.setState((prevState) => ({
      formData: {
        ...this.state.formData,
        player: {
          ...prevState.player,
          [namePlayerHost]: valeNamePlayerHost,
          [emailPlayerHost]: valueEmailPlayerHost,
          hostId: uid,
        },
      },
      player: {
        ...prevState.player,
        [namePlayerHost]: valeNamePlayerHost,
        [emailPlayerHost]: valueEmailPlayerHost,
        hostId: uid,
      },
      playerHostInfor: {
        [namePlayerHost]: valeNamePlayerHost,
        [emailPlayerHost]: valueEmailPlayerHost,
        hostId: uid,
      },
    }));
  }

  handleUserInputChange(name, value) {
    this.setState((prevState) => ({
      player: {
        ...prevState.player,
        [name]: value,
      },
    }));
  }

  getState(item) {
    if (this.state.player) {
      return _.get(this.state, item, '');
    }
  }

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  brokersOptions(search, prevOptions) {
    let filteredOptions;
    if (!search) {
      filteredOptions = this.state.correctors;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = this.state.correctors.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    const newSliceOtptions = _.map(slicedOptions, (option) => {
      return {
        value: option.idcorretor,
        label: `${option.nome} - ${option.imobiliaria}`,
      };
    });
    return {
      options: newSliceOtptions,
      hasMore,
    };
  }

  handleSelectedBrokers(e) {
    const brokerFinded = _.find(
      this.state.correctors,
      (broker) => e.value === broker.idcorretor
    );
    this.setState((prevState) => ({
      player: {
        ...prevState.player,
        corrector: { label: brokerFinded.nome, value: brokerFinded.idcorretor },
      },
    }));
  }
  addCupom() {
    const newCupom = [...this.state.coupons];
    newCupom.push({ value: '' });
    this.setState({ coupons: newCupom });
  }

  removeCupom(index) {
    const arrCoupons = [...this.state.coupons];
    const removedCupom = arrCoupons.filter(
      (cupom, indexCupom) => indexCupom !== index
    );
    this.setState({ coupons: removedCupom });
  }

  handleCupom(e, index) {
    const arrCoupons = [...this.state.coupons];
    const valueCupom = e.target.value;
    arrCoupons[index].value = valueCupom;

    this.setState({ coupons: arrCoupons });
  }

  renderProfileFields() {
    const {
      player,
      loadingPlayer,
      isLoading,
      listPlayersHost,
      playerHostInfor,
    } = this.state;
    const playerRegional = player.regional ? player.regional : '';
    let playerRegionaoWithNormalize;

    if (typeof playerRegional !== 'string' && playerRegional.length > 0) {
      playerRegionaoWithNormalize =
        playerRegional[0] &&
        playerRegional[0].label &&
        normalizeLowerCase(playerRegional[0].label);
    } else {
      playerRegionaoWithNormalize = normalizeLowerCase(playerRegional);
    }

    return (
      <div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Nome do usuário')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="name"
              name="name"
              value={player.name}
              disabled={loadingPlayer}
              onChange={(e) => this.handleUserInput(e)}
              placeholder={this.translate('Nome do usuário')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="cpf">
              {this.translate('CPF do usuário')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="cpf"
              name="cpf"
              disabled={loadingPlayer}
              value={player.cpf}
              placeholder={this.translate('CPF do usuário')}
              onChange={(event) => {
                const input = event.target;
                if (input.value.length <= 14) {
                  const maskCPF = mask.maskCPF(input.value);
                  this.updateFormData(input.name, maskCPF);
                } else if (
                  input.value.length > 14 &&
                  input.value.length <= 18
                ) {
                  const maskCNPJ = mask.maskCNPJ(input.value);
                  this.updateFormData(input.name, maskCNPJ);
                } else {
                  const maskedlimited = mask.maskLimited(input.value);
                  this.updateFormData(input.name, maskedlimited);
                }
              }}
            />
          </div>
        </div>
        {player.birthDate && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('Data de nascimento')}
              </label>
              <input
                type="date"
                className="form-control profile-placeholder"
                id="birthDate"
                name="birthDate"
                disabled={loadingPlayer}
                value={player.birthDate}
                placeholder={this.translate('Data de nascimento')}
                onChange={(e) => this.handleUserInput(e)}
              />
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('state')}
            </label>
            <select
              className="form-control profile-placeholder"
              disabled={loadingPlayer}
              onChange={(event) => {
                this.handleUserInput(event);
                this.getCityList(event.target.value);
              }}
              id="state"
              name="state"
              aria-describedby="stateHelp"
              value={player.state}
            >
              <option value="">Selecione um estado...</option>
              {this.state.stateList &&
                _.map(this.state.stateList, (state) => {
                  return <option value={state.sigla}>{state.nome}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="city">
              {this.translate('city')}
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              disabled={loadingPlayer}
              id="city"
              name="city"
              aria-describedby="cityHelp"
              value={player.city}
            >
              <option value="">Selecione a cidade...</option>
              {this.state.cityList &&
                _.map(this.state.cityList, (city) => {
                  return <option value={city.nome}>{city.nome}</option>;
                })}
            </select>
          </div>
        </div>
        {this.context.clientData.isTeamCompetition &&
          this.context.clientData.isTeamCompetition === 'true' &&
          this.state.player &&
          !this.state.player.influencer &&
          this.state.player.influencer !== true && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="influencer">
                  Influencer
                </label>
                <select
                  className="form-control profile-placeholder"
                  onChange={(event) => this.handleUserInput(event)}
                  disabled={loadingPlayer}
                  id="influencerId"
                  name="influencerId"
                  aria-describedby="influencerHelp"
                  value={player.influencerId}
                >
                  <option value="">Selecione o influencer...</option>
                  {this.state.formData &&
                    this.state.formData.listInfluencer &&
                    _.map(this.state.formData.listInfluencer, (influencer) => {
                      return (
                        <option value={influencer.uid}>
                          {influencer.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          )}
        {this.context.clientData.segmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.context.clientData &&
                this.context.clientData.scopeFieldName
                  ? this.context.clientData.scopeFieldName
                  : 'Você é corretor?'}
              </label>
              {/* {this.context.clientData.clientUrl === 'bild' && (
                <Select
                  isMulti
                  required={true}
                  name="regional"
                  options={_.map(this.context.clientData.regional, regional => {
                    return {
                      value: normalizeLowerCase(regional),
                      label: regional,
                    };
                  })}
                  disabled={loadingPlayer}
                  value={player.regional}
                  className="basic-multi-select"
                  defaultValue={player.regional}
                  onChange={selectedOption =>
                    this.handleUserInputChange('regional', selectedOption)
                  }
                  classNamePrefix="select"
                />
              )} */}
              {/* {this.context.clientData.clientUrl !== 'bild' && (
                <select
                  disabled={loadingPlayer}
                  className="form-control profile-placeholder"
                  id="regional"
                  name="regional"
                  value={playerRegionaoWithNormalize}
                  onChange={e => this.handleUserInput(e)}
                >
                  <option value="">Escolha ...</option>
                  {this.context.clientData.regional &&
                    this.context.clientData.regional.map(regional => (
                      <option
                        value={
                          regional ? normalizeLowerCase(regional) : regional
                        }
                      >
                        {regional}
                      </option>
                    ))}
                </select>
              )} */}
              <select
                disabled={loadingPlayer}
                className="form-control profile-placeholder"
                id="regional"
                name="regional"
                value={playerRegionaoWithNormalize}
                onChange={(e) => this.handleUserInput(e)}
              >
                <option value="">Escolha ...</option>
                {this.context.clientData.regional &&
                  this.context.clientData.regional.map((regional) => (
                    <option
                      value={regional ? normalizeLowerCase(regional) : regional}
                      key={regional}
                    >
                      {regional}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}

        {this.context.clientData.secondarySegmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                Segunda Segmentação -{' '}
                {this.context.clientData.nameSecondarySegmentation}
              </label>
              <select
                disabled={loadingPlayer}
                className="form-control profile-placeholder"
                id="secondarySegmentation"
                name="secondarySegmentation"
                value={player.secondarySegmentation}
                onChange={(e) => this.handleUserInput(e)}
              >
                <option value="">Escolha ...</option>
                {this.context.clientData.secondarySegmentation &&
                  this.flatten(
                    this.context.clientData.secondarySegmentation
                  ).map((segmentation) => (
                    <option value={segmentation.id} key={segmentation.id}>
                      {segmentation.text}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}

        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Celular')}
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                mask.maskTEL(input.value).then((masked) => {
                  this.updateFormData(input.name, masked);
                });
              }}
              disabled={loadingPlayer}
              maxLength={15}
              minLength={15}
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumberHelp"
              value={player.phoneNumber}
              placeholder="Telefone"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              E-mail
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="email"
              name="email"
              disabled
              value={player.email}
              placeholder="E-mail"
            />
          </div>
        </div>
        {this.context.clientData &&
          this.context.clientData.clientUrl === 'indiqueeganhe' && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="inputCorrectors">
                  Corretor
                </label>
                <AsyncPaginate
                  isDisabled={loadingPlayer}
                  id="custon-select-async-paginate"
                  // className="form-group"
                  value={
                    player.corrector
                      ? player.corrector
                      : { label: 'Nenhum', value: '' }
                  }
                  loadOptions={(search, prevOptions) =>
                    this.brokersOptions(search, prevOptions)
                  }
                  placeholder="Selecione um player..."
                  onChange={(e) => this.handleSelectedBrokers(e)}
                />
              </div>
            </div>
          )}
        {this.context.clientData &&
          this.context.clientData.mgmVinculated &&
          this.context.clientData.mgmVinculated === 'true' && (
            <>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="playerHostInfo">
                    Anfitrião
                  </label>
                  <Select
                    required
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    value={
                      playerHostInfor &&
                      playerHostInfor.playerNameHost && {
                        value: playerHostInfor.playerNameHost,
                        label: playerHostInfor.playerNameHost,
                      }
                    }
                    options={
                      listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, (playersHost) => {
                        return {
                          value: playersHost.name,
                          label: playersHost.name,
                        };
                      })
                    }
                    placeholder={
                      playerHostInfor && playerHostInfor.playerNameHost
                        ? playerHostInfor.playerNameHost
                        : 'Selecione aqui...'
                    }
                    onChange={(e) => {
                      const newPlayerHost = _.find(
                        listPlayersHost,
                        (player) => player.name === e.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.value,
                        newPlayerHost.email,
                        newPlayerHost.uid
                      );
                    }}
                  />
                  {/* <select
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.name === e.target.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.target.value,
                        newPlayerHost.email,
                        newPlayerHost.uid
                      );
                    }}
                    placeholder={
                      playerHostInfor && playerHostInfor.playerNameHost
                    }
                    value={playerHostInfor && playerHostInfor.playerNameHost}
                  >
                    {listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return (
                          <option value={playersHost.name} selected={player.playerNameHost === playersHost.name}>
                            {playersHost.name}
                          </option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="playerHostInfo">
                    E-mail do anfitrião
                  </label>
                  <Select
                    required
                    className="form-control profile-placeholder"
                    name="playerHostInfo" // playerHostInfor && playerHostInfor.playerEmailHost
                    value={
                      playerHostInfor &&
                      playerHostInfor.playerEmailHost && {
                        value: playerHostInfor.playerEmailHost,
                        label: playerHostInfor.playerEmailHost,
                      }
                    }
                    options={
                      listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, (playersHost) => {
                        return {
                          value: playersHost.email,
                          label: playersHost.email,
                        };
                      })
                    }
                    placeholder={
                      playerHostInfor && playerHostInfor.playerEmailHost
                    }
                    onChange={(e) => {
                      const newPlayerHost = _.find(
                        listPlayersHost,
                        (player) => player.email === e.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.value,
                        newPlayerHost.name,
                        newPlayerHost.uid
                      );
                    }}
                  />

                  {/* <select
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.email === e.target.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        newPlayerHost.name,
                        e.target.value,
                        newPlayerHost.uid
                      );
                    }}
                    placeholder={
                      playerHostInfor && playerHostInfor.playerEmailHost
                    }
                    value={playerHostInfor && playerHostInfor.playerEmailHost}
                  >
                    {listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return (
                          <option value={playersHost.email} selected={player.playerEmailHost === playersHost.email}>
                            {playersHost.email}
                          </option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
            </>
          )}

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('ID Customizado')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="idCustomer"
              name="idCustomer"
              onChange={(e) => this.handleUserInput(e)}
              value={player.idCustomer}
              placeholder={this.translate('ID Customizado')}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Data de adesão')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="createdAt"
              name="createdAt"
              disabled
              value={moment(player.createdAt).format('DD/MM/YYYY')}
              placeholder={this.translate('Data de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Tempo de adesão')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="createdAtAdesao"
              name="createdAtAdesao"
              disabled
              value={`${moment().diff(
                moment(player.createdAt),
                'days'
              )} dia(s)`}
              placeholder={this.translate('Tempo de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              Leads
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="leadsCount"
              name="leadsCount"
              disabled
              value={player.leads ? player.leads : 0}
              placeholder="Leads"
            />
          </div>
        </div>
        {this.context.clientData &&
          this.context.clientData.clientUrl === 'bomnegocio' && (
            <>
              <div className="col-md-12">
                <h3>Dados do imóvel</h3>
                <br />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Quartos
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyBedrooms}
                    placeholder=" Quartos"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    CEP
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyCep}
                    placeholder=" CEP"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Número
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyNumber}
                    placeholder="Número"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Área de lazer
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyRecreation}
                    placeholder="Área de lazer"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Metros²
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonySize}
                    placeholder="Metros²"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Tempo de construção
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyTimeConstruction}
                    placeholder="Tempo de construção"
                  />
                </div>
              </div>
            </>
          )}
        {player && player.influencer && player.influencer === true ? (
          <div className="col-md-6">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.translate('Código de Verificação')}
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                id="salesConversion"
                name="salesConversion"
                disabled
                value={
                  player && player.validationCode ? player.validationCode : '--'
                }
                placeholder={this.translate('Conversão')}
              />
            </div>
          </div>
        ) : null}

        {/* aqui código pros coupons */}

        {this.context.clientData.clientUrl === 'emr' && (
          <>
            {_.map(this.state.coupons, (cupom, index) => (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Cupom {index + 1}
                  </label>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="text"
                      className="form-control profile-placeholder"
                      id={`cupom${index}`}
                      name={`cupom${index}`}
                      value={cupom.value}
                      onChange={(e) => this.handleCupom(e, index)}
                      placeholder={`Cupom ${index + 1}`}
                    />
                    <button
                      className="btn"
                      style={{ backgroundColor: 'transparent' }}
                      data-index={index}
                      onClick={(e) => this.removeCupom(index)}
                    >
                      <i className="material-icons">delete</i>
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {this.state.coupons.length <= 2 && (
              <div className="col-md-6">
                <button
                  className="btn btn-block btn-oq"
                  onClick={(e) => this.addCupom()}
                >
                  Adicionar Cupom
                </button>
              </div>
            )}
          </>
        )}

        <div className="col-md-12">
          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center oq-padding-vertical">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                onClick={() => this.updatePlayer()}
                disabled={isLoading}
              >
                <span>{isLoading ? 'Salvando...' : 'Salvar'}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getPlayerColor(points) {
    const totalPoints =
      points &&
      points.extraPoints +
        points.leadRegistration +
        points.membergetmember +
        points.membergetmembergest +
        points.salesConversion;
    let color = '#000000';

    if (totalPoints < 999) {
      color = 'yellow';
    } else if (totalPoints < 4999) {
      color = 'orange';
    } else if (totalPoints < 9999) {
      color = 'blue';
    } else if (totalPoints < 19999) {
      color = 'green';
    } else if (totalPoints < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { player, pointsLeads } = this.state;
    player.availablePoints =
      Number(player.totalPoints ? player.totalPoints : 0) -
      Number(player.redeemedPoints ? player.redeemedPoints : 0);

    let colorPoints = '';
    if (player.totalPoints - pointsLeads > 0) {
      colorPoints = '#ff0000';
    } else if (player.totalPoints - pointsLeads < 0) {
      colorPoints = '#008000';
    }

    return (
      <div>
        <div className="oq-profile">
          <img
            src={player && player.photoURL ? player.photoURL : avatarImg}
            className="oq-profile-picture new-height"
            alt="player logo"
          />
          {/* <div className="teste" style={{ position: 'relative', right: '5%' }}>
            <svg
              className="oq-profile-trophy oq-profile-trophy-player-details"
              width="30px"
              height="30px"
              viewBox="0 0 22.529 25.012"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              // color={Color(this.getPlayerColor(player.points)).lighten(1.5)}

              data-tip="React-tooltip"
              data-for="pontuationRules"
              currentItem="false"
              style={{
                padding: 4,
                backgroundColor: this.getPlayerColor(player.points),
              }}
            >
              <defs />
              <g transform="translate(-445.444 -679.25)">
                <path
                  className="stroke"
                  fill="#fff"
                  d="M462.77,703.512H450.646a.961.961,0,0,1-.67-1.65l1.687-1.64a7.239,7.239,0,0,1,5.045-2.048h0a7.24,7.24,0,0,1,5.046,2.048l1.686,1.64A.961.961,0,0,1,462.77,703.512Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M456.708,693.871h0a7.606,7.606,0,0,1-7.606-7.607V680h15.213v6.264A7.607,7.607,0,0,1,456.708,693.871Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M448.509,687.237a2.315,2.315,0,0,1-2.315-2.315h0a2.314,2.314,0,0,1,2.315-2.315"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M464.908,682.607a2.314,2.314,0,0,1,2.315,2.315h0a2.315,2.315,0,0,1-2.315,2.315"
                />
                <line
                  className="stroke"
                  y2="2.883"
                  transform="translate(456.708 694.863)"
                />
              </g>
            </svg>
          </div> */}
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.totalPoints ? player.totalPoints.toFixed(1) : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.redeemedPoints
                ? player.redeemedPoints.toFixed(1)
                : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos Resgatados</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.availablePoints
                ? player.availablePoints.toFixed(1)
                : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos Disponíveis</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points" style={{ color: colorPoints }}>
              {pointsLeads || 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos dos Leads</span>
          </div>
        </div>
        <div className="oq-status-box row">
          <div className="col-md-4 col-md-offset-4 oq-status-box-switch">
            <ToggleButton
              disabled={this.state.updatingPlayer}
              inactiveLabel={<i className="fa fa-times" aria-hidden="true" />}
              activeLabel={<i className="fa fa-check" aria-hidden="true" />}
              value={player.active}
              trackStyle={{ width: '100%' }}
              onToggle={(value) => this.disablePlayer(!value)}
            />
          </div>
          <div className="col-md-4 oq-status-box-label">
            <span>
              {this.state.updatingPlayer
                ? this.translate('ATUALIZANDO STATUS...')
                : player.active
                  ? this.translate('DESATIVAR USUÁRIO')
                  : this.translate('ATIVAR USUÁRIO')}
            </span>
          </div>
        </div>
        {player && !player.active && player.disabledAt && (
          <div className="oq-box">
            <div className="oq-points-box">
              <span className="oq-points-desc">Desativado em</span>
              <br />
              <span className="oq-points">
                {moment(player.disabledAt).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  renderActionsTable() {
    const data = _.values(this.state.actions);

    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'actionTitle',
      },
      {
        Header: this.translate('start-date'),
        id: 'startDateEpoch',
        accessor: (d) =>
          d.startDateEpoch ? moment(d.startDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('end-date'),
        id: 'endDateEpoch',
        accessor: (d) =>
          d.endDateEpoch ? moment(d.endDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('totalPontuation'),
        id: 'points',
        accessor: (d) =>
          d.points ? this.numberWithCommas(Number(d.points).toFixed(1)) : 0,
      },
      {
        Header: this.translate('Posição'),
        id: 'position',
        accessor: (d) => (d.position ? `${d.position}º` : 'Carregando...'),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => {
          return (
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_ACTION_VIRTUAL}/${props.value}`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('DETALHES')}
              </Link>
            </span>
          );
        },
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingActions}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhuma ação para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('actions')}
      />
    );
  }

  renderTablePlayerGuest() {
    const data = _.values(this.state.playerGuest);
    const columns = [
      {
        Header: '',
        Cell: (props) => {
          return (
            <div>
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                }}
                src={props.original.photoURL}
              />
            </div>
          );
        },
        minWidth: 32,
      },
      {
        Header: 'Nome',
        id: 'name',
        accessor: (d) => d.name,
      },
      {
        Header: 'pontos totais',
        id: 'totalPoints',
        accessor: (d) => d.totalPoints,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => {
          return (
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_PLAYER}/${props.original.uid}/`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('DETALHES')}
              </Link>
            </span>
          );
        },
        minWidth: 54,
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingPlayers}
        defaultPageSize={3}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhuma ação para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText="prospectores"
      />
    );
  }

  renderTeamMembersTable() {
    const data = _.values(this.state.teamMembers);

    const columns = [
      {
        Header: this.translate('Avatar'),
        Cell: (row) => {
          return (
            <div>
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                }}
                src={row.original.photoURL}
              />
            </div>
          );
        },
        minWidth: 15,
      },
      {
        Header: this.translate('name'),
        accessor: 'name',
      },

      // {
      //   Header: '',
      //   accessor: 'id',
      //   Cell: props => {
      //     return (
      //       <span className="text-center clickable">
      //         <Link
      //           to={`${routes.EDIT_ACTION}/${props.value}`}
      //           className="btn btn-oq btn-sm btn-block"
      //         >
      //           {this.translate('DETALHES')}
      //         </Link>
      //       </span>
      //     );
      //   },
      // },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingTeamMembers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum membro da equipe para ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('Membros')}
      />
    );
  }

  renderPlayersIndicatedTable() {
    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'name',
      },
      {
        Header: this.translate('email'),
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        Header: this.translate('date'),
        id: 'createdAt',
        accessor: (d) =>
          d.createdAt ? moment(d.createdAt).format('DD/MM/YYYY') : 0,
      },
    ];
    return (
      <ReactTable
        data={this.state.playersIndicated}
        loading={this.state.loadingPlayers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum usuário para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('players')}
      />
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderPlayerGuestList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; Lista de prospectores
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTablePlayerGuest()}</div>
      </div>
    );
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          {/* &nbsp; {this.translate('Lista de ações do usuário')} */}
          &nbsp; {this.translate('Lista de publicações do usuário')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderActionsTable()}</div>
      </div>
    );
  }

  renderListTeamMembers() {
    const { teamLeader } = this.state;
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp;{' '}
          {this.translate(
            `${
              teamLeader
                ? `Lista da equipe ${
                    (teamLeader && teamLeader.name) || 'carregando'
                  }`
                : 'Lista da equipe'
            }`
          )}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTeamMembersTable()}</div>
      </div>
    );
  }

  renderPlayersIndicated() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; {this.translate('Lista de players indicados')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          {this.renderPlayersIndicatedTable()}
        </div>
      </div>
    );
  }

  async updateCoupomPlayer() {
    const { player, coupons } = this.state;

    try {
      this.setState({ isLoading: true });

      const parameters = {
        userType: 'client',
        updateFunctionality: 'updateCoupomPlayer',
        playerId: player.uid,
        clientId: player.clientId,
        coupons,
      };

      const responseUpdateCupom = await api.post(`/update`, parameters);
    } catch (error) {
      this.setState({ isLoading: false });
      console.log('erro updateCoupomPlayer', error);
    }
  }

  async getCouponPlayer(playerId) {
    const { clientData } = this.context;

    try {
      // this.setState({ isLoading: true });

      const parameters = {
        userType: 'client',
        searchFunctionality: 'getCouponPlayer',
        playerId: playerId,
        clientId: clientData.uid,
      };

      const responseGetCupom = await api.post(`/search`, parameters);

      if (responseGetCupom.data.success) {
        console.log('coupons: ', responseGetCupom.data.data.coupons);
        const { coupons } = responseGetCupom.data.data;
        this.setState({ coupons });
      } else {
        this.setState({ coupons: [] });
      }
    } catch (error) {
      // this.setState({ isLoading: false });
      console.log('erro getCouponPlayer: ', error);
      this.setState({ coupons: [] });
    }
  }

  async updatePlayer(deleting = false) {
    const { clientData } = this.context;
    // const clientId = user && user.userData && user.userData.clientId;

    if (this.state.coupons.length > 0) {
      await this.updateCoupomPlayer();
    }

    const { player } = this.state;
    const targetPublic = clientData.targetPublic;
    // player.deleted = true;

    console.log('public: ', targetPublic);
    console.log('client: ', clientData);

    player.editing = true;

    this.setState({ isLoading: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'updatePlayer',
        playerData: { ...player, targetPublic },
        // targetPublic: targetPublic,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          this.setState({ isLoading: false });
          const result = response.data;

          if (deleting && result.success) {
            this.props.history.goBack();
          }

          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('updatePlayer error:', error);
        });
    });
  }

  deletePlayerConfirm() {
    const { clientData } = this.context;

    const parameters = {
      searchFunctionality: 'deletePlayer',
      userType: 'client',
      playerId: this.state.player.uid,
      clientId: clientData && clientData.uid,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({ loadingBenefits: false });
        console.log('searchSeasons error:', error);
      });
  }

  toggleModalDeletePlayer() {
    this.setState({
      modalDeletePlayerIsOpen: !this.state.modalDeletePlayerIsOpen,
    });
  }

  deletePlayer() {
    return (
      <ModalDeletePlayer
        open={this.state.modalDeletePlayerIsOpen}
        close={this.toggleModalDeletePlayer.bind(this)}
        deleteSelectedPlayer={this.deletePlayerConfirm.bind(this)}
      />
    );
  }

  openPasswordModal() {
    this.toggleModal();
  }

  toggleModal() {
    this.setState({ modalPasswordIsOpen: !this.state.modalPasswordIsOpen });
  }

  renderModal() {
    const { player } = this.state;
    return (
      <ChangePassword
        open={this.state.modalPasswordIsOpen}
        playerData={player}
        toggleModal={() => this.toggleModal()}
      />
    );
  }

  render() {
    const { groupPermissions, userData } = this.context;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do usuário')}
              </h1>
              <div className="row">
                <div className="col-md-3">{this.renderProfilePicDetails()}</div>
                <div className="col-md-9">{this.renderProfileFields()}</div>
              </div>
              {this.context.clientData &&
                this.context.clientData.isTeamCompetition &&
                this.context.clientData.isTeamCompetition === 'true' && (
                  <div className="col-md-12">
                    {this.renderListTeamMembers()}
                  </div>
                )}
              {this.context.clientData &&
                this.context.clientData.mgmVinculated &&
                this.context.clientData.mgmVinculated === 'true' &&
                this.state.player &&
                this.state.player.regional &&
                this.state.player.regional === 'sim' && (
                  <div className="col-md-12">
                    {this.renderPlayerGuestList()}
                  </div>
                )}
              <div className="col-md-12">{this.renderTargetPublicList()}</div>
              {this.state.playersIndicated && (
                <div className="col-md-12">{this.renderPlayersIndicated()}</div>
              )}

              <div className="col-12 text-center oq-padding-vertical">
                <span
                  className="oq-primary-color clickable"
                  style={{ marginRight: '2rem' }}
                  onClick={() => this.openPasswordModal()}
                >
                  <i className="fa fa-key" aria-hidden="true" />
                  &nbsp; Alterar senha
                </span>
                <span
                  className="oq-primary-color clickable"
                  onClick={() => {
                    this.toggleModalDeletePlayer();
                  }}
                >
                  <i className="far fa-trash-alt" aria-hidden="true" />
                  &nbsp; {this.translate('Excluir usuário')}
                </span>
              </div>
              {this.deletePlayer()}
            </div>
          </div>
          {this.renderModal()}
        </div>
      </div>
    );
  }
}

export default EditPlayer;
