import './style.css';

import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'underscore';
import { withNamespaces } from 'react-i18next';

class ResponseProcessBatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processBatches: [],
      unprocessedBatches: [],
      totalValueProcessed: 0,
      totalValueUnprocessed: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resultProcessBatches) {
      // const teste = [
      //   {
      //     code: 200,
      //     success: true,
      //     message: 'Lote processado com sucesso',
      //     data: {
      //       rescue: {
      //         accountData: {
      //           paymentMethod: { label: 'pix', value: true },
      //           pixkey: '(86) 99979-7405',
      //           typePix: 'TELEFONE',
      //         },
      //         awards: [
      //           {
      //             active: true,
      //             amount: 1,
      //             awardsRescued: 202,
      //             clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //             createDate: 1620392110488,
      //             fileIndex: 'upddxgty.jpg',
      //             inStock: 9797,
      //             lastUpdate: 1659029718337,
      //             paymentValue: '100',
      //             premiumDescription: 'cem conto',
      //             premiumId: '-M_5tLRTY12d6692Z7ei',
      //             premiumImage:
      //               'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Premium%2Flxq3XprR3VarSdVol64ZRRf77QD2%2Fupddxgty.jpg?alt=media&token=abc971a3-2e7b-4ae8-9913-1f4d432979e8',
      //             premiumName: 'Cem reais',
      //             premiumPrice: 200,
      //             premiumQuantity: '9999',
      //             premiumStatus: { label: 'Ativo', value: 'Ativo' },
      //             premiumType: { label: 'BS2', value: 'BS2' },
      //             scope: 'national',
      //             seasonId: 'false',
      //             subtotal: 100,
      //             valueType: { label: 'Fixo', value: 'fixed' },
      //           },
      //         ],
      //         batchId: '134778',
      //         clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //         closeProccess: true,
      //         createdAt: 1660245014249,
      //         deliverAddress: {
      //           cep: '',
      //           city: '',
      //           complement: '',
      //           neighborhood: '',
      //           number: '',
      //           state: '',
      //           street: '',
      //         },
      //         id: '-N9DJ6QLKWDiRJY3Irt3',
      //         idempotencyId: '8C9D05DBFB',
      //         lastUpdate: 1660245014249,
      //         playerId: '9Pr9zKiaA1e9C8FQx40Z7FvOEsG3',
      //         redeemedPoints: 200,
      //         regional: 'sim',
      //         status: 'created',
      //         statusTransfer: 'FINALIZADO',
      //         totalPaymentValue: 100,
      //         transferId: '977842',
      //         transferPayment: true,
      //         statusTransferDescription: null,
      //       },
      //       player: {
      //         AccountData: {
      //           paymentMethod: { label: 'pix', value: true },
      //           pixkey: '(86) 99979-7405',
      //           typePix: 'TELEFONE',
      //         },
      //         acceptedTerms: true,
      //         actions: ['-N7uH3UKvWCVRHk9MPUK', '-N7LVRYizw28xwxawfdR'],
      //         actionsCount: 0,
      //         actionsEngaged: { '-N7uH3UKvWCVRHk9MPUK': { actionShares: 1 } },
      //         active: true,
      //         city: 'Barra do Corda',
      //         clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //         clientName: 'testesemgame',
      //         confirmEmail: 'thaynanbreno+brenoplayer@gmail.com',
      //         cpf: '807.831.600-38',
      //         createdAt: 1658511769910,
      //         deliverAddress: {
      //           cep: '',
      //           city: '',
      //           complement: '',
      //           neighborhood: '',
      //           number: '',
      //           state: '',
      //           street: '',
      //         },
      //         email: 'thaynanbreno+brenoplayer@gmail.com',
      //         emailVerified: false,
      //         fcmToken:
      //           'cgKSrdcxvQb9sJyuJp99t-:APA91bHkKKyjkcVQwSpDmHETYbDfmMbBC52LAtRoi6qy9l84BXGaxHri0g88XObUFT7cTTae_jSwtoLTn0yWpk5cOuprlAPnl2IOtHqvdvgMSgkxu2Aan7XVh-35VAxVJfRFzq61KFGc',
      //         leads: 0,
      //         name: 'Taynan Breno',
      //         phoneNumber: '(86) 99979-7405',
      //         photoURL:
      //           'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d',
      //         redeemedPoints: 8047,
      //         regional: 'sim',
      //         registerCompleted: true,
      //         state: 'MA',
      //         topics: {
      //           NATIONAL_BR_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511935273,
      //             topic: 'NATIONAL_BR_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //           REGIONAL_SIM_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511935770,
      //             topic: 'REGIONAL_SIM_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //           STATE_MA_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511936636,
      //             topic: 'STATE_MA_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //         },
      //         totalPoints: 0,
      //         uid: '9Pr9zKiaA1e9C8FQx40Z7FvOEsG3',
      //         updateTermsAccepted: 1658511911913,
      //       },
      //       playerActive: true,
      //     },
      //   },
      //   {
      //     code: 200,
      //     success: true,
      //     message: 'Lote processado com sucesso',
      //     data: {
      //       rescue: {
      //         accountData: {
      //           paymentMethod: { label: 'pix', value: true },
      //           pixkey: '(86) 99979-7405',
      //           typePix: 'TELEFONE',
      //         },
      //         awards: [
      //           {
      //             active: true,
      //             amount: 3,
      //             awardsRescued: 199,
      //             clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //             createDate: 1620392110488,
      //             fileIndex: 'upddxgty.jpg',
      //             inStock: 9800,
      //             lastUpdate: 1659029718337,
      //             paymentValue: '100',
      //             premiumDescription: 'cem conto',
      //             premiumId: '-M_5tLRTY12d6692Z7ei',
      //             premiumImage:
      //               'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Premium%2Flxq3XprR3VarSdVol64ZRRf77QD2%2Fupddxgty.jpg?alt=media&token=abc971a3-2e7b-4ae8-9913-1f4d432979e8',
      //             premiumName: 'Cem reais',
      //             premiumPrice: 200,
      //             premiumQuantity: '9999',
      //             premiumStatus: { label: 'Ativo', value: 'Ativo' },
      //             premiumType: { label: 'BS2', value: 'BS2' },
      //             scope: 'national',
      //             seasonId: 'false',
      //             subtotal: 300,
      //             valueType: { label: 'Fixo', value: 'fixed' },
      //           },
      //         ],
      //         batchId: '134779',
      //         clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //         closeProccess: true,
      //         createdAt: 1660244428145,
      //         deliverAddress: {
      //           cep: '',
      //           city: '',
      //           complement: '',
      //           neighborhood: '',
      //           number: '',
      //           state: '',
      //           street: '',
      //         },
      //         id: '-N9DGsKGPPDLyotbuP4e',
      //         idempotencyId: '655A52ADF5',
      //         lastUpdate: 1660244428145,
      //         playerId: '9Pr9zKiaA1e9C8FQx40Z7FvOEsG3',
      //         redeemedPoints: 600,
      //         regional: 'sim',
      //         status: 'created',
      //         statusTransfer: 'FINALIZADO',
      //         totalPaymentValue: 300,
      //         transferId: '977843',
      //         transferPayment: true,
      //         statusTransferDescription: null,
      //       },
      //       player: {
      //         AccountData: {
      //           paymentMethod: { label: 'pix', value: true },
      //           pixkey: '(86) 99979-7405',
      //           typePix: 'TELEFONE',
      //         },
      //         acceptedTerms: true,
      //         actions: ['-N7uH3UKvWCVRHk9MPUK', '-N7LVRYizw28xwxawfdR'],
      //         actionsCount: 0,
      //         actionsEngaged: { '-N7uH3UKvWCVRHk9MPUK': { actionShares: 1 } },
      //         active: true,
      //         city: 'Barra do Corda',
      //         clientId: 'lxq3XprR3VarSdVol64ZRRf77QD2',
      //         clientName: 'testesemgame',
      //         confirmEmail: 'thaynanbreno+brenoplayer@gmail.com',
      //         cpf: '807.831.600-38',
      //         createdAt: 1658511769910,
      //         deliverAddress: {
      //           cep: '',
      //           city: '',
      //           complement: '',
      //           neighborhood: '',
      //           number: '',
      //           state: '',
      //           street: '',
      //         },
      //         email: 'thaynanbreno+brenoplayer@gmail.com',
      //         emailVerified: false,
      //         fcmToken:
      //           'cgKSrdcxvQb9sJyuJp99t-:APA91bHkKKyjkcVQwSpDmHETYbDfmMbBC52LAtRoi6qy9l84BXGaxHri0g88XObUFT7cTTae_jSwtoLTn0yWpk5cOuprlAPnl2IOtHqvdvgMSgkxu2Aan7XVh-35VAxVJfRFzq61KFGc',
      //         leads: 0,
      //         name: 'Taynan Breno',
      //         phoneNumber: '(86) 99979-7405',
      //         photoURL:
      //           'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d',
      //         redeemedPoints: 8047,
      //         regional: 'sim',
      //         registerCompleted: true,
      //         state: 'MA',
      //         topics: {
      //           NATIONAL_BR_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511935273,
      //             topic: 'NATIONAL_BR_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //           REGIONAL_SIM_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511935770,
      //             topic: 'REGIONAL_SIM_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //           STATE_MA_lxq3XprR3VarSdVol64ZRRf77QD2: {
      //             fcmToken:
      //               'f2J4xPW8aiAuP3GZL1mMQT:APA91bHrGreyDPObv5AzbfFrX1I1McX3hI7OZ3dcy6ncI6NF2uTRbR8IkNo2wj-xVsgg-i5qNVSlC7t5tdLhAKb28bwGjDMMUpXqT9M_uPt_Ruh9kD-CBker-1tk8-IxHTRJuVVoz7Kl',
      //             subDate: 1658511936636,
      //             topic: 'STATE_MA_lxq3XprR3VarSdVol64ZRRf77QD2',
      //           },
      //         },
      //         totalPoints: 0,
      //         uid: '9Pr9zKiaA1e9C8FQx40Z7FvOEsG3',
      //         updateTermsAccepted: 1658511911913,
      //       },
      //       playerActive: true,
      //     },
      //   },
      // ];
      const processBatches = _.filter(
        nextProps.resultProcessBatches,
        (batch) => batch.success
      );

      const totalValueProcessed = _.reduce(
        processBatches,
        (total, batch) => {
          return total + Number(batch.data.rescue.totalPaymentValue);
        },
        0
      );
      const unprocessedBatches = _.filter(
        nextProps.resultProcessBatches,
        (batch) => !batch.success
      );

      const totalValueUnprocessed = _.reduce(
        unprocessedBatches,
        (total, batch) => {
          return total + Number(batch.data.rescue.totalPaymentValue);
        },
        0
      );

      this.setState({
        processBatches,
        unprocessedBatches,
        totalValueProcessed,
        totalValueUnprocessed,
      });
    }
  }

  formatMoney(amount) {
    if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  render() {
    console.log('unprocessedBatches: ', this.state.unprocessedBatches);

    return (
      <Modal
        // open={true}
        open={this.props.modalIsOpenProcessBatches}
        onClose={() => this.props.closeModal()}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <div className="oq-wrapper-contents">
          <h1 className="oq-font-medium title-alert">Detalhes dos Lotes</h1>
          <div className="content-wrapper">
            <div className="content-left">
              <span className="titleSlot">
                <i className="material-icons-outlined">check</i>
                Lotes processados
              </span>
              <div className="contentSlot">
                <ul className="list-process-batch">
                  <li>
                    <h5>Ação</h5>
                  </li>
                  <li>
                    <h5>Player</h5>
                  </li>
                  <li>
                    <h5>Valor</h5>
                  </li>
                </ul>
                {_.map(this.state.processBatches, (batch) => (
                  <ul className="list-process-batch">
                    <li>
                      <p>Resgate</p>
                    </li>
                    <li>
                      <p>{batch.data.player.name}</p>
                    </li>
                    <li>
                      {this.formatMoney(batch.data.rescue.totalPaymentValue) ||
                        '0'}
                    </li>
                  </ul>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '20px',
                }}
              >
                Total: {this.formatMoney(this.state.totalValueProcessed || '0')}
              </div>
            </div>

            <div className="content-right">
              <span className="titleSlot">
                <i className="material-icons-outlined">close</i>
                Lotes não processados
              </span>
              <div className="contentSlot">
                <ul className="list-process-batch">
                  <li>
                    <h5>Ação</h5>
                  </li>
                  <li>
                    <h5>Player</h5>
                  </li>
                  <li>
                    <h5>Valor</h5>
                  </li>
                </ul>
                {_.map(this.state.unprocessedBatches, (batch) => (
                  <ul className="list-process-batch">
                    <li>
                      <p>Resgate</p>
                    </li>
                    <li>
                      <p>{batch.data.player.name}</p>
                    </li>
                    <li>
                      {this.formatMoney(batch.data.rescue.totalPaymentValue) ||
                        '0'}
                    </li>
                  </ul>
                ))}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '20px',
                }}
              >
                Total:{' '}
                {this.formatMoney(this.state.totalValueUnprocessed || '0')}
              </div>
            </div>
          </div>
        </div>
        <div className="oq-group-horizontal">
          <button
            className="oq-btn btn-confirm"
            onClick={() => this.props.closeModal()}
          >
            Concluir
          </button>
        </div>
      </Modal>
    );
  }
}

export default withNamespaces()(ResponseProcessBatches);
