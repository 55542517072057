import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import ToggleButton from 'react-toggle-button';
import _ from 'underscore';
import { Notify } from '../../../../../components/Notify';
import { api } from '../../../../../Config';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

import avatarImg from '../../../../../Assets/Images/avatar.png';

class PlayerDetailsResearchAward extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      loadingPlayer: true,
      loadingPlayers: false,
      value: false,
      playerInAction: {},
      player: {},
      actions: [],
      actionsCount: 0,
      playerId: null,
      loadingActions: false,
      updatingPlayer: false,
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({ playerId: params.playerId });
    await this.getPlayerById(params.playerId);
    await this.getPlayerDetailsResearchAward(params.playerId, params.actionId);
  }

  async getPlayerDetailsResearchAward(playerId, actionId) {
    this.setState({ loadingPlayerInAction: true });

    const parameters = {
      searchFunctionality: 'getDetailsPlayerInActionResearchAward',
      userType: 'client',
      playerId,
      actionId,
    };

    try {
      const response = await api.post(`/search`, parameters);

      if (response.data.success) {
        this.setState({
          playerInAction: response.data.data,
          loadingPlayerInAction: false,
        });
      } else {
        this.setState({ playerInAction: [], loadingPlayerInAction: false });
      }
    } catch (error) {
      console.log('getPlayerDetailsResearchAward', error);
      this.setState({ playerInAction: [], loadingPlayerInAction: false });
    }
  }

  async getPlayerById(playerId) {
    const { clientData } = this.context;
    this.setState({ loadingPlayer: true });

    const parameters = {
      searchFunctionality: 'getPlayer',
      userType: 'client',
      playerId,
      clientId: clientData && clientData.uid,
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;

    if (!result.success) {
      Notify(result.message, result.success ? 'success' : 'error');
      return;
    }

    Notify(result.message, result.success ? 'success' : 'error');

    // this.setState({
    //   formData: {
    //     ...this.state.formData,
    //     ...result.data.player,
    //   },
    // });

    const { player } = result.data;

    this.setState({ player, loadingPlayer: false });
  }

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  getPlayerColor(points) {
    const totalPoints =
      points &&
      points.extraPoints +
        points.leadRegistration +
        points.membergetmember +
        points.membergetmembergest +
        points.salesConversion;
    let color = '#000000';

    if (totalPoints < 999) {
      color = 'yellow';
    } else if (totalPoints < 4999) {
      color = 'orange';
    } else if (totalPoints < 9999) {
      color = 'blue';
    } else if (totalPoints < 19999) {
      color = 'green';
    } else if (totalPoints < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { player } = this.state;
    return (
      <div>
        <div className="oq-profile">
          <img
            src={(player && player.photoURL) || avatarImg}
            className="oq-profile-picture new-height"
            alt="player logo"
          />
          <div className="teste" style={{ position: 'relative', right: '5%' }}>
            <svg
              className="oq-profile-trophy oq-profile-trophy-player-details"
              width="30px"
              height="30px"
              viewBox="0 0 22.529 25.012"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              data-tip="React-tooltip"
              data-for="pontuationRules"
              style={{
                padding: 4,
                backgroundColor: this.getPlayerColor(player.points),
              }}
            >
              <defs />
              <g transform="translate(-445.444 -679.25)">
                <path
                  className="stroke"
                  fill="#fff"
                  d="M462.77,703.512H450.646a.961.961,0,0,1-.67-1.65l1.687-1.64a7.239,7.239,0,0,1,5.045-2.048h0a7.24,7.24,0,0,1,5.046,2.048l1.686,1.64A.961.961,0,0,1,462.77,703.512Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M456.708,693.871h0a7.606,7.606,0,0,1-7.606-7.607V680h15.213v6.264A7.607,7.607,0,0,1,456.708,693.871Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M448.509,687.237a2.315,2.315,0,0,1-2.315-2.315h0a2.314,2.314,0,0,1,2.315-2.315"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M464.908,682.607a2.314,2.314,0,0,1,2.315,2.315h0a2.315,2.315,0,0,1-2.315,2.315"
                />
                <line
                  className="stroke"
                  y2="2.883"
                  transform="translate(456.708 694.863)"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.totalPoints ? player.totalPoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos</span>
          </div>
        </div>
        <div className="oq-status-box row">
          <div className="col-md-4 col-md-offset-4 oq-status-box-switch">
            <ToggleButton
              disabled
              inactiveLabel={<i className="fa fa-times" aria-hidden="true" />}
              activeLabel={<i className="fa fa-check" aria-hidden="true" />}
              value={player.active}
              trackStyle={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPlayerDetailsResearchAward() {
    const { playerInAction } = this.state;
    const questions =
      playerInAction && playerInAction.detailsResearchAwardPlayer
        ? playerInAction.detailsResearchAwardPlayer
        : [];

    return (
      <div>
        {questions && questions.length > 0 ? (
          _.map(questions, (itemQuestion, indexQ) => {
            return this.renderQuestions(itemQuestion, indexQ, questions);
          })
        ) : (
          <div>
            <span>Sem dados</span>
          </div>
        )}
      </div>
    );
  }

  renderQuestions(itemQuestion, indexQ, questions) {
    return _.map(itemQuestion, (valueQuestion, indexQuestion) =>
      this.renderAnswers(valueQuestion, indexQuestion, indexQ)
    );
  }

  renderAnswers(valueQuestion, indexQuestion, indexQ) {
    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#answer${indexQ}`}
            aria-expanded="false"
          >
            Pergunta {Number(indexQ) + 1}
          </h4>
          <div
            id={`answer${indexQ}`}
            aria-expanded="false"
            className="collapse"
          >
            <label htmlFor="">Tipo da pergunta</label>
            <select
              className="form-group profile-placeholder col-md-4"
              disabled
              id="choiceQuestion"
              name="choiceQuestion"
              aria-describedby="choiceQuestionHelp"
              value={valueQuestion.choiceQuestion}
            >
              <option value="">Selecione...</option>
              <option value="radio">Multipla escolha</option>
              <option value="text">Texto aberto</option>
              <option value="checkbox">Caixa de seleção</option>
            </select>

            {valueQuestion.choiceQuestion === 'radio' &&
              this.renderMultipleChoice(indexQuestion, valueQuestion)}
            {valueQuestion.choiceQuestion === 'checkbox' &&
              this.renderCheckboxChoice(indexQuestion, valueQuestion)}
            {valueQuestion.choiceQuestion === 'text' &&
              this.renderTextChoice(indexQuestion, valueQuestion)}
          </div>
        </div>
      </form>
    );
  }

  renderTextChoice(index, valueQuestion) {
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')}
          </label>
          <input
            disabled
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            value={valueQuestion.question}
          />
        </div>

        <label className="profile-label col-md-12">
          {this.translate('Resposta')}
        </label>

        <div className="col-md-12 d-flex p-0">
          <textarea
            className="form-control"
            name="textAnswer"
            rows="3"
            value={valueQuestion.textAnswer}
            disabled
          />
        </div>
      </>
    );
  }

  renderCheckboxChoice(index, valueQuestion) {
    const arrOptions = ['A', 'B', 'C', 'D'];
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')}
          </label>
          <input
            disabled
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            value={valueQuestion.question}
          />
        </div>
        {this.renderOption(valueQuestion, 'A')}
        {this.renderOption(valueQuestion, 'B')}
        {this.renderOption(valueQuestion, 'C')}
        {this.renderOption(valueQuestion, 'D')}

        <label className="profile-label col-md-12">
          {this.translate('Alternativa Marcada')}
        </label>

        <div className="col-md-12 d-flex p-0">
          {/* {this.renderRightAnswerCheckbox(valueQuestion, 'A')}
          {this.renderRightAnswerCheckbox(valueQuestion, 'B')}
          {this.renderRightAnswerCheckbox(valueQuestion, 'C')}
          {this.renderRightAnswerCheckbox(valueQuestion, 'D')} */}
          {this.renderRightAnswerCheckbox(valueQuestion, arrOptions)}
        </div>
      </>
    );
  }

  renderMultipleChoice(index, valueQuestion) {
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')}
          </label>
          <input
            disabled
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            value={valueQuestion.question}
          />
        </div>
        {this.renderOption(valueQuestion, 'A')}
        {this.renderOption(valueQuestion, 'B')}
        {this.renderOption(valueQuestion, 'C')}
        {this.renderOption(valueQuestion, 'D')}

        <label className="profile-label col-md-12">
          {this.translate('Alternativa Marcada')}
        </label>

        <div className="col-md-12 d-flex p-0">
          {/* {this.renderRightAnswerRadio(value)} */}
          {this.renderRightAnswerRadio(valueQuestion, 'A')}
          {this.renderRightAnswerRadio(valueQuestion, 'B')}
          {this.renderRightAnswerRadio(valueQuestion, 'C')}
          {this.renderRightAnswerRadio(valueQuestion, 'D')}
        </div>
      </>
    );
  }

  renderRightAnswerRadio(value, answer) {
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswerRadio"
          className="custom-control-input"
          checked={value.choosenAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderRightAnswerCheckbox(value, answer) {
    return _.map(answer, (ans) => {
      console.log('ans: ', ans);
      return (
        // <p>eaiiiii</p>
        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="checkbox"
            id={ans}
            value={ans}
            name="rightAnswerCheckbox"
            className="custom-control-input"
            checked={value.choosenAnswer.includes(ans)}
          />
          <label className="custom-control-label">{ans}</label>
        </div>
      );
      // return _.map(value.choosenAnswer, choosenAnswer => {

      // });
    });
  }

  renderOption(value, option) {
    return (
      <div key={option} className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          disabled
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          value={value[`answer${option}`]}
        />
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do usuário')}
              </h1>
              <div className="row">
                <div className="col-md-3">{this.renderProfilePicDetails()}</div>
                <div className="col-md-9">
                  {this.renderPlayerDetailsResearchAward()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerDetailsResearchAward;
