import FileSaver from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';

import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import * as routes from '../../routes';
import * as mask from '../../utils/Mascaras';
import { normalizeLowerCase } from '../../utils/normalizeLowerCase';
import RescueDetails from '../Modals/RescueDetails';
import ResponseProcessBatches from '../Modals/ResponseProcessBatches';
import { NavBar } from '../NavBar';
import { SideBar } from '../SideBar';
import { UserDataContext } from '../../hooks/useUserData';

class EditWalletPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      month: '',
      year: '',
      isCheckAll: false,
      isCheck: [],
      resultProcessBatches: [],
      modalIsOpenProcessBatches: false,
      modalIsOpenDetailsRescue: false,
      selectedRescuesBatches: [],
      selectedRescue: {},
      client: {},
      playersRescuesClient: [],
      formData: {
        regional: null,
        totalEntries: 0,
        totalExits: 0,
        balance: 0,
        entrance: 0,
      },
      isLoading: false,
      isLoadingWallet: false,
      isLoadingTransferRequest: false,
    };
    this.translate = props.t;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ clientId: params.clientId });
    this.getClientByUid(params.clientId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.month !== this.state.month) {
      this.handleSearchInputChange(this.state.month, this.state.year);
    }
    if (prevState.year !== this.state.year) {
      this.handleSearchInputChange(this.state.month, this.state.year);
    }
  }

  async getWalletClient(clientId, regional = null) {
    this.setState({ isLoadingWallet: true }, () => {
      const parameters = {
        searchFunctionality: 'getWalletClient',
        userType: 'administrator',
        clientId,
        regional,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data.data;

          if (!res.data.success) {
            this.setState(
              {
                isLoadingWallet: false,
                formData: {
                  regional: null,
                  totalEntries: 0,
                  totalExits: 0,
                  balance: 0,
                },
              }
              // () => {
              //   Notify(
              //     res.data.message,
              //     res.data.success ? 'success' : 'warn'
              //   );
              // }
            );
          } else {
            this.setState(
              {
                isLoadingWallet: false,
                formData: {
                  totalEntries: result.totalEntries,
                  totalExits: result.totalExits,
                  balance: result.balance,
                  regional:
                    !result.client.entrancePaymentByRegional ||
                    (result.client.entrancePaymentByRegional &&
                      result.client.entrancePaymentByRegional !== 'true')
                      ? ''
                      : result.regional
                        ? result.regional
                        : '',
                },
              }
              // Notify(res.data.message, res.data.success ? 'success' : 'warn')
            );
          }
        })
        .catch((error) => {
          console.log('getWalletClient error:', error);
          this.setState({
            isLoadingWallet: false,
            formData: {
              totalEntries: 0,
              totalExits: 0,
              balance: 0,
            },
          });
        });
    });
  }

  async getTransferenceRequest(
    clientId,
    month = null,
    year = null,
    regional = null,
    typeRescue = null
  ) {
    this.setState({ isLoadingTransferRequest: true }, () => {
      const parameters = {
        searchFunctionality: 'getTransferenceRequest',
        userType: 'administrator',
        clientId,
        month,
        year,
        regional,
        typeRescue,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data.data;

          if (!res.data.success) {
            this.setState(
              {
                isLoadingTransferRequest: false,
                client: {},
                playersRescuesClient: [],
              },
              () => {
                Notify(res.data.message, res.data.success ? 'success' : 'warn');
              }
            );
            return;
          }
          this.setState(
            {
              isLoadingTransferRequest: false,
              client: result.client,
              playersRescuesClient: result.playersRescuesClient,
            },
            Notify(res.data.message, res.data.success ? 'success' : 'warn')
          );
        })
        .catch((error) => {
          console.log('getWalletClient error:', error);
          this.setState({
            isLoadingTransferRequest: false,
            client: {},
            playersRescuesClient: [],
          });
        });
    });
  }

  async processBatches(typeRescue = null) {
    const { selectedRescuesBatches, clientId, client } = this.state;

    const arrTypeAccountTransfeera = [
      {
        label: 'Conta Corrente',
        value: 'CONTA_CORRENTE',
      },
      {
        label: 'Conta Poupança',
        value: 'CONTA_POUPANCA',
      },
      {
        label: 'Conta Fácil',
        value: 'CONTA_FACIL',
      },
      {
        label: 'Entidades Públicas',
        value: 'ENTIDADES_PUBLICAS',
      },
    ];

    // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
    const arrTypePixTransfeera = [
      {
        label: 'CPF',
        value: 'CPF',
      },
      {
        label: 'CNPJ',
        value: 'CNPJ',
      },
      {
        label: 'Telefone',
        value: 'TELEFONE',
      },
      {
        label: 'E-mail',
        value: 'EMAIL',
      },
      {
        label: 'Chave aleatória',
        value: 'CHAVE_ALEATORIA',
      },
    ];

    let accountType = null;
    let typePix = null;
    let isRescuesTransfeera = [];
    let isRescuesBs2 = [];
    let updateFunctionality = '';

    _.each(selectedRescuesBatches, (res) => {
      accountType = arrTypeAccountTransfeera.find((bankAccount) => {
        if (res.rescue) {
          return (
            res.rescue &&
            res.rescue.accountData &&
            res.rescue.accountData.accountType &&
            bankAccount.value.includes(res.rescue.accountData.accountType)
          );
        }
      });

      if (
        (res.rescue &&
          res.rescue.accountData &&
          res.rescue.accountData.paymentMethod &&
          res.rescue.accountData.paymentMethod.label === 'pix' &&
          res.rescue.accountData.paymentMethod.value) ||
        (res.rescue &&
          res.rescue.accountData &&
          res.rescue.accountData.paymentMethod === 'pix')
      ) {
        typePix = arrTypePixTransfeera.find(
          (type) => type.value === res.rescue.accountData.typePix
        );
      }

      if (accountType || typePix) {
        isRescuesTransfeera.push(res);
      } else {
        isRescuesBs2.push(res);
      }
    });

    if (isRescuesTransfeera.length > 0 && isRescuesBs2.length > 0) {
      return Notify(`Selecione apenas os resgates da mesma integração`, 'warn');
    }

    if (
      client &&
      client.integration &&
      client.integration.transfeera &&
      client.integration.transfeera.active === 'true' &&
      isRescuesTransfeera.length > 0 &&
      isRescuesBs2.length === 0
    ) {
      updateFunctionality = 'processBatches';
    } else if (
      client &&
      client.integration &&
      client.integration.bs2 &&
      client.integration.bs2.active === 'true' &&
      isRescuesTransfeera.length === 0 &&
      isRescuesBs2.length > 0
    ) {
      updateFunctionality = 'processBatchesBs2';
    } else {
      return Notify(
        `Integracao Ativa, mas é diferente da que está resgatando`,
        'warn'
      );
    }

    try {
      this.setState({ isLoadingprocessBatches: true });
      Notify('Processando lotes. Aguarde...', 'loading', {
        autoClose: true,
      });

      const parameters = {
        // updateFunctionality: 'processBatchesBs2',
        // updateFunctionality: 'processBatches',
        updateFunctionality,
        userType: 'administrator',
        clientId,
        selectedRescuesBatches,
        typeRescue,
      };

      const response = await api.post(`/update`, parameters);
      this.setState({
        isLoadingprocessBatches: false,
        selectedRescuesBatches: [],
        isCheckAll: false,
        isCheck: [],
      });

      console.log('response processBatches: ', response.data);

      if (response.data.success) {
        Notify(response.data.message, 'success');
        this.toggleModalProcessBatches(response.data.data);
        await this.getWalletClient(clientId);
        await this.getTransferenceRequest(clientId);
        // _.each(response.data.data, responseBatch => {
        //
        //   if (responseBatch && responseBatch.success) {
        //     Notify(responseBatch.message, 'success', 15000);
        //   } else {
        //     Notify(responseBatch.message, 'error', 15000);
        //   }
        // });
      }
    } catch (error) {
      this.setState({
        isLoadingprocessBatches: false,
        selectedRescuesBatches: [],
      });
      console.log('processBatches error:', error);
    }
  }

  async refreshWallet() {
    const { clientId } = this.state;
    this.setState({ selectedRescuesBatches: [] });
    await this.getTransferenceRequest(clientId);
    await this.getWalletClient(clientId);
  }

  getClientByUid(clientId) {
    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'administrator',
      clientId: clientId,
    };

    api
      .post(`/search`, parameters)
      .then(async (res) => {
        const result = res.data;

        if (!result.success) {
          this.setState({
            client: {},
          });
        } else {
          this.setState({
            client: result.data,
          });

          if (
            !result.data.entrancePaymentByRegional ||
            (result.data.entrancePaymentByRegional &&
              result.data.entrancePaymentByRegional !== 'true')
          ) {
            await this.getTransferenceRequest(result.data.uid);
            await this.getWalletClient(result.data.uid);
          }
        }
      })
      .catch((error) => {
        console.log('getClientByUid error:', error);
      });
  }

  confirmProcessBatches() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              Você tem certeza que deseja continuar?
            </h1>
            {/* <p className="oq-font-book">
              Você tem certeza que deseja realmente excluir esse usuário ?
            </p> */}
            <p>
              <b className="oq-font-medium">
                Esse procedimento não poderá ser desfeito após a confirmação.
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.processBatches(this.state.formData.typeRescue);
                  onClose();
                }}
              >
                CONFIRMAR
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  renderModalRescueDetails() {
    return (
      <RescueDetails
        closeModal={() => this.toggleModal()}
        getWalletClient={(clientId) => this.getWalletClient(clientId)}
        getTransferenceRequest={(clientId) =>
          this.getTransferenceRequest(clientId)
        }
        rescueData={this.state.selectedRescue}
        client={this.state.client}
        typeRescue={this.state.typeRescue}
        modalIsOpenDetailsRescue={this.state.modalIsOpenDetailsRescue}
      />
    );
  }

  renderResponseProcessBatches() {
    return (
      <ResponseProcessBatches
        closeModal={() => this.toggleModalProcessBatches()}
        resultProcessBatches={this.state.resultProcessBatches}
        modalIsOpenProcessBatches={this.state.modalIsOpenProcessBatches}
      />
    );
  }

  goToHistoricalWalletPage(clientId) {
    const { history } = this.props;
    history.push(`${routes.HISTORICAL_WALLET_ADMIN}/${clientId}`);
  }

  handleFormDataInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value, regional = null) {
    const { formData } = this.state;
    if (name === 'regional' && !value) {
      this.setState({
        formData: {
          totalEntries: 0,
          totalExits: 0,
          balance: 0,
        },
      });
      return Notify('Selecione a regional', 'warn');
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));

    if (regional) {
      const { clientId } = this.state;
      this.getWalletClient(clientId, value);
      this.getTransferenceRequest(clientId, formData.month, value);
    }
  }

  formatMoney(amount) {
    if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  renderDetailsWalletByRegional() {
    const { client, formData } = this.state;
    if (!client) return;

    const scopeFieldName =
      this.state.clientData &&
      this.state.clientData.scopeFieldName &&
      this.state.clientData.scopeFieldName !== ''
        ? this.state.clientData.scopeFieldName
        : 'Segmentação';

    return (
      <div className="col-md-12">
        <div className="row col-md-4">
          <h4 className="oq-font-book oq-padding-vertical">
            Detalhes da carteira
          </h4>
        </div>
        <div className="col-md-6">
          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="regional">
                {scopeFieldName}
              </label>
              <select
                required={true}
                className="form-control input-oq rounded"
                name="regional"
                onChange={(e) =>
                  this.handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    'byRegional'
                  )
                }
                disabled={this.state.isLoading}
                // value={formData && normalizeLowerCase(formData.regional)}
              >
                <option value="">
                  {this.state.isLoading ? 'Carregando ...' : 'Escolha ...'}
                </option>
                {this.state.client.regional &&
                  _.map(this.state.client.regional, (regional) => {
                    return (
                      <option
                        value={
                          regional ? normalizeLowerCase(regional) : regional
                        }
                      >
                        {regional}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-group  col-md-4"></div>

            <div className="form-group  col-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyName">
                Nome da empresa
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyName"
                name="companyName"
                defaultValue={client && client.companyName}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyInscription">
                CNPJ
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyInscription"
                name="companyInscription"
                defaultValue={client && client.companyInscription}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="accountableName">
                Responsável
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="accountableName"
                name="accountableName"
                defaultValue={client && client.accountableName}
                readOnly={true}
              />
            </div>
          </div>

          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalEntries">
                Total entradas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalEntries"
                name="totalEntries"
                readOnly={true}
                placeholder="Total entradas"
                type="text"
                value={
                  formData
                    ? this.formatMoney(formData.totalEntries) || '0'
                    : '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalExits">
                Total saídas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalExits"
                name="totalExits"
                readOnly={true}
                placeholder="Total saídas"
                type="text"
                value={
                  (formData && this.formatMoney(formData.totalExits)) || '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="balance">
                Saldo
              </label>
              <input
                className="form-control input-oq rounded"
                id="balance"
                name="balance"
                readOnly={true}
                placeholder="Saldo"
                type="text"
                value={(formData && this.formatMoney(formData.balance)) || '0'}
              />
            </div>
          </div>

          <div className="form-group  col-md-4">
            <label className="input-oq-label" htmlFor="entrance">
              Atualizar entrada
            </label>
            <input
              className="form-control input-oq rounded"
              id="entrance"
              name="entrance"
              placeholder="Atualizar entrada"
              type="text"
              disabled={!formData.regional || formData.regional === ''}
              value={formData && formData.entrance}
              onChange={(e) => {
                let input = e.target;
                mask.maskVALUE(input.value).then((maskedValue) => {
                  this.handleFormDataChange(input.name, maskedValue);
                });
              }}
            />
          </div>
        </div>

        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              // disabled={this.state.loadingSetEntrance}
              disabled={
                this.state.isLoadingprocessBatches ||
                this.state.isLoading ||
                this.state.isLoadingTransferRequest ||
                this.state.isLoadingWallet ||
                this.state.loadingSetEntrance
              }
              type="submit"
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDetailsWalletWithoutRegional() {
    const { client, formData } = this.state;
    if (!client) return;

    return (
      <div className="col-md-12">
        <div className="row col-md-4">
          <h4 className="oq-font-book oq-padding-vertical">
            Detalhes da carteira
          </h4>
        </div>
        <div className="col-md-6"></div>
        <div className="row">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyName">
                Nome da empresa
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyName"
                name="companyName"
                defaultValue={client && client.companyName}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyInscription">
                CNPJ
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyInscription"
                name="companyInscription"
                defaultValue={client && client.companyInscription}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="accountableName">
                Responsável
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="accountableName"
                name="accountableName"
                defaultValue={client && client.accountableName}
                readOnly={true}
              />
            </div>
          </div>

          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalEntries">
                Total entradas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalEntries"
                name="totalEntries"
                readOnly={true}
                placeholder="Total entradas"
                type="text"
                value={
                  formData
                    ? this.formatMoney(formData.totalEntries) || '0'
                    : '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalExits">
                Total saídas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalExits"
                name="totalExits"
                readOnly={true}
                placeholder="Total saídas"
                type="text"
                value={
                  (formData && this.formatMoney(formData.totalExits)) || '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="balance">
                Saldo
              </label>
              <input
                className="form-control input-oq rounded"
                id="balance"
                name="balance"
                readOnly={true}
                placeholder="Saldo"
                type="text"
                value={(formData && this.formatMoney(formData.balance)) || '0'}
              />
            </div>
          </div>

          <div className="row col-md-12">
            <div className="form-group  col-md-3">
              <label className="input-oq-label" htmlFor="entrance">
                Atualizar entrada
              </label>
              <input
                className="form-control input-oq rounded"
                id="entrance"
                name="entrance"
                placeholder="Atualizar entrada"
                type="text"
                value={formData && formData.entrance}
                onChange={(e) => {
                  let input = e.target;
                  mask.maskVALUE(input.value).then((maskedValue) => {
                    this.handleFormDataChange(input.name, maskedValue);
                  });
                }}
              />
            </div>
            <div className="form-group  col-md-3"></div>
            <div className="form-group  col-md-3"></div>
          </div>
        </div>

        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              disabled={this.state.loadingSetEntrance}
              type="submit"
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDetailsWallet() {
    if (
      !this.state.client.entrancePaymentByRegional ||
      (this.state.client.entrancePaymentByRegional &&
        this.state.client.entrancePaymentByRegional !== 'true')
    ) {
      return this.renderDetailsWalletWithoutRegional();
    } else {
      return this.renderDetailsWalletByRegional();
    }
  }

  exportWallets() {
    const { playersRescuesClient } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(playersRescuesClient, (client) => {
        const ArrTypeAccount = [
          {
            label: 'Conta Corrente',
            value: 'CONTA_CORRENTE',
          },
          {
            label: 'Conta Poupança',
            value: 'CONTA_POUPANCA',
          },
          {
            label: 'Conta Fácil',
            value: 'CONTA_FACIL',
          },
          {
            label: 'Entidades Públicas',
            value: 'ENTIDADES_PUBLICAS',
          },
        ];

        const arrTypePix = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'TELEFONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'CHAVE_ALEATORIA',
          },
        ];

        let isPaymentMethodTed = true;
        let typePix = {
          label: '',
          value: '',
        };

        if (
          (client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod &&
            client.rescue.accountData.paymentMethod.label === 'pix' &&
            client.rescue.accountData.paymentMethod.value) ||
          (client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePix.find(
            (type) => type.value === client.rescue.accountData.typePix
          );
        }

        const labelTypeAccount =
          client.rescue &&
          ArrTypeAccount.find(
            (type) => type.value === client.rescue.accountData.accountType
          );

        return {
          'Status da transferência': client.rescue.statusTransfer
            ? client.rescue.statusTransfer
            : 'N/A',
          Processado: client.rescue.closeProccess ? 'Sim' : 'Não',
          'Data do resgate': client.rescue
            ? moment(client.rescue.createdAt).format('DD/MM/YYYY')
            : '--',
          'Valor a ser pago':
            this.formatMoney(client.rescue.totalPaymentValue) || '0',

          'Qtd. de prêmios': client.rescue
            ? client.rescue.awards.length
            : 'N/A',
          'Nome do player': client.playerActive
            ? client.player.name
            : 'Deletado',
          'Método de pagamento':
            client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod &&
            client.rescue.accountData.paymentMethod.label
              ? client.rescue.accountData.paymentMethod.label
              : 'ted',
          'Tipo de conta':
            client.rescue && labelTypeAccount ? labelTypeAccount.label : 'N/A',
          'Tipo de chave pix':
            (client.rescue &&
              client.rescue &&
              client.rescue.accountData &&
              client.rescue.accountData.paymentMethod &&
              client.rescue.accountData.paymentMethod.label === 'pix') ||
            (client.rescue &&
              client.rescue &&
              client.rescue.accountData &&
              client.rescue.accountData.paymentMethod === 'pix')
              ? typePix.label
              : 'N/A',
          'Chave pix':
            (client.rescue &&
              client.rescue &&
              client.rescue.accountData &&
              client.rescue.accountData.paymentMethod &&
              client.rescue.accountData.paymentMethod.label === 'pix') ||
            (client.rescue &&
              client.rescue &&
              client.rescue.accountData &&
              client.rescue.accountData.paymentMethod === 'pix')
              ? client.rescue.accountData.pixkey
              : 'N/A',
          Agencia: client.rescue ? client.rescue.accountData.agency : 'N/A',
          'Digito da agencia': client.rescue
            ? client.rescue.accountData.digitAgency
            : '',
          Banco: client.rescue ? client.rescue.accountData.bank : 'N/A',
          Conta: client.rescue ? client.rescue.accountData.conta : 'N/A',
          'Digito da conta': client.rescue
            ? client.rescue.accountData.digitCount
            : 'N/A',
          CPF: client.rescue ? client.rescue.accountData.cpf : 'N/A',
          Nome: client.rescue ? client.rescue.accountData.name : 'N/A',
          'Pontos de resgate': client.rescue
            ? client.rescue.redeemedPoints
            : 'N/A',

          'Id da transferencia Transfeera': client.rescue
            ? client.rescue.transferId
            : 'N/A',
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Carteiras');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Carteiras.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  handleSearchInputChange(month = null, year = null) {
    const { formData } = this.state;
    const {
      match: { params },
    } = this.props;

    this.getTransferenceRequest(
      params.clientId,
      month,
      year,
      formData.regional,
      formData.typeRescue
    );
  }
  handleSearchInputLeadsOrPlayersChange(e) {
    const { formData } = this.state;
    const {
      match: { params },
    } = this.props;
    const { value, name } = e.target;
    const label = e.target.options[e.target.selectedIndex].text;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));

    console.log('label: ', label);
    console.log('value: ', value);

    this.getTransferenceRequest(
      params.clientId,
      formData.month,
      formData.regional,
      value
    );
  }

  toggleModal(rescueData) {
    this.setState({
      modalIsOpenDetailsRescue: !this.state.modalIsOpenDetailsRescue,
      selectedRescue: rescueData,
    });
  }

  toggleModalProcessBatches(resultProcessBatches) {
    this.setState({
      modalIsOpenProcessBatches: !this.state.modalIsOpenProcessBatches,
      resultProcessBatches: resultProcessBatches,
    });
  }

  handleSelectedBatch(e, props, index) {
    if (props.rescue && props.playerActive && props.rescue.closeProccess) {
      return Notify('Você não pode processar novamente', 'warn');
    }

    // const idsRescues = filterSelectedPlayersRescuesClient.map(
    //   li => li.rescue.id
    // );
    // this.setState(prevState => ({
    //   ...prevState,
    //   selectedRescuesBatches: filterSelectedPlayersRescuesClient,
    //   isCheck: idsRescues,
    // }));

    const { checked, value } = e.target;
    const { player, rescue } = props;
    if (checked === false) {
      this.setState((prevState) => ({
        ...prevState,
        selectedRescuesBatches: _.omit(
          this.state.selectedRescuesBatches,
          index
        ),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        selectedRescuesBatches: {
          ...prevState.selectedRescuesBatches,
          [index]: {
            player,
            rescue,
            checked: checked,
          },
        },
      }));
    }
  }

  handleSelectAll(e) {
    const { playersRescuesClient } = this.state;
    const { checked } = e.target;

    const filterSelectedPlayersRescuesClient = _.filter(
      playersRescuesClient,
      (select) =>
        select.playerActive && select.rescue && !select.rescue.closeProccess
    );

    if (
      !filterSelectedPlayersRescuesClient ||
      filterSelectedPlayersRescuesClient.length === 0
    ) {
      return Notify('Não há resgates para processar', 'warn');
    }

    console.log(
      'filterSelectedPlayersRescuesClient: ',
      filterSelectedPlayersRescuesClient
    );

    this.setState({ isCheckAll: !this.state.isCheckAll });

    if (checked === false) {
      this.setState((prevState) => ({
        ...prevState,
        selectedRescuesBatches: [],
        isCheck: [],
      }));
    } else {
      const idsRescues = filterSelectedPlayersRescuesClient.map(
        (li) => li.rescue.id
      );
      this.setState((prevState) => ({
        ...prevState,
        selectedRescuesBatches: filterSelectedPlayersRescuesClient,
        isCheck: idsRescues,
      }));
    }
  }

  renderRescuesList() {
    const { playersRescuesClient, client } = this.state;

    const arrTypeAccountTransfeera = [
      {
        label: 'Conta Corrente',
        value: 'CONTA_CORRENTE',
      },
      {
        label: 'Conta Poupança',
        value: 'CONTA_POUPANCA',
      },
      {
        label: 'Conta Fácil',
        value: 'CONTA_FACIL',
      },
      {
        label: 'Entidades Públicas',
        value: 'ENTIDADES_PUBLICAS',
      },
    ];

    // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
    const arrTypePixTransfeera = [
      {
        label: 'CPF',
        value: 'CPF',
      },
      {
        label: 'CNPJ',
        value: 'CNPJ',
      },
      {
        label: 'Telefone',
        value: 'TELEFONE',
      },
      {
        label: 'E-mail',
        value: 'EMAIL',
      },
      {
        label: 'Chave aleatória',
        value: 'CHAVE_ALEATORIA',
      },
    ];

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="user">
            {this.translate('Player')}
            <ReactTooltip
              id="user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Player')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.playerActive ? d.player.name : 'Deletado'),
        id: 'user',
        width: 130,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="integration">
            {this.translate('Integração')}
            <ReactTooltip
              id="integration"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Integração')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => {
          let accountType = null;
          let typePix = null;

          accountType = arrTypeAccountTransfeera.find((bankAccount) => {
            if (d.rescue) {
              return (
                d.rescue &&
                d.rescue.accountData &&
                d.rescue.accountData.accountType &&
                bankAccount.value.includes(d.rescue.accountData.accountType)
              );
            }
          });

          if (
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod.label === 'pix') ||
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod === 'pix')
          ) {
            typePix = arrTypePixTransfeera.find(
              (type) => type.value === d.rescue.accountData.typePix
            );
          }

          if (accountType || typePix) {
            return 'Transfeera';
          } else {
            return 'Bs2';
          }
        },
        id: 'integration',
        width: 100,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="tedOrPix">
            {this.translate('Ted/Pix')}
            <ReactTooltip
              id="tedOrPix"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Ted/Pix')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => {
          if (
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod.label === 'pix') ||
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod === 'pix')
          ) {
            return 'Pix';
          }
          if (
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod.label === 'ted') ||
            (d.rescue &&
              d.rescue.accountData &&
              d.rescue.accountData.paymentMethod &&
              d.rescue.accountData.paymentMethod === 'ted')
          ) {
            return 'Ted';
          }
          return '-';
        },
        id: 'tedOrPix',
        width: 80,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="closeProccess">
            {this.translate('Processado')}
            <ReactTooltip
              id="closeProccess"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Processado')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.rescue ? (d.rescue.closeProccess ? 'Sim' : 'Não') : 'N/A',
        id: 'closeProccess',
        width: 100,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="statusTransfer">
            {this.translate('Status da Transf.')}
            <ReactTooltip
              id="statusTransfer"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Status da Transferência')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.rescue
            ? d.rescue.statusTransfer
              ? d.rescue.statusTransfer
              : 'N/A'
            : 'N/A',
        id: 'statusTransfer',
      },
      {
        id: 'rescueData',
        Header: (
          <span data-tip="React-tooltip" data-for="date">
            {this.translate('Data do resgate')}
            <ReactTooltip
              id="date"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Data do resgate')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.rescue ? moment(d.rescue.createdAt).format('DD/MM/YYYY') : '--',
      },
      {
        Header: (
          <span
            data-tip="React-tooltip"
            data-for="nfNumber"
            className="center-cell"
          >
            {this.translate('Valor a ser pago')}
            <ReactTooltip
              id="redeemedPoints"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Valor a ser pago')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'redeemedPoints',
        width: 140,
        accessor: (d) => {
          if (d.rescue) {
            if (d.rescue.totalPaymentValue !== 0 && !d.rescue.totalPaymentValue)
              return 'N/A';
            if (d.rescue.totalPaymentValue === 1)
              return `${this.formatMoney(d.rescue.totalPaymentValue)}`;
            if (d.rescue.totalPaymentValue > 1)
              return `${this.formatMoney(d.rescue.totalPaymentValue)}`;
            if (d.rescue.statusTransfer === 'DEVOLVIDO') return 'DEVOLVIDO';
          } else {
            return '--';
          }
        },
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <span
              data-tip="React-tooltip"
              data-for="awards"
              className="center-cell"
            >
              {this.translate('Qtd de prêmios')}
              <ReactTooltip
                id="awards"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Qtd. de Prêmios')}
                </span>
              </ReactTooltip>
            </span>
          </div>
        ),
        id: 'awards',
        accessor: (d) =>
          d.rescue && d.rescue.awards ? d.rescue.awards.length : 'N/A',
        className: 'center-cell',
      },
      {
        id: 'conferenceRescue',
        Header: 'Conferir',
        accessor: 'transferId',
        Cell: (props) =>
          props.original.rescue &&
          props.original.playerActive && (
            <button
              type="button"
              className="btn btn-oq pull-right"
              onClick={() => this.toggleModal(props.original)}
              disabled={_.size(playersRescuesClient) === 0}
            >
              {this.translate('Conferir')}
            </button>
          ),
      },
      {
        id: 'id',
        Header: '',
        accessor: 'id',
        Cell: (props) =>
          props.original.rescue && (
            <span className="text-center clickable">
              <Link
                to={{
                  pathname: `${routes.DETAILS_BANK_TRANSFER_REQUEST}/${props.original.rescue.clientId}/${props.original.rescue.id}`,
                  query: { typeRescue: this.state.formData.typeRescue },
                }}
                params={{ testvalue: 'hello' }}
                className="btn btn-oq btn-sm btn-block"
              >
                Visualizar
              </Link>
            </span>
          ),
      },
      {
        Header: 'Processar',
        width: 100,
        Cell: (props) =>
          props.original.playerActive && (
            <div className="center-block">
              <input
                type="checkbox"
                className="oq-checkbox"
                onChange={(e) =>
                  this.handleSelectedBatch(e, props.original, props.row._index)
                }
                checked={
                  (this.state.selectedRescuesBatches[props.row._index] &&
                    this.state.selectedRescuesBatches[props.row._index]
                      .checked) ||
                  (props.original.playerActive &&
                    props.original.rescue &&
                    props.original.rescue.closeProccess) ||
                  this.state.isCheck.includes(props.original.rescue.id)
                }
                disabled={
                  props.original.playerActive &&
                  props.original.rescue &&
                  props.original.rescue.closeProccess
                }
              />
            </div>
          ),
      },
    ];

    const months = [
      {
        label: 'Janeiro',
        value: 0,
      },
      {
        label: 'Fevereiro',
        value: 1,
      },
      {
        label: 'Março',
        value: 2,
      },
      {
        label: 'Abril',
        value: 3,
      },
      {
        label: 'Maio',
        value: 4,
      },
      {
        label: 'Junho',
        value: 5,
      },
      {
        label: 'Julho',
        value: 6,
      },
      {
        label: 'Agosto',
        value: 7,
      },
      {
        label: 'Setembro',
        value: 8,
      },
      {
        label: 'Outubro',
        value: 9,
      },
      {
        label: 'Novembro',
        value: 10,
      },
      {
        label: 'Dezembro',
        value: 11,
      },
    ];

    const years = [
      {
        label: '2023',
        value: 2023,
      },
      {
        label: '2022',
        value: 2022,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <span className="oq-card-title">
                <i className="fa fa-gift" />
                &nbsp; Pedidos de transferências
              </span>
              <button
                type="button"
                className="btn btn-oq pull-right"
                onClick={() => this.exportWallets()}
                disabled={_.size(playersRescuesClient) === 0}
              >
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                {this.translate('Exportar')}
              </button>
              <button
                style={{ marginRight: '5px' }}
                type="button"
                className="btn btn-oq pull-right"
                onClick={() => this.confirmProcessBatches()}
                disabled={_.size(this.state.selectedRescuesBatches) === 0}
              >
                <i className="fa fa-money" aria-hidden="true"></i>
                {this.translate('Processar')}
              </button>
              <div className="col-md-3 form-group pull-right">
                <select
                  className="form-control input-oq"
                  placeholder="Ano"
                  name="year"
                  onChange={(e) => this.setState({ year: e.target.value })}
                >
                  <option value="">Selecione o ano</option>
                  {_.size(years) > 0 &&
                    _.map(years, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3 form-group pull-right">
                <select
                  className="form-control input-oq"
                  placeholder="Mês"
                  name="month"
                  onChange={(e) => this.setState({ month: e.target.value })}
                >
                  <option value="">Selecione o mês</option>
                  {_.size(months) > 0 &&
                    _.map(months, (month, idx) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                </select>
              </div>

              {client.clientUrl === 'emr' && (
                <div className="col-md-3 form-group pull-right">
                  <select
                    className="form-control input-oq"
                    placeholder="Rescue"
                    name="typeRescue"
                    onChange={(e) =>
                      this.handleSearchInputLeadsOrPlayersChange(e)
                    }
                  >
                    <option value="rescuePlayer">Resgate de players</option>
                    <option value="rescueLead">Resgate de leads</option>
                  </select>
                </div>
              )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <label htmlFor="isCheckAll" className="checkbox-inline">
                <input
                  className="oq-checkbox"
                  type="checkbox"
                  name="isCheckAll"
                  id="isCheckAll"
                  onChange={(e) => this.handleSelectAll(e)}
                  checked={this.state.isCheckAll}
                />
                &nbsp; Selecionar Todos{' '}
                {this.state.isCheck.length > 0
                  ? `(${this.state.isCheck.length})`
                  : ''}
              </label>
            </div>
          </div>

          <div className="oq-line" />
          <div className="oq-padding-bottom" />

          <ReactTable
            data={playersRescuesClient}
            defaultPageSize={10}
            columns={columns}
            loading={
              this.state.isLoadingWallet || this.state.isLoadingTransferRequest
            }
            previousText="Anterior"
            nextText="Próxima"
            loadingText="Carregando..."
            noDataText="Nenhum resgate para ser exibido"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </div>
      </div>
    );
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const { entrance, regional } = this.state.formData;
    const { clientId } = this.state;

    this.setState({ loadingSetEntrance: true });

    let parameters = {
      updateFunctionality: 'setEntranceClient',
      userType: 'administrator',
      entrance,
      clientId,
      regional,
    };

    let res = await api.post(`/update`, parameters);

    const result = res.data;

    if (result.success) {
      this.setState(
        {
          loadingSetEntrance: false,
          formData: { ...this.state.formData, entrance: 0 },
        },
        () => {
          if (
            !this.state.client.entrancePaymentByRegional ||
            (this.state.client.entrancePaymentByRegional &&
              this.state.client.entrancePaymentByRegional !== 'true')
          ) {
            this.getWalletClient(clientId);
            Notify(result.message, 'success');
          } else {
            this.getWalletClient(clientId, this.state.formData.regional);
            Notify(result.message, 'success');
          }
        }
      );
    } else {
      console.log('erro setEntranceClient', result);
      this.setState({ loadingSetEntrance: false }, () => {
        Notify(result.message, 'error');
      });
    }
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <SideBar />
        <div id="content">
          <NavBar />
          <div className="container-fluid">
            <div className="col-md-12">{this.renderBackButton()}</div>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <h1 className="oq-filter-title">
                    <i className="fa fa-wallet" aria-hidden="true" />
                    <span>&nbsp; Carteira do cliente</span>

                    <button
                      style={{ marginLeft: '10px' }}
                      type="button"
                      className="btn btn-oq"
                      onClick={() =>
                        this.goToHistoricalWalletPage(this.state.clientId)
                      }
                      disabled={
                        _.size(this.state.playersRescuesClient) === 0 ||
                        this.state.isLoadingprocessBatches
                      }
                    >
                      <i className="fa fa-history" aria-hidden="true"></i>
                      {this.translate('Histórico da carteira')}
                    </button>

                    <button
                      style={{ marginLeft: '10px' }}
                      type="button"
                      className="btn btn-oq"
                      onClick={() => this.refreshWallet()}
                      // disabled={_.size(this.state.playersRescuesClient) === 0}
                      disabled={
                        this.state.isLoadingprocessBatches ||
                        this.state.isLoading ||
                        this.state.isLoadingTransferRequest ||
                        this.state.isLoadingWallet
                      }
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                      {this.translate('Buscar')}
                    </button>
                  </h1>
                </div>
                <form onSubmit={(e) => this.handleFormSubmit(e)}>
                  {this.renderDetailsWallet()}
                </form>
                {this.renderRescuesList()}
                {this.renderModalRescueDetails()}
                {this.renderResponseProcessBatches()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditWalletPage;
