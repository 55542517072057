import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import { MdArrowBack, MdDone } from 'react-icons/md';
import ExcelImporter from './components/ExcelImporter';
import { api } from '../../../Config';
import { useUserData } from '../../../hooks/useUserData';
import { toast } from 'react-toastify';

export function WorksheetPoints() {
  let { clientData } = useUserData();
  const [excelData, setExceldata] = useState();
  const [cpfDataList, setCPFDataList] = useState();
  const [validatedCPFList, setValidatedCPFList] = useState();
  const [
    isEnabledRegisterUsersNotRegistred,
    setIsEnabledRegisterUsersNotRegistred,
  ] = useState(false);
  const [canProcess, setCanProcess] = useState(false);
  const [isUploadedWorksheet, setIsUploadedWorksheet] = useState(false);

  useEffect(() => {
    validateCPFFromWorksheet();
  }, [cpfDataList]);

  async function validateCPFFromWorksheet() {
    try {
      let result = await api.post(
        `/players/${clientData && clientData.id}/registration/list`,
        { cpfs: cpfDataList }
      );

      if (!result.data.error) {
        console.log(result.data.body);
        setValidatedCPFList(result.data.body);
        console.log(hasUnregisteredCpfs(result.data.body));
        setCanProcess(hasUnregisteredCpfs(result.data.body) === false);
      } else {
        alert('Não foi possível validar os dados!');
      }
    } catch (error) {
      console.log('error validateCPFFromWorksheet =>', error);
    }
  }

  async function processFromWorksheet() {
    try {
      console.log(excelData);

      let result = await api.post(
        `/points/process?clientUrl=${clientData && clientData.clientUrl}&registerNotFoundPlayers=${isEnabledRegisterUsersNotRegistred}`,
        { players: excelData }
      );

      if (!result.data.error) {
        toast.success('Planilha processada com sucesso!');
        setTimeout(() => {
          window.history.back();
        }, 3000);
      } else {
        alert('Não foi possível processar os dados!');
      }
    } catch (error) {
      console.log('error processFromWorksheet =>', error);
    }
  }

  function hasUnregisteredCpfs(cpfArray) {
    return cpfArray.some((item) => item.registered === false);
  }

  return (
    <>
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="wa-wrapper">
              <div className="wa-topbar">
                <div className="content-left">
                  <button
                    className="wa-back-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      window.history.back();
                    }}
                  >
                    <i>
                      <MdArrowBack />
                    </i>
                  </button>
                  <h1 className="wa-title">Pontos por planilha</h1>
                </div>
                <div className="content-right">
                  <button
                    className="wa-btn"
                    disabled={
                      canProcess === false
                        ? isEnabledRegisterUsersNotRegistred
                          ? false
                          : true
                        : false
                    }
                    style={{ backgroundColor: 'var(--primary-color)' }}
                    onClick={() => {
                      console.log('vai processar a planilha');
                      processFromWorksheet();
                    }}
                  >
                    <i>
                      <MdDone />
                    </i>
                    Processar planilha
                  </button>
                </div>
              </div>
              <div className="wa-tabs">
                <ul>
                  <li className={`wa-tab-item active`}>Detalhes</li>
                </ul>
              </div>
            </div>
            <div className="wa-content-tab">
              <div className="container">
                {!canProcess &&
                  !isEnabledRegisterUsersNotRegistred &&
                  isUploadedWorksheet && (
                    <div class="alert alert-warning mt-2" role="alert">
                      <h4 class="alert-heading">Atenção: Ação Necessária</h4>
                      <br />
                      <p>
                        Identificamos CPFs na sua planilha que ainda não estão
                        cadastrados em nossa plataforma. Para garantir que todos
                        sejam incluídos e pontuados corretamente, você tem duas
                        opções:
                      </p>
                      <ol>
                        <li>
                          Remover os CPFs não cadastrados da planilha e tentar
                          novamente, ou
                        </li>
                        <li>
                          Usar nossa função "Registrar usuários não cadastrados"
                          para incluí-los automaticamente.
                        </li>
                      </ol>
                      <hr />
                      <p class="mb-0">
                        Recomendamos a segunda opção para economizar seu tempo e
                        garantir que ninguém fique de fora.
                      </p>
                    </div>
                  )}
                <ExcelImporter
                  onDataImported={(data) => {
                    setExceldata(data);
                    setIsUploadedWorksheet(true);
                  }}
                  onCpfExtracted={(data) => {
                    setCPFDataList(data);
                  }}
                  registeredCpfs={validatedCPFList}
                  onOptionRegisterUsers={(option) => {
                    setIsEnabledRegisterUsersNotRegistred(option);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
