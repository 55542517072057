import React, { Component } from 'react';

import _ from 'lodash';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import XLSX from 'xlsx';

import { Notify } from '../../../../components/Notify';
import { api } from '../../../../Config';
import * as mask from '../../../../utils/Mascaras';
import ModalEditUsers from '../../../components/Modals/EditUsers';
import ModalConfirmImportPlayers from '../../../components/Modals/ModalConfirmImportPlayers';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

class EditUser extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.importPlayers = null;
    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      modalImporPlayersIsOpen: false,
      loadingUploadVoucher: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
      },
      modalDeleteUserIsOpen: false,
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const { clientData } = this.context;

    this.getUserById(params.userId);
    this.getGroupsClient(params.userId);
    if (
      clientData &&
      clientData.enableUserAndPlayerLinking &&
      clientData.enableUserAndPlayerLinking === 'true'
    ) {
      this.importPlayers = this.buildImportPlayersInput();
    }
  }

  getUserById(userId) {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getUserById',
        userType: 'client',
        userId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                formData: {},
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }
          this.setState(
            {
              isLoading: false,
              formValid: true,
              formData: result.user,
            }
            // Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          console.log('getUserById error:', error);
          this.setState({
            isLoading: false,
            formData: {},
          });
        });
    });
  }

  getGroupsClient() {
    this.setState({ loadingGroupsList: true }, () => {
      const parameters = {
        searchFunctionality: 'getGroupsClient',
        userType: 'client',
        searchObject: {},
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState({
              loadingGroupsList: false,
              groupsList: [],
            });
            return;
          }
          this.setState({
            loadingGroupsList: false,
            groupsList: result.groups,
          });
        })
        .catch((error) => {
          console.log('getGroupsClient error:', error);
          this.setState({
            loadingGroupsList: false,
            groupsList: [],
          });
        });
    });
  }

  dataUserIsUncompleted() {
    const { formData } = this.state;

    return !formData.name || !formData.cpf || !formData.email;
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'password') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          dataUserUncompleted: this.dataUserIsUncompleted(),
        });
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  updateUserData() {
    const { formData } = this.state;

    const { userData, getUserData } = this.context;

    this.setState({ updatingUser: true }, () => {
      this.toastId = toast.info('Atualizando usuário. Aguarde...', {
        autoClose: false,
      });

      const parameters = {
        userType: 'client',
        searchFunctionality: 'updateUserData',
        userData: formData,
      };

      api
        .post(`/users`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            this.setState({ updatingUser: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              // this.setState(this.getInitialState());
              this.setState(
                {
                  isLoading: false,
                  formData: res.data.data,
                },
                async () => {
                  this.validateForm();
                  await getUserData(userData.uid);
                }
              );
            });
          } else {
            this.setState({ updatingUser: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({ updatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel editar o usuário!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('updateUserData error:', error);
        });
    });
  }

  deleteSelectedUser(userId) {
    this.setState({ deletingUser: true }, () => {
      this.toastId = toast.info('Excluindo usuário. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deleteUser',
        userType: 'client',
        userId,
      };

      api
        .post(`/users`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            Notify('Usuário excluido', 'success');
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          this.setState({ deletingUser: false });
          console.log('deleteSelectedUser error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.updateUserData();
  }

  renderUserData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
          {dataUserUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="name-user"
                />
                <ReactTooltip
                  id="name-user"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome completo</span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                onChange={(event) => {
                  const input = event.target;
                  if (input.value.length <= 14) {
                    let maskCPF = mask.maskCPF(input.value);
                    this.handleFormDataUserChange(input.name, maskCPF);
                  } else if (
                    input.value.length > 14 &&
                    input.value.length <= 18
                  ) {
                    let maskCNPJ = mask.maskCNPJ(input.value);
                    this.handleFormDataUserChange(input.name, maskCNPJ);
                  } else {
                    let maskLimited = mask.maskLimited(input.value);
                    this.handleFormDataUserChange(input.name, maskLimited);
                  }
                }}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.email}
                disabled
              />
            </div>
            {/* <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="password">
                {this.translate('Senha')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="password"
                type="password"
                placeholder={this.translate('Senha')}
                onChange={e => this.handleUserInput(e)}
                value={formData.password}
                minLength={6}
              />
            </div> */}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  buildImportPlayersInput() {
    const stepLeadInput = document.createElement('input');
    stepLeadInput.setAttribute('type', 'file');
    stepLeadInput.setAttribute('className', 'hidden');
    stepLeadInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    stepLeadInput.onchange = (e) =>
      this.fileChanged(e.target.files, 'importPlayers');
    return stepLeadInput;
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      } else {
        Notify(
          'Formato de arquivo inválido. Permitido apenas xls/xlsx.',
          'error'
        );
      }
    }
  };

  parseFile(file, field) {
    if (field === 'importPlayers') {
      this.setState({ parsingImportPlayers: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          importPlayers: [file.name],
        },
      }));
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });

      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });

        if (roa.length) {
          result.push(roa);
        }
      });
      this.parseData(result, field);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'importPlayers') {
          return {
            playerId: item[0],
          };
        }
        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'importPlayers') {
      this.setState({ parsingImportPlayers: false });

      this.confirmImportPlayers(items);
    }
  }

  confirmImportPlayers(players) {
    let playersLinkingUser = [];

    _.each(players, (player) => {
      if (player.playerId) {
        playersLinkingUser.push({
          playerId: player.playerId,
        });
      }
    });

    this.userAndPlayersLinking(playersLinkingUser);
  }

  userAndPlayersLinking(playersLinkingUser) {
    const { userData } = this.context;
    const userId = userData.uid;

    this.setState({ loadingUploadVoucher: true }, () => {
      console.log('playersLinkingUser: ', playersLinkingUser);
      Notify('Aguarde enquanto estamos fazendo a vinculação.', 'loading');

      const parameters = {
        userType: 'client',
        updateFunctionality: 'playersLinkingUser',
        playersLinkingUser: playersLinkingUser,
        userId,
      };
      console.log('parameters: ', parameters);

      api
        .post(`/update`, parameters)
        .then(async (res) => {
          this.setState({ loadingUploadVoucher: false });
          const result = res.data;
          console.log('respostaa playersLinkingUser: ', result);

          if (result.success) {
            _.each(result.data, (responseUpdate) => {
              if (responseUpdate && responseUpdate.success) {
                Notify(responseUpdate.message, 'success', 15000);
              } else {
                Notify(responseUpdate.message, 'error', 15000);
              }
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingUploadVoucher: false });
          console.log('userAndPlayersLinking error:', error);
        });
    });
  }

  toggleModalImportPlayers() {
    this.setState({
      modalImporPlayersIsOpen: !this.state.modalImporPlayersIsOpen,
    });
  }

  modalConfirmImportPlayers() {
    return (
      <ModalConfirmImportPlayers
        open={this.state.modalImporPlayersIsOpen}
        close={this.toggleModalImportPlayers.bind(this)}
        importPlayers={this.importPlayers}
      />
    );
  }

  renderGroupsUser() {
    const { loadingGroupsList, groupsList } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="groups">
              Grupo
            </label>
            <Select
              name="groups"
              placeholder="Selecione o grupo..."
              isDisabled={loadingGroupsList}
              options={_.map(groupsList, (group) => {
                return {
                  value: group.id,
                  label: group.name,
                };
              })}
              isLoading={loadingGroupsList}
              className="basic-multi-select"
              value={this.state.formData.group}
              onChange={(e) => this.handleFormDataChange('group', e)}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderUserVinculation() {
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#userVinculation"
          aria-expanded="false"
        >
          Vinculação
        </h4>
        <div id="userVinculation" aria-expanded="false" className="collapse">
          {/* <div className="form-group col-md-12"> */}
          <label className="profile-label" htmlFor="groups">
            Upload de players
          </label>

          <div className="form-group">
            <button
              type="button"
              className="btn btn-oq-black"
              disabled={this.state.loadingUploadVoucher}
              onClick={() => this.toggleModalImportPlayers()}
            >
              {this.translate('Fazer upload de players')}
            </button>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/oq20-139d9.appspot.com/o/PublicTarget%2FPlanilha_exemplo_vincular_players.xlsx?alt=media&token=eef2c5f5-ad88-44c7-8553-4b6faf624ae0"
              style={{
                marginTop: '.5rem',
                marginLeft: '.5rem',
                display: 'block',
              }}
              className="menu-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Exemplo planilha de players
            </a>
          </div>
          {/* </div> */}
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    console.log('formData', this.state.formData);
    const { clientData } = this.context;

    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        {this.state.formData &&
          !this.state.formData.master &&
          this.renderGroupsUser()}

        {clientData &&
          clientData.enableUserAndPlayerLinking &&
          clientData.enableUserAndPlayerLinking === 'true' &&
          this.renderUserVinculation()}
        {/* {this.renderGroupsUser()} */}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.updatingUser}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  dataUserUncompleted: this.dataUserIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
        {this.renderRemoveUser()}
        {this.modalConfirmImportPlayers()}
      </div>
    );
  }

  renderRemoveUser() {
    const { groupPermissions, userData } = this.context;
    return (
      <div className="col-12 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() => this.toggleModalDeleteUser()}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir usuário')}
        </span>
        {this.deleteUser()}
      </div>
    );
  }

  toggleModalDeleteUser() {
    this.setState({
      modalDeleteUserIsOpen: !this.state.modalDeleteUserIsOpen,
    });
  }

  deleteUser() {
    return (
      <ModalEditUsers
        open={this.state.modalDeleteUserIsOpen}
        close={this.toggleModalDeleteUser.bind(this)}
        userId={this.state.formData.uid}
        deleteSelectedUser={this.deleteSelectedUser.bind(this)}
      />
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Editar Usuário')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
