import axios from 'axios';

let isRefreshing = false;
let failedRequestQueue = [];

export function getAPIURL() {
  switch (process.env.REACT_APP_VERSION) {
    case 'qa':
      return 'https://api.qa.oqdigital.com';
    case 'production':
      return 'https://api3.oqdigital.com';
    default:
      return 'http://localhost:4000';
  }
}
const api = axios.create({
  baseURL: getAPIURL(),
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (error.response.data.data.code === 'auth/id-token-expired') {
        // Solução temporária para o Refresh token
        // localStorage.removeItem('@admin:userData');
        // localStorage.removeItem('@admin:userAuth');
        // localStorage.removeItem('@admin:client');

        // window.location.href = `/client/signin/${localStorage.getItem(
        //   'clientAlias'
        // )}`;
        // Fim Solução temporária para o Refresh token

        //fazer o refresh e salvar novo token
        const originalConfig = error.config;

        const { refresh } = error.response.config.headers;

        if (!isRefreshing) {
          isRefreshing = true;
          const parameters = {
            refresh_token: refresh,
            searchFunctionality: 'refreshToken',
          };
          api
            .post('search', parameters)
            .then((response) => {
              const { accessToken, refreshToken } = response.data.data;

              api.defaults.headers.common['Authorization'] =
                `OQVCQUER ${accessToken}`;
              api.defaults.headers.common['refresh'] = refreshToken;

              failedRequestQueue.forEach((request) =>
                request.onSuccess(accessToken, refreshToken)
              );
              failedRequestQueue = [];
            })
            .catch((error) => {
              failedRequestQueue.forEach((request) => request.onFailure(error));
              failedRequestQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token, refresh) => {
              originalConfig.headers['Authorization'] = `OQVCQUER ${token}`;
              originalConfig.headers['refresh'] = refresh;

              resolve(api(originalConfig));
            },

            onFailure: (error) => {
              reject(error);
            },
          });
        });
      } else {
        localStorage.clear();
        //deslogar
      }
    }
  }
);

export { api };
