import {
  faChartLine,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import i18next from 'i18next';
import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api } from '../../../../Config';
import { auth } from '../../../../firebase';
import * as routes from '../../../../routes';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import ConversionsLine from './components/ConversionsLine';
import './style.css';

import { add } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactExport from 'react-export-excel';
import { DateRangePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import Select from 'react-select';
import UsersTypes from './components/UsersType';

import ConversionsCount from '../components/Counts/ConversionsCount';
import InstallPwaCount from '../components/Counts/InstallPwaCount';
import LeadsCount from '../components/Counts/LeadsCount';
import PageViewsCount from '../components/Counts/PageViewsCount';
import PercentageOfEngagement from '../components/Counts/PercentageOfEngagement';
import SharesCount from '../components/Counts/SharesCount';
import UsersCount from '../components/Counts/UsersCount';

import EngagementPlayers from './components/Reports/EngagementPlayers';
import FunnelChartSale from './components/Reports/FunnelChartSale';
import GeoMap from './components/Reports/GeoMap';
// import GoalData from './components/Reports/GoalData';
import LeadsConvertedAndCreated from './components/Reports/LeadsConvertedAndCreated';
import LeadsFunil from './components/Reports/LeadsFunil';
import LeadsPerAction from './components/Reports/LeadsPerAction';
import RankingCorrectors from './components/Reports/RankingCorrectors';
import RankingIndications from './components/Reports/RankingIndications';
import RankingPlayers from './components/Reports/RankingPlayers';
import ShareData from './components/Reports/ShareData';
import ShareInAction from './components/Reports/ShareInAction';
import { UserDataContext } from '../../../../hooks/useUserData';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Dashboard extends Component {
  _isMounted = false;
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      isOpenGraph: false,
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      currentUser: auth.getAuthUser(),
      loadingCounts: {
        loadingPercentageOfEngagement: true,
        loadingPlayersCount: true,
        loadingPageViewCount: true,
        loadingSharesCount: true,
        loadingLeadsCount: true,
        loadingConversionsCount: true,
        loadingTotalPoints: true,
        loadingRedeemPoints: true,
        loadingInstallPwaCount: true,
      },
      loadingReports: {
        loadingEngagementPlayers: true,
        loadingFunnelChart: true,
        loadingGeoMap: true,
        loadingLeadsConvertedAndCreated: true,
        loadingLeadsFunil: true,
        loadingPlayersTopFiveOrder: true,
        loadingShareInAction: true,
        loadingLeadsPerAction: true,
        loadingLeadsPerState: true,
        loadingShareChannel: true,
        loadingRankingIndications: true,
        loadingGoalData: true,
        loadingRankingCorrectors: false,
      },
      counts: {
        playersCount: {},
        pageViewCount: {},
        sharesCount: {},
        leadsCount: {},
        conversionsCount: {},
        totalPoints: {},
        installPwaCount: 0,
      },
      percentageOfEngagement: 0,
      modalIsOpen: false,
      modalIsOpenUsersCount: false,
      modalIsOpenPageViewsCount: false,
      modalTitle: '',
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],

      report: [],
      laodingReport: true,
      loadingUserType: true,
      filterSelect: 'week',
      startDate: null,
      endDate: null,
      laodingReportByInterval: false,
      totalUsersNormal: 0,
      totalUsersRegional: 0,
      dataBaseReports: [],
      dataBaseTotalReports: [],
      clientsPermisisonGamification: ['eph', 'meuvivaz'],
    };
    return initialState;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getCounts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLoadingStats(stats, loading) {
    this.setState((prevState) => ({
      loadingStats: {
        ...prevState.loadingStats,
        [stats]: loading,
      },
    }));
  }

  handleLoadingCounts(count, loading) {
    this.setState((prevState) => ({
      loadingCounts: {
        ...prevState.loadingCounts,
        [count]: loading,
      },
    }));
  }

  handleDataCounts(count, data) {
    this.setState((prevState) => ({
      counts: {
        ...prevState.counts,
        [count]: data,
      },
    }));
  }

  async getCounts() {
    await this.getPlayersCount();
    await this.getPageViewCount();
    await this.getLeadsCount();
    await this.getConversionCount();
    await this.getSharesCount();
    await this.getTotalPoints();
    await this.getInstallPwaCount();
    await this.getEngagementPlayers();
    // await this.getRedeemPoints();
  }

  getReportsByInterval(startDate, endDate) {
    this.setState({ laodingReportByInterval: true });
    const parameters = {
      searchFunctionality: 'getReports',
      userType: 'client',
      startDate,
      endDate,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const { data, total } = res.data;
        //this.generateSheetByInterval(data);
        this.setState({
          dataBaseReports: data,
          dataBaseTotalReports: total,
        });
        this.setState({ laodingReportByInterval: false });
      })
      .catch((error) => {
        console.log('getReportsByInterval error:', error);
        //this.handleLoadingStats('playersCount', false);
        this.setState({ laodingReportByInterval: false });
      });
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  async getIndications() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingRankingIndications: true,
      },
    }));

    try {
      const parameters = {
        searchFunctionality: 'getIndicationsMGM',
        userType: 'client',
      };

      const res = await api.post(`/search`, parameters);

      let ranking = res.data.data.slice(0, 5);

      this.setState((prevState) => ({
        report: {
          ...prevState.report,
          rankingIndications: ranking,
        },
        loadingReports: {
          ...prevState.loadingReports,
          loadingRankingIndications: false,
        },
      }));

      this.setState({ rankingIndications: ranking });
    } catch (error) {
      console.log('getIndicationsMGM error:', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingRankingIndications: false,
        },
      }));
    }
  }

  async getPlayersCount() {
    this.handleLoadingCounts('loadingPlayersCount', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'playersCount',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        amountOfPlayersPerWeekDay,
        amountOfPlayersPerDay,
        amountOfPlayersPerMonth,
        playersCount,
      } = res.data;

      if (clientData.primaryColor && clientData.secondaryColor) {
        const colors = this.processColors(
          clientData.primaryColor,
          clientData.secondaryColor
        );
        this.setState({ colors });
      }

      if (this._isMounted) {
        this.handleDataCounts('playersCount', {
          amountOfPlayersPerWeekDay,
          amountOfPlayersPerDay,
          amountOfPlayersPerMonth,
          playersCount,
        });

        this.handleLoadingCounts('loadingPlayersCount', false);
      }
    } catch (error) {
      console.log('getPlayersCount error:', error);
      this.handleLoadingCounts('loadingPlayersCount', false);
    }
  }

  async getSharesCount() {
    this.handleLoadingCounts('loadingSharesCount', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'sharesCount',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        amountOfSharesPerWeekDay,
        amountOfSharesPerDay,
        amountOfSharesPerMonth,
        sharesCount,
        shareMonthsPerPlayer,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('sharesCount', {
          amountOfSharesPerWeekDay,
          amountOfSharesPerDay,
          amountOfSharesPerMonth,
          sharesCount,
          shareMonthsPerPlayer,
        });

        this.handleLoadingCounts('loadingSharesCount', false);
      }
    } catch (error) {
      console.log('getPlayersCount error:', error);
      this.handleLoadingCounts('loadingSharesCount', false);
    }
  }

  percentageOfEngagement() {
    this.handleLoadingCounts('loadingPercentageOfEngagement', true);
    let percentageEngagement = 0;
    let totalPlayers = 0;
    let playersInAction = 0;
    if (this.state.report.engagementPlayers) {
      const { engagementPlayersTotal } = this.state.report;

      engagementPlayersTotal.map((engagementPlayer) => {
        totalPlayers += engagementPlayer.playersTotal;
        playersInAction += engagementPlayer.playerInAction;
      });
      percentageEngagement = (playersInAction / totalPlayers) * 100;

      this.setState({
        percentageOfEngagement: percentageEngagement.toFixed(1),
      });
      this.handleLoadingCounts('loadingPercentageOfEngagement', false);
    }
  }

  async getPageViewCount() {
    this.handleLoadingCounts('loadingPageViewCount', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'pageViewCount',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        pageViewsPerWeekDay,
        pageViewsPerDay,
        pageViewsPerMonth,
        pageViewCount,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('pageViewCount', {
          pageViewsPerWeekDay,
          pageViewsPerDay,
          pageViewsPerMonth,
          pageViewCount,
        });
        this.handleLoadingCounts('loadingPageViewCount', false);
      }
    } catch (error) {
      console.log('getPlayersCount error:', error);
      this.handleLoadingCounts('loadingPageViewCount', false);
    }
  }

  async getLeadsCount() {
    this.handleLoadingCounts('loadingLeadsCount', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'leadsCount',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        amountOfLeadsPerWeekDay,
        amountOfLeadsPerDay,
        amountOfLeadsPerMonth,
        leadsCount,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('leadsCount', {
          amountOfLeadsPerWeekDay,
          amountOfLeadsPerDay,
          amountOfLeadsPerMonth,
          leadsCount,
        });
        this.handleLoadingCounts('loadingLeadsCount', false);
      }
    } catch (error) {
      console.log('getLeadsCount error:', error);
      this.handleLoadingCounts('loadingLeadsCount', false);
    }
  }

  async getConversionCount() {
    this.handleLoadingCounts('loadingConversionsCount', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'conversionsCount',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        amountOfConversionsPerWeekDay,
        amountOfConversionsPerDay,
        amountOfConversionsPerMonth,
        conversionsCount,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('conversionsCount', {
          amountOfConversionsPerWeekDay,
          amountOfConversionsPerDay,
          amountOfConversionsPerMonth,
          conversionsCount,
        });
        this.handleLoadingCounts('loadingConversionsCount', false);
      }
    } catch (error) {
      console.log('getConversionCount error:', error);
      this.handleLoadingCounts('loadingConversionsCount', false);
    }
  }

  async getTotalPoints() {
    this.handleLoadingCounts('loadingTotalPoints', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'totalPoints',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        generatedPointsPerWeekDay,
        generatedPointsPerDay,
        generatedPointsPerMonth,
        totalPoints,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('totalPoints', {
          generatedPointsPerWeekDay,
          generatedPointsPerDay,
          generatedPointsPerMonth,
          totalPoints,
        });
        this.handleLoadingCounts('loadingTotalPoints', false);
      }
    } catch (error) {
      console.log('getTotalPoints error:', error);
      this.handleLoadingCounts('loadingTotalPoints', false);
    }
  }

  async getRedeemPoints() {
    this.handleLoadingStats('laodingRedeemPoints', true);

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        countsName: 'redeemPoints',
        clientId: clientData.uid,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const {
        redeemPointsPerWeekDay,
        redeemPointsPerDay,
        redeemPointsPerMonth,
        redeemPoints,
      } = res.data;

      if (this._isMounted) {
        this.handleDataCounts('redeemPoints', {
          redeemPointsPerWeekDay,
          redeemPointsPerDay,
          redeemPointsPerMonth,
          redeemPoints,
        });

        this.handleLoadingStats('laodingRedeemPoints', false);
      }
    } catch (error) {
      console.log('getRedeemPoints error:', error);
      this.handleLoadingStats('laodingRedeemPoints', false);
    }
  }

  async getShareChannelsGraphic() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingShareChannel: true,
      },
    }));

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadReports',
        userType: 'client',
        clientId: this.context.clientData.uid,
        reportsName: 'shareChannel',
        primaryColor: clientData.primaryColor,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const result = res.data;

      if (this._isMounted) {
        this.setState((prevState) => ({
          report: {
            ...prevState.report,
            shareData: result,
          },
          loadingReports: {
            ...prevState.loadingReports,
            loadingShareChannel: false,
          },
        }));
      }
    } catch (error) {
      console.log('getShareChannelsGraphic error:', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingShareChannel: false,
        },
      }));
    }
  }

  async getLeadsPerStateGraphic() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingLeadsPerState: true,
      },
    }));

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadReports',
        userType: 'client',
        reportsName: 'leadsPerState',
        clientId: clientData.uid,
        primaryColor: clientData.primaryColor,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const result = res.data;

      if (this._isMounted) {
        this.setState((prevState) => ({
          report: {
            ...prevState.report,
            stateLeadsData: result,
          },
          loadingReports: {
            ...prevState.loadingReports,
            loadingLeadsPerState: false,
          },
        }));
      }
    } catch (error) {
      console.log('getLeadsPerStateGraphic error:', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingLeadsPerState: false,
        },
      }));
    }
  }

  async getLeadsPerActionGraphic() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingLeadsPerAction: true,
      },
    }));

    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadReports',
        userType: 'client',
        reportsName: 'leadsPerAction',
        clientId: clientData.uid,
        primaryColor: clientData.primaryColor,
      };

      const res = await api.post(`/dashboardAction`, parameters);

      const result = res.data;

      if (this._isMounted) {
        this.setState((prevState) => ({
          report: {
            ...prevState.report,
            chartDataPie: result,
          },
          loadingReports: {
            ...prevState.loadingReports,
            loadingLeadsPerAction: false,
          },
        }));
      }
    } catch (error) {
      console.log('getLeadsPerActionGraphic error:', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingLeadsPerAction: false,
        },
      }));
    }
  }

  async getTesteReport() {
    const parameters = {
      userType: 'client',
      searchFunctionality: 'getReport',
    };

    const result = await api.post(`/search`, parameters);

    if (result.data.success) {
      this.setState({ report: result.data.data, laodingReport: true });
      this.percentageOfEngagement();
    }
  }

  async getReport() {
    // await this.getTesteReport();
    this.context &&
      this.context.clientData &&
      this.context.clientData.mgmVinculated === 'true' &&
      (await this.getPlayers());
    await this.getEngagementPlayers();
    await this.getLeadsConvertedAndCreated();
    await this.getLeadsFunil();
    await this.getShareInAction();
    await this.getLeadsPerActionGraphic();
    await this.getPlayersTopFiveOrder();
    this.context &&
      this.context.clientData &&
      this.context.clientData.mgmVinculated === 'true' &&
      (await this.getIndications());
    await this.getFunnelChart();
    await this.getShareChannelsGraphic();
    await this.getGeoMap();

    if (
      this.context.clientData &&
      this.context.clientData.scaleEnable === 'true' &&
      this.context.clientData.mgmVinculated &&
      this.context.clientData.mgmVinculated === 'true'
    ) {
      // this.getMonthlyGoal();
    }

    // console.log('CLIENT AQUI =>', this.context.clientData);

    // if (
    //   this.context.clientData &&
    //   this.context.clientData.enableRankingRange &&
    //   this.context.clientData.enableRankingRange === 'true' &&
    //   this.context.clientData.mgmVinculated &&
    //   this.context.clientData.mgmVinculated === 'true'
    // ) {
    //   console.log('entrou na verificação');
    //   // this.getRankingCorrectors();
    // }

    this.getRankingCorrectors();
  }

  async getMonthlyGoal() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingGoalData: true,
      },
    }));
    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        searchFunctionality: 'getMonthlyGoal',
        userType: 'player',
        clientId: clientData && clientData.uid,
      };

      const { data } = await api.post(`/search`, parameters);

      console.log(
        '🚀 ~ file: index.js:939 ~ Dashboard ~ getMonthlyGoal ~ data',
        data
      );

      if (data.success) {
        this.setState((prevState) => ({
          report: {
            ...prevState.report,
            goalData: data.data,
          },
          loadingReports: {
            ...prevState.loadingReports,
            loadingGoalData: false,
          },
        }));
      }
    } catch (error) {
      console.log('getMonthlyGoal error', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingGoalData: false,
        },
      }));
    }
  }

  async getRankingCorrectors() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingRankingCorrectors: true,
      },
    }));
    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        searchFunctionality: 'getRankingCorrectors',
        userType: 'client',
        clientId: clientData && clientData.uid,
      };

      const { data } = await api.post(`/search`, parameters);

      if (data.success) {
        this.setState((prevState) => ({
          report: {
            ...prevState.report,
            rankingCorrectorsData: data.data,
          },
          loadingReports: {
            ...prevState.loadingReports,
            loadingRankingCorrectors: false,
          },
        }));
      }
    } catch (error) {
      console.log('getRankingCorrectors error', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingRankingCorrectors: false,
        },
      }));
    }
  }

  async getEngagementPlayers() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingEngagementPlayers: true,
      },
    }));

    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'engagementPlayers',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              engagementPlayers: [],
              engagementPlayersTotal: 0,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingEngagementPlayers: false,
            },
            percentageOfEngagement: 0,
          }));
          this.handleLoadingCounts('loadingPercentageOfEngagement', false);
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              engagementPlayers: result.data.data.engagementPlayers,
              engagementPlayersTotal: result.data.data.engagementPlayersTotal,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingEngagementPlayers: false,
            },
          }));
          this.percentageOfEngagement();
        }
      }
    } catch (error) {
      console.log('error getEngagementPlayers: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingEngagementPlayers: false,
        },
      }));
    }
  }

  async getInstallPwaCount() {
    try {
      const { clientData, userData, userAuth } = this.context;

      const parameters = {
        dashboardFunctionality: 'loadCounts',
        userType: 'client',
        reportsName: 'installPwa',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      console.log('result getInstallPwaCount: ', result.data);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            counts: {
              ...prevState.counts,
              installPwaCount: 0,
            },
          }));
          this.handleLoadingCounts('loadingInstallPwaCount', false);
        } else {
          this.setState((prevState) => ({
            counts: {
              ...prevState.counts,
              installPwaCount: result.data.data.numberInstallPwa,
            },
          }));
          this.handleLoadingCounts('loadingInstallPwaCount', false);
        }
      }
    } catch (error) {
      console.log('error getInstallPwaCount: ', error);
      this.setState((prevState) => ({
        loadingCounts: {
          ...prevState.loadingCounts,
          loadingInstallPwaCount: false,
        },
      }));
    }
  }

  async getFunnelChart() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingFunnelChart: true,
      },
    }));
    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'funnelChart',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              funnelChart: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingFunnelChart: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              funnelChart: result.data.data.funnelChart,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingFunnelChart: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getFunnelChart: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingFunnelChart: false,
        },
      }));
    }
  }

  async getGeoMap() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingGeoMap: true,
      },
    }));
    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'geoMap',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              geoMap: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingGeoMap: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              geoMap: result.data.data.geoMap,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingGeoMap: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getGeoMap: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingGeoMap: false,
        },
      }));
    }
  }

  async getLeadsConvertedAndCreated() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingLeadsConvertedAndCreated: true,
      },
    }));

    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'leadsConvertedAndCreated',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              leadsConvertedAndCreated: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingLeadsConvertedAndCreated: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              leadsConvertedAndCreated:
                result.data.data.leadsConvertedAndCreated,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingLeadsConvertedAndCreated: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getLeadsConvertedAndCreated-->', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingLeadsConvertedAndCreated: false,
        },
      }));
    }
  }

  async getLeadsFunil() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingLeadsFunil: true,
      },
    }));
    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'leadsFunil',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              leadsFunil: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingLeadsFunil: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              leadsFunil: result.data.data.leadsFunil,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingLeadsFunil: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getLeadsFunil: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingLeadsFunil: false,
        },
      }));
    }
  }

  async getPlayersTopFiveOrder() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingPlayersTopFiveOrder: true,
      },
    }));
    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'playersTopFiveOrder',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              playersTopFiveOrder: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingPlayersTopFiveOrder: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              playersTopFiveOrder: result.data.data.playersTopFiveOrder,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingPlayersTopFiveOrder: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getPlayersTopFiveOrder: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingPlayersTopFiveOrder: false,
        },
      }));
    }
  }

  async getShareInAction() {
    this.setState((prevState) => ({
      loadingReports: {
        ...prevState.loadingReports,
        loadingShareInAction: true,
      },
    }));
    try {
      const { clientData } = this.context;

      const parameters = {
        userType: 'client',
        dashboardFunctionality: 'loadReports',
        reportsName: 'shareInAction',
        clientId: clientData.uid,
      };

      const result = await api.post(`/dashboardAction`, parameters);

      if (this._isMounted) {
        if (!result.data.success) {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              shareInAction: [],
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingShareInAction: false,
            },
          }));
        } else {
          this.setState((prevState) => ({
            report: {
              ...prevState.report,
              shareInAction: result.data.data.shareInAction,
            },
            loadingReports: {
              ...prevState.loadingReports,
              loadingShareInAction: false,
            },
          }));
        }
      }
    } catch (error) {
      console.log('error getShareInAction: ', error);
      this.setState((prevState) => ({
        loadingReports: {
          ...prevState.loadingReports,
          loadingShareInAction: false,
        },
      }));
    }
  }

  renderOptionsDash() {
    return (
      <div className="col-md-12 flex oq-margin-top oq-padding-top">
        <div className="col-md-2">
          <div className="btn btn-oq-no-color btn-block  active-btn">
            PUBLICAÇÕES
          </div>
        </div>
        {this.context.clientData &&
          this.state.clientsPermisisonGamification.includes(
            this.context.clientData.clientUrl
          ) && (
            <div className="col-md-2">
              <Link to={routes.DASHBOARD_GAMIFICATION}>
                <div className="btn btn-oq-no-color btn-block">GAMIFICAÇÃO</div>
              </Link>
            </div>
          )}

        <div className="col-md-2">
          <Link to={routes.DASHBOARD_RESEARCH_AWARD}>
            {' '}
            <div className="btn btn-oq-no-color btn-block ">PESQUISAS</div>
          </Link>
        </div>
        <div className="col-md-2">
          <div className="btn btn-oq-no-color btn-oq-large btn-block">
            OMNICHANNEL
          </div>
        </div>
        <div className="col-md-4">
          <Link to={routes.DASHBOARD_QUIZ_CLIENT}>
            <div className="btn btn-oq-no-color btn-block">
              QUIZ/TREINAMENTO
            </div>
          </Link>
        </div>
      </div>
    );
  }

  handleStartDate(e) {
    this.setState({ dataBaseReports: [], dataBaseTotalReports: [] });
    this.setState({ startDate: e });
  }
  handleEndDate(e) {
    this.setState({ dataBaseReports: [], dataBaseTotalReports: [] });
    this.setState({ endDate: e });
  }

  generateReportsByInterval() {
    const { startDate, endDate } = this.state;

    const startDateEpoch = startDate.getTime();
    const endDateEpoch = add(endDate, { hours: 20 }).getTime();

    this.getReportsByInterval(startDateEpoch, endDateEpoch);
  }

  generateSheetByInterval(database, total) {
    return (
      <>
        <ExcelFile
          element={
            <button className="btn btn-oq btn-dashboad">Gerar Relatório</button>
          }
        >
          <ExcelSheet data={total} name={'Total'}>
            <ExcelColumn label={'Tipo'} value={'type'} />
            <ExcelColumn label={'Quantidade'} value={'amount'} />
          </ExcelSheet>
          <ExcelSheet data={database[0].amountPerDay} name={'Usuários'}>
            <ExcelColumn label={'Dia'} value="x" />
            <ExcelColumn label="quantidade" value="y" />
          </ExcelSheet>
          <ExcelSheet data={database[1].amountPerDay} name="Pageviews">
            <ExcelColumn label={'Dia'} value="x" />
            <ExcelColumn label="quantidade" value="y" />
          </ExcelSheet>
          <ExcelSheet data={database[2].amountPerDay} name="Leads">
            <ExcelColumn label={'Dia'} value="x" />
            <ExcelColumn label="quantidade" value="y" />
          </ExcelSheet>
          <ExcelSheet data={database[3].amountPerDay} name="Conversão">
            <ExcelColumn label={'Dia'} value="x" />
            <ExcelColumn label="quantidade" value="y" />
          </ExcelSheet>
        </ExcelFile>
      </>
    );
  }

  generateReports() {
    const { filterSelect, startDate, endDate, shareMonthsPerPlayer } =
      this.state;

    const { amountOfConversionsPerWeekDay, amountOfConversionsPerMonth } =
      this.state.counts.conversionsCount;

    const { pageViewsPerWeekDay, pageViewsPerMonth } =
      this.state.counts.pageViewCount;

    const { amountOfLeadsPerWeekDay, amountOfLeadsPerMonth } =
      this.state.counts.leadsCount;

    const { amountOfPlayersPerWeekDay, amountOfPlayersPerMonth } =
      this.state.counts.playersCount;

    const options = [
      { value: 'week', label: 'Essa Semana' },
      { value: 'month', label: 'Esse Mês' },
      { value: 'year', label: 'Esse Ano' },
      { value: 'interval', label: 'Por Intervalo' },
      this.context.clientData &&
        this.context.clientData.clientUrl === 'bild' && {
          value: 'sharePlayer',
          label: 'Compartilhamento por mês',
        },
    ];

    const shareMonth = shareMonthsPerPlayer ? shareMonthsPerPlayer : {};
    const {
      countShareJan,
      countShareFev,
      countShareMar,
      countShareApr,
      countShareMay,
      countShareJun,
      countShareJul,
      countShareAug,
      countShareSept,
      countShareOct,
      countShareNov,
      countShareDec,
    } = shareMonth;

    let userFilter = null;
    let pageViewsFilter = null;
    let leadsFilter = null;
    let conversionFilter = null;
    let customLabel = null;
    let [jan, fev, mar, apr, may, jun, jul, aug, sept, oct, nov, dec] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];
    if (filterSelect === 'week') {
      userFilter = amountOfPlayersPerWeekDay;
      pageViewsFilter = pageViewsPerWeekDay;
      leadsFilter = amountOfLeadsPerWeekDay;
      conversionFilter = amountOfConversionsPerWeekDay;
      customLabel = 'Dia da semana';
    } else if (filterSelect === 'month') {
      userFilter = amountOfPlayersPerMonth;
      pageViewsFilter = pageViewsPerMonth;
      leadsFilter = amountOfLeadsPerMonth;
      conversionFilter = amountOfConversionsPerMonth;
      customLabel = 'Mês';
    } else if (filterSelect === 'year') {
      userFilter = amountOfPlayersPerMonth;
      pageViewsFilter = pageViewsPerMonth;
      leadsFilter = amountOfLeadsPerMonth;
      conversionFilter = amountOfConversionsPerMonth;
      customLabel = 'Ano';
    } else if (filterSelect === 'sharePlayer') {
      jan = countShareJan;
      fev = countShareFev;
      mar = countShareMar;
      apr = countShareApr;
      may = countShareMay;
      jun = countShareJun;
      jul = countShareJul;
      aug = countShareAug;
      sept = countShareSept;
      oct = countShareOct;
      nov = countShareNov;
      dec = countShareDec;
    }

    return (
      <div
        style={{
          display: 'flex',
          marginLeft: '2.5rem',
          marginTop: '2rem',
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Select
            className="select-dashboard"
            name="filter"
            onChange={(e) => this.handleFilter(e)}
            placeholder={'Selecione um filtro'}
            // options={options}
            options={_.map(options, (op) => {
              return {
                value: op.value,
                label: op.label,
              };
            })}
          />
        </div>
        <div>
          {filterSelect === 'interval' ? (
            <div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={(e) => this.handleStartDate(e)}
                onEndDateChange={(e) => this.handleEndDate(e)}
                maximumDate={new Date()}
                minimumLength={1}
                format="dd MMM yyyy"
                locale={ptBR}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className="date-range">
                    <input
                      className="datepicker-dashboard"
                      {...startDateInputProps}
                      placeholder="Data inicial"
                    />
                    <span className="label-datapicker-dashboard"> Até </span>
                    <input
                      className="datepicker-dashboard"
                      {...endDateInputProps}
                      placeholder="Data final"
                    />
                  </div>
                )}
              </DateRangePicker>
              <div className="div-btn-dashboard">
                <button
                  className="btn btn-oq btn-dashboad"
                  onClick={() => this.generateReportsByInterval()}
                >
                  {this.state.laodingReportByInterval === false
                    ? 'Pesquisar'
                    : 'Pesquisando...'}
                </button>
                {this.state.dataBaseReports.length > 0 &&
                  this.state.dataBaseTotalReports.length > 0 &&
                  this.generateSheetByInterval(
                    this.state.dataBaseReports,
                    this.state.dataBaseTotalReports
                  )}
              </div>
            </div>
          ) : (
            ''
          )}
          {filterSelect === 'sharePlayer' ? (
            <div>
              <ExcelFile
                element={
                  <button
                    className={`btn btn-oq btn-dashboad ${
                      filterSelect === 'interval' ? 'hidden' : ''
                    }`}
                  >
                    Gerar Relatório
                  </button>
                }
              >
                <ExcelSheet data={jan} name={'Janeiro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={fev} name={'Fevereiro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={mar} name={'Março'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={apr} name={'Abril'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={may} name={'Maio'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={jun} name={'Junho'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={jul} name={'Julho'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={aug} name={'Agosto'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={sept} name={'Setembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={oct} name={'Outubro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={nov} name={'Novembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={dec} name={'Dezembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <ExcelFile
            element={
              <button
                className={`btn btn-oq btn-dashboad ${
                  filterSelect === 'interval' || filterSelect === 'sharePlayer'
                    ? 'hidden'
                    : ''
                }`}
              >
                Gerar Relatório
              </button>
            }
          >
            <ExcelSheet data={userFilter} name={'Usuários'}>
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={pageViewsFilter} name="Pageviews">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={leadsFilter} name="Leads">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={conversionFilter} name="Conversão">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              marginRight: '20px',
            }}
            onClick={async () => {
              this.setState({ isOpenGraph: !this.state.isOpenGraph });
              await this.getReport();
            }}
            className="btn btn-oq btn-dashboad"
          >
            Carregar gráficos
          </button>
        </div>
      </div>
    );
  }

  handleFilter(e) {
    const { value } = e;
    this.setState({
      filterSelect: value,
    });
  }

  async getPlayers() {
    try {
      const parameters = {
        searchFunctionality: 'getPlayers',
        userType: 'client',
        isDashboard: true,
      };

      const res = await api.post(`/search`, parameters);

      const result = res.data;

      const { playersNormal, playersRegional } = this.dividerPlayers(
        result.data.players
      );

      //   'playersNormal, playersRegional-->',
      //   playersNormal,
      //   playersRegional
      // );

      this.setState({
        totalUsersNormal: playersNormal.length,
        totalUsersRegional: playersRegional.length,
        loadingUserType: false,
      });
    } catch (error) {
      console.log('getPlayers error:', error);
    }
  }

  dividerPlayers(players) {
    let playersRegional = [];
    let playersNormal = [];

    playersRegional = players.filter((el) => {
      return el.regional === 'sim';
    });

    playersNormal = players.filter((el) => {
      return el.regional === 'nao';
    });

    return { playersNormal, playersRegional };
  }

  renderGraphics() {
    const { amountOfConversionsPerMonth } = this.state.counts.conversionsCount;
    const { loadingConversionsCount } = this.state.loadingCounts;
    const { clientData } = this.context;

    const {
      rankingIndications,
      chartDataPie,
      engagementPlayers,
      engagementPlayersTotal,
      funnelChart,
      geoMap,
      leadsConvertedAndCreated,
      leadsFunil,
      playersTopFiveOrder,
      shareData,
      shareInAction,
      goalData,
      rankingCorrectorsData,
    } = this.state.report;
    const {
      loadingEngagementPlayers,
      loadingFunnelChart,
      loadingGeoMap,
      loadingLeadsConvertedAndCreated,
      loadingLeadsFunil,
      loadingLeadsPerAction,
      loadingLeadsPerState,
      loadingPlayersTopFiveOrder,
      loadingRankingIndications,
      loadingShareChannel,
      loadingShareInAction,
      loadingGoalData,
      loadingRankingCorrectors,
    } = this.state.loadingReports;

    const { primaryColor } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className={
              this.context &&
              this.context.clientData &&
              this.context.clientData.mgmVinculated === 'true'
                ? 'col-sm-8'
                : 'col-sm-12'
            }
          >
            <div
              className="card-chart"
              style={{ flexDirection: 'column', height: '400px' }}
            >
              <ConversionsLine
                loadingConversionsCount={loadingConversionsCount}
                amountOfConversionsPerMonth={amountOfConversionsPerMonth}
              />
            </div>
          </div>

          {this.context &&
            this.context.clientData &&
            this.context.clientData.mgmVinculated === 'true' && (
              <div className="col-sm-4">
                <div
                  className="card-chart"
                  style={{ flexDirection: 'column', height: '400px' }}
                >
                  <UsersTypes
                    totalUsersNormal={this.state.totalUsersNormal}
                    totalUsersRegional={this.state.totalUsersRegional}
                    color={this.context.clientData.primaryColor}
                    loading={this.state.loadingUserType}
                  />
                </div>
              </div>
            )}

          <EngagementPlayers
            dataReport={{
              loadingEngagementPlayers,
              engagementPlayers,
              primaryColor,
              PrimaryLighten,
            }}
          />

          <LeadsConvertedAndCreated
            dataReport={{
              loadingLeadsConvertedAndCreated,
              leadsConvertedAndCreated,
              primaryColor,
              PrimaryLighten,
            }}
          />

          <LeadsFunil
            dataReport={{
              loadingLeadsFunil,
              leadsFunil,
              style,
            }}
          />

          <ShareInAction
            dataReport={{
              loadingShareInAction,
              shareInAction,
              primaryColor,
              PrimaryLighten,
              total,
            }}
          />

          <LeadsPerAction
            dataReport={{
              loadingLeadsPerAction,
              chartDataPie,
            }}
          />

          <RankingPlayers
            dataReport={{
              loadingPlayersTopFiveOrder,
              playersTopFiveOrder,
            }}
          />

          {this.context &&
            this.context.clientData &&
            this.context.clientData.mgmVinculated === 'true' &&
            (this.context.clientData.clientUrl === 'quartzo' ||
              this.context.clientData.clientUrl === 'prix') && (
              <RankingIndications
                dataReport={{
                  loadingRankingIndications,
                  rankingIndications,
                }}
              />
            )}

          {/* {this.context.clientData &&
            this.context.clientData.enableRankingRange &&
            this.context.clientData.enableRankingRange === 'true' &&
            this.context.clientData.mgmVinculated &&
            this.context.clientData.mgmVinculated === 'true' &&
            this.context.clientData.clientUrl === 'quartzo' && (
              <RankingCorrectors
                dataReport={{
                  loadingRankingCorrectors,
                  rankingCorrectorsData,
                }}
              />
            )} */}

          {/* {this.context.clientData &&
            this.context.clientData.clientUrl === 'quartzo' && (
              <RankingCorrectors
                dataReport={{
                  loadingRankingCorrectors,
                  rankingCorrectorsData,
                }}
              />
            )} */}

          <FunnelChartSale
            dataReport={{
              loadingFunnelChart,
              funnelChart,
            }}
          />

          <ShareData
            dataReport={{
              loadingShareChannel,
              shareData,
            }}
          />

          {clientData &&
            clientData.customFormLead &&
            clientData.customFormLead.cityAndState !== 'false' && (
              <GeoMap
                dataReport={{
                  loadingGeoMap,
                  geoMap,
                  primaryColor,
                }}
              />
            )}

          {/* {this.context.clientData &&
            this.context.clientData.scaleEnable === 'true' &&
            this.context.clientData.mgmVinculated &&
            this.context.clientData.mgmVinculated === 'true' && (
              <GoalData
                dataReport={{
                  loadingGoalData,
                  goalData,
                }}
              />
            )} */}
        </div>
      </div>
    );
  }

  toggleModalUsersCount() {
    this.setState({ modalIsOpenUsersCount: !this.state.modalIsOpenUsersCount });
  }

  toggleModalPageViewsCount() {
    this.setState({
      modalIsOpenPageViewsCount: !this.state.modalIsOpenPageViewsCount,
    });
  }

  toggleModalLeadsCount() {
    this.setState({
      modalIsOpenLeadsCount: !this.state.modalIsOpenLeadsCount,
    });
  }

  toggleModalConversionsCount() {
    this.setState({
      modalIsOpenConversionsCount: !this.state.modalIsOpenConversionsCount,
    });
  }

  toggleModalSharesCount() {
    this.setState({
      modalIsOpenSharesCount: !this.state.modalIsOpenSharesCount,
    });
  }

  renderCounts() {
    const {
      modalIsOpenSharesCount,
      modalIsOpenConversionsCount,
      modalIsOpenLeadsCount,
      modalIsOpenUsersCount,
      modalIsOpenPageViewsCount,
      totalUsersNormal,
      totalUsersRegional,
    } = this.state;

    const {
      loadingConversionsCount,
      loadingLeadsCount,
      loadingPageViewCount,
      loadingPlayersCount,
      loadingSharesCount,
    } = this.state.loadingCounts;

    const {
      conversionsCount,
      amountOfConversionsPerWeekDay,
      amountOfConversionsPerDay,
      amountOfConversionsPerMonth,
    } = this.state.counts.conversionsCount;

    const {
      sharesCount,
      amountOfSharesPerWeekDay,
      amountOfSharesPerDay,
      amountOfSharesPerMonth,
    } = this.state.counts.sharesCount;

    const {
      pageViewCount,
      pageViewsPerWeekDay,
      pageViewsPerDay,
      pageViewsPerMonth,
    } = this.state.counts.pageViewCount;

    const {
      leadsCount,
      amountOfLeadsPerWeekDay,
      amountOfLeadsPerDay,
      amountOfLeadsPerMonth,
    } = this.state.counts.leadsCount;

    const {
      playersCount,
      amountOfPlayersPerWeekDay,
      amountOfPlayersPerDay,
      amountOfPlayersPerMonth,
    } = this.state.counts.playersCount;

    return (
      <>
        <div className="d-flex">
          <UsersCount
            dataAmount={{
              loadingPlayersCount,
              playersCount,
              amountPerDay: amountOfPlayersPerDay,
              amountPerWeekDay: amountOfPlayersPerWeekDay,
              amountPerMonth: amountOfPlayersPerMonth,
              totalUsersNormal,
              totalUsersRegional,
            }}
            closeModal={() => this.toggleModalUsersCount()}
            modalIsOpen={modalIsOpenUsersCount}
            modalTitle={this.translate('users')}
          />

          <PageViewsCount
            dataAmount={{
              loadingPageViewCount,
              pageViewCount,
              amountPerDay: pageViewsPerDay,
              amountPerWeekDay: pageViewsPerWeekDay,
              amountPerMonth: pageViewsPerMonth,
            }}
            closeModal={() => this.toggleModalPageViewsCount()}
            modalIsOpen={modalIsOpenPageViewsCount}
            modalTitle={this.translate('pageviews')}
          />

          <LeadsCount
            dataAmount={{
              loadingLeadsCount,
              leadsCount,
              amountPerDay: amountOfLeadsPerDay,
              amountPerWeekDay: amountOfLeadsPerWeekDay,
              amountPerMonth: amountOfLeadsPerMonth,
            }}
            closeModal={() => this.toggleModalLeadsCount()}
            modalIsOpen={modalIsOpenLeadsCount}
            modalTitle={this.translate('leads')}
          />

          <ConversionsCount
            dataAmount={{
              loadingConversionsCount,
              conversionsCount,
              amountPerWeekDay: amountOfConversionsPerWeekDay,
              amountPerDay: amountOfConversionsPerDay,
              amountPerMonth: amountOfConversionsPerMonth,
            }}
            closeModal={() => this.toggleModalConversionsCount()}
            modalIsOpen={modalIsOpenConversionsCount}
            modalTitle={this.translate('coversrions-card')}
          />

          <SharesCount
            dataAmount={{
              loadingSharesCount,
              sharesCount,
              amountPerWeekDay: amountOfSharesPerWeekDay,
              amountPerDay: amountOfSharesPerDay,
              amountPerMonth: amountOfSharesPerMonth,
            }}
            closeModal={() => this.toggleModalSharesCount()}
            modalIsOpen={modalIsOpenSharesCount}
            modalTitle={this.translate('shares')}
          />

          <InstallPwaCount
            dataAmount={{
              loadingInstallPwaCount:
                this.state.loadingCounts.loadingInstallPwaCount,
              installPwaCount: this.state.counts.installPwaCount,
            }}
          />

          <PercentageOfEngagement
            dataAmount={{
              loadingPercentageOfEngagement:
                this.state.loadingCounts.loadingPercentageOfEngagement,
              percentageOfEngagement: this.state.percentageOfEngagement,
            }}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />

              {/* HEADER CARDS */}

              <div className="container-fluid row">
                {this.generateReports()}

                <div className="col-md-12 col-xs-12">
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('counters')}
                    </span>
                  </div>
                  <div className="col-md-2 col-md-offset-7 col-xs-12" />
                </div>

                {/* CARDS CONTADORES */}
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%' }}
                >
                  {this.renderCounts()}
                </div>

                {this.renderOptionsDash()}

                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%', marginBottom: '3%' }}
                >
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('graphs')}
                    </span>
                  </div>
                </div>

                <div className="col-md-12 col-xs-12"></div>
              </div>

              {/* GRÁFICOS RELATÓRIOS */}
              {this.state.isOpenGraph && this.renderGraphics()}
              {!this.state.isOpenGraph && (
                <>
                  <p className="text-center">
                    <i className="material-icons" style={{ fontSize: '50px' }}>
                      grid_view
                    </i>
                  </p>
                  <p className="text-center">
                    Clique em &quot;Carregar gráficos&quot; para carregar todas
                    informações.
                  </p>
                </>
              )}

              <div
                className="container-fluid graph-message"
                style={{ paddingTop: '20px', paddingBottom: '20px' }}
              >
                * Quando o gráfico contém mais de 16 itens, somente os 16 com
                maior volume aparecem na legenda abaixo do mesmo.
              </div>

              {/* FIM FOOTER LABEL */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const DashboardLink = (props) => (
  <Link className="menu-link" to={routes.DASHBOARD_CLIENT}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t('dashboard')}</span>
  </Link>
);

export default Dashboard;

export { DashboardLink };
