import React from 'react';

import { withNamespaces } from 'react-i18next';
import WithAuth from './WithAuth';
import WithoutAuth from './WithoutAuth';
import { useUserData } from '../../../hooks/useUserData';

function RoutesClient(props) {
  const { signed } = useUserData();

  console.log('to nas rotas dos clientes: ', signed);
  return <>{signed ? <WithAuth {...props} /> : <WithoutAuth {...props} />}</>;
}

export default withNamespaces()(RoutesClient);
