import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import XLSX from 'xlsx';
import '../../../CSS/oq.css';
// import FileSaver from 'file-saver';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Steps } from 'rsuite';
// import Select from 'react-select';

// import ProgressDetails from './components/ProgressDetails';
// import DirectPagination from '../../components/DirectPagination';
import ModalImportLeads from '../../components/Modals/ModalImportLeads';
import ModalConfirmConversionLead from '../../components/Modals/PublicTargetConversion';
import alertActive from '../../Images/SVG/alertActive.png';
import baloonActive from '../../Images/SVG/baloonActive.svg';
import bookActive from '../../Images/SVG/bookActive.svg';
import canceled from '../../Images/SVG/canceled.png';
import handsActive from '../../Images/SVG/handsActive.svg';
import victoryActive from '../../Images/SVG/victoryActive.svg';

import baloon from '../../Images/SVG/baloon.svg';
import book from '../../Images/SVG/book.svg';
import hands from '../../Images/SVG/hands.svg';
import victory from '../../Images/SVG/victory.svg';

// import ReactPaginate from 'react-paginate';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as mask from '../../../utils/Mascaras';
import LeadDetails from '../../components/Modals/LeadDetails';
import { NavBar } from '../../components/NavBar';
import Pagination from '../../components/Pagination';
import { SideBar } from '../../components/SideBar';

import { api } from '../../../Config';

import DirectPagination from '../../components/DirectPagination';
import { UserDataContext } from '../../../hooks/useUserData';

class PublicTarget extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.inputStepLead = null;

    this.state = this.getInitialState();

    this.translate = props.t;
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  getInitialState() {
    const initialState = {
      isLoadingLeadsForExport: false,
      isLoading: false,
      isLoadingLeads: false,
      searchObj: {
        playerEmail: '',
        email: '',
        playerName: '',
        name: '',
        actionId: '',
        cpf: '',
        status: '',
        converted: false,
        searchLeadId: '',
      },
      players: [],
      leads: [],
      lead: {},
      clientData: {},
      status: [],
      leadsCustom: [],
      actions: [],
      loadingMyIndications: true,
      loadingActions: false,
      modalIsOpen: false,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      converting: false,
      isTableData: false,
      modalConfirmConversionLeadIsOpen: false,
      leadSelected: '',
      modalImportLeadIsOpen: false,
      // isLoadingLeads: true,
      startANext: '',
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
    this.getClient();
    this.inputStepLead = this.buildStepLeadInput();
    // this.getPlayers();
    // this.receivedData();
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevState.isLoading !== this.state.isLoading) {
  //     this.tableData();
  //   }
  // }

  getPlayers() {
    this.setState({ searchingPlayers: true }, () => {
      const parameters = {
        searchFunctionality: 'getPlayersForRegisterLeadAdmin',
        userType: 'client',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          const players = [
            { uid: '', name: 'Selecione' },
            ...result.data.players,
          ];

          this.setState({ players, searchingPlayers: false });
        })
        .catch((error) => {
          this.setState({ searchingPlayers: false });
          console.log('getPlayers error:', error);
        });
    });
  }

  async exporLeads() {
    const { clientData, groupPermissions, userData } = this.context;
    console.log('userData: ', userData);
    this.setState(
      { isLoadingLeadsForExport: true, converting: true },
      async () => {
        const parameters = {
          searchFunctionality: 'getLeadsForExportation',
          userType: 'client',
          searchLeadsForExpots: true,
          clientId: userData.clientId,
          userEmail: userData.email,
        };

        try {
          const res = await api.post(`/exportation`, parameters);

          const result = res.data;

          if (result.success) {
            this.setState({
              isLoadingLeadsForExport: false,
              converting: false,
            });
            Notify(result.message, 'success');
          } else {
            Notify(result.message, 'warn');

            this.setState({
              isLoadingLeadsForExport: false,
              converting: false,
            });
          }
        } catch (error) {
          this.setState({
            isLoadingLeadsForExport: false,
            converting: false,
          });
          console.log('exporLeads error:', error);
        }
      }
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  getClient() {
    const { clientData, groupPermissions, userData } = this.context;

    if (clientData) {
      if (!clientData.steps) {
        this.setState({
          clientData: clientData,
          status: [],
        });
      } else {
        this.setState({
          clientData: clientData,
          status: clientData.steps,
        });
      }
    }
  }

  getcustomCheckStatusLead() {
    const { clientData, groupPermissions, userData } = this.context;
    const clientId = userData.clientId;

    // const { searchObj } = this.state;
    this.setState({ loadingMyIndications: true }, async () => {
      const parameters = {
        searchFunctionality: 'getcustomCheckStatusLead',
        userType: 'client',
        clientData: this.context.clientData,
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          this.setState({
            leadsCustom: res.data.data,
            loadingMyIndications: false,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ loadingMyIndications: false });
          console.log('getPlayer error:', error);
        });
    });
  }

  loadActionList() {
    const parameters = {
      searchFunctionality: 'allActions',
      userType: 'client',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;

        this.setState({ ...result.data });
      })
      .catch((error) => {
        console.log('loadActionList error:', error);
      });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }

  handleSearchCheckedInput(e) {
    const { name, checked } = e.target;
    this.handleSearchInputChangeOther(name, checked);
  }

  handleSearchInputChangeOther = (name, value) => {
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };

  async filterList(selectedPage = 0, startANext = '') {
    const { clientData, groupPermissions, userData } = this.context;

    const idUser = userData.uid;
    const userMaster = userData.master;
    const actualPage = selectedPage || 0;

    this.setState(
      { isLoading: true, isLoadingLeads: true, converting: true },
      async () => {
        const { searchObj } = this.state;
        let isTenda = false;

        if (this.context.clientData.clientUrl === 'tenda') {
          isTenda = true;
          this.getcustomCheckStatusLead();
        }

        let functionality = 'getLeadsWithPagination';
        let userId = null;
        if (
          this.context.clientData.enableUserAndPlayerLinking &&
          this.context.clientData.enableUserAndPlayerLinking === 'true' &&
          !userMaster
        ) {
          functionality = 'getLeadsWithLinkingUserAndPlayers';
          userId = idUser;
        }

        const parameters = {
          searchFunctionality: functionality,
          userType: 'client',
          page: selectedPage,
          startANext,
          userId,
        };

        if (
          searchObj.name ||
          searchObj.actionId ||
          searchObj.cpf ||
          searchObj.email ||
          searchObj.status ||
          searchObj.playerName ||
          searchObj.playerEmail ||
          searchObj.converted === true ||
          searchObj.searchLeadId
        ) {
          parameters.filter = searchObj;
        }

        try {
          const res = await api.post(`/searchLeads`, parameters);

          const result = res.data;

          if (
            this.context.clientData.enableUserAndPlayerLinking &&
            this.context.clientData.enableUserAndPlayerLinking === 'true' &&
            !userMaster
          ) {
            if (result.success) {
              this.setState({
                leads: result.data.leads,
                isLoading: false,
                isLoadingLeads: false,
                converting: false,
              });
              this.tableData();
            } else {
              Notify(result.message, 'warn');

              this.setState(this.getInitialState());
              // this.getInitialState();
            }
          } else if (result.success) {
            this.setState({
              leads: result.data.leads,
              isLoading: false,
              isLoadingLeads: false,
              converting: false,
              startANext: result.data.startANext ? result.data.startANext : '',
              currentPage: actualPage,
            });
          } else {
            Notify(result.message, 'warn');
            this.setState({
              leads: [],
              startANext: '',
              currentPage: 0,
              isLoading: false,
              isLoadingLeads: false,
              converting: false,
            });
          }

          // if (result.success) {
          //   // this.setState({
          //   //   leads: result.data.leads,
          //   //   isLoading: false,
          //   // });
          //   // this.tableData();
          //   this.setState({
          //     leads: result.data.leads,
          //     isLoading: false,
          //     isLoadingLeads: false,
          //     converting: false,
          //     startANext: result.data.startANext ? result.data.startANext : '',
          //     currentPage: actualPage,
          //   });
          // } else {
          //   Notify(result.message, 'warn');
          //   this.setState({
          //     leads: [],
          //     startANext: '',
          //     currentPage: 0,
          //     isLoading: false,
          //     isLoadingLeads: false,
          //     converting: false,
          //   });
          //   // this.setState(this.getInitialState());
          //   // this.getInitialState();
          // }
        } catch (error) {
          console.log('filterList error:', error);
          // this.setState(this.getInitialState());
          this.setState({
            isLoading: false,
            isLoadingLeads: false,
            converting: false,
          });
          // this.getInitialState();
        }
      }
    );
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  handleNextPage() {
    let { currentPage } = this.state;
    currentPage += 1;

    this.setState({ currentPage });

    const { startANext } = this.state;

    this.filterList(currentPage, startANext);
  }

  handlePreviousPage() {
    let { currentPage } = this.state;
    currentPage -= 1;

    if (currentPage < 0) return;

    this.setState({ currentPage });

    const { startANext } = this.state;

    this.filterList(currentPage, startANext);
  }

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  buildStepLeadInput() {
    const stepLeadInput = document.createElement('input');
    stepLeadInput.setAttribute('type', 'file');
    stepLeadInput.setAttribute('className', 'hidden');
    stepLeadInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    stepLeadInput.onchange = (e) =>
      this.fileChanged(e.target.files, 'stepLeads');
    return stepLeadInput;
  }

  fileChanged = (file, field) => {
    this.setState((prevState) => ({}));

    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      } else {
        Notify(
          'Formato de arquivo inválido. Permitido apenas xls/xlsx.',
          'error'
        );
      }
    }
  };

  parseFile(file, field) {
    if (field === 'stepLeads') {
      this.setState({ parsingStepLeads: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          stepLeads: [file.name],
        },
      }));
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });

      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });

        if (roa.length) {
          result.push(roa);
        }
      });
      this.parseData(result, field);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'stepLeads') {
          return {
            uid: item[0],
            step: item[1],
            canceled: item[2],
            points: item[3],
          };
        }
        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'stepLeads') {
      this.setState({ parsingStepLeads: false });

      this.confirmAdvancedStepLeads(items);
    }
  }

  confirmAdvancedStepLeads(advancedStepLeads) {
    // const { leads } = this.state;
    const findLeadsStep = [];

    _.each(advancedStepLeads, (advancedStepLead) => {
      if (advancedStepLead.uid) {
        findLeadsStep.push({
          leadId: advancedStepLead.uid,
          advancedStep: advancedStepLead.step,
          canceled: advancedStepLead.canceled && advancedStepLead.canceled,
          points: advancedStepLead.points && advancedStepLead.points,
        });
      }
    });

    // return;

    // _.each(leads, lead => {
    //   _.each(advancedStepLeads, advancedStepLead => {
    //     if (lead.uid === advancedStepLead.uid) {
    //       findLeadsStep.push({
    //         lead,
    //         advancedStep: advancedStepLead.step,
    //         canceled: advancedStepLead.canceled && advancedStepLead.canceled,
    //         points: advancedStepLead.points && advancedStepLead.points,
    //       });
    //     }
    //   });
    // });

    // if (findLeadsStep.length <= 0) {
    //   Notify('Leads não encontrados', 'error');
    //   return;
    // }

    this.addAdvancedStepLeads(findLeadsStep);
  }

  addAdvancedStepLeads(findLeadsStep) {
    this.setState({ converting: true }, () => {
      Notify('Aguarde.', 'loading');

      const parameters = {
        userType: 'client',
        updateFunctionality: 'updateAdvancedStepLeads',
        advancedStepLeads: findLeadsStep,
      };

      api
        .post(`/update`, parameters)
        .then(async (res) => {
          this.setState({ converting: false });
          const result = res.data;

          if (result.success) {
            _.each(result.data, (responseUpdate) => {
              if (responseUpdate && responseUpdate.success) {
                Notify(responseUpdate.message, 'success', 15000);
              } else {
                Notify(responseUpdate.message, 'error', 15000);
              }
            });

            if (this.state.leads && this.state.leads.length > 0) {
              await this.filterList();
            }
          }
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('updateAdvancedStepLeads error:', error);
        });
    });
  }

  instanceVertical(clientData, lead) {
    const { leadsCustom } = this.state;
    let current = lead.status ? lead.status : 0;

    if (this.context.clientData.clientUrl === 'tenda') {
      _.map(leadsCustom, (leads) => {
        if (leads.code === 200 && leads.success === true) {
          if (leads.uid === lead.uid) {
            current = leads.data ? leads.data - 1 : 0;

            const names = [book, baloon, hands, victory, canceled].map(
              (img, index) => {
                return <img key={index} alt="" src={img} />;
              }
            );

            const namesActive = [
              bookActive,
              baloonActive,
              handsActive,
              victoryActive,
              canceled,
            ].map((img, index) => {
              return (
                <img
                  key={index}
                  className="img-responsive img-steps-oq"
                  alt=""
                  src={img}
                />
              );
            });

            return (
              <Steps current={current}>
                {clientData.map((step, index) => {
                  let iconReal = names[index];

                  if (index <= current) {
                    iconReal = namesActive[index];
                  }

                  return (
                    <Steps.Item
                      description={clientData[index].name}
                      key={step.name}
                      icon={iconReal}
                      className="icone"
                    />
                  );
                })}
              </Steps>
            );
          }
        }
      });
    }

    const names = [book, baloon, hands, victory, canceled].map(
      (lead, index) => {
        return (
          <img
            key={index}
            className="img-responsive img-steps-oq"
            alt=""
            src={lead}
          />
        );
      }
    );

    const namesActive = [book, baloon, hands, victory, canceled].map(
      (lead, index) => {
        return (
          <img
            key={index}
            className="img-responsive img-steps-oq"
            alt=""
            src={lead}
          />
        );
      }
    );

    return (
      <Steps current={current} vertical>
        {clientData.map((step, index) => {
          let iconReal = names[index];

          if (index <= current) {
            // iconReal = namesActive[index];
            iconReal = lead.canceled ? namesActive[4] : namesActive[index];
            step.name = lead.canceled ? 'Cancelado' : step.name;

            const verify =
              lead.statusConvertedWebhook === false
                ? (namesActive[2] = (
                    <img
                      className="img-responsive img-steps-oq"
                      src={canceled}
                    />
                  ))
                : (namesActive[2] = (
                    <img
                      className="img-responsive img-steps-oq"
                      src={handsActive}
                    />
                  ));
          }
          if (this.context.clientData.clientUrl === 'umuarama') {
            const verifyName =
              lead.statusConvertedWebhook === false
                ? (clientData[2].name = <div>Cancelado</div>)
                : (clientData[2].name = <div>Convertido</div>);
          }

          return (
            <Steps.Item
              title={clientData[index] && clientData[index].name}
              key={step.name}
              icon={iconReal}
              className="icone"
            />
          );
        })}
      </Steps>
    );
  }

  instance(clientData, lead) {
    // const { leadsCustom } = this.state;
    // Current = step atual do lead
    const current = lead.status ? lead.status : 0;

    // if (this.context.clientData.clientUrl === 'tenda') {
    //   _.map(leadsCustom, leads => {
    //     if (leads.code === 200 && leads.success === true) {
    //       if (leads.uid === lead.uid) {
    //         current = leads.data ? leads.data - 1 : 0;

    //         let names = [book, baloon, hands, victory, canceled].map(
    //           (img, index) => {
    //             return <img key={index} alt="" src={img} />;
    //           }
    //         );

    //         let namesActive = [
    //           bookActive,
    //           baloonActive,
    //           handsActive,
    //           victoryActive,
    //           canceled,
    //         ].map((img, index) => {
    //           return (
    //             <img
    //               key={index}
    //               className="img-responsive img-steps-oq"
    //               alt=""
    //               src={img}
    //             />
    //           );
    //         });

    //         return (
    //           <>
    //             <div
    //               style={{
    //                 position: 'relative',
    //                 right: '2rem',
    //                 bottom: '1rem',
    //               }}
    //             >
    //               <span
    //                 className="oq-primary-color"
    //                 style={{ fontWeight: 'bold' }}
    //               >
    //                 {clientData[current].name}
    //               </span>
    //             </div>
    //             <Steps current={current}>
    //               {clientData.map((step, index) => {
    //                 let iconReal = names[index];

    //                 if (index <= current) {
    //                   iconReal = namesActive[index];
    //                 }

    //                 return (
    //                   <div>
    //                     <Steps.Item
    //                       // description={clientData[index].name}
    //                       key={step.name}
    //                       icon={iconReal}
    //                       className="icone"
    //                     />
    //                     <div
    //                       style={{
    //                         position: 'relative',
    //                         right: '1rem',
    //                       }}
    //                     >
    //                       {/* <span>{clientData[index].name}</span> */}
    //                     </div>
    //                   </div>
    //                 );
    //               })}
    //             </Steps>
    //           </>
    //         );
    //       }
    //     }
    //   });
    // }

    const names = [book, baloon, hands, victory, canceled].map((img, index) => {
      return <img key={index} alt="" src={img} />;
    });

    names[clientData.length - 1] = names[3];

    const namesActive = [
      bookActive,
      baloonActive,
      handsActive,
      victoryActive,
      canceled,
      alertActive,
    ].map((img, index) => {
      return (
        <img
          key={index}
          className="img-responsive img-steps-oq"
          alt=""
          src={img}
        />
      );
    });

    namesActive[clientData.length - 1] = namesActive[3];

    return (
      <>
        <div style={{ position: 'relative', right: '2rem', bottom: '1rem' }}>
          <span className="oq-primary-color" style={{ fontWeight: 'bold' }}>
            {lead && lead.canceled
              ? 'Cancelado'
              : clientData[current]
                ? clientData[current].name
                : ''}
          </span>
        </div>
        <Steps className="step-custom" current={current}>
          {clientData.map((step, index) => {
            let iconReal = names[index];

            if (index <= current) {
              iconReal = namesActive[index];

              if (lead.canceled && index === lead.status) {
                iconReal = namesActive[4];
              }
            }

            // if (this.context.clientData.clientUrl === 'umuarama') {
            //   var verifyName =
            //     lead.statusConvertedWebhook === false
            //       ? (clientData[2].name = <div>Cancelado</div>)
            //       : (clientData[2].name = <div>Convertido</div>);
            // }

            return (
              <>
                <Steps.Item
                  key={step.name}
                  icon={iconReal}
                  className="class-teste"
                />
                {/* <div
                  style={{
                    position: 'relative',
                    right: '1rem',
                  }}
                ></div> */}
              </>
            );
          })}
        </Steps>
      </>
    );
  }

  renderAdvancedFilter(isPermissionExport, isPermissionStepAdvance) {
    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; {this.translate('advanced-filters')}
          </span>

          <div className="row">
            <div className="col-md-8">
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder={this.translate('Nome completo')}
                  name="name"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.name}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control input-oq"
                  placeholder={this.translate('actions')}
                  name="actionId"
                  value={this.state.searchObj.actionId}
                  disabled={this.state.loadingActions}
                  onChange={(e) => this.handleSearchInputChange(e)}
                >
                  <option value="">
                    {this.state.loadingActions
                      ? this.translate('loading')
                      : this.translate('Selecione uma ação...')}
                  </option>
                  {_.size(this.state.actions) > 0 &&
                    _.map(this.state.actions, (action) => (
                      <option key={action.id} value={action.id}>
                        {action.actionTitle}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder="CPF"
                  name="cpf"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.cpf}
                  onChange={(event) => {
                    const input = event.target;
                    if (input.value.length <= 14) {
                      const maskCPF = mask.maskCPF(input.value);
                      this.handleSearchInputChangeOther(input.name, maskCPF);
                    } else if (
                      input.value.length > 14 &&
                      input.value.length <= 18
                    ) {
                      const maskCNPJ = mask.maskCNPJ(input.value);
                      this.handleSearchInputChangeOther(input.name, maskCNPJ);
                    } else {
                      const maskLimited = mask.maskLimited(input.value);
                      this.handleSearchInputChangeOther(
                        input.name,
                        maskLimited
                      );
                    }
                  }}
                />
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control input-oq"
                  placeholder="Status"
                  name="status"
                  value={this.state.searchObj.status}
                  disabled={this.state.loadingActions}
                  onChange={(e) => this.handleSearchInputChange(e)}
                >
                  <option value="">
                    {this.state.loadingActions
                      ? this.translate('loading')
                      : 'Selecione um status...'}
                  </option>
                  {_.map(this.state.status, (status, index) => (
                    <option key={index} value={Number(index)}>
                      {status.name === '' ? index : status.name}
                    </option>
                  ))}
                  <option value="canceled">Cancelado</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder="Email do lead"
                  name="email"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.email}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="col-md-6 form-group">
                  <input
                    type="checkbox"
                    className="oq-checkbox"
                    name="converted"
                    onClick={() => this.filterList()}
                    checked={this.state.searchObj.converted}
                    onChange={(e) => this.handleSearchCheckedInput(e)}
                  />{' '}
                  Convertidos
                </label>
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder="Id do lead"
                  name="searchLeadId"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.searchLeadId}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder="Nome completo do usuário"
                  name="playerName"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.playerName}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  className="form-control input-oq"
                  placeholder="Email do usuário"
                  name="playerEmail"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.filterList();
                    }
                  }}
                  value={this.state.searchObj.playerEmail}
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>
            </div>

            <div className="row col-md-4">
              <div className="col-md-12 form-group pull-right">
                <button
                  className="btn btn-block btn-oq pull-right"
                  style={{ width: '70%' }}
                  disabled={this.state.isLoading || this.state.converting}
                  onClick={() => this.filterList()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('Pesquisando')
                    : this.translate('Pesquisar')}
                </button>
              </div>
              <div className="col-md-10 form-group pull-right">
                {this.state.clientData.enableStepManual &&
                  this.state.clientData.enableStepManual === 'true' && (
                    <>
                      <button
                        className="btn btn-oq col-md-12 pull-right"
                        disabled={this.state.converting}
                        onClick={() =>
                          isPermissionStepAdvance
                            ? this.toggleModalImportLead()
                            : Notify('Você não tem permissão!', 'warn')
                        }
                      >
                        <i className="fa fa-file-excel-o" aria-hidden="true" />
                        {this.translate('Step manual')}
                      </button>
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_step_manual_2.xlsx?alt=media&token=c53cd230-9af6-42ac-9031-ba9180e5e454"
                        style={{
                          marginTop: '.5rem',
                        }}
                        className="menu-link col-md-12"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Exemplo planilha de step manual
                      </a>
                    </>
                  )}
              </div>
              <div className="col-md-10 form-group pull-right">
                <button
                  className="btn btn-oq pull-right"
                  onClick={() =>
                    isPermissionExport
                      ? this.exporLeads()
                      : Notify('Você não tem permissão!', 'warn')
                  }
                  disabled={
                    this.state.isLoadingLeadsForExport || this.state.converting
                  }
                  // style={{ marginTop: '10px' }}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  {this.translate('Exportar')}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              style={{ paddingLeft: '0px' }}
              className="col-md-12 form-group"
            >
              {this.renderNewLead()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNewLead() {
    return (
      <div style={{ paddingLeft: '0px' }} className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.searching || this.state.converting}
            onClick={() => this.goToNewLeadPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.searching ? this.translate('loading') : 'Novo Lead'}
          </button>
        </div>
      </div>
    );
  }

  goToNewLeadPage() {
    const { history } = this.props;
    history.push(routes.LEAD_REGISTER);
  }

  toggleModalImportLead() {
    this.setState({ modalImportLeadIsOpen: !this.state.modalImportLeadIsOpen });
  }

  importLeads() {
    return (
      <ModalImportLeads
        open={this.state.modalImportLeadIsOpen}
        close={this.toggleModalImportLead.bind(this)}
        inputStepLead={this.inputStepLead}
      />
    );
  }

  handleSelectedLeadChange(lead, isPermissionVisualize) {
    console.log('LEAD: ', lead);
    this.setState(
      { lead },
      isPermissionVisualize
        ? () => this.toggleModal()
        : Notify('nao tem premissão', 'warn')
    );
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  toggleModalConfirmConversion(lead) {
    if (lead) this.setState({ leadSelected: lead });

    this.setState({
      modalConfirmConversionLeadIsOpen:
        !this.state.modalConfirmConversionLeadIsOpen,
    });
  }

  confirmConversion() {
    const { playerId, actionId, uid, action, player } = this.state.leadSelected;
    const leadConverted = !!(
      this.state.leadSelected && this.state.leadSelected.converted
    );

    const { converting } = this.state;
    return (
      <ModalConfirmConversionLead
        open={this.state.modalConfirmConversionLeadIsOpen}
        close={this.toggleModalConfirmConversion.bind(this)}
        addConversion={this.addConversion.bind(this)}
        converting={converting}
        playerId={playerId}
        actionId={actionId}
        uid={uid}
        action={action}
        player={player}
        dataConvertion={leadConverted}
      />
    );
  }

  addConversion(playerId, actionId, leadId, action, player) {
    if (!player) {
      return Notify(
        'Não encontramos o usuário para converter este lead',
        'warn'
      );
    }
    const { clientData } = this.context;
    const clientId = clientData.uid;

    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');

      const { clientData } = this.context;
      const parameters = {
        actionId,
        playerId,
        playerEmail: player.email,
        playerName: player.name,
        createdAt: moment().valueOf(),
        clientId,
        leadId,
        action,
        gamificationByGoalLevels: clientData.gamificationType === 'goalsLevels',
      };

      api
        .post(`/conversions`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ converting: false });
          Notify(result.message, result.success ? 'success' : 'error');

          if (result.success) {
            this.filterList();
          }
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('addConversion error:', error);
        });
    });
  }

  tableData() {
    let data;
    let slice;

    data = _.chain(this.state.leads && this.state.leads)
      .filter((lead) => lead.clientId === this.context.clientData.uid)
      .sortBy('createdAt')
      .reverse()
      .value();
    const dataCustom = [];

    const { leadsCustom, searchObj } = this.state;
    if (this.context.clientData.clientUrl === 'tenda') {
      data.map((lead, index) => {
        if (leadsCustom.length > 0 && lead.uid === leadsCustom[index].uid) {
          let statusValue = leadsCustom[index].data;
          if (statusValue === '') {
            statusValue = 0;
          }
          if (statusValue > 0) {
            statusValue -= 1;
          }

          const newDataLead = {
            player: lead.player,
            name: lead.name,
            email: lead.email,
            cpf: lead.cpf,
            createdAt: lead.createdAt,
            city: lead.city,
            action: lead.action,
            status: statusValue,
            converted: lead.converted,
          };
          dataCustom.push(newDataLead);
        }
      });
    }

    const playerss =
      this.context.clientData.clientUrl === 'tenda' ? dataCustom : data;

    if (
      searchObj.name ||
      searchObj.actionId ||
      searchObj.cpf ||
      searchObj.status
    ) {
      slice = playerss.slice(0, 0 + 10);

      this.setState({
        pageCount: Math.ceil(playerss.length / 10),
        orgtableData: playerss,
        tableData: slice,
        currentPage: 0,
        offset: 0,
      });
    } else {
      slice = playerss.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(playerss.length / this.state.perPage),
        orgtableData: playerss,
        tableData: slice,
      });
    }
  }

  renderLeadsPagination(
    clientData,
    isPermissionVisualize,
    isPermissionConvert
  ) {
    const names = [
      bookActive,
      baloonActive,
      handsActive,
      victoryActive,
      canceled,
    ].map((img, index) => {
      return <img key={index} alt="" src={img} />;
    });

    names[clientData.steps.length - 1] = names[3];

    return (
      <>
        {!this.state.isLoadingLeads &&
          this.state.leads &&
          this.state.leads.length > 0 &&
          this.state.leads.map((lead, index) => {
            const leadData = lead.status;

            return (
              <div
                className="row justify-content-center pb-4 oq-fundo"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
                  marginTop: 10,
                  marginBottom: 2,
                  borderRadius: '8px',
                }}
                key={index}
              >
                <>
                  <div
                    className="col-12 col-md-1 form-group icon-status-mobile m-0 p-0"
                    style={{ padding: '0px' }}
                  >
                    <div
                      className="oq-box icon-mobile"
                      style={{ width: '42%', marginLeft: '20px' }}
                    >
                      <div className="oq-indication ">
                        <span>
                          <img
                            style={{ width: '100%' }}
                            src={
                              lead.canceled
                                ? canceled
                                : names[lead.status].props.src
                            }
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 form-group m-0 p-0">
                    <div
                      className="oq-box"
                      onClick={() =>
                        this.handleSelectedLeadChange(
                          lead,
                          isPermissionVisualize
                        )
                      }
                    >
                      <div className="oq-indication">
                        <span
                          className="span-oq-title-steps"
                          style={{ fontWeight: 'bold' }}
                        >
                          {/* {(testeeee === null) ? "teste" : testeeee.email} */}
                          {lead.name}
                        </span>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">{lead.cpf}</span>
                        </div>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">{lead.email}</span>
                        </div>
                        {lead && lead.player && lead.player.name && (
                          <div
                            className="flex"
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <span className="span-oq-names">
                              Lead de{' '}
                              <Link
                                to={`${routes.EDIT_PLAYER}/${lead.playerId}/`}
                              >
                                <span
                                  className="span-oq-title-steps oq-primary-color"
                                  style={{
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {lead.player.name}
                                </span>
                              </Link>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-2 form-group m-0 p-0">
                    <div className="oq-box">
                      <div className="oq-indication">
                        <span
                          className="span-oq-title-steps"
                          style={{ fontWeight: 'bold' }}
                        >
                          {lead.city && lead.city}
                        </span>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">
                            {moment(lead.createdAt).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="col-12 col-md-5 form-group m-0 p-0">
                  <div className="oq-box">
                    <div className="form-group oq-status-lead justify-content-center">
                      {clientData.steps &&
                        this.instance(clientData.steps, lead)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="col-12 col-md-1 form-group m-0 p-0"
                >
                  <div className="oq-box">
                    <div
                      style={{
                        marginTop: '3px',
                      }}
                      className="form-group oq-status-lead justify-content-center"
                    >
                      <div>
                        <span>Convertido</span>
                      </div>
                      <div
                        style={{
                          width: 'fit-content',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        {/* <input
                        type="checkbox"
                        style={{ cursor: 'pointer' }}
                        className="oq-checkbox"
                        name="converted"
                        checked={lead.converted === true ? true : false }
                        // value={Boolean(lead.converted)}
                        onClick={() =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                        onChange={e =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                      /> */}
                        <Checkbox
                          style={{
                            marginTop: '1.2rem',
                            cursor: 'auto',
                          }}
                          onClick={() =>
                            isPermissionConvert
                              ? this.toggleModalConfirmConversion(lead)
                              : Notify('Você não tem permissão!', 'warn')
                          }
                          checked={lead.converted === true}
                          disabled={lead.converted === true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }

  renderLeadsReactPaginate(
    clientData,
    isPermissionVisualize,
    isPermissionConvert
  ) {
    return (
      <>
        {this.state.tableData &&
          this.state.tableData.length > 0 &&
          this.state.tableData.map((lead, index) => {
            const leadData = lead.status;
            return (
              <div
                className="row justify-content-center pb-4 oq-fundo"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
                  marginTop: 10,
                  marginBottom: 2,
                  borderRadius: '8px',
                }}
                key={index}
              >
                <>
                  <div
                    className="col-12 col-md-1 form-group icon-status-mobile m-0 p-0"
                    style={{ padding: '0px' }}
                  >
                    <div
                      className="oq-box icon-mobile"
                      style={{ width: '42%', marginLeft: '20px' }}
                    >
                      <div className="oq-indication ">
                        <br />

                        <span>
                          <img
                            style={{ width: '100%' }}
                            src={
                              leadData === 0 || lead.formCompleted
                                ? bookActive
                                : leadData === 1
                                  ? baloonActive
                                  : leadData === 2 &&
                                      lead.statusConvertedWebhook === false
                                    ? canceled
                                    : leadData === 2
                                      ? handsActive
                                      : leadData === 3
                                        ? victoryActive
                                        : !lead.formCompleted &&
                                            clientData.clientUrl === 'gzelo'
                                          ? alertActive
                                          : ''
                            }
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 form-group m-0 p-0">
                    <div
                      className="oq-box"
                      onClick={() =>
                        this.handleSelectedLeadChange(
                          lead,
                          isPermissionVisualize
                        )
                      }
                    >
                      <div className="oq-indication">
                        <span
                          className="span-oq-title-steps"
                          style={{ fontWeight: 'bold' }}
                        >
                          {/* {(testeeee === null) ? "teste" : testeeee.email} */}
                          {lead.name}
                        </span>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">{lead.cpf}</span>
                        </div>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">{lead.email}</span>
                        </div>
                        {lead && lead.player && lead.player.name && (
                          <div
                            className="flex"
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <span className="span-oq-names">
                              Lead de{' '}
                              <Link
                                to={`${routes.EDIT_PLAYER}/${lead.playerId}/`}
                              >
                                <span
                                  className="span-oq-title-steps oq-primary-color"
                                  style={{
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {lead.player.name}
                                </span>
                              </Link>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-2 form-group m-0 p-0">
                    <div className="oq-box">
                      <div className="oq-indication">
                        <span
                          className="span-oq-title-steps"
                          style={{ fontWeight: 'bold' }}
                        >
                          {lead.city && lead.city}
                        </span>
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <span className="span-oq-names">
                            {moment(lead.createdAt).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="col-12 col-md-5 form-group m-0 p-0">
                  <div className="oq-box">
                    <div className="form-group oq-status-lead justify-content-center">
                      {clientData.steps &&
                        this.instance(clientData.steps, lead)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="col-12 col-md-1 form-group m-0 p-0"
                >
                  <div className="oq-box">
                    <div
                      style={{
                        marginTop: '3px',
                      }}
                      className="form-group oq-status-lead justify-content-center"
                    >
                      <div>
                        <span>Convertido</span>
                      </div>
                      <div
                        style={{
                          width: 'fit-content',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        {/* <input
                        type="checkbox"
                        style={{ cursor: 'pointer' }}
                        className="oq-checkbox"
                        name="converted"
                        checked={lead.converted === true ? true : false }
                        // value={Boolean(lead.converted)}
                        onClick={() =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                        onChange={e =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                      /> */}
                        <Checkbox
                          style={{
                            marginTop: '1.2rem',
                            cursor: 'auto',
                          }}
                          onClick={() =>
                            isPermissionConvert
                              ? this.toggleModalConfirmConversion(lead)
                              : Notify('Você não tem permissão!', 'warn')
                          }
                          checked={lead.converted === true}
                          disabled={lead.converted === true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }

  renderTargetPublicTable(isPermissionConvert) {
    const { clientData, groupPermissions, userData } = this.context;
    const userMaster = userData && userData.master && userData.master;

    let isPermissionVisualize = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionVisualize =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.leads &&
          groupPermissions.permissions.leads.visualize) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionVisualize =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.leads &&
        groupPermissions[userData.group.value].permissions.leads.visualize;
    }

    if (this.state.isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>Carregando...</p>
        </div>
      );
    }

    let isPagination = true;
    if (
      this.context.clientData.enableUserAndPlayerLinking &&
      this.context.clientData.enableUserAndPlayerLinking === 'true' &&
      !userMaster
    ) {
      isPagination = false;
    }

    return (
      <>
        {isPagination
          ? this.renderLeadsPagination(
              clientData,
              isPermissionVisualize,
              isPermissionConvert
            )
          : this.renderLeadsReactPaginate(
              clientData,
              isPermissionVisualize,
              isPermissionConvert
            )}
      </>
    );
  }

  foundLeads() {
    return <p> Pesquise para ver os resultados</p>;
  }

  renderPagination() {
    this.state.leads.length === 0 && this.foundLeads();

    return (
      <>
        {!this.state.isLoadingLeads && (
          // this.state.startANext &&
          // this.state.startANext !== '' &&
          <div className="pagination-group">
            <Pagination
              items={this.state.leads}
              handleNextPage={() => this.handleNextPage()}
              handlePreviousPage={() => this.handlePreviousPage()}
              currentPage={this.state.currentPage}
              isNext={this.state.startANext !== ''}
            />
          </div>
        )}
      </>
    );
  }

  renderReactPaginate() {
    this.state.tableData.length === 0 && this.foundLeads();

    return (
      <>
        {this.state.isLoading === false && (
          <>
            {this.state.tableData.length != 0 && (
              <div className="pagination-group">
                <DirectPagination
                  pageCount={this.state.pageCount}
                  currentPage={this.state.currentPage}
                  onPageChange={this.handlePageClick}
                />
                <ReactPaginate
                  previousLabel="Anterior"
                  nextLabel="Próxima"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  initialPage={this.state.currentPage}
                  forcePage={this.state.currentPage}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }

  render() {
    const { clientData, groupPermissions, userData } = this.context;
    const userMaster = userData && userData.master && userData.master;

    let isPermissionDelete = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionDelete =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.leads &&
          groupPermissions.permissions.leads.delete) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionDelete =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.leads &&
        groupPermissions[userData.group.value].permissions.leads.delete;
    }

    let isPermissionExport = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionExport =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.leads &&
          groupPermissions.permissions.leads.export) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionExport =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.leads &&
        groupPermissions[userData.group.value].permissions.leads.export;
    }

    let isPermissionConvert = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionConvert =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.leads &&
          groupPermissions.permissions.leads.convert) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionConvert =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.leads &&
        groupPermissions[userData.group.value].permissions.leads.convert;
    }

    let isPermissionStepAdvance = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionStepAdvance =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.leads &&
          groupPermissions.permissions.leads.stepAdvance) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionStepAdvance =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.leads &&
        groupPermissions[userData.group.value].permissions.leads.stepAdvance;
    }

    let isPagination = true;
    if (
      this.context.clientData &&
      this.context.clientData.enableUserAndPlayerLinking &&
      this.context.clientData.enableUserAndPlayerLinking === 'true' &&
      !userMaster
    ) {
      isPagination = false;
    }

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            {this.renderAdvancedFilter(
              isPermissionExport,
              isPermissionStepAdvance
            )}
            <ToastContainer />
            <div className="oq-card-container">
              {this.renderTargetPublicTable(isPermissionConvert)}
            </div>
            <LeadDetails
              isPermissionDelete={isPermissionDelete}
              closeModal={() => this.toggleModal()}
              lead={this.state.lead}
              modalIsOpen={this.state.modalIsOpen}
              updateList={() => this.filterList()}
              authUser={userData}
            />
            <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {isPagination
                ? this.renderPagination()
                : this.renderReactPaginate()}
              {/* {this.state.tableData.length === 0 && this.foundLeads()} */}
              {/* {this.state.leads.length === 0 && this.foundLeads()}


              {!this.state.isLoadingLeads &&
                this.state.startANext &&
                this.state.startANext !== '' && (
                  <div className="pagination-group">
                    <Pagination
                      items={this.state.leads}
                      handleNextPage={() => this.handleNextPage()}
                      handlePreviousPage={() => this.handlePreviousPage()}
                      currentPage={this.state.currentPage}
                      isNext={this.state.startANext !== '' ? true : false}
                    />
                  </div>

                )} */}

              {/* {this.state.isLoading === false && (
                <>
                  {this.state.tableData.length != 0 && (
                    <div className="pagination-group">
                      <DirectPagination
                        pageCount={this.state.pageCount}
                        currentPage={this.state.currentPage}
                        onPageChange={this.handlePageClick}
                      />
                      <ReactPaginate
                        previousLabel={'Anterior'}
                        nextLabel={'Próxima'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        initialPage={this.state.currentPage}
                        forcePage={this.state.currentPage}
                      />
                    </div>
                  )}
                </>
              )} */}
            </div>
          </div>
          {this.confirmConversion()}
          {this.importLeads()}
        </div>
      </div>
    );
  }
}

const PublicTargetLink = (props) => (
  <Link className="menu-link collapsed list-unstyled" to={routes.PUBLIC_TARGET}>
    <FontAwesomeIcon icon={faCheckCircle} />
    <span className="sidebarText">{props.translate('Leads/Conversões')}</span>
  </Link>
);

export default PublicTarget;

export { PublicTargetLink };
