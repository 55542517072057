import React, { useState, useMemo, useEffect } from 'react';
import * as XLSX from 'xlsx';

const ExcelImporter = ({
  onDataImported,
  onCpfExtracted,
  registeredCpfs = [],
  onOptionRegisterUsers,
}) => {
  const [fileName, setFileName] = useState('Escolha um arquivo');
  const [error, setError] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [
    isEnabledRegisterUsersNotRegistred,
    setIsEnabledRegisterUsersNotRegistred,
  ] = useState(false);

  const cpfRegistrationMap = useMemo(() => {
    if (!Array.isArray(registeredCpfs)) return {};
    return registeredCpfs.reduce((acc, item) => {
      if (item && typeof item === 'object' && 'cpf' in item) {
        acc[item.cpf] = item.registered;
      }
      return acc;
    }, {});
  }, [registeredCpfs]);

  useEffect(() => {
    onOptionRegisterUsers(isEnabledRegisterUsersNotRegistred);
  }, [isEnabledRegisterUsersNotRegistred]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : 'Escolha um arquivo');
    setError('');
    setFilteredData([]);

    if (!file) {
      setError('Nenhum arquivo selecionado');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        const processedData = filterAndSanitizeData(data);

        if (processedData.length === 0) {
          setError('Nenhum dado válido encontrado na planilha');
        } else {
          setFilteredData(processedData);
          onDataImported(processedData);
          const cpfArray = extractCpfs(processedData);
          onCpfExtracted(cpfArray);
        }
      } catch (err) {
        console.error('Erro ao processar o arquivo:', err);
        setError(
          'Erro ao processar o arquivo. Verifique se é uma planilha válida.'
        );
      }
    };

    reader.onerror = () => {
      setError('Erro ao ler o arquivo');
    };

    reader.readAsBinaryString(file);
  };

  const filterAndSanitizeData = (data) => {
    const desiredFields = [
      'name',
      'email',
      'cpf',
      'phone',
      'state',
      'building',
      'vgv',
      'value',
    ];

    return data
      .filter((item) => {
        return desiredFields.some((field) => item.hasOwnProperty(field));
      })
      .map((item) => {
        const filteredItem = {};
        desiredFields.forEach((field) => {
          if (item.hasOwnProperty(field)) {
            filteredItem[field] = sanitizeField(field, item[field]);
          }
        });
        return filteredItem;
      });
  };

  const sanitizeField = (field, value) => {
    if (value === undefined || value === null) return '';

    switch (field) {
      case 'email':
        return String(value).toLowerCase().trim();
      case 'cpf':
        return String(value).trim();
      case 'phone':
        return String(value).trim();
      case 'building':
        return String(value).trim();
      case 'vgv':
      case 'value':
        return typeof value === 'number' ? value : parseFloat(value) || 0;
      default:
        return String(value).trim();
    }
  };

  const extractCpfs = (data) => {
    return data.map((item) => item.cpf).filter((cpf) => cpf);
  };

  return (
    <div>
      <div className="custom-file mb-3">
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
        />
        <label
          className="custom-file-label"
          style={{ backgroundColor: 'var(--primary-color)' }}
          htmlFor="customFile"
        >
          {fileName}
        </label>
        <a href="/modelo.xlsx" style={{ textAlign: 'center', width: '100%' }}>
          Baixar modelo de planilha
        </a>
      </div>
      {error && <div className="alert alert-danger mt-2">{error}</div>}

      {filteredData.length > 0 && (
        <div className="mt-4">
          <h3>Dados Importados</h3>
          <br />
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              checked={isEnabledRegisterUsersNotRegistred === true}
              onClick={() => {
                setIsEnabledRegisterUsersNotRegistred(true);
              }}
            />
            <label class="form-check-label" for="exampleRadios1">
              Registrar usuários não cadastrados
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              checked={isEnabledRegisterUsersNotRegistred === false}
              onClick={() => {
                setIsEnabledRegisterUsersNotRegistred(false);
              }}
            />
            <label class="form-check-label" for="exampleRadios2">
              Não registrar usuários não cadastrados
            </label>
          </div>
          <br />
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead className="thead-dark">
                <tr>
                  {Object.keys(filteredData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      cpfRegistrationMap[item.cpf] === false &&
                      !isEnabledRegisterUsersNotRegistred
                        ? 'table-danger-custom'
                        : ''
                    }
                  >
                    {Object.values(item).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExcelImporter;
