import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import renderHTML from 'react-render-html';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import loadingImage from '../Images/loading.gif';
import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class PreviewTextPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      htmlPreview: null,
      adminText: '',
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({ adminText: params.adminText });
    this.getClientText(params.adminText);
  }

  getClientText(adminText) {
    const parameters = {
      searchFunctionality: 'getText',
      userType: 'administrator',
      adminText,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          console.log('getClientText error:', result);

          this.setState({
            htmlPreview: null,
            isLoading: false,
          });
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }

        Notify(result.message, result.success ? 'success' : 'error');
        this.setState({
          htmlPreview: result.data.text,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log('getClientText error:', error);
      });
  }

  goToEditPage() {
    const { history } = this.props;
    const { adminText } = this.state;
    history.push(`${routes.EDIT_TEXT_ADMIN}/${adminText}/`);
  }

  renderLoading() {
    return (
      <img
        className="loading_image center-block"
        src={loadingImage}
        width="5%"
        alt="Loading"
      />
    );
  }

  renderNotFound() {
    return <h3>Texto não cadastrado ainda.</h3>;
  }

  renderEditButton() {
    const { htmlPreview, isLoading } = this.state;
    return (
      <div className="row">
        <button
          className="btn btn-oq pull-right clickable"
          disabled={isLoading}
          onClick={() => this.goToEditPage()}
        >
          <i className="fa fa-pencil-square-o" />{' '}
          {htmlPreview ? 'Editar' : 'Criar'}
        </button>
      </div>
    );
  }

  renderPreviewHTML() {
    const { htmlPreview, isLoading } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card-secondary">
              <div className="card-secondary-container">
                {this.renderEditButton()}

                {isLoading
                  ? this.renderLoading()
                  : htmlPreview
                    ? renderHTML(htmlPreview)
                    : this.renderNotFound()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <div className="container-fluid">
            {this.renderBackButton()}
            <span className="oq-filter-title">
              <i className="fa fa-eye" />
              <span>&nbsp; Pré-Visualização</span>
            </span>
          </div>
          {this.renderPreviewHTML()}
        </div>
      </div>
    );
  }
}

export default PreviewTextPage;
