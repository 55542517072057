import React, { Component } from 'react';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
// import XLSX from 'xlsx';
import ReactTable from 'react-table';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
// import * as mask from '../../../utils/Mascaras';
import ChangePassword from '../../components/Modals/ChangePassword';
// import ModalConfirmImportPlayers from '../../components/Modals/ModalConfirmImportPlayers';
import { api } from '../../../Config';
import ModalUnlinkPlayerIsOpen from '../../components/Modals/ModalUnlinkPlayerIsOpen';
import { UserDataContext } from '../../../hooks/useUserData';

class Profile extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.importPlayers = null;

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      unlinkPlayer: null,
      isLoadingPlayers: false,
      isLoadingUnlink: false,
      playersLinking: [],
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
        // password: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const { clientData, userData, userAuth } = this.context;

    this.getUserById(userData.uid);
    this.getGroupsClient(userData.uid);

    if (
      clientData &&
      clientData.enableUserAndPlayerLinking &&
      clientData.enableUserAndPlayerLinking === 'true'
    ) {
      // this.importPlayers = this.buildImportPlayersInput();
      this.getPlayersWithLinkingUserAndPlayers();
    }
  }

  getUserById(userId) {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getUserById',
        userType: 'client',
        userId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                formData: {},
              },
              () => Notify(res.data.message, 'error')
            );
            return;
          }
          this.setState(
            {
              isLoading: false,
              formValid: true,
              formData: result.user,
            },
            () => Notify(res.data.message, 'success')
            // Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          console.log('getUserById error:', error);
          this.setState({
            isLoading: false,
            formData: {},
          });
        });
    });
  }

  getGroupsClient() {
    this.setState({ loadingGroupsList: true }, () => {
      const parameters = {
        searchFunctionality: 'getGroupsClient',
        userType: 'client',
        searchObject: {},
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                loadingGroupsList: false,
                groupsList: [],
              }
              // () => Notify(res.data.message, 'error')
            );
            return;
          }
          this.setState(
            {
              loadingGroupsList: false,
              groupsList: result.groups,
            }
            // () => Notify(res.data.message, 'success')
          );
        })
        .catch((error) => {
          console.log('getGroupsClient error:', error);
          this.setState({
            loadingGroupsList: false,
            groupsList: [],
          });
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'password') {
        emptyFields.push(inputKey);
        console.log('inputKey: ', inputKey);
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  updateUserData() {
    const { formData } = this.state;

    const { userAuth, getUserData } = this.context;

    this.setState({ updatingUser: true }, () => {
      this.toastId = toast.info('Atualizando usuário. Aguarde...', {
        autoClose: false,
      });

      const parameters = {
        userType: 'client',
        searchFunctionality: 'updateUserData',
        userData: formData,
      };

      api
        .post(`/users`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            this.setState({ updatingUser: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              // this.setState(this.getInitialState());
              this.setState(
                {
                  isLoading: false,
                  formData: res.data.data,
                },
                async () => {
                  this.validateForm();
                  await getUserData(userAuth.uid);
                }
              );
            });
          } else {
            this.setState({ updatingUser: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({ updatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel editar o usuário!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('updateUserData error:', error);
        });
    });
  }

  async getPlayersWithLinkingUserAndPlayers() {
    const { clientData, userData, userAuth } = this.context;
    const userId = userData.uid;

    this.setState({ isLoadingPlayers: true });

    try {
      const parameters = {
        searchFunctionality: 'getPlayersWithLinkingUserAndPlayers',
        userType: 'client',
        userId,
      };

      const result = await api.post(`/search`, parameters);
      this.setState({ isLoadingPlayers: false });

      const response = result.data;
      console.log('responsePlayersLinking: ', response);

      if (response.success) {
        this.setState({ playersLinking: response.data.players });
      } else {
        this.setState({ playersLinking: [] });
      }
    } catch (error) {
      console.log('error getPlayersWithLinkingUserAndPlayers: ', error);
      this.setState({ isLoadingPlayers: false });
    }
  }

  async unlinkPlayerToUser(unlinkPlayerId) {
    console.log('unlinkPlayerId: ', unlinkPlayerId);
    // return;
    const { clientData, userData, userAuth } = this.context;
    const userId = userData.uid;

    this.setState({ isLoadingUnlink: true });

    try {
      const parameters = {
        updateFunctionality: 'unlinkPlayerToUser',
        userType: 'client',
        userId,
        unlinkPlayerId,
      };

      const result = await api.post(`/update`, parameters);
      this.setState({ isLoadingUnlink: false });

      const response = result.data;
      console.log('responseUnlinkPlayer: ', response);

      if (response.success) {
        Notify(response.message, 'success');
        await this.getPlayersWithLinkingUserAndPlayers();
      } else {
        Notify(response.message, 'warn');
        await this.getPlayersWithLinkingUserAndPlayers();
      }
    } catch (error) {
      console.log('error unlinkPlayerToUser: ', error);
      this.setState({ isLoadingUnlink: false });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.updateUserData();
  }

  openPasswordModal() {
    this.toggleModal();
  }

  toggleModal() {
    this.setState({ modalPasswordIsOpen: !this.state.modalPasswordIsOpen });
  }

  renderModal() {
    const { clientData, userData, userAuth } = this.context;
    return (
      <ChangePassword
        open={this.state.modalPasswordIsOpen}
        userData={userData}
        toggleModal={() => this.toggleModal()}
      />
    );
  }

  renderUserData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
          {dataUserUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                // onChange={e => {
                //   let input = e.target;
                //   if (input.value.length > 14) {
                //     mask.maskCNPJ(input.value).then(maskedValue => {
                //       this.handleFormDataUserChange(input.name, maskedValue);
                //     });
                //   } else {
                //     mask.maskCPF(input.value).then(maskedValue => {
                //       this.handleFormDataUserChange(input.name, maskedValue);
                //     });
                //   }
                // }}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.email}
              />
            </div>
            <div className="form-group col-md-12">
              <label
                className="btn btn-oq-back"
                onClick={() => this.openPasswordModal()}
              >
                <i className="fa fa-key" aria-hidden="true" />
                &nbsp; Alterar senha
              </label>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGroupsUser() {
    const { loadingGroupsList, groupsList } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Grupo
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="groups">
              Grupo
            </label>
            <Select
              isDisabled
              isOptionDisabled={(option) => option.value}
              name="groups"
              placeholder="Este usuário não pertence a nenhum grupo ainda"
              // isDisabled={loadingGroupsList}
              options={_.map(groupsList, (group) => {
                return {
                  value: group.id,
                  label: group.name,
                };
              })}
              isLoading={loadingGroupsList}
              className="basic-multi-select"
              value={this.state.formData.group}
              // onChange={e => this.handleFormDataChange('group', e)}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderUserAndPlayerLinking() {
    const { playersLinking } = this.state;

    const columns = [
      {
        Header: 'Imagem',
        accessor: 'photoURL',
        width: 80,
        Cell: (props) => (
          <img
            src={props.value}
            className="img-responsive img-rounded"
            style={{ width: '30px', height: '30px' }}
            alt="photoURL"
          />
        ),
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        id: 'uid',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span
            className="oq-primary-color clickable"
            onClick={() => this.toggleModalUnlinkPlayer(props.value)}
          >
            <i className="far fa-trash-alt" aria-hidden="true" />
            &nbsp; {this.translate('Desvincular')}
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Vincular Players ao Usuário
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Upload de players')}
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
            </div>
            {/* <div className="form-group col-md-12">
              <button
                type="button"
                className="btn btn-oq-black"
                disabled={this.state.loadingUploadVoucher}
                onClick={() => this.toggleModalImportPlayers()}
              >
                {this.translate('Fazer upload de players')}
              </button>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oq20-139d9.appspot.com/o/PublicTarget%2FPlanilha_exemplo_vincular_players.xlsx?alt=media&token=eef2c5f5-ad88-44c7-8553-4b6faf624ae0"
                style={{
                  marginTop: '.5rem',
                  marginLeft: '.5rem',
                  display: 'block',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de players
              </a>
            </div> */}
          </div>
          <div className="col-md-12">
            <div className="oq-card">
              <span className="oq-card-title">
                <i className="fa fa-users" />
                &nbsp; Players Vinculados
              </span>
              <div className="oq-line" />
              <div className="oq-padding-bottom" />
              <ReactTable
                // data={[]}
                // columns={[]}
                data={playersLinking}
                defaultPageSize={5}
                columns={columns}
                loading={
                  this.state.isLoadingPlayers || this.state.isLoadingUnlink
                }
                previousText="Anterior"
                nextText="Próxima"
                loadingText="Carregando..."
                noDataText="Nenhum player vinculado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
          {this.modalConfirmUnlinkPlayer()}
        </div>
        <hr />
      </div>
    );
  }

  toggleModalUnlinkPlayer(playerId) {
    this.setState({
      modalUnlinkPlayerIsOpen: !this.state.modalUnlinkPlayerIsOpen,
      unlinkPlayer: playerId,
    });
  }

  modalConfirmUnlinkPlayer() {
    return (
      <ModalUnlinkPlayerIsOpen
        open={this.state.modalUnlinkPlayerIsOpen}
        close={this.toggleModalUnlinkPlayer.bind(this)}
        unlinkPlayerToUser={() =>
          this.unlinkPlayerToUser(this.state.unlinkPlayer)
        }
      />
    );
  }

  // toggleModalImportPlayers() {
  //   this.setState({
  //     modalImporPlayersIsOpen: !this.state.modalImporPlayersIsOpen,
  //   });
  // }

  // modalConfirmImportPlayers() {
  //   return (
  //     <ModalConfirmImportPlayers
  //       open={this.state.modalImporPlayersIsOpen}
  //       close={this.toggleModalImportPlayers.bind(this)}
  //       importPlayers={this.importPlayers}
  //     />
  //   );
  // }

  renderForm() {
    const { clientData } = this.context;
    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        {this.state.formData &&
          !this.state.formData.master &&
          this.renderGroupsUser()}
        {clientData.enableUserAndPlayerLinking &&
          clientData.enableUserAndPlayerLinking === 'true' &&
          this.renderUserAndPlayerLinking()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.updatingUser}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
          {/* {this.modalConfirmImportPlayers()} */}
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Meu Perfil')}
                </span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
            <div>{this.renderModal()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const ProfileLink = (props) => (
  <Link className="menu-link" to={routes.PROFILE_USER}>
    <FontAwesomeIcon icon={faUser} />
    <span className="sidebarText">{props.translate('Meu Perfil')}</span>
  </Link>
);

export default Profile;

export { ProfileLink };
