import './styles.css';
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import axios from 'axios';
import lodash from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';
import { api, getAPIURL } from '../../../../../Config';

import Modal from 'react-responsive-modal';
import { MdArrowBack, MdDone } from 'react-icons/md';
import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';
import { Integrations } from './components/Integrations';
import { Widget } from './components/Widget';
import { UserDataContext } from '../../../../../hooks/useUserData';

class CreateActionVirtual extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      integrationIndexSelected: 3,
      tabIndex: 0,
      flag: false,
      authUser: {},
      actionId: {},
      numberOfImages: 5,
      isURLVideo: false,
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingSeasons: false,
      search: {},
      countSeasons: 0,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      images: [],
      isOpenModalExtraFields: false,
      isOpenModalExtraFieldsList: false,
      activeExtraInputType: '1',
      extraFieldName: 'Campos extras',
      extraFieldNameInput: 'Novo campo extra',
      extraFieldOptions: [
        {
          type: 'text',
          text: 'Texto',
          dataActive: '1',
        },
        {
          type: 'number',
          text: 'Número',
          dataActive: '2',
        },
        {
          type: 'date',
          text: 'Data',
          dataActive: '3',
        },
        {
          type: 'select',
          text: 'Seleção',
          dataActive: '4',
        },
      ],
      extraFieldInputSelectOptions: [
        { name: 'Nova opção 1' },
        { name: 'Nova opção 2' },
        { name: 'Nova opção 3' },
      ],
      formData: {
        actionTypePerson: '',
        isIntegrationPixel: false,
        isIntegrationGoogleAnalytics: false,
        isIntegrationGoogleTagManager: false,
        integrationPixelCode: '',
        integrationGoogleAnalyticsCode: '',
        integrationGoogleTagManagerCode: '',
        integrationGoogleTagManagerBodyCode: '',
        actionClientUrl: '',
        extraFieldsInputs: [],
        actionType: '',
        active: true,
        isAutoIndicateEnabled: 'false',
        imageSocialMedia: {},
        redirectWhatsapp: 'false',
        btnRedirectTextForPlayer: '',
        btnRedirectLinkForPlayer: '',
        redirectForPlayer: false,
        redirectLandingPage: '',
        publicationSocialMedia: 'false',
        contactPlayerWhatsapp: 'false',
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        externalId: '',
        marcaId: '',
        season: {},
        pitchVerb: '',
        files: [],
        images: [],
        videoURL: '',
        countDownVisible: true,
        // qrCodeButtonsVisible: false,
        showPoster: false,
        shareButtonsVisible: true,
        // startDateVisible: true,
        description: '',
        rulesOfAction: '',
        redirectDashCallToAction: '/',
        membergetmemberTotalSignUp: '0',
        membergetmemberMax: '0',
        // landingPagePreviewPoint: 0,
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        conversionFieldName: '',
        extraFieldName1: '',
        hasAwards: 'false',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      socialMediaUncompleted: false,
      phase3Uncompleted: false,
      active: true,
    };

    return initialState;
  }

  async componentDidMount() {
    const { clientData } = this.context;
    await this.getStateList();

    if (clientData.isGamified !== 'false') {
      this.searchSeasons();
    } else {
      const season = {
        id: false,
        seasonName: 'defaultSeason',
      };
      this.handleFormDataChange('season', season);
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          scope: 'national',
        },
      }));
    }
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        let sortedStates = _.sortBy(states.data, 'nome');
        sortedStates = [
          { sigla: 'todos', nome: 'Selecionar todos' },
          ...sortedStates,
        ];

        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    let newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    newCitiesarr = [
      { city: 'Selecionar todas', state: 'todos' },
      ...newCitiesarr,
    ];

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      const parameters = {
        searchFunctionality: 'filterSeasons',
        userType: 'client',
        search,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.data.seasons && result.data.countSeasons) {
            this.setState({
              seasons: result.data.seasons,
              countSeasons: result.data.countSeasons,
              loadingSeasons: false,
            });
          }
          this.setState({ loadingSeasons: false });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: true }, () => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const result = [];

          let binary = '';
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const oFile = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true,
          });
          oFile.SheetNames.forEach(function (sheetName) {
            const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
              header: 1,
            });
            if (roa.length) {
              result.push(roa);
            }
          });

          this.parseData(result, field);
        };

        fileReader.readAsArrayBuffer(file);
      });
      this.setState({ parsingExtraField1: false });
    }
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'extraFieldSheet1') {
          return {
            class: item[0],
          };
        }

        return item[0];
      });

      items = lodash.concat(items, _items);
    });

    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: false });
      this.handleFormDataChange(field, items);
    }
  }

  goToEditPage(actionId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_ACTION_VIRTUAL}/${actionId}`);
  }

  createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Criando publicação. Aguarde...', {
        autoClose: false,
      });

      const { startDate, startDateTime, endDate, endDateTime } = formData;

      formData.startDateEpoch = moment(
        `${startDate} ${startDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();
      formData.endDateEpoch = moment(
        `${endDate} ${endDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();

      const { clientData } = this.context;
      if (clientData.gamificationType === 'goalsLevels') {
        //* Se a gamificação do cliente for por METAS as ações, necessariamente,
        //* o método de conversão será "Direta"
        formData.conversionType = 'direct';

        //* as conversões terão o valor fixo de 100 pontos
        formData.leadConversion = 100;
      }

      formData.qrCodeShareURL = false;

      const parameters = {
        userType: 'client',
        searchFunctionality: 'createAction',
        actionData: formData,
        images: this.state.images,
      };

      api
        .post(`/actions`, parameters)
        .then((res) => {
          this.setState({ creatingAction: false }, () => {
            Notify(
              res.data[0].message,
              res.data[0].success ? 'success' : 'error'
            );
          });
          setTimeout(() => {
            this.props.history.goBack();
          }, 3000);
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            Notify(
              error.data[0].message,
              error.data[0].success ? 'success' : 'error'
            );
          });
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    let noRequired = '';
    if (formData.videoURL.trim() === '') {
      noRequired = 'videoURL';
    } else if (!formData.videoURL.trim() === '') {
      noRequired = 'images';
    } else {
      noRequired = '';
    }

    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'usersCount' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'physicalAwards' &&
        inputKey !== 'callToAction' &&
        inputKey !== 'numberOfWinners' &&
        // inputKey !== 'videoURL' &&
        inputKey !== noRequired &&
        inputKey !== 'membergetmember' &&
        inputKey !== 'conversionFieldName' &&
        inputKey !== 'extraFieldName1' &&
        inputKey !== 'extraFieldName' &&
        inputKey !== 'leadRegistration' &&
        inputKey !== 'salesConversion' &&
        inputKey !== 'scope' &&
        inputKey !== 'shareButtonsVisible' &&
        inputKey !== 'qrCodeButtonsVisible' &&
        inputKey !== 'showPoster' &&
        inputKey !== 'description' &&
        inputKey !== 'redirectForPlayer' &&
        inputKey !== 'redirectLandingPage' &&
        inputKey !== 'isIntegrationPixel' &&
        inputKey !== 'isIntegrationGoogleAnalytics' &&
        inputKey !== 'isIntegrationGoogleTagManager' &&
        inputKey !== 'integrationPixelCode' &&
        inputKey !== 'integrationGoogleAnalyticsCode' &&
        inputKey !== 'integrationGoogleTagManagerCode' &&
        inputKey !== 'integrationGoogleTagManagerBodyCode' &&
        inputKey !== 'btnRedirectLinkForPlayer' &&
        inputKey !== 'btnRedirectTextForPlayer' &&
        inputKey !== 'conversionType' &&
        inputKey !== 'externalId' &&
        inputKey !== 'marcaId' &&
        inputKey !== 'rulesOfAction' &&
        inputKey !== 'scopeValues' &&
        inputKey !== 'marca' &&
        inputKey !== 'modelo' &&
        inputKey !== 'fileIndexSocialMedia' &&
        inputKey !== 'pitchVerbPlayer' &&
        inputKey !== 'actionType' &&
        inputKey !== 'extraFieldSheet1' &&
        inputKey !== 'actionClientUrl' &&
        inputKey !== 'actionTypePerson' &&
        inputKey !== 'membergetmemberTotalSignUp' &&
        inputKey !== 'urlRedirectAction'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'salesConversion' &&
        (formData.conversionType === 'form' ||
          formData.conversionType === 'direct')
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.conversionType === ''
      ) {
        emptyFields.push('conversionType');
        emptyFieldsCount++;
      }
      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.rulesOfAction === ''
      ) {
        emptyFields.push('rulesOfAction');
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'leadRegistration' &&
        formData.conversionType === 'form'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'imageSocialMedia' &&
        !input.image &&
        formData.publicationSocialMedia === 'true'
      ) {
        {
          emptyFields.push(inputKey);
          emptyFieldsCount++;
        }
      }

      // if (
      //   inputKey === 'redirectForPlayer' &&
      //   formData.redirectForPlayer === true &&
      //   (formData.btnRedirectLinkForPlayer === '' ||
      //     formData.btnRedirectTextForPlayer === '')
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }

      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });

    console.log('emptyFields', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }

  onRadioChange(e) {
    const objectEvent = {
      target: {
        name: e.target.name,
        value: this.str2bool(e.target.value),
      },
    };
    this.handleUserInput(objectEvent);
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleIconsShare(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        iconsShare: {
          ...prevState.formData.iconsShare,
          [name]: value,
        },
      },
    }));
  }

  // HERE

  handleDateChangeValid() {
    const { clientData } = this.context;
    const { startDate, endDate, startDateTime, endDateTime, season } =
      this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (clientData.isGamified && clientData.isGamified === 'true') {
      if (season.id !== false) {
        if (
          startDate !== '' &&
          season.startDate !== '' &&
          startDate <= season.startDate
        ) {
          this.setState({
            errorForm1: true,
            msg1: 'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
          });
          document.getElementById('handleDateChangeValid-1').className =
            'help-block';
        }

        if (
          endDate !== '' &&
          season.endDate !== '' &&
          endDate >= season.endDate
        ) {
          this.setState({
            errorForm1: true,
            msg3: 'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
          });
          document.getElementById('handleDateChangeValid-3').className =
            'help-block';
        } else {
          this.setState({ errorForm1: false });
          document.getElementById('handleDateChangeValid-3').className =
            'help-block hidden';
        }
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: this.phase2IsUncompleted(),
          socialMediaUncompleted: this.socialMediaIsUncompleted(),
          phase3Uncompleted: this.phase3IsUncompleted(),
        });
      }
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  /* Para adicionar o input com verificação de tamanho INICIO */
  async handleUserImage(e, ref, fileIndex) {
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));
      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.setState((oldState) => ({
      formData: {
        ...oldState.formData,
        images: [...oldState.formData.images, { url: data.data }],
      },
      isUploading: false,
    }));
  }

  /* Para adicionar o input com verificação de tamanho FIM */

  async handleUpload(e, ref, fileIndex) {
    const { formData } = this.state;
    const { files } = formData;

    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;
    this.setState({ isUploading: true });
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        fileIndexSheet: name,
      },
    }));

    const filename = randomizeName;
    const file = e.target.files[0];

    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    const newFiles = {
      ...files,
      [fileIndex]: data.data,
    };
    this.handleFormDataChange('files', newFiles);
    this.setState({ isUploading: false });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  handleDeleteImage = (id) => {
    const { images } = this.state.formData;

    const newImages = images.filter((img, index) => index !== id);
    // const newImages = images.splice(id, 1);
    // delete images[id];

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        images: newImages,
      },
    }));
  };

  handleChange = (event) => {
    this.handleUserInput(event);
    const inputValue = event.target.value;
    this.setState({
      numberOfImages: inputValue ? 4 : 5,
      isURLVideo: inputValue ? true : false,
    });
  };

  renderPhase1() {
    const { formData, phase1Uncompleted, formButtonPressed, seasons } =
      this.state;
    const { clientData } = this.context;

    const seasonSelected =
      formData.season &&
      formData.season.id &&
      seasons &&
      seasons.length > 0 &&
      seasons.find((season) => season.id === formData.season.id);

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Copywriting
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                Título da copy <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário Ex.: Solicitar Proposta; Fazer Simulação;
                    Deixar Contatos.
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Título da publicação')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Ver e responder')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder="Link do vídeo"
                onChange={this.handleChange}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                {this.translate('Mensagem de engajamento')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 140 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Texto que o usuário cadastrado na Plataforma vê antes de se
                    engajar na publicação Ex.: Compartilhe já; Indicar Amigos;
                    Divulgar para todos os meus contatos.{' '}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionSubtitle}
              />
            </div>

            {/* {this.context.clientData.clientUrl === 'emr' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="urlRedirectAction">
                  {this.translate('Url base para página de Checkout')}{' '}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="urlRedirectAction"
                  />
                  <ReactTooltip
                    id="urlRedirectAction"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Link de redirecionamento para a página de checkout do Eu
                      médico residente
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  name="urlRedirectAction"
                  placeholder="https://exemplo.url-redirecionamento"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.urlRedirectAction}
                />
              </div>
            )} */}

            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  disabled={this.state.loadingSeasons}
                  placeholder={this.translate('loading')}
                  required
                  onChange={(e) => this.handleSeasonChange(e)}
                >
                  <option value="">
                    {this.translate(
                      'Selecione a qual Temporada essa publicação pertence...'
                    )}
                  </option>
                  {seasons &&
                    _.map(seasons, (season) => {
                      return (
                        <option value={season.id}>{season.seasonName}</option>
                      );
                    })}
                </select>
                {seasonSelected && (
                  <strong className="help-block" id="showDateSeason">
                    {`Início: ${moment(seasonSelected.startDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )} - Fim: ${moment(seasonSelected.endDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )}`}
                  </strong>
                )}
              </div>
            )}
            {clientData && clientData.clientUrl === 'emr' && (
              <div className="row" style={{ marginLeft: '4px' }}>
                {this.renderSelectActionType()}
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' &&
              clientData.segmentationChoice === 'true' &&
              this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.renderStateSelection()}
                  {this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')}{' '}
              {formData.videoURL ? '' : <code>*</code>}
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            <strong style={{ marginTop: '0' }} className="help-block">
              {`Até ${this.state.numberOfImages} imagens`}
            </strong>
            {formData.images
              ? formData.images.map((image, i) => {
                  return (
                    <div className="flex" key={i}>
                      {image && (
                        <>
                          <div
                            className="delete-banner"
                            key={i}
                            onClick={() => {
                              this.handleDeleteImage(i);
                            }}
                          >
                            <IconDelete />
                          </div>
                          <img
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      )}
                      {/* {this.renderImagePreview(image.url)} */}
                    </div>
                  );
                })
              : null}
            <input
              required
              disabled={
                formData.images &&
                formData.images.length === this.state.numberOfImages
              }
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={async (event) => {
                await this.handleUserImage(event, `Actions/`, 'image1');
              }}
            />
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDateTime}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12 row p-0-right">
              <div className="daterow">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="startDate">
                    {this.translate('status')} <code>*</code>
                  </label>
                  <select
                    className="form-control profile-placeholder"
                    name="active"
                    onChange={(e) => this.handleScopeChange(e)}
                    value={formData.active}
                  >
                    <option value>{this.translate('active')}</option>
                    <option value={false}>{this.translate('inactive')}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderIconsShare() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#inconsShare"
          aria-expanded="false"
        >
          Ícones de compartilhamento
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="inconsShareI"
            style={{ marginLeft: 8 }}
          />
          <ReactTooltip
            id="inconsShareI"
            type="dark"
            effect="solid"
            multiline
            className="tool-tip"
          >
            <span>
              Você deve selecionar os canais de distribuição do conteúdo a cada
              publicação de acordo com sua estratégia e perfil de público
            </span>
          </ReactTooltip>
        </h4>
        <div
          id="inconsShare"
          aria-expanded="false"
          className="collapse"
          style={{ marginLeft: -60 }}
        >
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="whatsapp">
                Whatsapp &nbsp;
              </label>

              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="whatsapp"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.whatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="whatsapp"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.whatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="facebook">
                Facebook &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="facebook"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.facebook === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="facebook"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.facebook !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="telegram">
                Telegram &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="telegram"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.telegram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="telegram"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.telegram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="twitter">
                Twitter &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="twitter"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.twitter === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="twitter"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.twitter !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="pinterest">
                Pinterest &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="pinterest"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.pinterest === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="pinterest"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.pinterest !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="copyLink">
                Copiar link &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="copyLink"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.copyLink === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="copyLink"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.copyLink !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="instagram">
                Instagram &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="instagram"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.instagram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="instagram"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.instagram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="email">
                Email &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="email"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={this.state.formData.iconsShare.email === 'true'}
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="email"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={this.state.formData.iconsShare.email !== 'true'}
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="linkedin">
                Linkedin &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="linkedin"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.linkedin === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="linkedin"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.linkedin !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="messenger">
                Messenger &nbsp;
              </label>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="messenger"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.messenger === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="messenger"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare.messenger !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    const { formData, phase2Uncompleted, formButtonPressed } = this.state;

    const container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link'],
      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'align',
      'link',
    ];

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Detalhes da publicação
          {phase2Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          {this.context.clientData &&
            this.context.clientData.clientUrl === 'grupodirecional' && (
              <div
                className="form-group points-by-share col-md-12"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label className="profile-label" htmlFor="actionClientUrl">
                  Definir cliente para a publicação&nbsp; <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="actionClientUrl"
                  />
                  <ReactTooltip
                    id="actionClientUrl"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Defina para qual cliente irá aparecer a publicação.
                    </span>
                  </ReactTooltip>
                </label>
                <div className="form-group col-md-4">
                  <select
                    required
                    className="form-control profile-placeholder"
                    name="actionClientUrl"
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.actionClientUrl}
                  >
                    <option value="">Selecione um cliente</option>
                    <option value="riva">Riva</option>
                    <option value="direcional">Direcional</option>
                  </select>
                </div>
              </div>
            )}

          {this.state.formData.conversionType === 'form' && (
            <div
              className="form-group points-by-share col-md-12"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <label className="profile-label" htmlFor="personType">
                Qual o tipo de seu empreendimento?
                <i
                  className="fa fa-info-circle ms-3"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="personType"
                />
                <code>*</code>
              </label>
              <ReactTooltip
                id="personType"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Defina qual o tipo/modelo do seu empreendimento para que se
                  adeque á indicação.
                </span>
              </ReactTooltip>
              <div className="form-group col-md-4">
                <select
                  className="form-control profile-placeholder"
                  name="actionTypePerson"
                  onChange={(e) => this.handleUserInput(e)}
                  value={
                    formData.actionTypePerson ? formData.actionTypePerson : ''
                  }
                  // required
                >
                  <option disabled selected value="">
                    Selecione o tipo do empreendimento
                  </option>
                  <option value="0">MCMV</option>
                  <option value="1">Médio ou alto padrão</option>
                  <option value="2">Comercial</option>
                </select>
              </div>
            </div>
          )}

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="defaultImagePosterInfo">
              Definir imagem padrão para publicação
              <i
                className="fa fa-info-circle ms-3"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="defaultImagePosterInfo"
              />
            </label>
            <ReactTooltip
              id="defaultImagePosterInfo"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Defina qual imagem irá aparecer por padrão para publicação. Esta
                configuração define também a geração dos cartazes de impressão e
                compartilhamento.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <select
                className="form-control profile-placeholder"
                name="defaultImagePoster"
                onChange={(e) => this.handleUserInput(e)}
                value={
                  formData.defaultImagePoster ? formData.defaultImagePoster : ''
                }
              >
                <option disabled selected value="">
                  Selecione uma imagem
                </option>
                {/* registrando alteração para deploy */}
                <option value="false">Sem imagem padrão</option>
                {/* fim registrando alteração para deploy */}
                {formData.images.map((index) => (
                  <option value={index} key={index}>{`Imagem ${
                    index + 1
                  }`}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="countDownVisible">
              {this.translate(
                'Exibir cronômetro regressivo informando o tempo para o término da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="countDownVisible"
              />
            </label>
            <ReactTooltip
              id="countDownVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('countDownVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="countDownVisible"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="countDownVisible"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="isAutoIndicateEnabled">
              Habilitar indicação direta?
            </label>
            <br />
            <p className="tiny-text">
              Na Indicação Direta o prospector/usuário pode fazer a indicação
              preenchendo ele <br />
              mesmo os dados do lead, sem necessidade de compartilhar o link com
              o indicado
            </p>
            <br />
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isAutoIndicateEnabled"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.isAutoIndicateEnabled &&
                      this.state.formData.isAutoIndicateEnabled !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isAutoIndicateEnabled"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.isAutoIndicateEnabled &&
                      this.state.formData.isAutoIndicateEnabled === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="shareButtonsVisible">
              {this.translate(
                'Exibir botões de compartilhamento com terceiros?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="shareButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="shareButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('shareButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="shareButtonsVisible"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={this.state.formData.shareButtonsVisible}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="shareButtonsVisible"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={!this.state.formData.shareButtonsVisible}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="qrCodeButtonsVisible">
              Exibir QRCode para usuários e terceiros? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="qrCodeButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="qrCodeButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('qrCodeButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="qrCodeButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeButtonsVisible !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="qrCodeButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeButtonsVisible === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="showPoster">
              Exibir poster da publicação para o usuário? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="showPoster"
              />
            </label>
            <ReactTooltip id="showPoster" type="dark" effect="solid" multiline>
              <span>
                Com este recursos, o usuário pode imprimir ou exibir o poster{' '}
                <br />
                para captar novos usuários/leads sem a necessidade de
                compartilhamentos <br />
                nas redes sociais ou aplicativos de mensagens.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="showPoster"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={this.state.formData.showPoster}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="showPoster"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={!this.state.formData.showPoster}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="startDateVisible">
              {this.translate(
                'Data de início visível para o usuário no card da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="startDateVisible"
              />
            </label>
            <ReactTooltip
              id="startDateVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('startDateVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}

          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="publicationSocialMedia">
              {this.translate(
                'Deseja ativar o compartilhamento por Instagram/TikTok?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="publicationSocialMedia"
              />
            </label>
            <ReactTooltip
              id="publicationSocialMedia"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Publicação que permite fazer upload de imagem para ser compatilhada no formato das mídias sociais.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="publicationSocialMedia"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.publicationSocialMedia !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="publicationSocialMedia"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.publicationSocialMedia === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="redirectWhatsapp">
              {this.translate(
                'Habilitar o contato direto do lead com o vendedor?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="call-action"
              />
            </label>
            <ReactTooltip id="call-action" type="dark" effect="solid" multiline>
              <span>
                {this.translate(
                  'Ao habilitar esta função, o lead terá a opção de falar, via WhatsApp, direto com o vendedor que compartilhou o conteúdo, agilizando o primeiro contato.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="redirectWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="redirectWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          {this.context &&
            this.context.clientData &&
            this.context.clientData.showCTAIndication && (
              <div className="form-group col-md-12">
                <label
                  style={{ marginRight: 5 }}
                  className="profile-label"
                  htmlFor="redirectLandingPage"
                >
                  Link de redirecionamento para indicados.
                </label>

                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="redirectLandingPage"
                />
                <ReactTooltip
                  id="redirectLandingPage"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>Link de redirecionamento para o indicado</span>
                </ReactTooltip>

                <input
                  className="form-control profile-placeholder"
                  name="redirectLandingPage"
                  placeholder="Link de redirecionamento"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.redirectLandingPage}
                />
              </div>
            )}

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="redirectForPlayer">
              {this.translate(
                'Habilitar botão de redirecionamento dentro da publicação para o player?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="redirectForPlayer"
              />
            </label>
            <ReactTooltip
              id="redirectForPlayer"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Ao habilitar essa função, o player terá um botão de redirecionamento dentro da publicação'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="redirectForPlayer"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectForPlayer
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="redirectForPlayer"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={
                      this.state.formData &&
                      !this.state.formData.redirectForPlayer
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData && this.state.formData.redirectForPlayer && (
            <div>
              <div className="form-group col-md-12">
                <label
                  className="profile-label col-md-6"
                  htmlFor="btnRedirectTextForPlayer"
                >
                  {this.translate('Texto do botão de redirecionamento')}{' '}
                  <code>*</code> &nbsp;
                  <span style={{ fontSize: '10px', display: 'contents' }}>
                    (Até 38 caracteres)
                  </span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="btnRedirectTextForPlayer"
                  />
                  <ReactTooltip
                    id="btnRedirectTextForPlayer"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>
                      {this.translate(
                        'Esse será o nome do seu botão de redirecionamento'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <div className="col-md-6">
                  <input
                    required={
                      this.state.formData &&
                      this.state.formData.redirectForPlayer
                    }
                    type="text"
                    className="form-control profile-placeholder "
                    name="btnRedirectTextForPlayer"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.btnRedirectTextForPlayer}
                    placeholder="Redirecionar"
                  />
                </div>
              </div>
              {/* <div className="form-group col-md-12">
                <label
                  className="profile-label col-md-6"
                  htmlFor="btnRedirectLinkForPlayer"
                >
                  {this.translate('Link de redirecionamento')}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="btnRedirectLinkForPlayer"
                  />
                  <ReactTooltip
                    id="btnRedirectLinkForPlayer"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Link que será vinculado ao botão configurado anteriormente. Ao'
                      )}
                      <br />
                      {this.translate(
                        'clicar o player será redirecionado para o link informado nesse'
                      )}
                      <br />
                      {this.translate('campo.')}
                      <br />
                    </span>
                  </ReactTooltip>
                </label>
                <div className="col-md-6">
                  <input
                    required={
                      this.state.formData &&
                      this.state.formData.redirectForPlayer
                    }
                    type="text"
                    className="form-control profile-placeholder"
                    name="btnRedirectLinkForPlayer"
                    onChange={e => this.handleUserInput(e)}
                    value={this.state.formData.btnRedirectLinkForPlayer}
                    placeholder="Link de redirecionamento"
                  />
                </div>
              </div> */}
            </div>
          )}

          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              {/* <label className="profile-label" htmlFor="description">
                {this.translate('pitch')} <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('description-action-text')}</span>
                </ReactTooltip>
              </label> */}
              <label className="profile-label" htmlFor="pitch">
                Por que... (para o público-alvo) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  Coloque aqui um verbo para complementar. Ex.: Por que...
                  comprar?; Por que... contratar?; Por que... se associar?
                </ReactTooltip>
              </label>
              <input
                required
                className="input-pitch"
                placeholder="Verbo"
                name="pitchVerb"
                value={formData.pitchVerb}
                style={{ marginLeft: 10 }}
                onChange={(e) => this.handleUserInput(e)}
              />
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="pitchVerb"
              />
              <ReactTooltip
                id="pitchVerb"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('description-action-text2')}</span>
              </ReactTooltip>
            </div>

            <ReactQuill
              ref={(ref) => (this.quillRef = ref)}
              modules={{
                toolbar: {
                  container,
                },
              }}
              style={{ height: 150, marginBottom: 50 }}
              placeholder="Copy com argumento de venda"
              formats={format}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                this.handleFormDataChange('description', editor.getHTML());
              }}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="rulesOfAction">
              {this.translate('Regras da Publicação')} <code>*</code> &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="rulesOfAction"
              />
              <ReactTooltip
                id="rulesOfAction"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('rules-of-action-text1')}</span>
              </ReactTooltip>
            </label>

            <ReactQuill
              ref={(ref) => (this.quillRef = ref)}
              modules={{
                toolbar: {
                  container,
                },
              }}
              style={{ height: 150, marginBottom: 50 }}
              placeholder="Regras da Publicação"
              formats={format}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                this.handleFormDataChange('rulesOfAction', editor.getHTML());
              }}
            />
          </div>

          <div>
            {this.state.formData &&
              this.state.formData.files &&
              this.state.formData.files.dataSheet && (
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="dataSheet">
                    {this.translate('Tipo da Ficha Técnica')} &nbsp;
                    <code>*</code>&nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="dataSheet"
                    />
                    <ReactTooltip
                      id="dataSheet"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline
                    >
                      <span>
                        Campo obrigatório se possuir Ficha técnica. <br />
                        Você define a nomeclatura: regras, regulamento, ficha
                        técnica, condições... &nbsp;
                      </span>
                    </ReactTooltip>
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control profile-placeholder"
                    name="conversionFieldName"
                    placeholder="Ex: Regulamento"
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.conversionFieldName}
                  />
                </div>
              )}
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="dataSheet">
              {formData.conversionFieldName
                ? formData.conversionFieldName
                : this.translate('Ficha Técnica')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="technicalFix"
              />
              <ReactTooltip
                id="technicalFix"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('technical-fix-text1')}</span>
              </ReactTooltip>
            </label>
            <label className="profile-label">
              Arquivo:{' '}
              {this.state.formData &&
                this.state.formData.files &&
                this.state.formData.files.dataSheet && (
                  <div style={{ display: 'flex' }}>
                    <a
                      href={this.state.formData.files.dataSheet}
                      style={{
                        marginTop: '.5rem',
                      }}
                      className="menu-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.formData.fileIndexSheet}
                    </a>
                    <div className="clickable">
                      <IconDelete
                        onClick={() => {
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              files: null,
                              conversionFieldName: null,
                              fileIndexSheet: null,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}
            </label>
            <input
              type="file"
              disabled={this.state.isUploading}
              id="profilePic"
              onChange={async (event) => {
                await this.handleUpload(event, `Actions/`, 'dataSheet');
              }}
            />
          </div>
          <div className="form-group col-md-12">
            <label
              style={{ marginRight: 5 }}
              className="profile-label"
              htmlFor="externalId"
            >
              ID Externa
            </label>

            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="externalID"
            />
            <ReactTooltip
              id="externalID"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>ID usado para integrações customizadas</span>
            </ReactTooltip>

            <input
              className="form-control profile-placeholder"
              name="externalId"
              placeholder="ID de identificação externa"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.externalId}
            />
          </div>
          {this.context.clientData.clientUrl === 'umuarama' && (
            <>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="marcaId">
                  Categoria
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="marcaId"
                  placeholder="ID de identificação externa"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.marcaId}
                >
                  <option value="">Selecione a marca</option>
                  <option value="b54e8890e455df3d7eddff24291009bc">
                    Motos
                  </option>
                  <option value="629fc8a198550ddb8b6f0c0c0a0c91f6">
                    Seminovos
                  </option>
                  <option value="7c6e89ae3632f07022026a1e9eab5ac0">
                    Novos
                  </option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="marca">
                  Marca
                </label>
                <input
                  className="form-control profile-placeholder"
                  name="marca"
                  placeholder="Marca"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.marca}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="modelo">
                  Modelo
                </label>
                <input
                  className="form-control profile-placeholder"
                  name="modelo"
                  placeholder="Modelo"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.modelo}
                />
              </div>
            </>
          )}
        </div>

        <hr />
      </div>
    );
  }

  renderPhase3() {
    const { formData, phase3Uncompleted, formButtonPressed } = this.state;
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Regras de Pontuação
          {phase3Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-12 form-group">
            {clientData.gamificationType === 'goalsLevels'
              ? this.renderGoalLevelsFields()
              : this.renderActionPointsFields(formData)}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderSocialMedia() {
    const { formData, socialMediaUncompleted, formButtonPressed } = this.state;
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#socialMedia"
          aria-expanded="false"
        >
          Publicação Instagram/TikTok
          {socialMediaUncompleted && this.renderUncompletedText()}
        </h4>
        <div id="socialMedia" aria-expanded="false" className="collapse">
          <div className="col-md-12 form-group">
            <div className="form-group col-md-5 imgpv">
              <label className="profile-label" htmlFor="imageSocialMedia">
                {this.translate('image')} <code>*</code>
                ( 1080x1920)&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="imageSocialMedia"
                />
                <ReactTooltip
                  id="imageSocialMedia"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span className="span-oq-create-action">
                    Imagem em modo retrato.
                  </span>
                </ReactTooltip>
                {this.state.isUploading && <span>{this.state.progress}%</span>}
              </label>
              <div className="flex">
                {formData.imageSocialMedia &&
                  formData.imageSocialMedia.image && (
                    <>
                      <div className="delete-banner">
                        <IconDelete
                          className="clickable"
                          onClick={() => {
                            this.setState((oldState) => ({
                              formData: {
                                ...oldState.formData,
                                fileIndexSocialMedia: '',
                                imageSocialMedia: {
                                  image: '',
                                },
                              },
                              isUploading: false,
                            }));
                          }}
                        />
                      </div>
                      <img
                        className="img-responsive img-thumbnail oq-margin-bottom"
                        src={formData.imageSocialMedia.image}
                      />
                    </>
                  )}
              </div>
              <input type="file" accept="image/*" id="profilePic" />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderMemberGetMemberFields(formData) {
    return (
      <div>
        <div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmember">
              {this.translate('Pontuação para o player anfitrião')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmember"
              />
              <ReactTooltip
                id="membergetmember"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Pontuação que o player ganhará caso outro player convidado se cadastre a partir dessa publicação.'
                  )}
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="number"
              className="form-control profile-placeholder"
              name="membergetmember"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.membergetmember}
              placeholder={this.translate('Member Get Member Anfitrião')}
            />
          </div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberguest">
              {this.translate('Pontuação para o player convidado')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberguest"
              />
              <ReactTooltip
                id="membergetmemberguest"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Pontuação que o player convidado ganhará ao se cadastrar na plataforma através do link da publicação.'
                  )}
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="number"
              className="form-control profile-placeholder"
              name="membergetmemberguest"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.membergetmemberguest}
              placeholder={this.translate('Member Get Member Convidado')}
            />
          </div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberMax">
              Número máximo de pessoas que poderão se cadastrar <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberMax"
              />
              <ReactTooltip
                id="membergetmemberMax"
                type="dark"
                effect="solid"
                multiline
              >
                <span>Número máximo de pessoas que poderão se cadastrar.</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="number"
              className="form-control profile-placeholder"
              name="membergetmemberMax"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.membergetmemberMax}
              placeholder="Número máximo de pessoas que poderão se cadastrar. "
            />
          </div>
        </div>
        <div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberCTA">
              {this.translate('Nome do CTA')} <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberCTA"
              />
              <ReactTooltip
                id="membergetmemberCTA"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('***')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="membergetmemberCta"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.membergetmemberCta}
              placeholder={this.translate('Nome do CTA')}
            />
          </div>
        </div>
      </div>
    );
  }

  renderActionPointsFields(formData) {
    const { clientData } = this.context;
    return (
      <div>
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="conversionType">
              {this.translate('Geração de Pontos')}&nbsp; <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="conversionType"
              />
              <ReactTooltip
                id="conversionType"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('conversionType-tooltip-text')}</span>
              </ReactTooltip>
            </label>
            <select
              required
              className="form-control profile-placeholder"
              name="conversionType"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.conversionType}
            >
              <option value="">{this.translate('select')}...</option>
              <option value="autoindicate">Indicação Direta*</option>
              <option value="direct">{this.translate('Direta')}</option>
              <option value="form">{this.translate('Formulário')}</option>
              <option value="none">
                {this.translate('Nenhum (Apenas Link)')}
              </option>
              <option value="membergetmember">
                {this.translate('Member Get Member')}
              </option>
            </select>
            {formData.conversionType === 'autoindicate' && (
              <>
                <br />
                <span>
                  Na Indicação Direta o prospector/usuário pode fazer a
                  indicação preenchendo ele mesmo os dados do lead, sem
                  necessidade de compartilhar o link com o indicado
                </span>
              </>
            )}
          </div>
        </div>

        {this.renderConversionTypeForm()}

        <div className="col-md-12 row p-0-right">
          <div className="form-group col-md-4 hidden">
            <label className="profile-label" htmlFor="landingPagePreviewPoint">
              {this.translate('Visualização de Landing Page')}&nbsp;
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="landingPagePreviewPoint"
              />
              <ReactTooltip
                id="landingPagePreviewPoint"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Esta função está temporariamente desabilitada!'
                  )}
                  {/* <br />
									{this.translate("de compartilhamento")} */}
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              disabled
              type="number"
              className="form-control profile-placeholder"
              name="landingPagePreviewPoint"
              onChange={(e) => this.handleUserInput(e)}
              value={0}
              // value={formData.landingPagePreviewPoint}
              placeholder="Visualização de Landing Page"
            />
          </div>

          {formData.conversionType === 'form' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="leadRegistration">
                  {this.translate('Cadastro de Lead')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="leadRegistration"
                  />
                  <ReactTooltip
                    id="leadRegistration"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>{this.translate('lead-registration-text1')}</span>
                  </ReactTooltip>
                  {/* <ReactTooltip id="leadRegistration" type="dark" effect="solid" multiline>
								<span>
									{this.translate("Pontuação dada ao player quando algum lead informa seus")}
									<br />
									{this.translate("dados no cadastro vindo pelo link de compartilhamento")}
								</span>
							</ReactTooltip> */}
                </label>
                <input
                  required
                  type="number"
                  className="form-control profile-placeholder"
                  name="leadRegistration"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.leadRegistration}
                  placeholder="Cadastro de Lead"
                />
              </div>
            </>
          )}

          {(formData.conversionType === 'direct' ||
            formData.conversionType === 'form' ||
            formData.conversionType === 'autoindicate') && (
            <>
              <div className="form-group col-md-4 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Conversão de Vendas')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="salesConversion"
                  />
                  <ReactTooltip
                    id="salesConversion"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Pontuação dada ao player quando algum lead é convertido'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required
                  type="number"
                  className="form-control profile-placeholder"
                  name="salesConversion"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.salesConversion}
                  placeholder={this.translate('Conversão de Vendas')}
                />
              </div>
            </>
          )}

          {formData.conversionType === 'form' &&
            this.context.clientData &&
            this.context.clientData.redirectWhatsapp &&
            this.context.clientData.redirectWhatsapp === 'true' && (
              <div className="form-group col-md-6">
                <div className="profile-placeholder">
                  <label
                    className="profile-label"
                    htmlFor="contactPlayerWhatsapp"
                  >
                    {this.translate(
                      'Habilitar o WhatsApp para o lead entrar em contato direto com o vendedor?'
                    )}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="contactPlayerWhatsapp"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={
                        this.state.formData.contactPlayerWhatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="contactPlayerWhatsapp"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={
                        this.state.formData.contactPlayerWhatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            )}

          {formData.conversionType === 'membergetmember' &&
            this.renderMemberGetMemberFields(formData)}
        </div>
        <div className="col-md-12 row points-by-share p-0-right">
          <div className="form-group col-md-6 p-0-right">
            <div className="profile-placeholder">
              <label className="profile-label" htmlFor="comment">
                {this.translate('Pontuar pelo primeiro compartilhamento?')}
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value
                  name="playerSharePoints"
                  onChange={(e) => this.handleUserInput(e)}
                  checked={formData.playerSharePoints === 'true'}
                />
                &nbsp;{this.translate('yes')}
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value={false}
                  name="playerSharePoints"
                  onChange={(e) => this.handleUserInput(e)}
                  checked={formData.playerSharePoints !== 'true'}
                />
                &nbsp;{this.translate('no')}
              </label>
            </div>
          </div>

          {formData.playerSharePoints === 'true' && (
            <>
              <div className="form-group col-md-6 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Pontuação pelo primeiro compartilhamento')}{' '}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="pointsByShare"
                  />
                  <ReactTooltip
                    id="pointsByShare"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>
                      {this.translate(
                        'Esses pontos são importantes pois estimulam o engajamento dos usuários'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required={formData.playerSharePoints}
                  type="number"
                  className="form-control profile-placeholder"
                  name="pointsByShare"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.pointsByShare}
                  placeholder={this.translate(
                    'Pontuação pelo primeiro compartilhamento'
                  )}
                />
              </div>
            </>
          )}
        </div>

        {clientData.isGamified === 'true' && (
          <div className="col-md-12">
            <div className="row">
              <div className="col-xs-12 row">
                <div className="form-group col-md-4">
                  <div className="profile-placeholder">
                    <label className="profile-label" htmlFor="comment">
                      {this.translate('has-awards')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="hasAwards"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={formData.hasAwards === 'true'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="hasAwards"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={formData.hasAwards !== 'true'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('awardsModalIsOpen')}
            >
              {this.translate('awards')}
            </button>
          </div>
        )}
        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq-black w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('extraPointsModalIsOpen')}
            >
              {this.translate('extra-points')}
            </button>
          </div>
        )}
      </div>
    );
  }

  renderGoalLevelsFields() {
    return (
      <div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectText">
            {this.translate('Call to Action')} <code>*</code> &nbsp;
            <span style={{ fontSize: '10px', display: 'contents' }}>
              (Até 38 caracteres)
            </span>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="callToAction"
            />
            <ReactTooltip
              id="callToAction"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('call-to-action2-text1')}</span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectText"
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.btnDirectText}
            placeholder="Call to action"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectRedirectLink">
            {this.translate('Call to action (Link de redirecionamento)')}{' '}
            <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="btnDirectRedirectLink"
            />
            <ReactTooltip
              id="btnDirectRedirectLink"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Link que será vinculado ao botão configurado anteriormente. Ao'
                )}
                <br />
                {this.translate(
                  'clicar o lead será redirecionado para o link informado nesse'
                )}
                <br />
                {this.translate('campo.')}
                <br />
              </span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectRedirectLink"
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.btnDirectRedirectLink}
            placeholder="Link de redirecionamento"
          />
        </div>
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible, publicationSocialMedia } = this.state.formData;
    return (
      <div className="container-fluid ">
        {this.renderPhase1()}
        {this.renderPhase2()}
        {publicationSocialMedia &&
          publicationSocialMedia === 'true' &&
          this.renderSocialMedia()}
        {this.renderPhase3()}
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        key={img}
        src={img}
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    if (e.length === 0) {
      this.handleFormDataChange('selectedStates', []);
      this.handleFormDataChange('selectedCities', []);
      this.setState({ cityList: [] });
    } else {
      this.handleFormDataChange('selectedStates', e);

      this.setState({ cityList: [] }, () => this.getCityList(e));
    }
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderSelectActionType() {
    const { formData } = this.state;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="actionType">
          {this.translate('Tipo de Publicação')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="actionType"
          onChange={(e) => this.handleScopeChange(e)}
          value={formData.actionType}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="normal">Normal</option>
          <option value="combo">Combo</option>
          <option value="recurrent">Recorrente</option>
        </select>
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  handleExtraFieldInputSelectOptions(e, options) {
    const index = Number(e.target.getAttribute('data-index'));
    options[index].name = e.target.value;
    this.setState({ extraFieldInputSelectOptions: options });
  }

  removeOptionsFromExtraInputsFields(e, options) {
    const index = Number(e.currentTarget.getAttribute('data-index-remove'));
    options.splice(index, 1);
    this.setState({ extraFieldInputSelectOptions: options });
  }

  toggleModalExtraFieldsList() {
    this.setState({
      isOpenModalExtraFieldsList: !this.state.isOpenModalExtraFieldsList,
    });
  }

  modalExtraFieldsList() {
    return (
      <Modal
        open={this.state.isOpenModalExtraFieldsList}
        onClose={this.toggleModalExtraFieldsList.bind(this)}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <div className="oq-wrapper-contents">
          <h1 className="title-alert title-normal">
            Listagem de campos extras
          </h1>
          <p className="content-alert">
            Confira abaixo todos campos extras ativos nessa publicação.
            <br />
            Caso queria remover algum campo, clique no icone de <b>lixeira</b>.
          </p>
          <br />
          <div className="oq-input-field">
            {this.state.formData.extraFieldsInputs &&
              this.state.formData.extraFieldsInputs.map((input, index) => (
                <>
                  <div
                    className="oq-group-horizontal"
                    style={{ marginBottom: '10px' }}
                  >
                    <input
                      type="text"
                      placeholder={input.name}
                      value={input.name}
                      disabled
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeOptionsFromExtraInputsFields(
                          e,
                          this.state.formData.extraFieldsInputs
                        );
                      }}
                      data-index-remove={index}
                      className="btn-action-extra"
                    >
                      <i
                        className="material-icons-outlined"
                        style={{ marginRight: '0' }}
                      >
                        delete
                      </i>
                    </button>
                  </div>
                </>
              ))}
            {this.state.formData.extraFieldsInputs &&
              this.state.formData.extraFieldsInputs.length === 0 && (
                <>
                  <p className="content-alert">Nenhum campo extra criado.</p>
                </>
              )}
          </div>
        </div>
      </Modal>
    );
  }

  handleExtraFieldNameInput(e) {
    this.setState({ extraFieldNameInput: e.target.value });
  }

  toggleModalExtraFields() {
    this.setState({
      isOpenModalExtraFields: !this.state.isOpenModalExtraFields,
    });
  }

  modalExtraFields() {
    return (
      <Modal
        open={this.state.isOpenModalExtraFields}
        onClose={this.toggleModalExtraFields.bind(this)}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <div className="oq-wrapper-contents">
          <h1 className="title-alert title-normal">Novo campo extra</h1>
          <p className="content-alert">
            Para adicionar um novo item de campo extra defina as opções abaixo.
            <br />
            Preencha todos os campos corretamente.
          </p>
          <br />
          <div className="oq-input-field">
            <label htmlFor="extraInputName">Nome do campo extra</label>
            <input
              type="text"
              id="extraInputName"
              placeholder={this.state.extraFieldNameInput}
              required
              onChange={(e) => this.handleExtraFieldNameInput(e)}
              value={this.state.extraFieldNameInput}
            />
          </div>
          <div className="oq-input-field">
            <label htmlFor="extraInputName">Tipo de campo extra</label>
            <div>
              {this.state.extraFieldOptions.map((field) => {
                return (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        activeExtraInputType: field.dataActive,
                        extraInputTypeSelected: field.type,
                      });
                    }}
                    className={
                      this.state.activeExtraInputType === field.dataActive
                        ? 'btn-extra-type active'
                        : 'btn-extra-type'
                    }
                    key={field.text}
                  >
                    {field.text}
                  </button>
                );
              })}
            </div>
          </div>
          {this.state.activeExtraInputType === '4' && (
            <div className="oq-input-field">
              <label htmlFor="extraInputName">Opções da seleção</label>
              {this.state.extraFieldInputSelectOptions.map((option, index) => (
                <>
                  <div
                    className="oq-group-horizontal"
                    style={{ marginBottom: '10px' }}
                  >
                    <input
                      type="text"
                      placeholder={option.name}
                      value={option.name}
                      onChange={(e) => {
                        this.handleExtraFieldInputSelectOptions(
                          e,
                          this.state.extraFieldInputSelectOptions
                        );
                      }}
                      data-index={index}
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeOptionsFromExtraInputsFields(
                          e,
                          this.state.extraFieldInputSelectOptions
                        );
                      }}
                      data-index-remove={index}
                      className="btn-action-extra"
                    >
                      <i
                        className="material-icons-outlined"
                        style={{ marginRight: '0' }}
                      >
                        delete
                      </i>
                    </button>
                  </div>
                </>
              ))}
              <br />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.addOptionsFromExtraInputsFields();
                }}
                className="btn-action-extra"
              >
                <i className="material-icons-outlined">add</i>
                Adicionar opção
              </button>
            </div>
          )}
        </div>
        <div className="oq-group-horizontal">
          <button
            className="oq-btn btn-confirm"
            onClick={(e) => this.addExtraFields(e)}
          >
            Adicionar campo extra
          </button>
        </div>
      </Modal>
    );
  }

  addOptionsFromExtraInputsFields() {
    this.setState({
      extraFieldInputSelectOptions: [
        ...this.state.extraFieldInputSelectOptions,
        {
          name: `Nova opção ${
            this.state.extraFieldInputSelectOptions.length + 1
          }`,
        },
      ],
    });
  }

  addExtraFields(e) {
    e.preventDefault();
    const indexType = Number(this.state.activeExtraInputType) - 1;
    const extraFieldsInputs =
      this.state.formData.extraFieldsInputs &&
      this.state.formData.extraFieldsInputs.length > 0
        ? this.state.formData.extraFieldsInputs
        : [];

    extraFieldsInputs.push({
      name: this.state.extraFieldNameInput,
      type: this.state.extraFieldOptions[indexType].dataActive,
      inputOptions:
        this.state.activeExtraInputType === '4'
          ? this.state.extraFieldInputSelectOptions
          : [],
    });
    this.setState(
      {
        formData: {
          ...this.state.formData,
          extraFieldsInputs,
        },
      },
      () => {
        this.toggleModalExtraFields();
      }
    );
  }

  renderConversionTypeForm() {
    const { conversionType } = this.state.formData;
    let html;

    if (conversionType === 'direct') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to Action')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction"
              />
              <ReactTooltip
                id="callToAction"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('call-to-action2-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 1"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 1 (Link de redirecionamento)')}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectRedirectLink"
              />
              <ReactTooltip
                id="btnDirectRedirectLink"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Link que será vinculado ao botão configurado anteriormente. Ao'
                  )}
                  <br />
                  {this.translate(
                    'clicar o lead será redirecionado para o link informado nesse'
                  )}
                  <br />
                  {this.translate('campo.')}
                  <br />
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'none') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Link de redirecionamento')}
              <code>*</code>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="redirectDashCallToAction"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.redirectDashCallToAction}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'form') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnFormText">
              {this.translate('Call to action 1 (Texto do botão)')}{' '}
              <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction"
              />
              <ReactTooltip
                id="callToAction"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnFormText"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.btnFormText}
              placeholder="Call to action 1 texto do botão"
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.extraFieldName}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName
                ? this.state.formData.extraFieldName
                : 'Campo extra'}
              <i
                className="fa fa-info-circle ml-5"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
            </label>

            <div className="group-buttons-horizontal">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleModalExtraFields();
                }}
                className="btn-action-extra"
              >
                <i className="material-icons-outlined">add</i>
                Adicionar campos extras
              </button>

              {this.state.formData.extraFieldsInputs &&
                this.state.formData.extraFieldsInputs.length > 0 && (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModalExtraFieldsList();
                      }}
                      className="btn-action-extra"
                    >
                      <i className="material-icons-outlined">view_list</i>
                      Ver campos extras
                    </button>
                  </>
                )}
            </div>

            {this.modalExtraFields()}
            {this.modalExtraFieldsList()}
          </div>
        </div>
      );
    } else if (conversionType === 'both') {
      html = (
        <div>
          <div className="col-md-12 row p-0-right">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="btnFormText">
                {this.translate('Call to action 1 (Texto do botão)')}{' '}
                <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>{this.translate('call-to-action-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required
                type="text"
                className="form-control profile-placeholder"
                name="btnFormText"
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.formData.btnFormText}
                placeholder="Call to action 1 texto do botão"
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to action 2 (Texto do botão)')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction2"
              />
              <ReactTooltip
                id="callToAction2"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('call-to-action2-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 2 texto do botão"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 2 (Link de redirecionamento) ')}
              <code>*</code>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Call to action 2 (Link de redirecionamento)"
            />
          </div>
          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_campo_extra.xlsx?alt=media&token=d3e1f13b-a565-4b6c-9e1b-67b8291cd5d3"
                style={{
                  marginLeft: '1rem',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de campo extra
              </a>
            </label>
            <input
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
              disabled={this.state.isUploading}
              required={false}
              onChange={(e) =>
                this.fileChanged(e.target.files, 'extraFieldSheet1')
              }
            />
          </div>
        </div>
      );
    }

    return html;
  }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season
    );
  }

  phase2IsUncompleted() {
    const { formData } = this.state;

    return !formData.description || !formData.rulesOfAction;
  }

  socialMediaIsUncompleted() {
    const { formData } = this.state;

    if (formData.publicationSocialMedia === 'true') {
      const result =
        !formData.imageSocialMedia.image ||
        formData.imageSocialMedia.image === '';

      return result;
    }
  }

  phase3IsUncompleted() {
    const { formData } = this.state;
    const { clientData } = this.context;

    if (clientData.gamificationType === 'goalsLevels') {
      return !formData.btnDirectText || !formData.btnDirectRedirectLink;
    }
    return (
      (!formData.conversionType && formData.shareButtonsVisible === true) ||
      (formData.conversionType === 'direct' &&
        (!formData.btnDirectText || !formData.btnDirectRedirectLink)) ||
      (formData.conversionType === 'form' && !formData.btnFormText) ||
      (formData.conversionType === 'both' &&
        (!formData.btnDirectText ||
          !formData.btnFormText ||
          !formData.btnDirectRedirectLink)) ||
      (formData.conversionType === 'membergetmember' &&
        (!formData.membergetmember || !formData.membergetmemberguest)) ||
      // !formData.landingPagePreviewPoint ||
      !formData.leadRegistration ||
      !formData.salesConversion
    );
  }

  validateLengthCallToAction(callToAction, btnDirectText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    if (btnDirectText && btnDirectText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error('Call to Action1: Limite de 38 caracteres.', {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle, actionSubtitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }

    if (actionSubtitle && actionSubtitle.length > 140) {
      toast.warn(
        'Mensagem de engajamento: Você ultrapassou o limite de 140 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (
      !this.validateLengthCallToAction(
        formData.callToAction,
        formData.btnDirectText
      )
    ) {
      return;
    }

    this.warningLengthTitleAndSubtitle(
      formData.actionTitle,
      formData.actionSubtitle
    );

    // if (this.validateScope())
    this.createAction();
  }

  // validateScope() {
  // 	const { scope, scopeValues } = this.state.formData;
  // 	if (scope === "regional" && (!scopeValues || !scopeValues.length)) {
  // 		this.handleFormDataChange("scopeValue", null);
  // 		this.setState({ regionalIsEmpty: true });
  // 		return false;
  // 	}

  // 	return true;
  // }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              <div className="wa-wrapper">
                <div className="wa-topbar">
                  <div className="content-left">
                    <button
                      className="wa-back-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}
                    >
                      <i>
                        <MdArrowBack />
                      </i>
                    </button>
                    <h1 className="wa-title">Nova publicação</h1>
                  </div>
                  <div className="content-right">
                    <button
                      className="wa-btn"
                      style={{
                        backgroundColor: this.context.clientData.primaryColor,
                      }}
                      disabled={
                        !this.state.formValid ||
                        this.state.creatingAction ||
                        this.state.isUploading ||
                        this.state.errorForm1 ||
                        this.state.errorTime1
                      }
                      onClick={() => {
                        this.setState({
                          formButtonPressed: true,
                          phase1Uncompleted: this.phase1IsUncompleted(),
                          phase2Uncompleted: this.phase2IsUncompleted(),
                          socialMediaUncompleted:
                            this.socialMediaIsUncompleted(),
                          phase3Uncompleted: this.phase3IsUncompleted(),
                        });
                      }}
                    >
                      <i>
                        <MdDone />
                      </i>
                      Salvar alterações
                    </button>
                  </div>
                </div>
                <div className="wa-tabs">
                  <ul>
                    <li
                      className={`wa-tab-item ${
                        this.state.tabIndex === 0 && 'active'
                      }`}
                      onClick={() => {
                        this.setState({ tabIndex: 0 });
                      }}
                    >
                      Campanha
                    </li>
                    <li
                      className={`wa-tab-item ${
                        this.state.tabIndex === 1 && 'active'
                      }`}
                      onClick={() => {
                        this.setState({ tabIndex: 1 });
                      }}
                    >
                      Integrações de Marketing
                    </li>
                    <li
                      className={`wa-tab-item ${
                        this.state.tabIndex === 2 && 'active'
                      }`}
                      onClick={() => {
                        this.setState({ tabIndex: 2 });
                      }}
                    >
                      Widgets
                    </li>
                  </ul>
                </div>
              </div>
              <div className="wa-content-tab">
                <div className="container">
                  {this.state.tabIndex === 0 && this.renderForm()}
                  {this.state.tabIndex === 1 && (
                    <Integrations
                      selected={this.state.integrationIndexSelected}
                      value={
                        (this.state.formData.isIntegrationPixel &&
                          this.state.formData.integrationPixelCode) ||
                        (this.state.formData.isIntegrationGoogleAnalytics &&
                          this.state.formData.integrationGoogleAnalyticsCode) ||
                        (this.state.formData.isIntegrationGoogleTagManager &&
                          this.state.formData.integrationGoogleTagManagerCode)
                      }
                      valueSecondary={
                        this.state.formData.isIntegrationGoogleTagManager &&
                        this.state.formData.integrationGoogleTagManagerBodyCode
                      }
                      handleIntegration={(integration) => {
                        // INTEGRAÇÃO COM PIXEL
                        if (integration === 0) {
                          this.setState({ integrationIndexSelected: 0 });
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: true,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: false,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: '',
                            },
                          }));
                        }

                        // INTEGRAÇÃO COM ANALYTICS
                        if (integration === 1) {
                          this.setState({ integrationIndexSelected: 1 });
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: true,
                              isIntegrationGoogleTagManager: false,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: '',
                            },
                          }));
                        }

                        // INTEGRAÇÃO COM GOOGLE TAGS
                        if (integration === 2) {
                          this.setState({ integrationIndexSelected: 2 });
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: true,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: '',
                            },
                          }));
                        }

                        // NENHUMA INTEGRAÇÃO
                        if (!integration && integration !== 0) {
                          console.log(
                            'this.state.formData: ',
                            this.state.formData
                          );
                          this.setState({ integrationIndexSelected: 3 });
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: false,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: '',
                              integrationGoogleTagManagerBodyCode: '',
                            },
                          }));
                        }
                      }}
                      onChange={(e) => {
                        // CODIGO INTEGRAÇÃO PIXEL
                        if (this.state.formData.isIntegrationPixel) {
                          let code = e.target.value;
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: true,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: false,
                              integrationPixelCode: code,
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: '',
                            },
                          }));
                        }

                        // CODIGO INTEGRAÇÃO ANALYTICS
                        if (this.state.formData.isIntegrationGoogleAnalytics) {
                          let code = e.target.value;
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: true,
                              isIntegrationGoogleTagManager: false,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: code,
                              integrationGoogleTagManagerCode: '',
                            },
                          }));
                        }

                        // CODIGO INTEGRAÇÃO GOOGLE TAGS
                        if (this.state.formData.isIntegrationGoogleTagManager) {
                          let code = e.target.value;
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: true,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerCode: code,
                            },
                          }));
                        }
                      }}
                      onChangeSecondary={(e) => {
                        // CODIGO INTEGRAÇÃO GOOGLE TAGS
                        if (this.state.formData.isIntegrationGoogleTagManager) {
                          let code = e.target.value;
                          this.setState((prevState) => ({
                            formData: {
                              ...prevState.formData,
                              isIntegrationPixel: false,
                              isIntegrationGoogleAnalytics: false,
                              isIntegrationGoogleTagManager: true,
                              integrationPixelCode: '',
                              integrationGoogleAnalyticsCode: '',
                              integrationGoogleTagManagerBodyCode: code,
                            },
                          }));
                        }
                      }}
                    />
                  )}
                  {this.state.tabIndex === 2 && (
                    <Widget
                      client={this.context.clientData}
                      action={this.state.formData}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={(awardsList) =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={(extraPointsList) =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

export default CreateActionVirtual;
