import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'underscore';
import { api, getAPIURL } from '../../../Config';
import { ImageDrop } from 'quill-image-drop-module';
import { ImageResize } from 'quill-image-resize-module';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as IconDelete } from '../../../Assets/Images/delete.svg';
import { Notify } from '../../../components/Notify';
import { auth } from '../../../firebase';
import * as routes from '../../../routes';
import { normalizeLowerCase } from '../../../utils/normalizeLowerCase';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class EmailTemplates extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: null,
      templates: {
        welcome: [],
      },
      isUploading: false,
      progress: 0,
      updating: false,
      authClient: auth.getAuthUser(),
      aqui: [],
    };

    this.translate = props.t;
  }

  componentDidMount() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;
    if (clientData) {
      this.setState({
        clientData,
      });
      // this.setState({
      //   loadingRegionalList: false,
      //   regionalList: clientData.regional,
      // });
    }

    this.getTemplates();
  }

  toggleSelectedTemplate(template) {
    if (template === this.state.selectedTemplate) template = null;
    this.setState({ selectedTemplate: template });
  }

  handleTemplateFormChange(e, template, index) {
    const { name, value } = e.target;
    this.handleTemplateDataChange(name, value, template, index);
  }

  // handleTemplateFormChangeEditor(name, value, template, index) {
  //   this.handleTemplateDataChange(name, value, template, index);
  // }

  handleTemplateDataChange(
    name,
    value,
    template,
    index,
    newName = 'showBody',
    showBody = null
  ) {
    const { welcome } = this.state.templates;

    welcome.map((wel, ind) => {
      if (ind === index) {
        wel[name] = value;
        wel[newName] = showBody ? showBody : wel[newName];
      }
    });

    this.setState((prevState) => ({
      templates: {
        ...prevState.templates,
        [template]: welcome,
      },
    }));
  }

  async handleUserImage(e, ref, fileIndex, template, index) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        template,
        index
      );
    } else {
      this.setState({ isUploading: false });
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref, template, index) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleTemplateDataChange(fileIndex, data.data, template, index);
    this.setState({ isUploading: false });
  }

  handleFormSubmit(e, template) {
    e.preventDefault();
    this.updateTemplate(template);
  }

  getTemplates() {
    this.setState({ updating: true }, async () => {
      const parameters = {
        searchFunctionality: 'getTemplates',
        userType: 'client',
      };

      await api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          Notify(result.message, result.success ? 'success' : 'error');
          this.setState({ updating: false });
          if (result.success) {
            this.setState({
              templates: { welcome: result.data.welcome },
            });
          }
        })
        .catch((error) => {
          this.setState({ updating: false });
        });
    });
  }

  updateTemplate(template) {
    this.setState({ updating: true }, () => {
      const { templates } = this.state;
      const templateData = templates[template];
      const parameters = {
        updateFunctionality: 'updateTemplate',
        userType: 'client',
        template,
        templateData,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          const result = res.data;
          Notify(result.message, result.success ? 'success' : 'error');
          this.setState({ updating: false });
        })
        .catch((error) => {
          this.setState({ updating: false });
        });
    });
  }

  getTemplateData(template, attr, index) {
    const { templates } = this.state;
    const currentTemplate = templates[template][index];
    if (currentTemplate && currentTemplate.body) return currentTemplate[attr];
    return '';
  }
  renderImagePreview(img) {
    return (
      <img
        style={{ marginLeft: '20px' }}
        src={img}
        className="img-thumbnail img-responsive form-group"
        alt="action banner"
      />
    );
  }

  async handleUploadSuccessQuill(file, ref) {
    return new Promise((resolve) => {
      const fileFormData = new FormData();
      fileFormData.append('userType', 'client');
      fileFormData.append('uploadFunctionality', 'uploadImage');
      fileFormData.append('ref', ref);
      fileFormData.append('file', file);
      fileFormData.set('Content-Type', 'multipart/form-data');

      const requestOptions = {
        method: 'POST',
        body: fileFormData,
      };

      const response = fetch(`${getAPIURL()}/upload`, requestOptions);
      const data = response.json();

      const dataFile = new FormData();
      dataFile.append('file', file);

      resolve(data.data);

      return dataFile;
    });
  }

  async handleImageQuill(file) {
    let name = file.name;
    let size = file.size;
    let fileImage = file;
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      return await this.handleUploadSuccessQuill(
        fileImage,
        randomizeName,
        'emailImage',
        'TermsClientImages/'
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return {
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      };
    }
  }

  imageHandler = () => {
    this.quillEditor = this.quillRef.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      try {
        const file = input.files[0];

        const link = await this.handleImageQuill(file);
        const range = this.quillEditor.getSelection();

        this.quillEditor.insertEmbed(range.index, 'image', link);
      } catch (error) {
        console.log('erro: ', error);
      }
    };
  };

  handleScopeChange(e, template, index) {
    const { name, value } = e.target;
    this.handleTemplateDataChange(name, value, template, index);
  }

  renderScopeField(welcome, index) {
    const { clientData, templates } = this.state;

    if (clientData) {
      return (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scope">
            {this.translate('comprehensiveness')}
          </label>
          <select
            className="form-control profile-placeholder"
            name="scope"
            onChange={(e) => this.handleScopeChange(e, 'welcome', index)}
            value={welcome.scope}
            required
          >
            <option value="">{this.translate('Selecione...')}</option>
            <option value="national">{this.translate('Nacional')}</option>
            {clientData.regional && clientData.regional.length > 0 && (
              <option value="regional">Segmentada</option>
            )}
          </select>
        </div>
      );
    }
  }

  renderScopeForm(welcome, index) {
    const { clientData, templates } = this.state;
    // const { scope } = this.state.formData;
    // const {
    //   stateList,
    //   regionalList,
    //   loadingRegionalList,
    //   loadingStateList,
    //   loadingPlayerList,
    //   playersList,
    // } = this.state;
    let html;
    if (clientData) {
      switch (welcome.scope) {
        case 'regional':
          html = (
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="scopeValues">
                {clientData && clientData.scopeFieldName
                  ? clientData.scopeFieldName
                  : this.translate('Unidade')}
                {/* {this.translate('Setor')} */}
              </label>
              <Select
                isMulti
                name="scopeValues"
                placeholder={this.translate('Selecione um setor...')}
                options={_.map(clientData.regional, (regional) => {
                  return {
                    value: normalizeLowerCase(regional),
                    label: regional,
                  };
                })}
                className="basic-multi-select"
                value={welcome.scopeValues}
                classNamePrefix="select"
                onChange={(e) =>
                  this.handleTemplateDataChange(
                    'scopeValues',
                    e,
                    'welcome',
                    index
                  )
                }
              />
            </div>
          );
          break;

        // case 'state':
        //   html = (
        //     <div className="form-group col-md-12">
        //       <label className="profile-label" htmlFor="scopeValue">
        //         {this.translate('state')}
        //       </label>
        //       <Select
        //         name="scopeValue"
        //         placeholder={this.translate('Selecione um estado...')}
        //         isDisabled={loadingStateList}
        //         options={_.map(stateList, state => {
        //           return {
        //             value: state.id,
        //             label: state.sigla,
        //           };
        //         })}
        //         isLoading={loadingStateList}
        //         className="basic-multi-select"
        //         defaultValue={this.state.formData.scopeValue}
        //         onChange={e => {
        //           this.handleUserState('state', e);
        //           this.getCityList(e.value);
        //         }}
        //         classNamePrefix="select"
        //       />
        //     </div>
        //   );
        //   break;

        // case 'players':
        //   html = (
        //     <div className="form-group col-md-12">
        //       <label className="profile-label" htmlFor="players">
        //         Players
        //       </label>
        //       <Select
        //         isMulti
        //         name="players"
        //         placeholder="Selecione os players..."
        //         isDisabled={loadingPlayerList}
        //         options={_.map(playersList, player => {
        //           return {
        //             value: player.uid,
        //             label: player.name,
        //           };
        //         })}
        //         isLoading={loadingPlayerList}
        //         className="basic-multi-select"
        //         defaultValue={this.state.formData.playerSelected}
        //         onChange={e => this.handleFormDataChange('scopePlayers', e)}
        //         classNamePrefix="select"
        //       />
        //     </div>
        //   );
        //   break;

        // case 'national':
        //   html = (
        //     <div className="form-group col-md-12">
        //       <label className="profile-label" htmlFor="scopeValue">
        //         {this.translate('national')}
        //       </label>
        //       <input
        //         disabled
        //         value="BR"
        //         className="form-control profile-placeholder"
        //       />
        //     </div>
        //   );
        //   break;

        default:
          break;
      }

      return html;
    }
  }

  handleDeleteTeplateWelcome(index) {
    const { welcome } = this.state.templates;

    const indexWelcome = welcome.findIndex((wel, id) => {
      return id === index;
    });
    welcome.splice(indexWelcome, 1);

    this.setState((prevState) => ({
      templates: {
        ...prevState.templates,
        welcome: welcome,
      },
    }));
  }

  handleDeleteImageTemplateWelcome(index) {
    const { welcome } = this.state.templates;
    const findWelcome = welcome[index];
    findWelcome.imgURL = '';

    this.setState((prevState) => ({
      templates: {
        ...prevState.templates,
        welcome: welcome,
      },
    }));
  }

  renderTemplatesCard() {
    // const {welcome} = this.state.templates

    const { selectedTemplate, isUploading, templates, authClient, updating } =
      this.state;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'align',
      'link',
      'image',
    ];

    return _.map(templates.welcome, (welcome, index) => (
      <div className="oq-card col-md-12">
        <div
          className="row clickable"
          // data-toggle="collapse"
          // data-target={`#demo${index}`}
          // onClick={() => this.toggleSelectedTemplate('welcome')}
        >
          <IconDelete
            onClick={() => {
              this.handleDeleteTeplateWelcome(index);
            }}
          />
          <div
            className="col-md-10 col-xs-8"
            data-toggle="collapse"
            data-target={`#demo${index}`}
          >
            <span className="notification col-md-12 vertical-center">
              <span className="oq-card-span oq-font-medium h4-oq">
                {this.translate('Boas vindas')}
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div
            className="col-md-1 hidden-xs vertical-center"
            data-toggle="collapse"
            data-target={`#demo${index}`}
          >
            <i
              className={`fa ${
                selectedTemplate === 'welcome'
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              } fa-2x`}
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="col-xs-12">
          <div id={`demo${index}`} className="collapse">
            <hr />
            <div className="row-fluid">
              <form
              // onSubmit={e => this.handleFormSubmit(e, 'welcome')}
              >
                {this.renderScopeField(welcome, index)}
                {this.renderScopeForm(welcome, index)}
                <div className="form-group col-md-12">
                  <label className="profile-label">
                    {this.translate('Assunto')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    type="text"
                    required
                    name="title"
                    placeholder={this.translate('Título do e-mail')}
                    value={welcome.title}
                    onChange={(e) =>
                      this.handleTemplateFormChange(e, 'welcome', index)
                    }
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="profile-label">
                    {this.translate('Texto')}
                  </label>

                  <ReactQuill
                    ref={(ref) => (this.quillRef = ref)}
                    modules={{
                      imageDrop: true,
                      imageResize: {
                        displaySize: true,
                      },
                      toolbar: {
                        container,
                        handlers: {
                          image: this.imageHandler,
                        },
                      },
                    }}
                    formats={format}
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      const body = editor.getText();
                      const showBody = editor.getHTML();
                      this.handleTemplateDataChange(
                        'body',
                        body,
                        'welcome',
                        index,
                        'showBody',
                        showBody
                      );
                    }}
                    value={this.getTemplateData('welcome', 'showBody', index)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label">
                    {this.translate('image')}
                    {welcome.imgURL
                      ? `(${this.translate('Enviada')})`
                      : isUploading
                        ? `(${this.translate('Enviando...')})`
                        : ''}
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {welcome.imgURL && (
                      <div className="row clickable">
                        <IconDelete
                          onClick={() =>
                            this.handleDeleteImageTemplateWelcome(index)
                          }
                        />
                      </div>
                    )}
                    {welcome.imgURL
                      ? this.renderImagePreview(
                          welcome.imgURL ? welcome.imgURL : ''
                        )
                      : ''}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    id="imgURL"
                    onChange={async (event) => {
                      await this.handleUserImage(
                        event,
                        `EmailTemplates/${authClient.uid}/welcome/`,
                        'imgURL',
                        'welcome',
                        index
                      );
                    }}
                    // value={this.getTemplateData('welcome', 'imgURL')}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label">
                    {this.translate('Assinatura')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    type="text"
                    required
                    name="signature"
                    placeholder={this.translate('Assinatura do e-mail')}
                    value={welcome.signature}
                    onChange={(e) =>
                      this.handleTemplateFormChange(e, 'welcome', index)
                    }
                  />
                </div>
                {/* <div className="form-group">
                  <button
                    className="btn btn-oq btn-oq-lg-create-template float-right"
                    disabled={isUploading || updating}
                    type="submit"
                  >
                    {this.translate('Salvar Template')}
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  renderBtnSubmit() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        className="form-group"
      >
        <button
          className="btn btn-oq btn-oq-lg-create-template float-right"
          disabled={this.state.isUploading || this.state.updating}
          type="button"
          onClick={(e) => this.handleFormSubmit(e, 'welcome')}
        >
          {this.translate('Salvar Template')}
        </button>
      </div>
    );
  }

  renderBtnAddTemplate() {
    return (
      <button
        className="btn btn-oq pull-left"
        id="btn-add"
        onClick={() => this.addTemplate()}
      >
        <i className="fa fa-plus icon-small" /> Adicionar
      </button>
    );
  }

  addTemplate() {
    let { welcome } = this.state.templates;
    let templateWelcome = [...welcome];

    const bodyTemplate = {
      body: '',
      imgURL: '',
      signature: '',
      title: '',
    };

    templateWelcome.push(bodyTemplate);

    this.setState((prevState) => ({
      templates: {
        ...prevState.templates,
        welcome: templateWelcome,
      },
    }));
  }

  render() {
    console.log('this.state.templates: ', this.state.templates);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <h1 className="oq-filter-title">
                <span>&nbsp; {this.translate('E-mail de boas-vindas')}</span>
              </h1>
              {this.renderBtnAddTemplate()}
              <div className="row">
                <div className="col-md-12">{this.renderTemplatesCard()}</div>
                <div className="col-md-12"> {this.renderBtnSubmit()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <ToastContainer />
      //   <div id="content">
      //     <NavBar />
      //     <div className="container-fluid">
      //       <h1 className="oq-filter-title">
      //         <span>&nbsp; {this.translate('E-mail de boas-vindas')}</span>
      //       </h1>
      //       <div className="row">
      //         <div className="col-md-12">{this.renderTemplatesCard()}</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest(resolve, reject);
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  async _initRequest(resolve, reject) {
    const loader = this.loader;
    let name = null;
    let size = null;
    let file = null;
    await loader.file.then((result) => {
      size = result.size;
      name = result.name;
      file = result;
    });
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      // this.setState(prevState => ({
      //   formData: {
      //     ...prevState.formData,
      //     fileIndex: name,
      //   },
      // }));

      return this.handleUploadSuccess(
        file,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
        resolve,
        reject
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return reject({
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      });
      // this.setState({isUploading: false})
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref, resolve, reject) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    const dataFile = new FormData();
    dataFile.append('file', file);
    resolve({
      default: data.data,
    });
    return dataFile;
  }
}

const EmailTemplateLink = (props) => (
  <Link
    className="menu-link collapsed list-unstyled"
    to={routes.EMAIL_TEMPLATES}
  >
    <FontAwesomeIcon icon={faEnvelope} />
    &nbsp;
    <span className="sidebarText">{props.translate('emailTemplate')}</span>
  </Link>
);
export default EmailTemplates;

export { EmailTemplateLink };
