import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BlockPicker } from 'react-color';
import { LogoIndicacaoPremiada } from './LogoIndicacaoPremiada';
import { LogoGanheMais } from './LogoGanheMais';
import { LogoIndiqueGanhe } from './LogoIndiqueGanhe';

import imageCompose1 from '../../Images/compose-1.png';
import imageCompose2 from '../../Images/compose-2.png';
import imageCompose3 from '../../Images/compose-3.png';
import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import * as routes from '../../../routes';

import { useUserData } from '../../../hooks/useUserData';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from 'react-tooltip';
import { api, getAPIURL } from '../../../Config';
import CustomFileInput from './CustomFileInput';

import { ReactComponent as P1 } from '../../Images/kv/p1.svg';
import { ReactComponent as P2 } from '../../Images/kv/p2.svg';
import { ReactComponent as P3 } from '../../Images/kv/p3.svg';
import { ReactComponent as P4 } from '../../Images/kv/p4.svg';
import { ReactComponent as P5 } from '../../Images/kv/p5.svg';
import { ReactComponent as P6 } from '../../Images/kv/p6.svg';
import { ReactComponent as P7 } from '../../Images/kv/p7.svg';
import { ReactComponent as P8 } from '../../Images/kv/p8.svg';
import { ReactComponent as P9 } from '../../Images/kv/p9.svg';
import { ReactComponent as P11 } from '../../Images/kv/p11.svg';
import { ReactComponent as P12 } from '../../Images/kv/p12.svg';
import { ReactComponent as P13 } from '../../Images/kv/p13.svg';

export function LandingEditor() {
  const { clientData, userData, userAuth } = useUserData();
  const [pageViewMobie, setPageViewMobie] = useState(false);
  const [template, setTemplate] = useState([]);

  const [primaryColor, setPrimaryColor] = useState('#000');
  const [secondaryColor, setSecondaryColor] = useState('#000');
  const [copyHeader, setCopyHeader] = useState(
    'Seu texto personalizado aqui para a primeira sessão.'
  );
  const [premiumTotal, setPremiumTotal] = useState('1000');
  const [steps, setSteps] = useState();
  const [textColor, setTextColor] = useState();
  const [textCtaColor, setTextCtaColor] = useState();
  const [indexAvatar, setIndexAvatar] = useState(0);
  const [programLogoOption, setProgramLogoOption] = useState(1);
  const [programLogo, setProgramLogo] = useState();
  const [programLogoDesign, setProgramLogoDesign] = useState();
  const [description, setDescription] = useState('sua descrição aqui');
  const [logoMark, setLogoMark] = useState('');
  const [isVisibleKV, setIsVisibleKV] = useState(false);

  const iframeRef = useRef();

  const p1Ref = useRef(null);
  const p2Ref = useRef(null);
  const p3Ref = useRef(null);
  const p4Ref = useRef(null);
  const p5Ref = useRef(null);
  const p6Ref = useRef(null);
  const p7Ref = useRef(null);
  const p8Ref = useRef(null);
  const p9Ref = useRef(null);
  const p11Ref = useRef(null);
  const p12Ref = useRef(null);
  const p13Ref = useRef(null);

  const logoProgram1Ref = useRef(null);
  const logoProgram2Ref = useRef(null);
  const logoProgram3Ref = useRef(null);

  let avatarGroup = [
    {
      headerPersona: 'https://iili.io/J4xpDFV.png',
      stepsPersona: 'https://iili.io/J4z9rhu.png',
      formPersona: 'https://iili.io/J4z94Lb.png',
    },
    {
      headerPersona: 'https://iili.io/J4xpZ6Q.png',
      stepsPersona: 'https://iili.io/J4z9rhu.png',
      formPersona: 'https://iili.io/J4zJ0Nt.png',
    },
    {
      headerPersona: 'https://iili.io/J4xpQMx.png',
      stepsPersona: 'https://iili.io/J4z9rhu.png',
      formPersona: 'https://iili.io/J4zdTeS.png',
    },
  ];

  useEffect(() => {
    getClientSite();
    getClientActual();
  }, []);

  useEffect(() => {
    applyChangesDesign();
  }, [
    primaryColor,
    secondaryColor,
    textColor,
    programLogoDesign,
    programLogo,
    indexAvatar,
  ]);

  useEffect(() => {
    applyChangesDesign();
  }, [isVisibleKV]);

  function applyChangesDesign() {
    // Edição peça 1
    if (p1Ref.current) {
      const rectElement = p1Ref.current.querySelector('g > rect');
      const textElements = p1Ref.current.querySelectorAll('text');
      const textElementsHtml = p1Ref.current.querySelectorAll('p');
      const logo = p1Ref.current.querySelector('g > g:nth-child(10) > image');
      const logoOriginal = p1Ref.current.querySelector(
        'g > g:nth-child(13) > image'
      );
      const persona = p1Ref.current.querySelector('g > g:nth-child(7) > image');

      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      textElementsHtml.forEach(text => {
        text.style.color = textColor;
      });

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      if (persona) {
        persona.setAttribute(
          'xlink:href',
          avatarGroup[indexAvatar].headerPersona
        );
      }
    }
    // Edição peça 1

    // Edição peça 2
    if (p2Ref.current) {
      const rectElement = p2Ref.current.querySelector('g > rect');
      const rectElement2 = p2Ref.current.querySelector(
        'g > g:nth-child(10) > path'
      );
      const rectElement3 = p2Ref.current.querySelector(
        'g > g:nth-child(11) > path'
      );
      const rectElement4 = p2Ref.current.querySelector(
        'g > g:nth-child(12) > path'
      );
      const rectElement5 = p2Ref.current.querySelector(
        'g > g:nth-child(13) > path'
      );

      const textElements = p2Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const icon = p2Ref.current.querySelector(
        'g > g:nth-child(10) > g:nth-child(2) > path:nth-child(2)'
      );

      const icon2 = p2Ref.current.querySelector(
        'g > g:nth-child(11) > g:nth-child(2) > path'
      );

      const icon3 = p2Ref.current.querySelector(
        'g > g:nth-child(12) > g:nth-child(2) > g:nth-child(2) > path'
      );

      const icon4 = p2Ref.current.querySelector(
        'g > g:nth-child(13) > g:nth-child(2) > g:nth-child(2) > path'
      );

      const logoOriginal = p2Ref.current.querySelector(
        'g > g:nth-child(8) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p2Ref.current.querySelector('g > g:nth-child(4) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
      if (rectElement2) {
        rectElement2.style.fill = secondaryColor;
      }
      if (rectElement3) {
        rectElement3.style.fill = secondaryColor;
      }
      if (rectElement4) {
        rectElement4.style.fill = secondaryColor;
      }
      if (rectElement5) {
        rectElement5.style.fill = secondaryColor;
      }
      if (icon) {
        icon.style.fill = textColor;
      }
      if (icon2) {
        icon2.style.fill = textColor;
      }
      if (icon3) {
        icon3.style.fill = textColor;
      }
      if (icon4) {
        icon4.style.fill = textColor;
      }
    }
    // Edição peça 2

    // Edição peça 3
    if (p3Ref.current) {
      const rectElement = p3Ref.current.querySelector('g > rect');
      const textElements = p3Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p3Ref.current.querySelector(
        'g > g:nth-child(12) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p3Ref.current.querySelector('g > g:nth-child(8) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 3

    // Edição peça 4
    if (p4Ref.current) {
      const rectElement = p4Ref.current.querySelector('g > rect');
      const textElements = p4Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p4Ref.current.querySelector(
        'g > g:nth-child(9) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p4Ref.current.querySelector('g > g:nth-child(5) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 4

    // Edição peça 5
    if (p5Ref.current) {
      const rectElement = p5Ref.current.querySelector('g > rect');
      const textElements = p5Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p5Ref.current.querySelector(
        'g > g:nth-child(10) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p5Ref.current.querySelector('g > g:nth-child(6) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 5

    // Edição peça 6
    if (p6Ref.current) {
      const rectElement = p6Ref.current.querySelector('g > rect');
      const textElements = p6Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p6Ref.current.querySelector(
        'g > g:nth-child(16) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p6Ref.current.querySelector('g > g:nth-child(4) > image');
      const logo2 = p6Ref.current.querySelector('g > g:nth-child(12) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }
      if (logo2) {
        logo2.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 6

    // Edição peça 7
    if (p7Ref.current) {
      const rectElement = p7Ref.current.querySelector('g > rect');
      const textElements = p7Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p7Ref.current.querySelector(
        'g > g:nth-child(9) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p7Ref.current.querySelector('g > g:nth-child(5) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 7

    // Edição peça 8
    if (p8Ref.current) {
      const rectElement = p8Ref.current.querySelector('g > rect');
      // const textElements = p8Ref.current.querySelectorAll('text');
      // textElements.forEach(text => {
      //   text.style.fill = textColor;
      // });

      const logoOriginal = p8Ref.current.querySelector(
        'g > g:nth-child(8) > g:nth-child(4) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p8Ref.current.querySelector('g > g:nth-child(4) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 8

    // Edição peça 9
    if (p9Ref.current) {
      const rectElement = p9Ref.current.querySelector('g > rect');
      const textElements = p9Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p9Ref.current.querySelector(
        'g > g:nth-child(5) > g:nth-child(4) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p9Ref.current.querySelector('g > g:nth-child(4) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 9

    // Edição peça 11
    if (p11Ref.current) {
      const rectElement = p11Ref.current.querySelector('g > rect');
      const textElements = p11Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p11Ref.current.querySelector(
        'g > g:nth-child(9) > g:nth-child(4) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p11Ref.current.querySelector('g > g:nth-child(6) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 11

    // Edição peça 12
    if (p12Ref.current) {
      const rectElement = p12Ref.current.querySelector('g > rect');
      const textElements = p12Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p12Ref.current.querySelector(
        'g > g:nth-child(11) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p12Ref.current.querySelector('g > g:nth-child(8) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 12

    // Edição peça 13
    if (p13Ref.current) {
      const rectElement = p13Ref.current.querySelector('g > rect');
      const textElements = p3Ref.current.querySelectorAll('text');
      textElements.forEach(text => {
        text.style.fill = textColor;
      });

      const logoOriginal = p13Ref.current.querySelector(
        'g > g:nth-child(7) > image'
      );

      if (logoOriginal) {
        logoOriginal.setAttribute('xlink:href', logoMark);
        if (isColorDark(primaryColor)) {
          logoOriginal.classList.add('image-white');
        } else {
          logoOriginal.classList.add('image-black');
        }
      }

      const logo = p13Ref.current.querySelector('g > g:nth-child(4) > image');

      if (logo) {
        logo.setAttribute('xlink:href', programLogoDesign);
      }

      if (rectElement) {
        rectElement.style.fill = primaryColor;
      }
    }
    // Edição peça 13
  }

  function isColorDark(hexColor) {
    // Remove o hash (#) se estiver presente
    hexColor = hexColor.replace('#', '');

    // Converte a cor hexadecimal em valores RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calcula o brilho relativo (relative luminance)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Se o brilho é menor que 128, considera a cor escura
    return brightness < 128;
  }

  function populateTemplate(template) {
    
    if (template && typeof template === 'object') {
      setPrimaryColor(template.primary_color);
      setSecondaryColor(template.secondary_color);
      setTextColor(template.text_color);
      setTextCtaColor(template.text_cta_color);
      setCopyHeader(template.texts.fold_1_paragraph);
      setPremiumTotal(template.texts.max_award);
      setSteps(template.steps);
      setDescription(template.meta_tags[0].content);
      setProgramLogoOption(Number(template.client_program_logo_url));
      setProgramLogo(template.client_program_logo_url);
      setLogoMark(clientData && clientData.originalLogoClient);

      if (
        Number(template.client_program_logo_url) &&
        Number(template.client_program_logo_url) === 1
      ) {
        convertSvgToBase64(logoProgram1Ref);
      }
      if (
        Number(template.client_program_logo_url) &&
        Number(template.client_program_logo_url) === 2
      ) {
        convertSvgToBase64(logoProgram2Ref);
      }
      if (
        Number(template.client_program_logo_url) &&
        Number(template.client_program_logo_url) === 3
      ) {
        convertSvgToBase64(logoProgram3Ref);
      }
    }
  }

  function transformData(data) {
    const steps = data.map((item, index) => {
      let icon = '';
      switch (index) {
        case 0:
          icon = 'share';
          break;
        case 1:
          icon = 'co_present';
          break;
        case 2:
          icon = 'task';
          break;
        case 3:
          icon = 'paid';
          break;
        default:
          icon = 'paid'; // Adicione um ícone padrão caso não haja correspondência
      }

      return {
        icon: icon,
        position: index + 1,
        description:
          item.value > 0
            ? `Aqui você já ganha até ${item.value} pontos`
            : `Este é Passo ${index + 1}`,
        title: item.name,
      };
    });

    return steps;
  }

  async function updateSite() {
    try {
      let params = {
        client_name: clientData && clientData.clientUrl,
        logo_URL: logoMark,
        fav_icon: clientData && clientData.favIcon,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        text_color: textColor,
        text_cta_color: textCtaColor,
        template_name: 'default',
        home_link: '0',
        regulation_link: '0',
        terms_of_use_link: '0',
        privacy_policy_link: '0',
        menu_links: [
          { title: 'Regulamento', link: '0' },
          { title: 'Privacidade', link: '0' },
          { title: 'Termos de uso', link: '0' },
          { title: 'Sobre a empresa', link: '0' },
        ],
        meta_tags: [
          {
            name: 'og:title',
            content: `Indicação Premiada ${clientData &&
              clientData.clientUrl}. Ganhe até R$ ${Number(
              premiumTotal
            )} por indicação.`,
          },
          { name: 'og:type', content: 'website' },
          {
            name: 'og:url',
            content: `https://indicacaopremiada.me/${clientData &&
              clientData.clientUrl}`,
          },
          { name: 'og:image', content: avatarGroup[indexAvatar].headerPersona },
        ],
        client_program_logo_url: programLogo
          ? programLogo
          : programLogoOption.toString(),
        assets: {
          fold_1_avatar_image: avatarGroup[indexAvatar].headerPersona,
          fold_2_avatar_image: avatarGroup[indexAvatar].stepsPersona,
          fold_3_avatar_image: avatarGroup[indexAvatar].formPersona,
        },
        texts: {
          fold_1_paragraph: copyHeader,
          max_award: Number(premiumTotal),
        },

        steps: transformData(clientData && clientData.steps),
        social_links: {
          instagram: '0',
          facebook: '0',
          whatsapp: '0',
        },
        facebook_pixel_id: '0',
        google_tag_id: '0',
        register: {
          url: `https://app.oqdigital.com/_/${clientData &&
            clientData.clientUrl}`,
          action_id: '0',
          player_id: '0',
          client_id: clientData && clientData.uid,
        },
      };

      let result = await api.post(
        `clients/${clientData &&
          clientData.clientUrl}/indique-e-ganhe/template-props`,
        params
      );

      iframeRef.current.src += '';

      console.log('atualizou o site', result);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async function getClientSite() {
    try {
      let result = await api.get(
        `clients/${clientData &&
          clientData.clientUrl}/indique-e-ganhe/template-props`
      );

      if (result && result.data) {
        console.log('result.data: ', result.data);
        setTemplate(result.data);
        populateTemplate(result.data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async function getClientActual() {
    const clientId = userData.clientId;

    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'administrator',
      clientId,
    };

    api
      .post(`/search`, parameters)
      .then(res => {
        const clientData = res.data.data;
        if (clientData) {
          setLogoMark(
            clientData.originalLogoClient
              ? clientData.originalLogoClient
              : clientData.logoURL
          );
        }
      })
      .catch(error => {
        console.log('getClientByUid error:', error);
      });
  }

  const convertSvgToBase64 = svgRef => {
    const svgOriginal = svgRef.current;
    const svg = svgOriginal.cloneNode(true);

    svg.style.zoom = 0.25;

    if (!svg) {
      console.log('SVG element not found');
      return;
    }

    const svgString = new XMLSerializer().serializeToString(svg);
    const encodedData = `data:image/svg+xml;base64,${btoa(svgString)}`;

    setProgramLogoDesign(encodedData);
  };

  return (
    <>
      <div className="oq-dash-wrapper" style={{ fontSize: '14px' }}>
        {/* <SideBar /> */}
        <div className="oq-content-area" style={{ width: '100%' }}>
          <NavBar />
          <div className="oq-content-work">
            <div className="row">
              <div className="col-3">
                <form className="ms-3">
                  <div className="border p-3 rounded-3 my-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-bold">Customização</span>
                      <div className="d-flex gap-2">
                        <Link
                          to={routes.LANDING_PAGE}
                          className="text-decoration-none"
                        >
                          <button className="btn border ms-3 d-flex align-items-center">
                            Voltar ao painel
                            <i className="material-symbols-outlined ms-3">
                              exit_to_app
                            </i>
                          </button>
                        </Link>
                        <button
                          className="btn border btn-primary ms-3 d-flex align-items-center"
                          onClick={e => {
                            e.preventDefault();
                            updateSite();
                          }}
                        >
                          Salvar
                          <i className="material-symbols-outlined ms-3">
                            check
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Cores
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex justify-content-center mb-3">
                                <span className="text-center">
                                  Cor primária
                                </span>
                              </div>
                              {primaryColor && (
                                <BlockPicker
                                  color={primaryColor}
                                  className="w-100"
                                  onChangeComplete={e => {
                                    setPrimaryColor(e.hex);
                                  }}
                                />
                              )}
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-center mb-3">
                                <span className="text-center">
                                  Cor secundária
                                </span>
                              </div>
                              <BlockPicker
                                color={secondaryColor}
                                className="w-100"
                                onChangeComplete={e => {
                                  setSecondaryColor(e.hex);
                                }}
                              />
                            </div>
                            <div className="col-6 mt-3">
                              <div className="d-flex justify-content-center mb-3">
                                <span className="text-center">Cor do CTA</span>
                              </div>
                              {primaryColor && (
                                <BlockPicker
                                  color={textCtaColor}
                                  className="w-100"
                                  onChangeComplete={e => {
                                    setTextCtaColor(e.hex);
                                  }}
                                />
                              )}
                            </div>
                            <div className="col-6 mt-3">
                              <div className="d-flex justify-content-center mb-3">
                                <span className="text-center">
                                  Cor do texto
                                </span>
                              </div>
                              <BlockPicker
                                color={textColor}
                                className="w-100"
                                onChangeComplete={e => {
                                  setTextColor(e.hex);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Textos
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="mb-3">
                            <label
                              className="fw-semibold d-flex align-items-center"
                              htmlFor="exampleFormControlInput1"
                            >
                              Texto da primeira sessão
                              <i
                                className="material-symbols-outlined ms-3"
                                style={{ fontSize: '14px' }}
                                data-tip="Este texto deve ser persoasivo e chamativo para o usuário que estiver visitando sua página"
                              >
                                info
                              </i>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              value={copyHeader}
                              placeholder="Copywrite secundário"
                              onChange={e => {
                                setCopyHeader(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className="fw-semibold d-flex align-items-center"
                              htmlFor="exampleFormControlInput2"
                            >
                              Valor da premiação total
                              <i
                                className="material-symbols-outlined ms-3"
                                style={{ fontSize: '14px' }}
                                data-tip="Aqui você insere o valor total da premiação"
                              >
                                info
                              </i>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput2"
                              placeholder="Premiação total"
                              value={premiumTotal}
                              onChange={e => {
                                setPremiumTotal(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Personas
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div
                            style={{ cursor: 'pointer' }}
                            className={`border ${indexAvatar === 0 &&
                              'border-primary'} p-3 rounded-3 mb-3`}
                            onClick={() => {
                              setIndexAvatar(0);
                            }}
                          >
                            <img src={imageCompose1} className="w-100" alt="" />
                          </div>
                          <div
                            style={{ cursor: 'pointer' }}
                            className={`border ${indexAvatar === 1 &&
                              'border-primary'} p-3 rounded-3 mb-3`}
                            onClick={() => {
                              setIndexAvatar(1);
                            }}
                          >
                            <img src={imageCompose2} className="w-100" alt="" />
                          </div>
                          <div
                            style={{ cursor: 'pointer' }}
                            className={`border ${indexAvatar === 2 &&
                              'border-primary'} p-3 rounded-3 mb-3`}
                            onClick={() => {
                              setIndexAvatar(2);
                            }}
                          >
                            <img src={imageCompose3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Logomarcas
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body bg-dark">
                          <div
                            style={{ cursor: 'pointer' }}
                            className={` ${programLogoOption === 1 &&
                              'border border-light'} p-3 rounded-3`}
                            onClick={e => {
                              setProgramLogoOption(1);
                              setProgramLogo(null);
                              convertSvgToBase64(logoProgram1Ref);
                            }}
                          >
                            <LogoIndicacaoPremiada
                              primaryColor={primaryColor}
                              secondaryColor={secondaryColor}
                              ref={logoProgram1Ref}
                            />
                          </div>
                          <div
                            style={{ cursor: 'pointer' }}
                            className={` ${programLogoOption === 2 &&
                              'border border-light'} p-3 rounded-3`}
                            onClick={() => {
                              setProgramLogoOption(2);
                              setProgramLogo(null);
                              convertSvgToBase64(logoProgram2Ref);
                            }}
                          >
                            <LogoGanheMais
                              primaryColor={primaryColor}
                              ref={logoProgram2Ref}
                            />
                          </div>
                          <div
                            style={{ cursor: 'pointer' }}
                            className={` ${programLogoOption === 3 &&
                              'border border-light'} p-3 rounded-3`}
                            onClick={() => {
                              setProgramLogoOption(3);
                              setProgramLogo(null);
                              convertSvgToBase64(logoProgram3Ref);
                            }}
                          >
                            <LogoIndiqueGanhe
                              primaryColor={primaryColor}
                              secondaryColor={secondaryColor}
                              ref={logoProgram3Ref}
                            />
                          </div>
                          <div className="p-3 border border-light rounded-4 mt-3">
                            <p className="text-center text-white">
                              Aplicar sua própria logo do programa.
                            </p>
                            <img
                              src={programLogo}
                              className="w-100 px-4"
                              alt=""
                            />
                            <div className="my-2">
                              <div
                                className="alert alert-info tiny-text"
                                role="alert"
                              >
                                IMPORTANTE: Para uma melhor aplicação, sugerimos
                                que a logo seja totalmente branca ou com
                                out-line branco como nos exemplos acima. Arquivo
                                PNG com fundo transparente.
                              </div>
                            </div>
                            <CustomFileInput
                              onFileChange={e => {
                                const file = e.files[0];
                                const reader = new FileReader();

                                reader.onload = function(event) {
                                  const base64String = event.target.result;
                                  setProgramLogo(base64String);
                                  setProgramLogoDesign(base64String);
                                };

                                reader.readAsDataURL(file);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Steps
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {steps &&
                            steps.map((step, index) => (
                              <>
                                <div className="border rounded-2 p-3 mb-3">
                                  <div className="d-flex justify-content-between">
                                    <div className="w-100">
                                      <div className="mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          placeholder={`Título do passo ${index +
                                            1}`}
                                          value={step.title}
                                          onChange={e => {}}
                                        />
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={step.description}
                                        id="exampleFormControlInput1"
                                        placeholder="Descrição do step"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          SEO
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="border rounded-2 p-3 mb-3">
                            <div className="d-flex justify-content-between">
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Descrição da página"
                                  value={description}
                                  onChange={e => {
                                    setDescription(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-9">
                <div className="border p-3 rounded-3 my-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <span className="fw-bold">Modo</span>
                      <button
                        className="btn border btn-primary ms-3 d-flex align-items-center"
                        onClick={e => {
                          e.preventDefault();
                          setIsVisibleKV(!isVisibleKV);
                        }}
                      >
                        {isVisibleKV ? 'landing page' : 'Key Visual'}
                        <i className="material-symbols-outlined ms-3">
                          visibility
                        </i>
                      </button>
                    </div>

                    <div>
                      <a
                        href={`https://indicacaopremiada.me/${clientData &&
                          clientData.clientUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn border me-3">
                          <i className="material-symbols-outlined">
                            visibility
                          </i>
                        </button>
                      </a>
                      <button
                        className="btn border"
                        onClick={() => {
                          setPageViewMobie(false);
                        }}
                      >
                        <i className="material-symbols-outlined">computer</i>
                      </button>
                      <button
                        className="btn border ms-3"
                        onClick={() => {
                          setPageViewMobie(true);
                        }}
                      >
                        <i className="material-symbols-outlined">
                          phone_iphone
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border p-3 rounded-3 d-flex justify-content-center align-items-center">
                  {isVisibleKV && (
                    <div
                      id="carouselExample"
                      className="carousel slide bg-black w-100"
                      style={{ maxHeight: 'unset', height: 800 }}
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P1 height="550" className="m-3" ref={p1Ref} />
                            <p className="text-white">Banner mobile</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P2 height="550" className="m-3" ref={p2Ref} />
                            <p className="text-white">Banner mobile steps</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P3 height="550" className="m-3" ref={p3Ref} />
                            <p className="text-white">Banner mobile regras</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P4
                              height="550"
                              className="m-3 w-100"
                              ref={p4Ref}
                            />
                            <p className="text-white">Banner desktop</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P5
                              height="550"
                              className="m-3 w-100"
                              ref={p5Ref}
                            />
                            <p className="text-white">Banner desktop steps</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P6
                              height="550"
                              className="m-3 w-100"
                              ref={p6Ref}
                            />
                            <p className="text-white">Banner desktop regras</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P7
                              height="550"
                              className="m-3 w-100"
                              ref={p7Ref}
                            />
                            <p className="text-white">Banner perfil</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P8 height="550" className="m-3" ref={p8Ref} />
                            <p className="text-white">Vouchers</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P9 height="550" className="m-3" ref={p9Ref} />
                            <p className="text-white">Ícone</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P11 height="550" className="m-3" ref={p11Ref} />
                            <p className="text-white">Convite</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P12 height="550" className="m-3" ref={p12Ref} />
                            <p className="text-white">Login desktop</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ maxHeight: 'unset', height: 800 }}
                          >
                            <P13 height="550" className="m-3" ref={p13Ref} />
                            <p className="text-white">Login mobile</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  )}
                  {/* <P2 height="400" className="m-3" ref={p2Ref} />
                      <P3 height="400" className="m-3" ref={p3Ref} />
                      <P4 height="400" className="m-3" ref={p4Ref} />
                      <P5 height="400" className="m-3" ref={p5Ref} />
                      <P6 height="400" className="m-3" ref={p6Ref} />
                      <P7 height="400" className="m-3" ref={p7Ref} />
                      <P8 height="400" className="m-3" ref={p8Ref} />
                      <P9 height="400" className="m-3" ref={p9Ref} />
                      <P11 height="400" className="m-3" ref={p11Ref} />
                      <P12 height="400" className="m-3" ref={p12Ref} />
                      <P13 height="400" className="m-3" ref={p13Ref} /> */}
                  {!isVisibleKV && (
                    <iframe
                      src={`https://indicacaopremiada.me/${clientData &&
                        clientData.clientUrl}`}
                      style={{
                        width: pageViewMobie ? '500px' : '100%',
                      }}
                      ref={iframeRef}
                      height={800}
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip />
    </>
  );
}
