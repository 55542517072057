import moment from 'moment';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import * as handleMask from '../../../../utils/Mascaras';
import { Banks } from '../../../utils/Banks/banks';

import ReactTable from 'react-table';

import _ from 'underscore';

import { Notify, NotifyUpdate } from '../../../../components/Notify';
import { api } from '../../../../Config';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

let nId = null;

class DetailsRescue extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      value: false,
      player: {},
      lead: {},
      actions: [],
      actionsCount: 0,
      loadingActions: false,
      updatingPlayer: false,
      loading: false,
      rescueId: null,
      rescueLeadSelecet: false,
      rescue: {},
      rescueLead: {},
      error: false,
      isLoading: false,
      isLoadingDeleteRescue: false,
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { rescue } = this.props.location;

    if (rescue && rescue.typeRescue === 'rescueLead') {
      this.getRescueLead(rescue);
      await this.getLead(rescue);
    } else {
      await this.getRescue(params.rescueId);
    }
  }

  getRescueLead(rescue) {
    this.setState({
      rescue: rescue,
    });
  }

  async getLead(rescue) {
    this.setState({ loading: true, error: false });

    let { clientData } = this.context;
    let parameters = {
      searchFunctionality: 'getLeadsWithPagination',
      userType: 'client',
      filter: {
        searchLeadId: rescue.leadId,
      },
    };

    let res = await api.post(`/searchLeads`, parameters);

    const result = res.data;

    if (result.success === true) {
      this.setState({ lead: result.data.leads[0], loading: false });
    } else {
      this.setState({ loading: false, error: true });
    }
  }

  async getRescue(rescueId) {
    this.setState({ loading: true, error: false });

    let { clientData } = this.context;
    let parameters = {
      searchFunctionality: 'getRescueById',
      userType: 'client',
      clientId: clientData.uid,
      rescueId,
    };

    let res = await api.post(`/search`, parameters);

    const result = res.data;

    if (result.success === true) {
      this.setState({
        rescue: {
          ...result.data.rescue,
          valuePointsEdited: 0,
          valuePaymentEdited: 0,
        },
        player: result.data.player,
        loading: false,
        // rescueLeadSelecet: result.data.rescueLeadSelecet,
      });
    } else {
      this.setState({ loading: false, error: true });
    }
  }

  numberWithCommas(x) {
    var decimalComma = x.replace('.', ',');
    var parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderRescueTed(rescue, arrTypeAccount, accountType) {
    //   'rescue, arrTypeAccount, accountType-->',
    //   rescue,
    //   arrTypeAccount,
    //   accountType
    // );
    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="name">
              Favorecido
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="name"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => {
                const { name, value } = e.target;
                this.setInputs(name, value);
              }}
              name="name"
              value={rescue.accountData ? rescue.accountData.name : ''}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="cpf">
              CPF
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="cpf"
              name="cpf"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(event) => {
                const input = event.target;
                if (input.value.length <= 14) {
                  let maskCPF = handleMask.maskCPF(input.value);
                  this.setInputs(input.name, maskCPF);
                } else if (
                  input.value.length > 14 &&
                  input.value.length <= 18
                ) {
                  let maskCNPJ = handleMask.maskCNPJ(input.value);
                  this.setInputs(input.name, maskCNPJ);
                } else {
                  let maskLimited = handleMask.maskLimited(input.value);
                  this.setInputs(input.name, maskLimited);
                }
              }}
              value={rescue.accountData ? rescue.accountData.cpf : ''}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group col-md-6" style={{ padding: 0 }}>
            <label className="profile-label" htmlFor="name">
              Tipo de transferência
            </label>
            <select
              name="paymentMethod"
              className="form-control profile-placeholder"
              value={
                rescue.accountData.paymentMethod &&
                rescue.accountData.paymentMethod.label
                  ? rescue.accountData.paymentMethod.label
                  : 'ted'
              }
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => {
                const { value } = e.target;
                if (rescue.accountData.paymentMethod.label) {
                  this.setState((prevState) => ({
                    rescue: {
                      ...prevState.rescue,
                      accountData: {
                        ...prevState.rescue.accountData,
                        paymentMethod: {
                          ...prevState.rescue.accountData.paymentMethod,
                          label: value,
                        },
                      },
                    },
                  }));
                } else {
                  this.setState((prevState) => ({
                    rescue: {
                      ...prevState.rescue,
                      accountData: {
                        ...prevState.rescue.accountData,
                        paymentMethod: value,
                      },
                    },
                  }));
                }
              }}
            >
              <option value="ted">Ted</option>
              <option value="pix">Pix</option>
            </select>
          </div>
          <div className="form-group col-md-6" style={{ padding: 0 }}>
            <label className="profile-label" htmlFor="accountType">
              Tipo de conta
            </label>
            <select
              className="form-control profile-placeholder"
              name="accountType"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              required={true}
              id="accountType"
              value={accountType && accountType.value}
              onChange={(e) => {
                let value = e.target.value;

                this.setState((prevState) => ({
                  rescue: {
                    ...prevState.rescue,
                    accountData: {
                      ...prevState.rescue.accountData,
                      accountType: value,
                    },
                  },
                  player: {
                    ...prevState.player,
                    AccountData: {
                      ...prevState.player.AccountData,
                      accountType: value,
                    },
                  },
                }));
              }}
            >
              <option className="formlabel">Selecione o tipo de conta</option>
              {arrTypeAccount.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="bank">
              Banco
            </label>
            <select
              className="form-control profile-placeholder"
              name="bank"
              required={true}
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              id="bank"
              value={
                this.state.rescue &&
                this.state.rescue.accountData &&
                this.state.rescue.accountData.bank
              }
              onChange={(e) => {
                const labelBank = e.target.options[e.target.selectedIndex].text;
                this.setState({
                  rescue: {
                    ...rescue,
                    accountData: {
                      ...rescue.accountData,
                      bank: e.target.value,
                      labelBank,
                    },
                  },
                });
                return;
              }}
            >
              <option
                className="formlabel"
                value=""
                selected={!this.state.rescue.accountData.bank}
              >
                Escolha seu banco
              </option>
              {Banks.map((bank) => {
                return (
                  <option
                    className="font-12"
                    key={bank.value}
                    value={bank.value}
                    selected={this.state.rescue.accountData.bank === bank.value}
                  >
                    {bank.value} - {bank.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="agency">
              Agência
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="agency"
              name="agency"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => this.setInputs(e.target.name, e.target.value)}
              value={
                this.state.rescue.accountData
                  ? this.state.rescue.accountData.agency
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="digitAgency">
              Dígito da Agência
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="digitAgency"
              name="digitAgency"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => this.setInputs(e.target.name, e.target.value)}
              value={
                this.state.rescue.accountData
                  ? this.state.rescue.accountData.digitAgency
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="conta">
              Conta
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="conta"
              name="conta"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => this.setInputs(e.target.name, e.target.value)}
              value={
                this.state.rescue.accountData
                  ? this.state.rescue.accountData.conta
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="digitCount">
              Dígito da Conta
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="digitCount"
              name="digitCount"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => this.setInputs(e.target.name, e.target.value)}
              value={
                this.state.rescue.accountData
                  ? this.state.rescue.accountData.digitCount
                  : ''
              }
            />
          </div>
        </div>
      </>
    );
  }

  renderRescuePix(
    rescue,
    userRescue,
    arrTypePix,
    typePix,
    typeInput,
    maxCharacter
  ) {
    //   'rescue, userRescue, arrTypePix,typePix, typeInput,maxCharacter-->',
    //   rescue,
    //   userRescue,
    //   arrTypePix,
    //   typePix,
    //   typeInput,
    //   maxCharacter
    // );
    return (
      <>
        <div className="row col-md-12">
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="name">
              Favorecido
            </label>
            <input
              type="text"
              className="form-control input-oq rounded"
              id="name"
              name="name"
              defaultValue={userRescue.name}
              readOnly={true}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="cpf">
              CPF
            </label>
            <input
              type="text"
              className="form-control input-oq rounded"
              id="cpf"
              name="cpf"
              defaultValue={userRescue.cpf}
              readOnly={true}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="createdAt">
              Data do resgate
            </label>
            <input
              type="text"
              className="form-control input-oq rounded"
              id="createdAt"
              name="createdAt"
              defaultValue={moment(rescue.createdAt).format('DD/MM/YYYY')}
              readOnly={true}
            />
          </div>
        </div>

        <div className="row col-md-12">
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="name">
              Tipo de transferência
            </label>
            <select
              name="paymentMethod"
              className="form-control profile-placeholder"
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              value={
                rescue.accountData.paymentMethod &&
                rescue.accountData.paymentMethod.label
                  ? rescue.accountData.paymentMethod.label
                  : 'pix'
              }
              onChange={(e) => {
                const { value } = e.target;
                if (rescue.accountData.paymentMethod.label) {
                  this.setState((prevState) => ({
                    rescue: {
                      ...prevState.rescue,
                      accountData: {
                        ...prevState.rescue.accountData,
                        paymentMethod: {
                          ...prevState.rescue.accountData.paymentMethod,
                          label: value,
                        },
                      },
                    },
                  }));
                } else {
                  this.setState((prevState) => ({
                    rescue: {
                      ...prevState.rescue,
                      accountData: {
                        ...prevState.rescue.accountData,
                        paymentMethod: value,
                      },
                    },
                  }));
                }
              }}
            >
              <option value="ted">Ted</option>
              <option value="pix">Pix</option>
            </select>
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="cpf">
              Tipo de chave pix
            </label>

            <select
              className="form-control profile-placeholder"
              name="typePix"
              value={typePix && typePix.value}
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              onChange={(e) => {
                let value = e.target.value;
                this.setState({
                  rescue: {
                    ...rescue,
                    accountData: {
                      ...this.state.rescue.accountData,
                      typePix: value,
                    },
                  },
                });
              }}
            >
              <option value="">Selecione uma chave</option>
              {arrTypePix &&
                _.map(arrTypePix, (type) => {
                  return <option value={type.value}>{type.label}</option>;
                })}
            </select>
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="name">
              Chave pix
            </label>
            <input
              disabled={
                rescue.closeProccess || rescue.batchId || rescue.transferId
              }
              type={typeInput}
              maxLength={maxCharacter}
              className="form-control input-oq rounded"
              id="pixkey"
              name="pixkey"
              value={rescue.accountData.pixkey}
              onChange={(event) => {
                // CPF / CNPJ / PHONE / EMAIL / EVP
                const input = event.target;
                if (rescue.accountData.typePix === 'CPF') {
                  let masked = handleMask.maskCPF(input.value);
                  this.setState({
                    rescue: {
                      ...rescue,
                      accountData: {
                        ...this.state.rescue.accountData,
                        pixkey: masked,
                      },
                    },
                  });
                } else if (rescue.accountData.typePix === 'CNPJ') {
                  let maskedValue = handleMask.maskCNPJ(input.value);
                  this.setState({
                    rescue: {
                      ...rescue,
                      accountData: {
                        ...this.state.rescue.accountData,
                        pixkey: maskedValue,
                      },
                    },
                  });
                } else if (rescue.accountData.typePix === 'PHONE') {
                  handleMask.maskTEL(input.value).then((maskedValue) => {
                    this.setState({
                      rescue: {
                        ...rescue,
                        accountData: {
                          ...this.state.rescue.accountData,
                          pixkey: maskedValue,
                        },
                      },
                    });
                  });
                } else if (rescue.accountData.typePix === 'EVP') {
                  this.setState({
                    rescue: {
                      ...rescue,
                      accountData: {
                        ...this.state.rescue.accountData,
                        pixkey: input.value,
                      },
                    },
                  });
                } else {
                  this.setState({
                    rescue: {
                      ...rescue,
                      accountData: {
                        ...this.state.rescue.accountData,
                        pixkey: input.value,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  renderProfileFields(searchAwardMoney) {
    const { clientData } = this.context;
    const { rescue, player, lead } = this.state;
    const userRescue = rescue.typeRescue === 'rescueLead' ? lead : player;

    let isPaymentMethodTed = true;
    let accountType = {
      label: '',
      value: '',
    };
    let typePix = {
      label: '',
      value: '',
    };
    let maxCharacter;
    let typeInput = 'text';

    if (Object.keys(rescue).length > 0) {
      var isCloseProccess = rescue.closeProccess ? rescue.closeProccess : false;

      var searchAwardBS2 = _.find(
        rescue.awards,
        (award) => award.premiumType.value === 'BS2'
      );

      var searchAwardOnlypay = _.find(
        rescue.awards,
        (award) => award.premiumType.value === 'onlypay'
      );

      if (
        clientData &&
        clientData.integration &&
        clientData.integration.transfeera &&
        clientData.integration.transfeera.active === 'true'
      ) {
        var arrTypeAccountTransfeera = [
          {
            label: 'Conta Corrente',
            value: 'CONTA_CORRENTE',
          },
          {
            label: 'Conta Poupança',
            value: 'CONTA_POUPANCA',
          },
          {
            label: 'Conta Fácil',
            value: 'CONTA_FACIL',
          },
          {
            label: 'Entidades Públicas',
            value: 'ENTIDADES_PUBLICAS',
          },
        ];

        accountType = arrTypeAccountTransfeera.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;

          // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
          var arrTypePixTransfeera = [
            {
              label: 'CPF',
              value: 'CPF',
            },
            {
              label: 'CNPJ',
              value: 'CNPJ',
            },
            {
              label: 'Telefone',
              value: 'TELEFONE',
            },
            {
              label: 'E-mail',
              value: 'EMAIL',
            },
            {
              label: 'Chave aleatória',
              value: 'CHAVE_ALEATORIA',
            },
          ];

          typePix = arrTypePixTransfeera.find(
            (type) => type.value === rescue.accountData.typePix
          );

          if (typePix) {
            if (typePix === 'CPF') {
              // 072.222.940-23
              maxCharacter = 14;
            } else if (typePix === 'CNPJ') {
              // 37.010.996/0001-26
              maxCharacter = 18;
            } else if (typePix === 'TELEFONE') {
              // (98) 99962-1348
              maxCharacter = 15;
              typeInput = 'phone';
            } else if (typePix === 'CHAVE_ALEATORIA') {
              // 034c0406-66fd-4ee0-9566-6102a6967764
              // 034c040666fd4ee095666102a6967764
              maxCharacter = 36;
            } else if (typePix === 'EMAIL') {
              typeInput = 'email';
            }
          }
        }
      } else {
        var arrTypeAccountBs2 = [
          {
            label: 'Conta Corrente',
            value: '2',
          },
          {
            label: 'Conta Poupança',
            value: '3',
          },
          {
            label: 'Conta de Pagamento',
            value: '1',
          },
        ];

        accountType = arrTypeAccountBs2.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        // CPF / CNPJ / PHONE / EMAIL / EVP
        var arrTypePixBs2 = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'PHONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'EVP',
          },
        ];

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePixBs2.find(
            (type) => type.value === rescue.accountData.typePix
          );
        }
      }

      var arrTypeAccount = arrTypeAccountTransfeera
        ? arrTypeAccountTransfeera
        : arrTypeAccountBs2;

      var arrTypePix = arrTypePixTransfeera
        ? arrTypePixTransfeera
        : arrTypePixBs2;

      if (
        (rescue.accountData &&
          !rescue.accountData.name &&
          rescue.accountData.paymentMethod.label &&
          rescue.accountData.paymentMethod.label === 'ted') ||
        (rescue.accountData &&
          !rescue.accountData.name &&
          rescue.accountData.paymentMethod &&
          rescue.accountData.paymentMethod === 'ted')
      ) {
        this.setState((prevState) => ({
          rescue: {
            ...prevState.rescue,
            accountData: {
              ...prevState.rescue.accountData,
              name: userRescue.name,
            },
          },
        }));
      }
    }

    if (searchAwardBS2) {
      return (
        <div>
          {isPaymentMethodTed ? (
            <>{this.renderRescueTed(rescue, arrTypeAccount, accountType)}</>
          ) : (
            <>
              {this.renderRescuePix(
                rescue,
                userRescue,
                arrTypePix,
                typePix,
                typeInput,
                maxCharacter
              )}
            </>
          )}

          {!rescue.closeProccess && !rescue.batchId && !rescue.transferId && (
            <div className="row col-md-12">
              <div className="form-group col-md-3">
                <label className="profile-label" htmlFor="valuePointsEdited">
                  Valor (pontos)
                </label>
                <input
                  type="number"
                  className="form-control profile-placeholder"
                  id="valuePointsEdited"
                  disabled={
                    rescue.closeProccess || rescue.batchId || rescue.transferId
                  }
                  onChange={(e) => {
                    const { name, value } = e.target;
                    this.setInputsRescue(name, value);
                  }}
                  name="valuePointsEdited"
                  value={
                    rescue.valuePointsEdited || rescue.valuePointsEdited >= 0
                      ? rescue.valuePointsEdited
                      : 0
                  }
                />
              </div>
              <div className="form-group col-md-3">
                <label className="profile-label" htmlFor="valuePaymentEdited">
                  Valor (pagamento)
                </label>
                <input
                  type="number"
                  className="form-control profile-placeholder"
                  id="valuePaymentEdited"
                  disabled={
                    rescue.closeProccess || rescue.batchId || rescue.transferId
                  }
                  onChange={(e) => {
                    const { name, value } = e.target;
                    this.setInputsRescue(name, value);
                  }}
                  name="valuePaymentEdited"
                  value={
                    rescue.valuePaymentEdited || rescue.valuePaymentEdited >= 0
                      ? rescue.valuePaymentEdited
                      : 0
                  }
                />
              </div>
            </div>
          )}

          {rescue.transferPayment && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="transfer">
                  Pedido de transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="transfer"
                  name="transfer"
                  disabled
                  value={'Pedido feito com sucesso'}
                />
              </div>
            </div>
          )}

          {rescue.statusTransfer && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="statusTransfer">
                  Status da Transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="statusTransfer"
                  disabled
                  value={rescue.statusTransfer}
                />
              </div>
            </div>
          )}

          {rescue.statusTransferDescription && (
            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="profile-label"
                  htmlFor="statusTransferDescription"
                >
                  Descrição Status
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="statusTransferDescription"
                  disabled
                  value={rescue.statusTransferDescription}
                />
              </div>
            </div>
          )}

          {rescue.closeProccess && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="transferProccess">
                  Processo de transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="transferProccess"
                  disabled
                  value={'Transferência processada com sucesso'}
                />
              </div>
            </div>
          )}

          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="col-md-12"
          >
            {searchAwardMoney && (
              <button
                style={{ marginRight: '10px' }}
                className="btn btn-oq col-md-3"
                onClick={() => this.makeBankTransfer(typePix, accountType)}
                disabled={
                  isCloseProccess ||
                  this.state.isLoading ||
                  rescue.statusTransfer
                }
              >
                Fazer transferência
              </button>
            )}
            {!rescue.closeProccess && !rescue.batchId && !rescue.transferId && (
              <button
                style={{ marginRight: '10px' }}
                className="btn btn-oq col-md-3"
                onClick={() => this.deleteRescue()}
                disabled={
                  rescue.closeProccess ||
                  rescue.batchId ||
                  rescue.transferId ||
                  rescue.statusTransfer ||
                  this.state.isLoadingDeleteRescue ||
                  this.state.isLoading
                }
              >
                Excluir
              </button>
            )}

            {!rescue.closeProccess && !rescue.batchId && !rescue.transferId && (
              <button
                className="btn btn-oq col-md-3"
                onClick={() => this.updateAccountRescue()}
                disabled={this.state.isLoading || rescue.statusTransfer}
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="street">
              Logradouro
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="street"
              name="street"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.street : ''}
              placeholder="Logradouro"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="number">
              Número
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              name="number"
              id="number"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.number : ''}
              placeholder="Número"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="complement">
              Complemento
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="complement"
              name="complement"
              disabled
              value={
                rescue.deliverAddress ? rescue.deliverAddress.complement : ''
              }
              placeholder="Complemento"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="neighborhood"
              name="neighborhood"
              disabled
              value={
                rescue.deliverAddress ? rescue.deliverAddress.neighborhood : ''
              }
              placeholder="Bairro"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="city">
              {this.translate('city')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="city"
              name="city"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.city : ''}
              placeholder={this.translate('city')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="state">
              Estado
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="state"
              name="state"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.state : ''}
              placeholder="Estado"
            />
          </div>
        </div>
        {searchAwardOnlypay && (
          <div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="name">
                  Favorecido
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="name"
                  name="name"
                  disabled
                  value={rescue.accountData ? rescue.accountData.name : ''}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="cpf">
                  CPF
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="cpf"
                  name="cpf"
                  disabled
                  onChange={(e) => {
                    let name = e.target.name;
                    let cpf = e.target.value.replace(/\D/g, '');
                    if (cpf.length <= 11) {
                      handleMask.maskCPF(e.target.value).then((masked) => {
                        this.setInputs(name, masked);
                      });
                    } else if (cpf.length <= 14) {
                      handleMask.maskCNPJ(e.target.value).then((masked) => {
                        this.setInputs(name, masked);
                      });
                    }
                  }}
                  value={rescue.accountData ? rescue.accountData.cpf : ''}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="accountType">
                  Tipo de conta
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="accountType"
                  required={true}
                  id="accountType"
                  disabled
                  value={
                    this.state.rescue &&
                    this.state.rescue.accountData &&
                    this.state.rescue.accountData.typeAccount
                  }
                  onChange={(e) => {
                    let value = e.target.value;
                    this.setState({
                      rescue: {
                        ...rescue,
                        accountData: {
                          ...this.state.rescue.accountData,
                          accountType: value,
                        },
                      },
                    });
                    return;
                  }}
                >
                  <option
                    className="formlabel"
                    value=""
                    selected={!this.state.rescue.accountData.accountType}
                  >
                    Selecione o tipo de conta
                  </option>
                  <option
                    className="formlabel"
                    value="CONTA_CORRENTE"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_CORRENTE'
                    }
                  >
                    Conta Corrente
                  </option>
                  <option
                    className="formlabel"
                    value="CONTA_POUPANCA"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_POUPANCA'
                    }
                  >
                    Conta Poupança
                  </option>
                  <option
                    className="formlabel"
                    value="CONTA_FACIL"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_FACIL'
                    }
                  >
                    Conta Fácil
                  </option>
                  <option
                    className="formlabel"
                    value="ENTIDADES_PUBLICAS"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'ENTIDADES_PUBLICAS'
                    }
                  >
                    Entidades Públicas
                  </option>
                </select>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="bank">
                  Banco
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="bank"
                  required={true}
                  disabled
                  id="bank"
                  value={
                    this.state.rescue &&
                    this.state.rescue.accountData &&
                    this.state.rescue.accountData.bank
                  }
                  onChange={(e) => {
                    const labelBank =
                      e.target.options[e.target.selectedIndex].text;
                    this.setState({
                      rescue: {
                        ...rescue,
                        accountData: {
                          ...rescue.accountData,
                          bank: e.target.value,
                          labelBank,
                        },
                      },
                    });
                    return;
                  }}
                >
                  <option
                    className="formlabel"
                    value=""
                    selected={!this.state.rescue.accountData.bank}
                  >
                    Escolha seu banco
                  </option>
                  {Banks.map((bank) => {
                    return (
                      <option
                        className="font-12"
                        key={bank.value}
                        value={bank.value}
                        selected={
                          this.state.rescue.accountData.bank === bank.value
                        }
                      >
                        {bank.value} - {bank.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="agency">
                  Agência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="agency"
                  name="agency"
                  disabled
                  onChange={(e) =>
                    this.setInputs(e.target.name, e.target.value)
                  }
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.agency
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="digitAgency">
                  Dígito da Agência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="digitAgency"
                  name="digitAgency"
                  disabled
                  onChange={(e) =>
                    this.setInputs(e.target.name, e.target.value)
                  }
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.digitAgency
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="conta">
                  Conta
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="conta"
                  name="conta"
                  disabled
                  onChange={(e) =>
                    this.setInputs(e.target.name, e.target.value)
                  }
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.conta
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="digitCount">
                  Dígito da Conta
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="digitCount"
                  name="digitCount"
                  disabled
                  onChange={(e) =>
                    this.setInputs(e.target.name, e.target.value)
                  }
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.digitCount
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  setInputs(name, value) {
    const { accountData } = this.state.rescue;
    this.setState({
      rescue: {
        ...this.state.rescue,
        accountData: {
          ...accountData,
          [name]: value,
        },
      },
    });
  }
  setInputsRescue(name, value) {
    let isEditValuePointsAndPayment = true;
    if (value >= 0) {
      this.setState({
        rescue: {
          ...this.state.rescue,
          [name]: Number(value),
          isEditValuePointsAndPayment,
        },
      });
    }
  }

  async updateAccountRescue() {
    this.toastId = toast.info('Atualizando dados bancários. Aguarde...', {
      autoClose: false,
    });
    this.setState({ isLoading: true });

    const { rescue } = this.state;
    let parameters = {
      updateFunctionality: 'updateAccountRescue',
      userType: 'client',
      rescue,
    };

    await api
      .post(`/update`, parameters)
      .then(async (result) => {
        if (!result.data.success) {
          // Notify(result.data.message, 'error');
          toast.update(this.toastId, {
            render: result.data.message,
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          this.setState({ isLoading: false });
        } else {
          // Notify(result.data.message, 'success');
          toast.update(this.toastId, {
            render: result.data.message,
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          await this.getRescue(this.state.rescue.id);
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log('erro updateAccountRescue: ', error);
        // Notify(error.data.message, 'error');
        this.setState({ isLoading: false });
        toast.update(this.toastId, {
          render: error.data.message,
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
  }

  async deleteRescue() {
    this.toastId = toast.info('Excluindo resgate. Aguarde...', {
      autoClose: false,
    });
    this.setState({ isLoadingDeleteRescue: true });

    const { rescue } = this.state;
    let parameters = {
      updateFunctionality: 'deleteRescue',
      userType: 'client',
      rescueId: rescue.id,
      clientId: rescue.clientId,
      typeRescue: rescue.typeRescue === 'rescueLead' ? true : false,
    };

    await api
      .post(`/update`, parameters)
      .then((result) => {
        if (!result.data.success) {
          toast.update(this.toastId, {
            render: result.data.message,
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          this.setState({ isLoadingDeleteRescue: false });
        } else {
          toast.update(this.toastId, {
            render: result.data.message,
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          this.setState({ isLoadingDeleteRescue: false });
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log('erro deleteRescue: ', error);
        // Notify(error.data.message, 'error');
        this.setState({ isLoadingDeleteRescue: false });
        toast.update(this.toastId, {
          render: error.data.message,
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
  }

  getPlayerColor(points) {
    let color = '#000000';

    if (points < 999) {
      color = 'yellow';
    } else if (points < 4999) {
      color = 'orange';
    } else if (points < 9999) {
      color = 'blue';
    } else if (points < 19999) {
      color = 'green';
    } else if (points < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { rescue } = this.state;
    return (
      <div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {this.numberWithCommas(Number(rescue.redeemedPoints).toFixed(0))}
            </span>
            <br />
            <span className="oq-points-desc">pontos</span>
          </div>
        </div>
      </div>
    );
  }

  renderProfileDetailsPaymentValue() {
    const { rescue } = this.state;
    const searchAwardBS2 = _.find(
      rescue.awards,
      (award) => award.premiumType.value === 'BS2'
    );
    if (searchAwardBS2) {
      return (
        <div>
          <div className="oq-box">
            <div className="oq-points-box">
              <span className="oq-points">
                R${' '}
                {this.numberWithCommas(
                  Number(rescue.totalPaymentValue).toFixed(2)
                )}
              </span>
              <br />
              <span className="oq-points-desc">reais</span>
            </div>
          </div>
        </div>
      );
    }
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  renderActionsTable() {
    const data = _.values(this.state.rescue.awards);

    const searchAwardBS2 = _.find(
      this.state.rescue.awards,
      (award) => award.premiumType.value === 'BS2'
    );

    const columnsBS2 = [
      {
        Header: '',
        id: 'premiumImage',
        accessor: (d) => (
          <img
            src={d.premiumImage}
            className="img-fluid img-rounded"
            style={{ width: '30%', height: 'auto' }}
            alt="action banner"
          />
        ),
        width: 100,
      },
      {
        Header: 'Prêmio',
        id: 'premiumName',
        accessor: (d) => <span>{d.premiumName}</span>,
      },
      {
        Header: 'Tipo',
        id: 'premiumType',
        accessor: (d) => <span>{d.premiumType.value}</span>,
        width: 100,
      },
      {
        Header: 'Valor (pontos)',
        id: 'premiumPrice',
        accessor: (d) => {
          const value =
            !d.valueType || d.valueType.value === 'fixed'
              ? d.premiumPrice && d.premiumPrice > 0
                ? Number(d.premiumPrice).toFixed(1)
                : 0
              : d.balancePlayer && d.balancePlayer > 0
                ? Number(d.balancePlayer).toFixed(1)
                : 0;

          return <span>{value}</span>;
        },
        width: 110,
      },
      {
        Header: 'Valor (pagamento)',
        id: 'paymentValue',
        // accessor: d => <span>R$ {d.paymentValue}</span>,
        accessor: (d) => {
          const value =
            !d.valueType || d.valueType.value === 'fixed'
              ? d.paymentValue && d.paymentValue > 0
                ? Number(d.paymentValue).toFixed(1)
                : 0
              : d.balancePlayer && d.balancePlayer > 0
                ? Number(d.balancePlayer).toFixed(1)
                : 0;

          return <span>{value}</span>;
        },
        width: 150,
      },

      {
        Header: 'Quantidade',
        id: 'premiumQuantity',
        accessor: (d) => <span>{d.amount}</span>,
        width: 100,
      },
      {
        Header: 'Total (pontos)',
        id: 'points',
        accessor: (d) => {
          const value =
            !d.valueType || d.valueType.value === 'fixed'
              ? d.premiumPrice && d.premiumPrice > 0
                ? Number(d.premiumPrice * d.amount).toFixed(1)
                : 0
              : d.balancePlayer && d.balancePlayer > 0
                ? Number(d.balancePlayer).toFixed(1)
                : 0;

          return <span>{value}</span>;
          //   !d.valueType || d.valueType.value === 'fixed' ? (
          //   <span>
          //     {numberWithCommas(Number(d.premiumPrice * d.amount).toFixed(1))}
          //   </span>
          // ) : (
          //   <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          // )
        },
        width: 120,
      },
      {
        Header: 'Total (pagamento)',
        id: 'subtotal',
        accessor: (d) => {
          const value =
            !d.valueType || d.valueType.value === 'fixed'
              ? d.subtotal && d.subtotal > 0
                ? Number(d.subtotal).toFixed(1)
                : 0
              : d.balancePlayer && d.balancePlayer > 0
                ? Number(d.balancePlayer).toFixed(1)
                : 0;

          return <span>{value}</span>;
          // !d.valueType || d.valueType.value === 'fixed' ? (
          //   <span>{numberWithCommas(Number(d.subtotal).toFixed(1))}</span>
          // ) : (
          //   <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          // )
        },
        width: 150,
      },
    ];

    const columns = [
      {
        Header: '',
        id: 'premiumImage',
        accessor: (d) => (
          <img
            src={d.premiumImage}
            className="img-fluid img-rounded"
            style={{ width: '30%', height: 'auto' }}
            alt="action banner"
          />
        ),
        width: 150,
      },
      {
        Header: 'Prêmio',
        id: 'premiumName',
        accessor: (d) => <span>{d.premiumName}</span>,
      },
      {
        Header: 'Tipo',
        id: 'premiumType',
        accessor: (d) => <span>{d.premiumType.value}</span>,
        width: 150,
      },
      {
        Header: 'Valor (pontos)',
        id: 'premiumPrice',
        accessor: (d) => <span>{d.premiumPrice}</span>,
        width: 150,
      },

      {
        Header: 'Quantidade',
        id: 'premiumQuantity',
        accessor: (d) => <span>{d.amount}</span>,
        width: 150,
      },
      {
        Header: 'Total (pontos)',
        id: 'subtotal',
        accessor: (d) => <span>{d.subtotal}</span>,
        width: 150,
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loading}
        defaultPageSize={5}
        columns={searchAwardBS2 ? columnsBS2 : columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum prêmio a ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText="prêmios"
      />
    );
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; Lista dos prêmios resgatados
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderActionsTable()}</div>
      </div>
    );
  }

  async makeBankTransfer(typePix, accountType) {
    const { clientData } = this.context;
    const { rescue } = this.state;
    let searchFunctionality = '';

    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }

    if (
      clientData &&
      clientData.integration &&
      clientData.integration.transfeera &&
      clientData.integration.transfeera.active === 'true'
    ) {
      searchFunctionality = 'makeBankTransfer';
    } else {
      searchFunctionality = 'makeBankTransferBs2';
    }

    this.setState({ isLoading: true });
    NotifyUpdate('Aguarde... realizando a transferência...', 'loading', false);
    try {
      let parameters = {
        searchFunctionality,
        // searchFunctionality: 'makeBankTransferBs2',
        // searchFunctionality: 'makeBankTransfer',
        userType: 'client',
        clientId: clientData.uid,
        rescueId: rescue.id,
        playerId: rescue.playerId,
        typeRescue:
          rescue.typeRescue && rescue.typeRescue === 'rescueLead'
            ? true
            : false,
        leadId: rescue.leadId ? rescue.leadId : null,
      };

      console.log('parameters: ', parameters);

      const responseMakeBankTransfer = await api.post(`/search`, parameters);

      if (responseMakeBankTransfer.data.success) {
        // Chegou aqui, quer dizer que deu tudo certo
        this.setState({ isLoading: false });
        NotifyUpdate(
          responseMakeBankTransfer.data.message,
          'success',
          nId,
          true
        );
        await this.getRescue(rescue.id);
      } else {
        this.setState({ isLoading: false });
        NotifyUpdate(responseMakeBankTransfer.data.message, 'error', nId, true);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log('error makeBankTransfer: ', error);
    }
  }

  render() {
    const { rescue, loading } = this.state;

    let searchAwardMoney = _.find(
      rescue.awards,
      (award) => award.premiumType.value === 'BS2'
    );

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do resgate')}
              </h1>
              {!loading && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      {this.renderProfilePicDetails()}
                      {this.renderProfileDetailsPaymentValue()}
                      {/* {searchAwardMoney && this.renderBtnTransfer()} */}
                    </div>
                    <div className="col-md-9">
                      {this.renderProfileFields(searchAwardMoney)}
                    </div>
                  </div>
                  <div className="col-md-12">
                    {(!rescue.typeRescue ||
                      rescue.typeRescue !== 'rescueLead') &&
                      this.renderTargetPublicList()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsRescue;
