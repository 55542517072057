import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';

import * as routes from '../../routes';

import { Notify } from '../../components/Notify';

import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class AdministratorsPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      admins: [],
      countAdmins: 0,
      isLoading: false,
      stateList: [],
      cityList: [],
      searchObject: {
        username: '',
      },
    };

    return initialState;
  }

  filterAdmins() {
    const { searchObject } = this.state;
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'filterAdmins',
        userType: 'administrator',
        searchObject,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (!result.success) {
            console.log('filterAdmins !success:', result);
            this.setState(
              {
                isLoading: false,
                countAdmins: 0,
                admins: [],
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }
          this.setState(
            {
              isLoading: false,
              countAdmins: result.data.adminsCount,
              admins: result.data.admins,
            },
            Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('filterAdmins error:', error);
        });
    });
  }

  getAdministrators() {
    const parameters = {
      searchFunctionality: 'allAdministrators',
      userType: 'administrator',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          this.setState({ isLoading: false }, () => {
            Notify(result.message, result.success ? 'success' : 'error');
          });
          return;
        }

        this.setState(
          {
            isLoading: false,
            countAdmins: result.data.adminsCount,
            admins: result.data.admins,
          },
          Notify(result.message, result.success ? 'success' : 'error')
        );
      })
      .catch((error) => {
        console.log('getAdministrators error:', error);
      });
  }

  renderAdminsTable() {
    const { admins } = this.state;

    const columns = [
      {
        Header: 'Nome',
        accessor: 'username',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        id: 'editAdmin',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable" style={{ width: '100%' }}>
            <Link
              to={`${routes.EDIT_ADMIN}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              Editar Administrador
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-user" />
            &nbsp; Lista de administradores
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={_.values(admins)}
              defaultPageSize={5}
              loading={this.state.isLoading}
              columns={columns}
              previousText="prev"
              nextText="next"
              noDataText="Nenhum cliente encontrado"
              pageText="page"
              ofText="of"
              rowsText="lines"
            />
          </div>
        </div>
      </div>
    );
  }

  goToEditAdmin(clientId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_CLIENT}/${clientId}/`);
  }

  goToNewAdminScreen() {
    const { history } = this.props;
    history.push(routes.SIGN_UP_ADMIN);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  renderUpdateButton() {
    if (!this.state.isLoading) {
      return (
        <div>
          <div className="col-md-4 text-center p-2-top p-2-bottom">
            <button
              className="btn-oq btn-block text-center"
              onClick={() => this.getAdministrators()}
            >
              <i className="fa fa-refresh fa-padding" aria-hidden="true" />
              <span>Atualizar</span>
            </button>
          </div>
        </div>
      );
    }
  }

  renderNewAdminButton() {
    if (!this.state.isLoading) {
      return (
        <div>
          <div className="col-md-12 oq-padding-top">
            <div className="form-group col-md-4">
              <button
                onClick={() => this.goToNewAdminScreen()}
                type="button"
                className="btn btn-block btn-oq btn-oq-lg"
              >
                <i className="fa fa-plus icon-small" aria-hidden="true" />
                &nbsp; Novo Admin
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderSearchButton() {
    return (
      <div>
        <div className="col-md-4 text-center p-2-top p-2-bottom">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.isLoading}
            onClick={() => this.filterAdmins()}
          >
            <i className="fa fa-search fa-padding" aria-hidden="true" />
            <span>{this.state.isLoading ? 'Pesquisando...' : 'Pesquisar'}</span>
          </button>
        </div>
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; Filtros Avançados
            </span>
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isLoading}
                  onChange={(event) => this.handleSearchChange(event)}
                  name="username"
                  placeholder="Nome"
                />
              </div>

              {this.renderSearchButton()}
            </div>
          </div>
        </div>
        {this.renderNewAdminButton()}
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          {this.renderSearchFields()}
          <hr />
          <hr />
          {this.renderAdminsTable()}
        </div>
      </div>
    );
  }
}

const AdministratorsPageLink = () => (
  <Link className="menu-link" to={routes.ADMINS}>
    <FontAwesomeIcon icon={faUnlockAlt} />
    &nbsp;
    <span>Administradores</span>
  </Link>
);

export default AdministratorsPage;

export { AdministratorsPageLink };
